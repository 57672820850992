export default {
    surveys:undefined,
    selected:null,
    surveysResults:{},
    filters:{},
    guideId:null,
    loading: false,
    error: null,
    errorShow: false
}
