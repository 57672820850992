import React, { useContext, useState } from 'react'
import { EntityToExport, ToExcel, ToPdf } from '../../../../utils/exportService'
import { GenericsNames, ZoneNames } from '../../../../translations'
import { printGrid } from '../../../../utils/utils'
import { faPencilAlt, faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { getTranslation, useCustomTranslation } from '../../../../hooks/useTranslations'

import { Column } from 'primereact/column'
import { CustomIconButton } from '../../../../Components/Buttons/CustomIconButton'
import { EvaluaTable } from '../../../../Components/Table/EvaluaTable'
import { FilterMatchMode } from 'primereact/api'
import { TableButton } from '../../../../Components/Buttons/TableButtons'
import ToolBarThreeColumns from '../../../../Components/Table/ToolBarThreeColumns'
import ZoneObj from '../../../../utils/Constanst/FormikConstanst/Zone/ZoneObject'
import ZoneSchema from '../../../../utils/Constanst/FormikConstanst/Zone/ZoneSchema'
import { useFormik } from 'formik'
import { StoreContext } from '../../../../business/Provider'
import { Toast } from '../../../../Components/toast'

export const ZoneGrid = ({
  onEdit,
  onDelete,
  loading,
  rows,
  handleOnAdd,
  selectionMode,
  selection,
  onSelectionChange
}) => {
  const { t } = useCustomTranslation()
  const { dispatchLoading } = useContext(StoreContext)
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const [sort, setSort] = useState([{ field: '', order: -1 }])
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const exportColumns = [{ title: getTranslation(t, ZoneNames.GRID_COLUMN_KEY), dataKey: 'scode' },
  { title: getTranslation(t, ZoneNames.GRID_COLUMN_NAME), dataKey: 'name' },];

  const onGlobalFilterChange = (e) => {
    const { value } = e.target
    const _filters = { ...filters }
    _filters.global.value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }
  const formik = useFormik({
    initialValues: { ...ZoneObj },
    validationSchema: ZoneSchema,
    //onSubmit: addOrUpdateDepartments
  })
  const handleExcelExport = async () => {
    const printObj = { ...filters, sort: sort[0] };

    //exportToExcel(printObj)
    if (rows.length > 0) {
      return ToExcel(printObj, EntityToExport.CATALOG_AREA, dispatchLoading)
    } else {
      Toast('warning', getTranslation(t, GenericsNames.NO_EXPORT_DATA))
    }

  }

  const handleToPDF = async () => {
    const printObj = { ...filters };

    //exportToExcel(printObj)
    if (rows.length > 0) {
      return ToPdf(printObj, EntityToExport.CATALOG_AREA, dispatchLoading)
    } else {
      Toast('warning', getTranslation(t, GenericsNames.NO_EXPORT_DATA))
    }

  }
  const renderHeader = () => (
    <ToolBarThreeColumns
      setValue={setGlobalFilterValue}
      setFilters={setFilters}
      rightChildren={
        <CustomIconButton
          className='!m-1'
          toolTip={getTranslation(t, GenericsNames.ADD)}
          onClick={() => { handleOnAdd() }}
          icon={faPlusCircle}
        />}
      value={globalFilterValue}
      onChange={onGlobalFilterChange}
      formik={formik}
      placeholder={getTranslation(t, GenericsNames.SEARCH)}
      onExcelClick={() => {
        handleExcelExport()
      }}
      onPDFClick={() => {
        handleToPDF()
      }}
      onPrintClick={() => {
        const printObj = { exportableColumns: exportColumns, rows: rows, title: getTranslation(t, ZoneNames.TITLE), subTitle: '' };
        printGrid(printObj);
      }}
    >

    </ToolBarThreeColumns>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <TableButton
          onClick={() => onEdit(rowData)}
          icon={faPencilAlt}
          toolTip={getTranslation(t, GenericsNames.EDIT)}
        />
        <TableButton
          onClick={() => onDelete(rowData)}
          icon={faTrashAlt}
          toolTip={getTranslation(t, GenericsNames.DELETE)}
        />
      </>
    )
  }
  return (
    <div className='datatable-doc-demo'>
      <div className='card'>
        <EvaluaTable
          setSort={setSort}
          sort={sort}
          value={rows}
          sortField='name'
          header={renderHeader()}
          filters={filters}
          //filterDisplay='row' // input search
          responsiveLayout='scroll'
          globalFilterFields={['scode', 'name']}
          emptyMessage={getTranslation(t, ZoneNames.EMPTY_ZONE)}
          currentPageReportTemplate={getTranslation(t, ZoneNames.GRID_PAGE_REPORT_TEMPLATE)}
          doubleClick={onEdit}
          // height={height}
          selectionMode={selectionMode}
          selection={selection}
          onSelectionChange={onSelectionChange}
          columnResizeMode="fit"
          loading={loading}
        >
          {selectionMode &&
            <Column
              selectionMode="multiple"
              //headerStyle={{ width: "5px" }}
              style={{ flexGrow: 1, flexBasis: '100px' }}
            ></Column>

          }
          <Column
            field='scode'
            header={getTranslation(t, ZoneNames.GRID_COLUMN_KEY)}
            sortable
            style={{ flexGrow: 1, flexBasis: '100px' }}
          />
          <Column
            field='name'
            header={getTranslation(t, ZoneNames.GRID_COLUMN_NAME)}
            sortable
            style={{ flexGrow: 1, flexBasis: '100px' }}

          />

          <Column
            header={getTranslation(t, ZoneNames.GRID_ACTION)}
            headerStyle={{ width: '4rem', textAlign: 'center' }}
            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
            body={actionBodyTemplate}
            style={{ flexGrow: 1, flexBasis: '100px' }}
          />
        </EvaluaTable>
      </div>
    </div>
  )
}