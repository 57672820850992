import React, { useContext } from 'react'
import { StoreContext } from '../../../business/Provider'
import { BaseButton } from '../../../Components/Buttons/BaseButton'
import { StepsMenu } from '../../../Components/Wizard/stepsMenu'
import { getTranslation, useCustomTranslation } from '../../../hooks/useTranslations'
import { GenericsNames } from '../../../translations'
import SidePanel from './sidePanel'

export const Wizard = ({ children, title, handleSubmit, steps, activeStep, setActiveStep, handleNext, handleBack, disabled = false }) => {
    const {importErrorState, dispatchImportError} = useContext(StoreContext);
    const { t } = useCustomTranslation();
    return (
        <div className="grid grid-cols-12 gap-x-1 flex-grow shadow-sideMenu-box-shadow border border-border">
            <div className="col-span-2">
                <StepsMenu
                    textSave={getTranslation(t, GenericsNames.SAVE)}
                    textCancel={getTranslation(t, GenericsNames.CANCEL)}
                    title={title}
                    maxWidth={"lg"}
                    steps={steps}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                />
            </div>
            <div className={`${!importErrorState.show ? 'col-span-8' : 'col-span-10'} flex flex-col`}>
                {children}
                <div className='flex justify-end py-1 mr-2  gap-x-1'>
                    {activeStep > 0 &&
                        <BaseButton type='submit' onClick={handleBack} label={getTranslation(t, GenericsNames.BACK)} icon="pi pi-angle-left" />
                    }
                    {activeStep === steps.length - 1 ?
                        <BaseButton disabled={disabled} type='submit' onClick={handleSubmit} label={getTranslation(t, GenericsNames.END)} icon="pi pi-check" />
                        :
                        <BaseButton disabled={disabled} type='submit' onClick={handleNext} iconPos="right" label={getTranslation(t, GenericsNames.NEXT)} icon="pi pi-angle-right" />
                    }

                </div>
            </div>
            <div className={`${!importErrorState.show ? 'col-span-2' : 'hidden'}`}>
               <SidePanel importErrorState={importErrorState} dispatchImportError={dispatchImportError}/>
            </div>
        </div>

    )
}