import * as yup from 'yup'

import { GenericsNames, PositionNames, WorkCenterNames } from '../../../../translations'

import { regexDescription } from '../../../utils'

// import { PositionNames, WorkCenterNames } from '../../../../../translations'

const WorkCenterSchema = yup.object().shape({
  scode: yup // licenseId: DataTypes.UUID,
    .string('')
    .required(WorkCenterNames.VALIDATOR_ID_REQUIRED)
    .matches(/^[0-9]+$/, WorkCenterNames.VALIDATOR_DIGIT_ALLOW)
    .min(4, WorkCenterNames.VALIDATOR_ID_REQUIRED_SIZE)
    .max(4, WorkCenterNames.VALIDATOR_ID_REQUIRED_SIZE)
    .test(
      'greaterThanZero',
      PositionNames.VALIDATOR_ID_GREATER_THAN_ZERO,
      (value) => parseInt(value, 10) > 0
    ),
  name: yup
    .string('')
    .required(WorkCenterNames.VALIDATOR_NAME_REQUIRED)
    // .min(4, 'El código postal debe tener al menos 5 caracteres')
    .max(100, WorkCenterNames.VALIDATOR_NAME_REQUIRED_SIZE)
    .matches(regexDescription, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS),
  zipCode: yup // zipCode: DataTypes.INTEGER,
    .string('')
    .required(WorkCenterNames.VALIDATOR_ZIPCODE_REQUIRED)
    .nullable()
    .min(5, WorkCenterNames.VALIDATOR_ZIPCODE_MIN)
    .max(5, WorkCenterNames.VALIDATOR_ZIPCODE_MIN)
    .matches(/^[0-9]+$/, WorkCenterNames.VALIDATOR_DIGIT_ALLOW),
  state: yup // state: DataTypes.STRING,
    .string()
    .required(WorkCenterNames.VALIDATOR_STATE_REQUIRED),
  municipality: yup //  municipality: DataTypes.STRING,
    .string('')
    .required(WorkCenterNames.VALIDATOR_MUNICIPALITY_REQUIRED)
    .max(50, WorkCenterNames.VALIDATOR_MUNICIPALITY_MAX),
  street: yup // street: DataTypes.STRING,
    .string('').required(WorkCenterNames.VALIDATOR_STREE_REQUIRED)
    .max(100, WorkCenterNames.VALIDATOR_STREET_MAX),
  exteriorNumber: yup.string()
    .max(30, WorkCenterNames.VALIDATOR_EXTERIOR_MAX)
    .required(WorkCenterNames.VALIDATOR_EXTERIOR_REQUIRED)
    .matches(regexDescription, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS).nullable(),
  interiorNumber: yup.string()
    .max(30, WorkCenterNames.VALIDATOR_INTERIOR_MAX)
    .matches(regexDescription, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS).nullable(),
  suburb: yup.string().max(50, WorkCenterNames.VALIDATOR_SUBURB_MAX).required(WorkCenterNames.VALIDATOR_SUBURB_REQUIRED).nullable(),
  principalActivity: yup.string()
    .max(255, WorkCenterNames.VALIDATOR_PRINCIPAL_ACTIVITY)
    .nullable(),
  objective: yup.string().max(255, WorkCenterNames.VALIDATOR_PRINCIPAL_ACTIVITY).nullable(),
  activities: yup.string().max(255, WorkCenterNames.VALIDATOR_PRINCIPAL_ACTIVITY).nullable(),
  //evaluatorId: yup.string('').required(WorkCenterNames.VALIDATOR_EVALUATOR_ID),
  // responsableId:string.uuid('WORK_CENTER_VALIDATOR_RESPONSABLE_ID')
})
export default WorkCenterSchema
