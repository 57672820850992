export const initialStates = {
    states: undefined,
    loading: false,
    error: null,
    errorShow: false
}
export const initialCities = {
    cities: undefined,
    loading: false,
    error: null,
    errorShow: false
}
export const initialSuburbs = {
    suburbs: undefined,
    loading: false,
    error: null,
    errorShow: false
}
        