import React from 'react'
import { Knob } from 'primereact/knob';
export const MigrateComponent = ({ listCatalogsToSend = [] }) => {
  console.log('listCatalogsToSend', listCatalogsToSend)

  //generate value int random between 60 and 100
  const generateValue = () => {
    return Math.floor(Math.random() * (100 - 60 + 1)) + 60;
  }



  return (
    <div className='flex absolute top-0 right-0 bottom-0 left-0  overflow-auto flex-wrap'>
      {listCatalogsToSend.map((catalog, index) => {
        return (
          <div key={index} className="flex w-1/4 h-1/2  flex-col">
            <div className="flex w-full h-[10%] justify-center content-center items-center">
              <h5 className="font-bold">{catalog.name}</h5>
            </div>
            <div className="flex w-full h-[90%] justify-center content-center items-center p-4">
              <Knob readOnly size="small" className='!flex !w-full !h-full' value={generateValue()} valueTemplate={"{value}%"} valueColor={"blue"} rangeColor={"gray"} />
            </div>
          </div >
        )
      })}

    </div >
  )
}