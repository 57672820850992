import { Form, Formik } from 'formik';
import React, { Fragment, useState } from 'react'

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FullLoading } from '../FullLoading';
import { ProgressSpinner } from 'primereact/progressspinner';
import PropTypes from 'prop-types';
import { TabMenu } from 'primereact/tabmenu';

export const DialogWithTab = ({
  onClose,
  open,
  formik,
  title,
  textSave,
  textCancel,
  onSave,
  items,
  sendDataLoading,
  activeTab,
  setActiveTab,
  disabled = false
}) => {
 
  const checkValidationsFields=async ()=>{
    const validationErrors = await formik.validateForm();
    if (Object.keys(validationErrors).length > 0) {
      //Filter the error that belongs to the step.
        const keysError=Object.keys(validationErrors);
        for (let index = 0; index < keysError.length; index++) {
            const keyError = keysError[index];
            for (let indexItems = 0; indexItems < items.length; indexItems++) {
                const item = items[indexItems];
                if(_.find(item.validationFields,function(field) { return field === keyError; }))
                {
                    item.validationFields.forEach(element => formik.setFieldTouched(element, true));
                    setActiveTab(indexItems);
                    return true;
                } 
            }
        }
    }
    return false;
  }
  const executeSubmit=async()=>{
        const hasError=await checkValidationsFields();
        if(!hasError){         
          // console.log('onSave', formik.values);
          if (onSave) {
            onSave(formik.values);
          } else {
            formik.handleSubmit();
          }
          
        }
  }
  const renderFooter = () => {
    return (
      <div>        
        <Button className="p-button-text" label={textCancel} icon="pi pi-times" onClick={() => onClose()} autoFocus />
        <Button disabled={disabled } type='submit' label={textSave} icon="pi pi-check" onClick={executeSubmit}
        />
      </div>
    );
  }

  const loadingComponent = () => {
    return (
      <div className='absolute opacity-50 z-50 flex w-full top-0 bottom-0 items-center justify-center '>
        <div className={'flex w-full h-full flex-col items-center justify-center p-24'}>
          <p className='text-13 sm:text-20 mb-16'>{'Enviando información'}</p>
          <ProgressSpinner mode="indeterminate" className='flex w-full h-8' ></ProgressSpinner>
        </div>
      </div>
    )
  }
  return (

    <Dialog header={title.toUpperCase()} visible={open} maximizable modal style={{ width: '50vw', }} footer={renderFooter()} onHide={() => onClose()}>

      {sendDataLoading ? loadingComponent() : null}
      <TabMenu model={items} activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)} />    
      <div className='w-full'>
        <Formik
          initialValues={formik.values}
          onSubmit={(e) => {
            e.preventDefault();
            //Overrides Formik, uggly fix for multiple formiks. TODO check later.
            if (onSave) {
              onSave(formik.values);
            } else {
              formik.handleSubmit();
            }
          }}
        >
          <Form>
            {items.map((item, index) => {
              return (index === activeTab ?
                <Fragment key={index}>
                  {item.component}
                </Fragment> : null)
            })}
          </Form>

        </Formik>
      </div>

    </Dialog>

  )
}

DialogWithTab.propTypes = {
  open: PropTypes.bool.isRequired,
  formik: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  title: PropTypes.string.isRequired,
  textSave: PropTypes.string,
  textCancel: PropTypes.string.isRequired,
  maxWidth: PropTypes.string,
  items:PropTypes.array,
  activeTab:PropTypes.number.isRequired
};
