
import axios from "axios"
import { getLocalAccessToken } from "../Auth/auth/token"
import { LOADING_OFF, LOADING_ON } from "../business/constants"
import { Toast } from "../Components/toast"




const baseUri = `${window.REACT_APP_EVALUA_API_URI}/export`

/**
 * Enumerado para los tipos de enumerados permitidos a mandar a excel o PDF.
 * usualmente relacionados con un Grid en pantalla
 */
export const EntityToExport = {
    UNKNOWN: 0,
    /**
     * CATÁLOGO DE AREA
     */
    CATALOG_AREA: 1,
    /**
     * CATÁLOGO DE COLABORADORES
     */
    CATALOG_COLLABORATOR: 2,
    /**
     * CATÁLOGO DE DEPARTAMENTOS
     */
    CATALOG_DEPARTMENT: 3,
    /**
     * CATALOGO DE EVALUADORES
     */
    CATALOG_EVALUATOR: 4,
    /**
     * CATÁLOGO DE POSICIONES
     */
    CATALOG_POSITION: 5,
    /**
     * CATÁLOGO DE CENTROS DE TRABAJO
     */
    CATALOG_WORKCENTER: 6,
    /**
     * LISTADO DE ENCUESTAS DE UNA GUÍA
     */
    GUIDE_SURVEYS: 7,
    /**
     * LISTADO DE COLABORADORES EN UNA ENCUESTA
     */
    GUIDE_SURVEYS_COLLABORATORS: 8,
    /**
     * LISTADO DE PREGUNTAS DE UNA GUÍA
     */
    GUIDE_QUESTIONS: 9,
    /**
     * RANGOS DE EVALUACIÓN DE UNA GUÍA
     */
    GUIDE_EVALUATION_RANGES: 10,
    /**
     * REPORTE DE RESULTADOS PARA UN SOLO COLABORADOR-ENCUESTA
     */
    RESULT_BY_COLLABORATOR: 11,
    /**
     * REPORTE GENERAL DE RESULTADOS PARA UNA ENCUESTA GENERAL, POR CENTRO DE TRABAJO, AREA, DEPARTAMENTO, ETC.
     */
    RESULT_BY_SURVEY: 12,
    /**
     * REPORTE DE RESULTADOS PARA UN SOLO COLABORADOR-ENCUESTA GUÍA 1 SOLAMENTE
     */
    RESULT_BY_COLLABORATOR_G1: 13,
    /**
    * REPORTE DE CONSULTAS
    * */
    QUERY: 14,

    /*
    REPORTE DE GUIA 1
    */
    GUIDE_SURVEYS_GUIDE_ONE: 15,

}


const downloadFile = async (url, method, data = {}, params = {}) => {
    const accessToken = getLocalAccessToken();

    // const response = await axios({
    //     method,
    //     url,
    //     data,
    //     params,
    //     responseType: 'blob',
    //     headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${accessToken}`
    //     },
    // });
    // //validate response
    // if (response.status === 200) {
    //     return response
    // } else {
    //     //throw error
    //     throw new Error(response.data.message)
    // }
    return axios({
        method,
        url,
        data,
        params,
        responseType: 'blob',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
    })
        .then(response => {
            return response
        })
        .catch(error => {
            if (error.response.status === 401) {
                Toast('warning', 'Sesión expirada', 'la pagina se actualizara para que volver a ver informacion')
                setTimeout(() => {
                    window.location.reload()
                }, 3000)
            }
        })
}




/**
 * Este metodo manda a llamar al API para generar el archivo a exportar
 * @param {*} params - se espera un objeto que contenga el filtro a aplicar para generar el archivo 
 * @param {*} entity - tipo de entidad definido en el enumerado EntityToExport
 * @returns descarga 
 */
export const ToExcel = async (params, entity = EntityToExport.UNKNOWN, dispatchLoading = () => { }) => {

    try {
        dispatchLoading({ type: LOADING_ON })
        const resp = await downloadFile(baseUri, 'POST', params, { to: 'XLSX', entity: entity });
        dispatchLoading({ type: LOADING_OFF })


        import('file-saver').then(module => {
            if (module && module.default) {

                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
                let fileName = resp.headers["x-suggested-filename"];
                const data = new Blob([resp.data], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName);
            }
        });

    } catch (err) {
        dispatchLoading({ type: LOADING_OFF })
        console.error('exportService.ToExcel', err);
        return undefined;

    }
}

export const ToPdf = async (params, entity = EntityToExport.UNKNOWN, dispatchLoading = () => { }) => {

    //opcion a evaluar: https://github.com/elwerene/libreoffice-convert#readme
    try {
        dispatchLoading({ type: LOADING_ON })
        const resp = await downloadFile(baseUri, 'POST', params, { to: 'PDF', entity: entity });
        dispatchLoading({ type: LOADING_OFF })
        import('file-saver').then(module => {
            if (module && module.default) {
                let PDF_TYPE = 'application/pdf;charset=UTF-8'
                let fileName = resp.headers["x-suggested-filename"];
                const data = new Blob([resp.data], {
                    type: PDF_TYPE
                });

                module.default.saveAs(data, fileName);
            }
        });

    } catch (err) {
        dispatchLoading({ type: LOADING_OFF })
        console.error('exportService.ToPdf', err);
        return undefined;

    }

}

export const print = (params, entity = EntityToExport.UNKNOWN) => {
    var newWin = window.open('')
    //newWin.document.head.appendChild(getStylesTable())
    newWin.document.body.innerHTML = "FALTA IMPLEMENTAR ALGO PARA IMPRIMIR";
    //newWin.focus()
    newWin.print()
    //setTimeout(newWin.close, 5);

}