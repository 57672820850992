import { faCheck, faCheckCircle, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

import { ButtonTextWithIcon } from '../Buttons/ButtonTextWithIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'

//import { ButtonTextWithIcon } from './../Buttons/ButtonTextWithIcon';

export const AlertComponentModal = ({ showAlert, actionButton, buttonActionText, validations, textBody, type, showFinallyAlert, setShowFinallyAlert, disableButton = false }) => {
	const border = type === 1 ? 'border border-red-100' : 'border border-green-100'
	const w = ' w-60 text-xs  sm:w-200 md:text-base lg:text-base xl:text-base 2xl:text-base lg:w-200 xl:w-200 2xl:w-200'
	const h = ' h-60 sm:h-h-80 md:h-80 lg:h-80 xl:h-80 2xl:h-80'
	const hIcon = ' h-20 sm:h-36 md:h-36 lg:h-36 xl:h-36 2xl:h-36'
	const wIcon = ' w-20 sm:w-36 md:w-36 lg:w-36 xl:w-36 2xl:w-36'

	return (
		<div style={{ zIndex: 50 }}>
			{/* add this line if you want to hide the layers behind  bg-white bg-opacity-100*/}
			<div className={`absolute  flex w-full top-0 bottom-0 items-center justify-center `}>
				<div className={'flex w-full h-full flex-col items-center justify-center p-24'}>
					<div
						className={"absolute  mx-auto  border   shadow-lg rounded-md bg-white z-51 " + w + h}
					>
						<div className="flex h-full w-full flex-col content-center justify-center items-center">
							<div className={"flex w-full h-1/5 items-center justify-center content-center border-b-1 border-grey-500 " + hIcon + wIcon}>
								<div
									className={"  flex items-center justify-center content-center rounded-full " + border}
								>
									{type === 2 || type === 3 ?

										<FontAwesomeIcon className='!text-green' size='2xl' icon={faCheckCircle} title={'check'} />
										:
										<FontAwesomeIcon size='2xl' className='!text-red' icon={faXmarkCircle} title={'check'} />
									}
								</div>
							</div>
							<div className="flex h-3/5 w-full content-center justify-center items-center flex-col ">
								<h3 className="text-sm sm:text-base md:text-base lg:text-base xl:text-base 2xl:text-base leading-6 font-medium text-gray-900">
									{type === 1 ? 'Error' : type === 2 ? 'Bienvenido a la evaluación.' : '¡Felicidades!'}</h3>
								<div className="mt-2 px-7 py-3">
									{validations ? validations.map((validation, index) => {
										return (
											<p key={index} className="text-10 md:text-base lg:text-base xl:text-base 2xl:text-base  text-gray-500">
												{validation}
											</p>
										)
									}) : <p className="text-10 md:text-base lg:text-base xl:text-base 2xl:text-base text-gray-500">
										{textBody}
									</p>}
								</div>
							</div>
							<div className={"flex h-1/5  items-center justify-center content-center " + (showFinallyAlert ? 'w-full' : '')}>


								<div className="flex justify-between h-full w-full px-4 py-3">
									<ButtonTextWithIcon
										onClick={() => actionButton()}
										title={buttonActionText}
										type='primary'
										disabled={disableButton}
										Icon={<FontAwesomeIcon icon={faCheck} title={'anterior'} />}
										position={'left'}
										className={'mr-5'}
									/>

									{showFinallyAlert ? <ButtonTextWithIcon
										onClick={() => setShowFinallyAlert()}
										title={'cancelar'}
										type='warning'
										disabled={disableButton}
										Icon={<FontAwesomeIcon icon={faXmark} title={'anterior'} />}
										position={'left'}
										className={'mr-5'}
									/> : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}