import React, { Fragment } from 'react';
import {
	useCustomTranslation,
	getTranslation,
} from '../../../hooks/useTranslations';
import { SurveysQuestionsNames } from '../../../translations';
import { SurveyItem } from './SurveyItem';
export const ListContainerSurvey = ({
	questionType,
	questions,
	sectionStep,
	validateForm,
	sendData,
	setValueRadio,
	isSelect,
	collaboratorData,
}) => {
	const { t } = useCustomTranslation();
	const renderHeader = questionType === 1 ? ' h-[90%] ' : ' h-[80%] ';

	const names =
		collaboratorData.questionaryId > 1
			? [
				SurveysQuestionsNames.SurveyAlwaysText,
				SurveysQuestionsNames.SurveyAlmostAlwaysText,
				SurveysQuestionsNames.SurveySometimesText,
				SurveysQuestionsNames.SurveyAlmostNeverText,
				SurveysQuestionsNames.SurveyNeverText,
			]
			: [
				SurveysQuestionsNames.SurveyYesText,
				SurveysQuestionsNames.SurveyNoText,
			];

	const renderHeaderQuestions = () => {
		return (
			<div className=' flex-row w-full hidden  md:h-[10%]  lg:h-[10%]  xl:h-[10%]  2xl:h-[10%]  md:flex lg:flex :xl:flex 2xl:flex'>
				<div className='flex h-full w-[55%] ' />
				<div className='flex h-full w-[45%] gap-x-1 '>
					{names.map((item, index) => {
						return (
							<div
								title={item}
								key={index}
								className='flex h-full text-xxs xl:text-xs w-1/5 font-bold text-center border-b-2 border-r-2 justify-center items-center'>
								{getTranslation(t, item)}
							</div>
						);
					})}
				</div>
			</div>
		);
	};

	const convertToObject = (str) => {
		return JSON.parse(str);
	};

	const renderSurvey = (item, index, sectionId, indexSection) => {
		return (
			<SurveyItem
				item={item}
				index={index}
				sectionId={sectionId}
				indexSection={indexSection}
				validateForm={validateForm}
				sendData={sendData}
				questionType={questionType}
				setValueRadio={setValueRadio}
				isSelect={isSelect}
				questionaryId={collaboratorData.questionaryId}
			/>
		);
	};

	// const renderHeaderValidation = questionType === 1 ? null : renderHeaderQuestions()
	return (
		<div className='flex flex-grow w-full  flex-col '>
			{questions.map((item, indexSection) => {
				const indexStep = indexSection + 1;
				return indexStep === sectionStep ? (
					<div
						className='flex w-full flex-col h-full relative '
						key={indexStep}>
						<div className='flex w-full h-[10%]  lg:h-[10%] xl:h-[10%] 2xl:h-[10%] bg-[#cae6fc] opacity-95 items-center content-center justify-center'>
							<p className='font-bold text-center text-xxs   lg:text-sm xl:text-base 2xl:text-base '>
								{item.sectionHtml}
							</p>
						</div>
						{renderHeaderQuestions()}
						<div
							className={
								'flex w-full h-[90%]  relative md:h-[80%] lg:h-[80%] xl:h-[80%] 2xl:h-[80%]'
							}>
							<div className='flex flex-grow top-0 bottom-0 right-0 left-0 overflow-auto absolute flex-col p-1 gap-y-1 border-separate  '>
								{item.questionsList.map((questionItem, indexQuestion) => {
									const ValidateDependency = convertToObject(
										questionItem.dependencySettings
									).dependencyId;
									const questionItemData = item?.questionsList?.filter(
										(itemFilter) => itemFilter.questionId === ValidateDependency
									)[0];
									const value = questionItemData?.responses?.filter(
										(itemFilter) =>
											itemFilter.optionValueId ===
											questionItemData.questionValue
									)[0]?.value;
									const validateComponentByDependency =
										questionItemData?.questionValue !== '' && value !== 0 ? (
											<Fragment key={indexQuestion}>
												{renderSurvey(
													questionItem,
													indexQuestion,
													item.sectionId,
													indexSection
												)}
											</Fragment>
										) : null;
									return ValidateDependency === null ? (
										<Fragment key={indexQuestion}>
											{renderSurvey(
												questionItem,
												indexQuestion,
												item.sectionId,
												indexSection
											)}
										</Fragment>
									) : (
										validateComponentByDependency
									);
								})}
							</div>
						</div>
					</div>
				) : null;
			})}
		</div>
	);
};
