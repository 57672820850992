export default {
    surveys:{},
    filter:{
        filterList: [],
        filters: {}
    },
    additionalData:{},
    surveysDetails:[],
    surveysResults:[],
    guideId:null,
    loading: false,
    error: null,
    errorShow: false
}