import React, { useContext } from 'react'
import { getTranslation, useCustomTranslation } from '../../../hooks/useTranslations'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavigationContext } from '../../../context/navigationContext';
import { faCircleXmark } from '@fortawesome/pro-thin-svg-icons'
import { useHover } from '../../../hooks/useHover';

export const BreadCrumbItem = ({ item, close, redirect, closeBeforeAction }) => {
    const { t } = useCustomTranslation();
    const navigation = useContext(NavigationContext)
    const border = navigation.currentTap?.url === item.url ? 'border-b-4 border-black' : ''
    const color = navigation.currentTap?.url === item.url ? '' : 'opacity-50 hover:border-b-4 hover:border-black'
    const [hoverRef, isHovered] = useHover();
    //if item.tapTitle is string, then it is a link

    const tapName = typeof item.tapTitle === 'string' || item.tapTitle instanceof String ? getTranslation(t, item.tapTitle) : ''
    return (
        // eslint-disable-next-line react/no-unknown-property
        <li className={`w-44 h-full mr-3 flex hover:cursor-pointer font-font-family-base ${border} ${color}`} ref={hoverRef} >
            <div className={`w-10/12 mt-2 ml-1 mr-2 !text-black text-code-font-size truncate text-center`} onClick={() => redirect(item.url)}>
                <p>{getTranslation(t, item.father) + ' ' + tapName}</p>
            </div>
            <button className='z-10' onClick={() => { close(item.url, item) }}>
                <FontAwesomeIcon className={`!text-black ${isHovered ? '' : 'hidden'}`} icon={faCircleXmark} />
            </button>
        </li>
    )
}