import { getAccessToken, getLocalAccessToken } from "../Auth/auth/token"

import axios from "axios"
import { setUserData } from "../Auth/auth/store/userSlice";
import { useMsal } from "@azure/msal-react";
import utf8 from 'utf8';

// exeption object
function ExceptionApi(message, status = 500) {
    this.status = status;
    this.message = message;
    this.name = "ExceptionApi";
}
export default function useApi() {
    const { instance, accounts, inProgress } = useMsal();
    // generic api call
    const genericApiCall = async (url, method, data = {}, params = {}, headers = {}, attemp = 0) => {
        const accessToken = getLocalAccessToken();
        const ses = sessionStorage.getItem('sessionState');
        const combinedHeaders = {
            ...headers,
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'sessionState': utf8.encode(ses)
        };
        return axios({
            method,
            url,
            data,
            params,
            headers: combinedHeaders,
        }).then((res) => {
            return res;
        }).catch(async (error) => {
            if (attemp > 2) {//Magic number..3 intentos de obtener token correcto.. 
                throw new ExceptionApi(error?.response?.data?.error, error.response.status);
            }
            if (error.response.status === 401) {
                const token = await getAccessToken({ instance, accounts })
                //set token in local storage 
                setUserData(token);
                //retry the request
                const count = attemp + 1;
                return genericApiCall(url, method, data, params, headers, count)
            } else if (error.response.status === 410) {
                console.log('error 410 encontrado:', error, error.response)
                let response = new ExceptionApi(error.response.error);
                if (error?.response?.data?.error) response = new ExceptionApi(error.response.data.error, error.response.status);
                throw response
            } else if (error.response.status === 500) {

                console.log('error 500 encontrado:', error.response)
                let response = new ExceptionApi(error.response.error);
                if (error?.response?.data?.error) response = new ExceptionApi(error.response.data.error, error.response.status);
                if (error?.response?.data?.data?.message) response = new ExceptionApi(error?.response?.data?.data?.message, error.response.status);
                if (error?.response?.data?.message) response = new ExceptionApi(error.response.data.message, error.response.status);
                // if text have next lines delete them 
                response.message = response.message.replace('Error add ocurred SequelizeValidationError: Validation error:', '')
                throw response
            }
            else {
                console.log('error encontrado:', error, error.response)

                let response = new ExceptionApi("Hubo un problema al obtener información del servidor. Intente más tarde");

                if (error?.response?.data?.message) response = new ExceptionApi(error?.response?.data?.message, error?.response?.data?.status);
                if (error?.response?.data?.data?.message) response = new ExceptionApi(error?.response?.data?.data?.message, error.response.status);
                if (error?.response?.data?.error) response = new ExceptionApi(error?.response?.data?.error, error.status);
                throw response
            }
        });
    }
    return {
        genericApiCall,
    }

}
