
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { CLEAR_IMPORT_ERROR_LIST } from '../../../business/constants';
import { StoreContext } from '../../../business/Provider';

const SidePanel = ({importErrorState, dispatchImportError} ) => {
    const action = () => {
        dispatchImportError({type: CLEAR_IMPORT_ERROR_LIST})
    }
  return (
    <aside className={`relative border-l border-[#f4f7fc] h-full text-xs font-normal font-input-font-family ${importErrorState.show ? 'hidden' : ''}`}>
        <button className='z-10 p-1 absolute' onClick={()=> action() }>
            <FontAwesomeIcon icon={faXmark} />
        </button>
        <header className='pt-2 w-full text-center'>
            <h2>Errores de {importErrorState?.data}</h2>
        </header>
        <ul className='p-1'>
            {importErrorState?.error.map((error, index) => (
                <li key={index}>{error}</li>
            ))}
        </ul>
    </aside>
  );
}
export default SidePanel;