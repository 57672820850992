import React from 'react'
import PropTypes from 'prop-types'
import './styles.css'
import { Button } from 'primereact/button';
import { btn, btnDanger, btnDangerOutline, btnDisable, btnInfo, btnInfoOutline, btnPrimary, btnPrimaryOutline, btnSecondary, btnSecondaryOutline, btnSuccess, btnSuccessOutline, btnWarning, btnWarningOutline } from '../../assets/customStyles'
// comments about props
// onClick: function
// text: string (text to display)
// icon: html element (icon to display) ex: <FontAwesomeIcon icon={faPlus} />
// type: string (primary, secondary, success, info, warning, danger)
// disabled: boolean
export const ButtonTextWithIcon = ({ onClick, title, Icon, type, outline = false, disabled = false, position, className, ...props }) => {
    // switch the type of the button
    let btnType = '';
    switch (type) {
        case 'primary':
            btnType = outline ? btnPrimaryOutline : btnPrimary;
            break;
        case 'secondary':
            btnType = !outline ? btnSecondary : btnSecondaryOutline;
            break;
        case 'success':
            btnType = !outline ? btnSuccess : btnSuccessOutline;
            break;
        case 'info':
            btnType = !outline ? btnInfo : btnInfoOutline;
            break;
        case 'warning':
            btnType = !outline ? btnWarning : btnWarningOutline;
            break;
        case 'danger':
            btnType = !outline ? btnDanger : btnDangerOutline;
            break;
        default:
            btnType = outline ? btnPrimaryOutline : btnPrimary;
            break;
    }
    const content = (
        position === 'left' ? (
            <>
                {Icon}
                {title}
            </>
        ) : (
            <>
                {title}
                {Icon}
            </>
        )
    )

    return (
        <Button disabled={disabled} onClick={onClick} className={`${btn}  ${btnType} ${btnDisable} gap-x-1 ${className}`} {...props}> {content}  </Button>
    )
}

ButtonTextWithIcon.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string,
    Icon: PropTypes.element,
    type: PropTypes.oneOf(['primary', 'secondary', 'success', 'info', 'warning', 'danger']),
    outline: PropTypes.bool,
    disabled: PropTypes.bool,
    position: PropTypes.oneOf(['left', 'right']),
    className: PropTypes.string
}

ButtonTextWithIcon.defaultProps = {
    onClick: () => { },
    title: '',
    Icon: null,
    type: 'primary',
    outline: false,
    disabled: false,
    position: 'left',
    className: ''
}