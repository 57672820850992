import {GET_SCORE_RANGE, GET_SCORE_RANGE_ERROR, GET_SCORE_RANGE_SUCCESS} from '../../constants';

export const scoreRanges = (state, actions) => {
    switch (actions.type) {
        case GET_SCORE_RANGE:
            return {
                ...state,
                loading: true,
            }
        case GET_SCORE_RANGE_ERROR:
            return {
                ...state,
                loading: false,
                error: actions.payload,
                errorShow: false
            }
        case GET_SCORE_RANGE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                errorShow: false,
                scoreRanges: {
                    ...state.scoreRanges,
                    [actions.payload.id]: actions.payload.data
                },
            }
        default:
            return state;
    }
}