import React from 'react';

export const FinallyComponent = () => {
  return (
    <div className="flex text-xs lg:text-sm xl:text-sm 2xl:text-sm h-[80%] w-[90%] lg:w-3/5 xl:w-3/5 2xl:w-3/5 lg:h-2/5 xl:h-2/5 2xl:h-2/5  shadow-2xl border font-font-family-base text-font-size-base ">
      <div className="w-full h-full bg-white flex flex-col justify-center content-center items-center">
        <div className="w-full h-1/5 text-center flex flex-col justify-center content-center items-center text-modal-title-font-size">
          <h1>{'Gracias por contestar la evaluación.'}</h1>
        </div>
        <div className="w-full h-3/5 flex flex-col lg:p-10 xl:p-10 2xl:p-10">
          <div className="flex w-full h-full flex-col !bg-[#cae6fc]  ">
            <div className="flex w-full h-1/2 content-center items-center justify-center">
              <h6 className="text-green">{'Registro realizado exitosamente.'}</h6>
            </div>
            <div className=" w-full h-1/2 content-center items-center justify-center">
              <p className=" text-sm text-center">
                {' Muchas gracias por su colaboración. Sus respuestas han sido enviadas y su participación en la evaluación se ha registrado satisfactoriamente.'}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full h-1/5 flex flex-col justify-center content-center items-center">
          <p className=" text-sm text-center">
            {'Este mensaje es para confirmar que ha completado el cuestionario. Desde este momento puede cerrar la ventana.'}
          </p>
        </div>
      </div>
    </div>
  );
};
