import React, { Fragment, useEffect, useState, useRef } from 'react';
import {
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import { AlertComponentModal } from '../Modal';
import { ButtonTextWithIcon } from '../Buttons/ButtonTextWithIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { AlertComponentModal } from '../Modal';
// import { getLocalAccessToken } from 'app/auth/token';
import { Toast } from './../toast/index';
import axios from 'axios';
import { getLocalAccessToken } from '../../Auth/auth/token';
import { SurveyItem } from './components/SurveyItem';
import { ContentHeaderComponent } from './components/ContentHeader';
import { ListContainerSurvey } from './components/ListContainerSurvey';
import {
	useCustomTranslation,
	getTranslation,
} from '../../hooks/useTranslations';
import { SurveysQuestionsNames } from '../../translations';

export const NewSurveyComponent = ({ setStep, collaboratorData }) => {
	const { t } = useCustomTranslation();
	const [questions, setQuestions] = useState([]);
	const [sectionStep, setSectionStep] = useState(1);
	const [showAlert, setShowAlert] = useState(true);
	const [validateForm, setValidateForm] = useState(false);
	const [sendData, setSendData] = useState(false);
	const [showFinallyAlert, setShowFinallyAlert] = useState(false);
	const [validationInternal, setValidationInternal] = useState(false);
	const [disableSaveButton, setDisableButton] = useState(false);
	const [questionType, setQuestionType] = useState(1);
	const [isSelect, setIsSelect] = useState(null);

	useEffect(() => {
		const hostProd = `${window.REACT_APP_EVALUA_API_URI}`;
		const baseUrl = hostProd + '/surveysResponse/surveys/surveysQuestions/';
		if (collaboratorData !== undefined || collaboratorData !== null) {
			const id = collaboratorData.questionaryId;
			return axios
				.get(baseUrl + id, {
					headers: {
						'Content-Type': 'application/json',
					},
				})
				.then((res) => {
					const { questionary } = res.data.data;
					if (questionary.length > 0) {
						setQuestions(questionary);
						setQuestionType(questionary[0].questionsList[0].questionTypeId);
						setIsSelect(questionary[0].questionsList[0].questionId);
					}
				})
				.catch((err) => {
					console.log('err', err);
				});
		}
	}, [collaboratorData]);

	useEffect(() => {
		let questionValueDependency = null;
		if (questions.length > 0) {
			const newSectionStep = sectionStep < 0 ? 1 : sectionStep;
			const index = newSectionStep - 1;

			const getSection = questions[index];
			const { questionsList } = getSection;
			// console.log('questionsList ----------------------', questionsList[0].questionId)
			setIsSelect(questionsList[0].questionId);
			setQuestionType(questionsList[0].questionTypeId);
			questionsList.map((item) => {
				const ValidateDependency = convertToObject(
					item.dependencySettings
				).dependencyId;
				if (ValidateDependency !== null) {
					questionValueDependency = ValidateDependency;
				}
			});
			if (
				questionValueDependency !== 0 ||
				questionValueDependency !== undefined ||
				questionValueDependency !== null
			) {
				questions.map((item) => {
					item.questionsList.map((questionItem) => {
						if (questionItem.questionId === questionValueDependency) {
							if (
								questionItem.responseValue !== undefined &&
								questionItem.responseValue === 0
							) {
								if (questions.length === sectionStep) {
									sentToData(true);
								} else {
									setSectionStep(sectionStep + 1);
								}
							}
						}
					});
				});
			}
		}
	}, [sectionStep]);
	// conver {dependencyId:NULL} to object
	/**
	 * It converts a string to an object.
	 * @returns the result of the JSON.parse() method.
	 */
	const convertToObject = (str) => {
		return JSON.parse(str);
	};

	const renderContentHeader = () => {
		return <ContentHeaderComponent collaboratorData={collaboratorData} />;
	};

	const textInHtml = `<label>Lea detenidamente cada pregunta. Es muy fácil de responder, en las preguntas se le pide que elija las siguientes opciones: <b>"Siempre"</b>, <b>"Casi Siempre"</b> , <b>"Algunas Veces"</b>, <b>"Casi Nunca"</b>, <b>"Nunca"</b>. Solo tendrá que seleccionar alguna de las opciones para seguir avanzando.<label>`

	const renderInformationContent = () => {
		return (
			<div className='flex w-full h-[30px] md:h-[60px] lg:h-[60px] xl:h-[60px] 2xl:h-[60px] flex-col  md:p-2 lg:p-2 xl:p-2 2xl:p-2 '>
				<div className='flex h-full w-full bg-green-100 opacity-95  justify-center'>
					<p className='text-center text-xs  md:text-sm lg:text-sm xl:text:base 2xl:text-base '>
						{collaboratorData.questionaryId > 1 && (
							// <p> { getTranslation(t, SurveysQuestionsNames.SurveyInfo)} </p>
							<>
								<div
									className='text-xs flex-row text-ellipsis overflow-hidden lg:text-md xl:text-base 2xl:text-base  p-1 hidden lg:flex xl:flex 2xl:flex'
									dangerouslySetInnerHTML={{
										__html: textInHtml
									}}></div>
								<p className='text-xs text-justify p-1 flex lg:hidden xl:hidden 2xl:hidden'><label >{'Selecciona una respuesta a cada pregunta'}</label></p>
							</>
						)}
						{collaboratorData.questionaryId === 1 && (
							<>
								<p className='text-xs hidden lg:flex xl:flex 2xl:flex'>
									{' '}
									{getTranslation(t, SurveysQuestionsNames.SurveyInfo2)}{' '}
								</p>
								<p className='text-xs text-justify p-1 flex lg:hidden xl:hidden 2xl:hidden'>
									<label htmlFor=''>
										{'Selecciona una respuesta a cada pregunta'}
									</label>
								</p>
							</>
						)}
					</p>
				</div>
			</div>
		);
	};
	// convert "{"dependencyId":null}" to object

	/**
	 * I'm trying to set the value of a radio button in a form
	 */
	const setValueRadio = (
		e,
		question,
		sectionId,
		indexSection,
		responseValue
	) => {
		const value = e.target.value;
		if (value !== undefined) {
			const getSection = questions.filter(
				(item) => item.sectionId === sectionId
			)[0];
			const getListQuestions = getSection.questionsList.filter(
				(item) => item.questionId === question.questionId
			)[0];
			getListQuestions.questionValue = parseInt(value);
			getListQuestions['responseValue'] = parseInt(responseValue);
			questions[indexSection] = getSection;
			setSendData(false);
			setValidationInternal(false);
			setValidateForm(false);
			setQuestions([...questions]);
		}
	};

	const renderSurveysList = () => {
		return (
			<ListContainerSurvey
				questionType={questionType}
				questions={questions}
				sectionStep={sectionStep}
				validateForm={validateForm}
				sendData={sendData}
				setValueRadio={setValueRadio}
				isSelect={isSelect}
				collaboratorData={collaboratorData}
			/>
		);
	};

	/**
	 * If the questionValueDependency is not equal to 0, undefined, or null, then map through the
	 * questions array and if the questionItem.questionId is equal to questionValueDependency, then if the
	 * questionItem.questionValue is equal to 1, then set the validateForm to true and
	 * validationInternalForm to true.
	 * @returns the result of the function renderForms(sectionValue)
	 */

	const validationForm = (sectionValue) => {
		let questionValueDependency = 0;
		setSendData(true);
		let validationInternalForm = false;
		const index = sectionStep - 1;
		const getSection = questions[index];
		const { questionsList } = getSection;
		const itemsWithErrors = [];
		questionsList.map((item) => {
			const ValidateDependency = convertToObject(
				item.dependencySettings
			).dependencyId;
			if (item.questionValue === '') {
				// console.log('questionItem.responseValue', item)
				itemsWithErrors.push(item);
				setValidateForm(true);
				// console.log('itemsWithErrors', itemsWithErrors)
				setIsSelect(itemsWithErrors[0].questionId);
				validationInternalForm = true;
				if (ValidateDependency !== null) {
					//
					questionValueDependency = ValidateDependency;
				}
			}
		});
		if (
			questionValueDependency !== 0 ||
			questionValueDependency !== undefined ||
			questionValueDependency !== null
		) {
			questions.map((item) => {
				item.questionsList.map((questionItem) => {
					if (questionItem.questionId === questionValueDependency) {
						if (
							questionItem.responseValue !== undefined &&
							questionItem.responseValue === 1
						) {
							setValidateForm(true);
							validationInternalForm = true;
						} else {
							if (
								questionItem.responseValue === 0 &&
								questionItem.questionValue !== ''
							) {
								setValidateForm(false);
								validationInternalForm = false;
							}
						}
					}
				});
			});
		}
		//Aquí se manda el toast al no responder la pregunta
		const validationFormSend =
			validationInternalForm === true
				? null
				: sectionStep === questions.length
					? sentToData(sectionStep)
					: renderForms(sectionValue);
		return validateForm
			? Toast(
				'error',
				getTranslation(t, SurveysQuestionsNames.SurveyUnanswered)
			)
			: validationInternalForm
				? Toast(
					'error',
					getTranslation(t, SurveysQuestionsNames.SurveyUnanswered)
				)
				: validationFormSend;
	};

	const renderForms = (valueStep) => {
		setSectionStep(valueStep + 1);
		setValidateForm(false);
		setValidationInternal(false);
	};

	const sentToData = (dataStep) => {
		setSectionStep(dataStep);
		if (validateForm === false) {
			setShowFinallyAlert(true);
		}
	};

	const renderBackButton = () => {
		return sectionStep === questions.length ? (
			<div>
				{' '}
				<br />{' '}
			</div>
		) : (
			<ButtonTextWithIcon
				onClick={() => {
					setSectionStep(
						sectionStep === questions.length ? 1 : sectionStep - 1
					);
					setValidateForm(false);
					setValidationInternal(false);
				}}
				title={getTranslation(t, SurveysQuestionsNames.SurveyPreviousButton)}
				type='primary'
				// disabled={!enableButton}
				Icon={<FontAwesomeIcon icon={faChevronLeft} title={'anterior'} />}
				position={'left'}
				className={'mr-5 text-xs w-20'}
			/>
		);
	};

	const renderButton = () => {
		return (
			<div className='flex w-full h-[10%] text-xs content-center items-center justify-between flex-row p-1 '>
				{sectionStep !== 1 ? (showFinallyAlert === true ? null : (renderBackButton())) : (<div>{' '}<br />{' '}</div>)}

				<ButtonTextWithIcon
					className={'text-xs w-20'}
					onClick={() => {
						validationForm(sectionStep);
					}}
					title={getTranslation(t, SurveysQuestionsNames.SurveyNextButton)}
					type='primary'
					// disabled={!enableButton}
					Icon={
						<FontAwesomeIcon
							icon={faChevronRight}
							title={getTranslation(t, SurveysQuestionsNames.SurveyNextButton)}
						/>
					}
					position={'right'}
				/>
			</div>
		);
	};

	const renderContent = () => {
		return (
			<div className='flex w-full h-full flex-col shadow-2xl bg-white relative rounded-lg p-0  md:p-4'>
				{renderContentHeader()}
				{renderInformationContent()}
				{renderSurveysList()}
				{showFinallyAlert ? null : renderButton()}
			</div>
		);
	};

	const postQuestionsAnswers = async (dataEvaluator) => {
		const accessToken = getLocalAccessToken();
		//  console.log("collaboratorData", collaboratorData)
		const baseUrl = `${window.REACT_APP_EVALUA_API_URI}/surveyQuestionAnswers/surveyCollaboratorId/${collaboratorData?.surveyCollaboratorId}`;
		///
		return axios
			.post(baseUrl, dataEvaluator, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
				},
			})
			.then((response) => {
				//  console.log('response data to send', response)
				if (response.data.status === 'success') {
					Toast('success', 'Se ha enviado la información correctamente');
					setDisableButton(true);
					return setStep(3);
				}
			})
			.catch((error) => {
				console.log(error);
				Toast('warning', 'Ha ocurrido un error al enviar la información');
				setDisableButton(false);
			});
	};

	const sendDataToServer = async () => {
		setDisableButton(true);
		const responses = [];
		const questionsList = questions;
		questionsList.map((item) => {
			item.questionsList.map((questionItem) => {
				const dataToPush = {
					AnswerId: questionItem.questionValue,
					QuestionId: questionItem.questionId,
				};
				return responses.push(dataToPush);
			});
		});
		// console.log('----------------------------- ', responses)
		return postQuestionsAnswers(responses); //
	};

	return (
		<>
			{showAlert ? (
				<AlertComponentModal
					actionButton={() => setShowAlert(false)}
					buttonActionText={getTranslation(
						t,
						SurveysQuestionsNames.SurveyButtonStart
					)}
					type={2}
					textBody={
						getTranslation(t, SurveysQuestionsNames.SurveyInfoAlertStart) +
						collaboratorData?.questionaryName
					}
				/>
			) : null}

			{showFinallyAlert ? (
				<AlertComponentModal
					actionButton={() => sendDataToServer()}
					disableButton={disableSaveButton}
					buttonActionText={getTranslation(
						t,
						SurveysQuestionsNames.SurveyButtonSend
					)}
					type={3}
					textBody={getTranslation(t, SurveysQuestionsNames.SurveyInfoAlertEnd)}
				/>
			) : null}

			{showFinallyAlert || showAlert ? <div className='flex w-full h-full shadow-2xl flex-col bg-slate-100'> </div> : <div className='flex w-full h-full shadow-2xl flex-col '>
				<div className='flex w-full h-full  flex-col relative '>
					<div className=' flex h-[10%] w-full   flex-row justify-center items-center content-center border '>
						<h1 className='text-base font-bold pl-4'>
							{collaboratorData?.questionaryName}
						</h1>
					</div>
					<div className=' flex h-[90%] w-full  flex-col  md:p-4 '>
						{renderContent()}
					</div>
				</div>
			</div>}
		</>
	);
};
