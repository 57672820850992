import { useTranslation } from "react-i18next";


export function useCustomTranslation() {
  const [t, i18n] = useTranslation('app');
  return { t, i18n };
}

export function getTranslation(t, key) {
  try {
    return t(key, { returnObjects: true });
  } catch (e) {
    return '¡¡¡¡----Not Text---- !!!!';
  }
}
export function getLanguageSelect(i18n) {
  // console.log('i18n.language', i18n.language)
  return i18n.language;
}
export function getListLanguages(i18n) {
  return Object.keys(i18n.services.resourceStore.data);
}
export function setLanguages(i18n, key) {
  if (getListLanguages(i18n).includes(key)) {
    i18n.changeLanguage(key);
    if (getLanguageSelect(i18n) === key) {
      return true;
    }
    return false;
  }
  return false;
}
