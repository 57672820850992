import { DepartmentsNames, GenericsNames } from '../../../translations'
import { ERROR_SHOW, GET_DEPARTMENT_SUCCESS } from '../../../business/constants'
import React, { useContext, useEffect, useState } from 'react'
import { getTranslation, useCustomTranslation } from '../../../hooks/useTranslations'

import { CompanyRelatedContext } from '../../../context/CompanyRelatedContext';
import { DeparmentDialog } from './Components/DepartmentDialog'
import { DepartmentGrid } from './Components/DepartmentGrid'
import DepartmentObject from '../../../utils/Constanst/FormikConstanst/Department/DeparmentObject'
import DepartmentsSchema from '../../../utils/Constanst/FormikConstanst/Department/DepartmentSchema'
import { EvaluaAlertDialog } from '../../../Components/Dialogs/EvaluaAlertDialog'
import { FullLoading } from '../../../Components/FullLoading'
import { IsNullOrEmpty } from '../../../utils/utilsService'
import { LoadingContext } from '../../../context/LoadingContext'
import { SUCCESS } from './../../../utils/Constanst/ResponseStatus/index';
import { StoreContext } from '../../../business/Provider'
import { Toast } from '../../../Components/toast'
import { WorkArea } from '../../../Components/WorkArea'
import { firstIndex } from '../../../utils/arrayService';
import useDepartments from './../../../hooks/Departaments/useDepartaments';
import useError from '../../../hooks/useErrors'
import { useFormik } from 'formik'
import useReload from '../../../hooks/useReload'

export const Departments = () => {
  const context = useContext(StoreContext); 
  const { t } = useCustomTranslation()
  const {
    loadingDepartments,
    postDepartment,
    putDepartment,
    deleteDepartment,
    getNextCode,
    getDepartment
  } = useDepartments()
  /** Reload **/
  useReload([getDepartment])
  const {} = useError(context.departmentState, context.dispatchDepartment)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [selectedRows, setSelectedRows] = useState(null)
  const [sendDataLoading, setSendDataLoading] = useState(false)
  const [departments, setDepartments] = useState([])
  const [company, setCompany] = useState(null);
  const {relatedCompanies}= useContext(CompanyRelatedContext);
  //const { enqueueSnackbar } = useSnackbar()

  const [titlePopUp, setTitlePopUp] = useState('')
  useEffect(() => {
    if (context.departmentState?.departments) {
      setDepartments(context.departmentState?.departments)
    }
  }, [context.departmentState])

  useEffect(() => {
    if (!company) {
      setCompany(JSON.parse(localStorage.getItem('companyInfo')))
    }
  }, [company])

  const handleClose = () => {
    setOpenDialog(false)
    formik.resetForm()
    formik.setErrors({})
  }

  const toggleModal = () => setOpenDialog(!openDialog)
  const toggleConfirmation = () => setOpenConfirmation(!openConfirmation)
  const loadingObj= useContext(LoadingContext)
  const findEvaluatorIndex = (data, item) => {
    return firstIndex('id', item.id, data)
  }
  const handleOnEdit = (row) => {
    setTitlePopUp(getTranslation(t, DepartmentsNames.DIALOG_EDIT))
    formik.setValues(row)
    toggleModal()
  }

  const handleOnDelete = (row) => {
    formik.setValues(row)
    toggleConfirmation()
  }

  const handleSaveFunction = (isNew, item) => {
    addOrUpdateGrid(isNew, item, departments)
    toggleModal()
  }

  const addOrUpdateGrid = (isNew, item, data) => {
    const evaluatorCopy = [...departments]
    if (item) {
      if (isNew) evaluatorCopy.push(item)
      else {
        const pos = findEvaluatorIndex(data, formik.values)
        evaluatorCopy[pos] = formik.values
      }
    }
    context.dispatchDepartment({ type: GET_DEPARTMENT_SUCCESS, payload: evaluatorCopy })
    setDepartments(evaluatorCopy)
  }

  const handleOnAdd = async () => {
    setTitlePopUp(getTranslation(t, DepartmentsNames.DIALOG_ADD))
    const response = await getNextCode()
    const nextCode = response.data
    const newEvaluator = { ...DepartmentObject }
    newEvaluator.scode = nextCode
    newEvaluator.code = nextCode
    await formik.setValues({ ...newEvaluator })
    setOpenDialog(true)
  }



  const addDepartments = async (department) => {
      const response = await postDepartment(department)
      //setSendDataLoading(false)
      return response
   
  }

  const updateDepartments = async (department) => {
      return putDepartment(department)
  }

  const addOrUpdateDepartments = async (values, { resetForm, setStatus, setSubmitting }) => {
    try {
      let response
      const isNew = IsNullOrEmpty(values.id)
      loadingObj.setLoading(true);
       //scode to number
      values.code = parseInt(values.scode) 
      if (isNew) {
        response = await addDepartments(values)
      } else {
        response = await updateDepartments(values)
        //setSendDataLoading(true)
      }
      if (response !== undefined) {
        handleSaveFunction(isNew, response)
        resetForm()
        setStatus({ success: true })
        setSubmitting(false)
        //setSendDataLoading(true)
      }
      loadingObj.setLoading(false);
    } catch (err) {
      console.error(err)
      setStatus({ success: false })
      setSubmitting(false)
      loadingObj.setLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: { ...DepartmentObject },
    validationSchema: DepartmentsSchema,
    onSubmit: addOrUpdateDepartments
  })



  const removeEvaluator = async (department) => {
      loadingObj.setLoading(true);
      const response = await deleteDepartment(department)
      loadingObj.setLoading(false);
      return response
  }

  const deleteEvaluatorGrid = async () => {
    toggleConfirmation()
    const result = await removeEvaluator(formik.values)
    if (result !== undefined) {
      const filteredEvaluator = departments.filter(
        (department) => department.id !== formik.values.id
      )
      context.dispatchDepartment({ type: GET_DEPARTMENT_SUCCESS, payload: filteredEvaluator })
      setDepartments(filteredEvaluator)
    }
  }
  
  
  return (
    <>
      <EvaluaAlertDialog
        title={getTranslation(t, GenericsNames.CONFIRM)}
        setOpen={toggleConfirmation}
        open={openConfirmation}
        message={getTranslation(t, DepartmentsNames.PERIOD_CONFIRM_MESSAGE_DELETE)}
        cancelLabel={getTranslation(t, GenericsNames.CANCEL)}
        agreeLabel={getTranslation(t, GenericsNames.ACCEPT)}
        onConfirmFunction={deleteEvaluatorGrid}
      />
      <DeparmentDialog
        formik={formik}
        onClose={handleClose}
        open={openDialog}
        title={titlePopUp}
        textCancel={getTranslation(t, GenericsNames.ADD)}
        textSave={getTranslation(t, GenericsNames.SAVE)}
        sendDataLoading={sendDataLoading}
      />

      <WorkArea grid={
        <>          
          <DepartmentGrid
            onEdit={handleOnEdit}
            onDelete={handleOnDelete}
            loading={context.departmentState.loading}
            rows={departments}
            handleOnAdd={handleOnAdd}
            hasRelationWithPersonia={relatedCompanies && relatedCompanies.length>0}
          />
        </>
      } />

    </>
  )
}