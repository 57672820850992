import {SET_QUERY_PERSISTANCE, CLEAR_QUERY_PERSISTANCE} from '../constants';

export const queryPercistence = (state, action) => {
    switch (action.type) {
        case SET_QUERY_PERSISTANCE:
            return {
                ...state,
                columns: action.payload.columns,
                filter: action.payload.filter,
                filterList: action.payload.filterList,
                sort: action.payload.sort,
                selectedEntity: action.payload.selectedEntity,
                querySelected: action.payload.querySelected
            }
        case CLEAR_QUERY_PERSISTANCE:
            return {
                ...state,
                columns: [],
                filter: {},
                filterList: [],
                sort: [],
                selectedEntity: null,
                querySelected: null
            }
        default:
            return state;
    }
}