import { GenericsNames, ZoneNames } from '../../../translations'
import React, { useContext, useEffect, useState } from 'react'
import { getTranslation, useCustomTranslation } from '../../../hooks/useTranslations'

import { EvaluaAlertDialog } from '../../../Components/Dialogs/EvaluaAlertDialog'
import { IsNullOrEmpty } from '../../../utils'
import { LoadingContext } from '../../../context/LoadingContext';

import { WorkArea } from '../../../Components/WorkArea'
import { ZoneDialog } from './Components/ZoneDialog'
import { ZoneGrid } from './Components/ZoneGrid'
import ZoneObj from '../../../utils/Constanst/FormikConstanst/Zone/ZoneObject'
import ZoneSchema from '../../../utils/Constanst/FormikConstanst/Zone/ZoneSchema'
import { firstIndex } from '../../../utils/arrayService';
import { useFormik } from 'formik'
import useReload from './../../../hooks/useReload';
import useZones from './../../../hooks/zone/useZone';
import { StoreContext } from '../../../business/Provider';
import { GET_AREA_SUCCESS } from '../../../business/constants';
import useError from '../../../hooks/useErrors';

export const Zones = () => {
  const { postZone, putZone, deleteZone, getNextCode, getZones } = useZones()

  const context = useContext(StoreContext)
  const [areas, setAreas] = useState([])

  /** Reload **/
  useReload([getZones])
  useError(context.areaState, context.dispatch)
  const { t } = useCustomTranslation()
  const [open, setOpen] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [titlePopUp, setTitlePopUp] = useState('')
  const loadingObj = useContext(LoadingContext)
  // region [Confirmation]
  const toggleConfirmation = () => {
    setOpenConfirmation(!openConfirmation)
  }
  // endregion
  // region [Dialogs Methods]
  const toggleModal = () => {
    setOpen(!open)
  }
  const handleClose = (value) => {
    toggleModal()
    formik.resetForm()
    formik.setErrors({})
  }

  useEffect(() => {
    if (context.areaState?.areas) {
      setAreas(context.areaState?.areas)
    }
  }
    , [context.areaState])

  const addZone = async (zone) => {
    const response = await postZone(zone)

    return response

  }

  const updateZone = async (zone) => {

    const response = await putZone(zone)
    return response

  }
  const deleteZoneGrid = async () => {
    try {
      loadingObj.setLoading(true);
      toggleConfirmation()
      const result = await handleDeleteZone(formik.values)
      if (result !== undefined) {
        const filteredZone = areas.filter((zone) => zone.id !== formik.values.id)
        context.dispatch({ type: GET_AREA_SUCCESS, payload: filteredZone })
        setAreas(filteredZone)
      }
      loadingObj.setLoading(false);
    } catch (error) {
      loadingObj.setLoading(false);
    }

  }
  const handleDeleteZone = async (values) => {
    const zone = values
    const response = await deleteZone(zone)
    return response
  }
  const addOrUpdateZone = async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
    try {
      loadingObj.setLoading(true);
      console.log({ values })
      let response
      const isNew = IsNullOrEmpty(values.id)
      values.code = parseInt(values.scode)
      if (isNew) {
        response = await addZone(values)
      } else {
        response = await updateZone(values)
      }
      loadingObj.setLoading(false);
      if (response !== undefined) {
        // TODO ADD AREA.
        handleSaveFunction(isNew, response)
        resetForm()
        setStatus({ success: true })
        setSubmitting(false)
      }
    } catch (err) {
      console.error(err)
      setStatus({ success: false })
      // setErrors({ submit: err.message });
      setSubmitting(false)
      loadingObj.setLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: { ...ZoneObj },
    validationSchema: ZoneSchema,
    onSubmit: addOrUpdateZone
  })

  const handleOnAdd = async (value) => {
    setTitlePopUp(getTranslation(t, ZoneNames.DIALOG_ADD))
    const response = await getNextCode()
    const nextCode = response.data
    const newZone = { ...ZoneObj }
    newZone.scode = nextCode
    newZone.code = nextCode
    formik.setValues({ ...newZone })
    toggleModal()
  }
  const handleOnEdit = (row) => {
    setTitlePopUp(getTranslation(t, ZoneNames.DIALOG_EDIT))
    formik.setValues(row)
    toggleModal()
  }
  const handleOnDelete = (row) => {
    formik.setValues(row)
    toggleConfirmation()
  }
  const handleSaveFunction = (isNew, item) => {
    addOrUpdateGrid(isNew, item, areas)
    toggleModal()
  }
  // endregion
  // region [Grid Method]
  const findZoneIndex = (data, item) => {
    return firstIndex('id', item.id, data)
  }
  const addOrUpdateGrid = (isNew, item, data) => {
    const zoneCopy = [...areas]
    if (item) {
      if (isNew) zoneCopy.push(item)
      else {
        const pos = findZoneIndex(data, item)
        zoneCopy[pos] = item
      }
    }
    context.dispatch({ type: GET_AREA_SUCCESS, payload: zoneCopy })
    setAreas(zoneCopy)
  }

  return (
    <>
      <EvaluaAlertDialog
        title={getTranslation(t, GenericsNames.CONFIRM)}
        message={getTranslation(t, ZoneNames.CONFIRM_MESSAGE_DELETE)}
        open={openConfirmation}
        setOpen={toggleConfirmation}
        cancelLabel={getTranslation(t, GenericsNames.CANCEL)}
        agreeLabel={getTranslation(t, GenericsNames.ACCEPT)}
        onConfirmFunction={deleteZoneGrid}
      />
      <ZoneDialog
        formik={formik}
        onClose={handleClose}
        open={open}
        title={titlePopUp}
        textCancel={getTranslation(t, GenericsNames.CANCEL)}
        textSave={getTranslation(t, GenericsNames.SAVE)}
        id='ZoneDialog'
        name='ZoneDialog'
      />
      <WorkArea
        grid={
          <>
            <ZoneGrid
              onEdit={handleOnEdit}
              onDelete={handleOnDelete}
              loading={context.areaState?.loading}
              rows={areas}
              handleOnAdd={handleOnAdd}
            />
          </>
        }
      />

    </>
  )
}