import './index.css';
import * as yup from 'yup';
import { ADD_QUERY_STATE, CLEAR_QUERY_PERSISTANCE, GET_QUERY_PROPERTIES_SUCCESS, GET_QUERY_SUCCESS, SET_QUERY_PERSISTANCE } from '../../business/constants';
import { EntityToExport, ToExcel } from '../../utils/exportService';
import { NavigationNames, QueryNames, ToolBarNames, GenericsNames } from '../../translations';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { TreeView, findIndex } from '../../Components/tree';
import { contains, dateDifferent, dateEqual, dateGreaterThan, dateLessThan, dateRange, empty, equals, greaterThan, lessThan, notContains, notEmpty, notEquals, range } from './filters';
import { faArrowRight, faClose, faFileExcel, faPlay, faSave } from '@fortawesome/free-solid-svg-icons';
import { getTranslation, useCustomTranslation } from '../../hooks/useTranslations';
import { Column } from 'primereact/column';
import { ContextMenu } from 'primereact/contextmenu';
import { CustomIconButton } from '../../Components/Buttons/CustomIconButton';
import { DiscardDialog } from './componets/discardDialog';
import { EvaluaTable } from '../../Components/Table/EvaluaTable';
import { FilterComponent } from './componets/filterComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingContext } from '../../context/LoadingContext';
import { NavigationContext } from '../../context/navigationContext';
import { NewQueryDialog } from './componets/newQueryDialog';
import { StoreContext } from '../../business/Provider';
import { Toast } from '../../Components/toast';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import useError from '../../hooks/useErrors';
import { useFormik } from 'formik';
import useQueries from '../../hooks/queries/useQueries';
import useReload from '../../hooks/useReload';
import { EvaluaAlertDialog } from '../../Components/Dialogs/EvaluaAlertDialog';
import { faFilterSlash } from '@fortawesome/pro-regular-svg-icons';

export const Queries = () => {
  const { queryState, dispatchQuery, dispatchLoading, queryPercistenceState, dispatchQueryPercistence } = useContext(StoreContext)
  const { setSave, awitTap, setAwaitTap, setAfterClose, closeUrl } = useContext(NavigationContext)
  const loadingObj = useContext(LoadingContext)
  const { getEntity, getQueries, addQuery, deleteQuery, updateQuery, getQueryProperties, getQueryData } = useQueries();
  const [open, setOpen] = useState(false);
  const [saveAs, setSaveAs] = useState(false);
  const [discard, setDiscard] = useState(false);
  // data of the selected query
  const [querySelected, setQuerySelected] = useState(null);
  useReload([getEntity, getQueries]);
  const { t } = useCustomTranslation();
  useError(queryState, deleteQuery);
  const [collapsed, setCollapsed] = useState({
    left: false,
    right: false
  });
  const [entities, setEntities] = useState([])
  const [queries, setQueries] = useState([])
  const [sort, setSort] = useState([{ field: '', order: -1 }])
  //dropdown used when creating a new query
  const [dropdown, setDropdown] = useState(null)
  // the columns of the table
  const [columns, setColumns] = useState([])
  // columns copy
  const [columnsCopy, setColumnsCopy] = useState([])
  // use to identify the current query
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  const [edit, setEdit] = useState(false);
  // this variable is used to prevent the user from closing the dialog without saving or selecting a query
  const [previousSelected, setPreviousSelected] = useState(null);
  // selected entity
  const [selectedEntity, setSelectedEntity] = useState(null);
  // control the context menu for the queries
  const cm = useRef(null);
  // filter value
  const [filter, setFilter] = useState({});
  const [filterList, setFilterList] = useState([]);
  // delete modal
  const [deleteModal, setDeleteModal] = useState(false);
  // object to delete
  const [objectToDelete, setObjectToDelete] = useState(null);
  // copy modal
  const [copyPredefinedModal, setCopyPredefinedModal] = useState(false);
  const [executeSave, setExecuteSave] = useState(false);
  // update setUpdateRenamed
  const [updateRenamed, setUpdateRenamed] = useState(false);
  // updated query
  const [updated, setUpdated] = useState(null);
  // queryList
  const [queryList, setQueryList] = useState([]);
  // isNewQuery prevent delete new query on rename or copy
  const [isNewQuery, setIsNewQuery] = useState(false);
  const menu = [
    {
      label: getTranslation(t, QueryNames.QUERY_OPEN),
      icon: 'pi pi-external-link',
      command: () => {
        const node = findIndex(queries, selectedNodeKey);
        if (querySelected && node.key === querySelected.key && querySelected?.data.isNew) {
          return;
        }
        onSelectQuery(node, true);
      }
    },
    {
      label: getTranslation(t, QueryNames.QUERY_DELETE),
      icon: 'pi pi-trash',
      command: () => {
        const node = findIndex(queries, selectedNodeKey);
        if (node.data.isPredefined) {
          Toast('warning', 'No se puede eliminar una consulta predefinida')
          return;
        }
        setObjectToDelete(selectedNodeKey);
        setDeleteModal(true);
      }
    },
    {
      label: getTranslation(t, QueryNames.QUERY_RENAME),
      icon: 'pi pi-pencil',
      command: () => {
        const node = findIndex(queries, selectedNodeKey);
        if (node.data.isPredefined) {
          Toast('warning', 'No se puede renombrar una consulta predefinida.')
          return;
        }
        editFormik.setFieldValue('name', node.label);
        editFormik.setFieldValue('id', selectedNodeKey);
        setEdit(true);
      }
    },
    {
      label: getTranslation(t, QueryNames.QUERY_SAVE_AS),
      icon: 'pi pi-save',
      command: () => {
        const node = findIndex(queries, selectedNodeKey);
        if (querySelected && node.key === querySelected.key && querySelected?.data.isNew) {
          return;
        }
        if (querySelected?.data.isNew) {
          Toast('warning', 'No se puede copiar una consulta nueva hasta que se guarde la consulta actual.')
          return
        }
        handlerSaveAsQuery(node);
      }
    }
  ]

  useEffect(() => {
    if (queryPercistenceState) {
      setUpdated(false)
      setQuerySelected(queryPercistenceState.querySelected)
      setFilter(queryPercistenceState.filter)
      setColumnsCopy(queryPercistenceState.columns)
      setColumns(queryPercistenceState.columns)
      setFilterList(queryPercistenceState.filterList)
    }
    // set afterClose to true, to execute the function after close tab (more info in the useEffect)
    setAfterClose({
      [window.location.pathname]: true
    })
  }, [])
  // useEffect to execute the function after close tab, dont forget to set afterClose to false
  useEffect(() => {
    if (closeUrl != null) {
      dispatchQueryPercistence({ type: CLEAR_QUERY_PERSISTANCE })
      setAfterClose({
        [window.location.pathname]: false
      })
    }
  }, [closeUrl])
  useEffect(() => {
    loadingObj.setLoading(queryState.loading);
  }, [queryState.loading])
  // useEffect to set state
  useEffect(() => {
    if (queryState.loading === false && queryState.entity.length > 0) {
      // deep copy of the array
      setEntities(JSON.parse(JSON.stringify(queryState.entity)))
    }
  }, [queryState.entity])
  useEffect(() => {
    if (queryState.loading === false && queryState.query.length > 0 && isNewQuery === false) {
      setQueries(JSON.parse(JSON.stringify(queryState.query)))
      setQueryList(getChildren(JSON.parse(JSON.stringify(queryState.query))))
    }
  }, [queryState.query])
  useEffect(() => {
    //columns and columnsCopy are different deep compared 
    if (JSON.stringify(columns) !== JSON.stringify(columnsCopy) && querySelected) {
      setUpdated(true);
      setSave(false);
    }
    if (querySelected === null && columns.length > 0) {
      const data = []
      queries.forEach(query => {
        data.push({
          label: query.label,
          value: query.key
        })
      })
      setDropdown(data)
      setOpen(true)
    } else {
      // if columns and columnsCopy are not equals
      selectedEntitiesFunction(columns)
      // add or remove entity from filter
      const filterKeys = Object.keys(filter || {});
      const tempFilter = JSON.parse(JSON.stringify(filter || {}));
      // remove all elements from the filter object if not in the columns.feilds
      filterKeys.forEach(key => {
        if (!columns.find(column => column.field === key)) {
          delete tempFilter[key]
        }
      })
      // compare the filter with tempFilter
      if (JSON.stringify(tempFilter) !== JSON.stringify(filter)) {
        setFilter(tempFilter)
      }
    }
  }, [columns])
  // awaitTap change state
  useEffect(() => {
    if (awitTap) {
      setDiscard(true)
    }
  }, [awitTap])
  useEffect(() => {
    if (querySelected === null && previousSelected !== null) {
      previousSelected && onSelectQuery(previousSelected)
      setPreviousSelected(null)
    }
  }, [querySelected])
  /* Filtering the mock_data based on the filter object. */
  useEffect(() => {
    //filter !== {} && f
    const delayDebounceFn = setTimeout(() => {
      let temp = JSON.parse(JSON.stringify(queryState.queryData[querySelected?.data.queryId] || []))
      filterFn(temp)
    }, 500)

    return () => clearTimeout(delayDebounceFn)

  }, [filter])
  useEffect(() => {
    if (executeSave) {
      onSave();
    }
  }, [executeSave])
  const filterFn = (temp) => {
    if (Object.keys(filter).length > 0) {

      if (temp.length > 0) {
        temp = executeFilter(temp)
        setFilterList(temp)
      }
    } else {
      setFilterList([])
    }
  }
  const executeFilter = (temp) => {
    for (const item in filter) {
      if (filter[item] !== null) {
        let value = filter[item].value
        switch (filter[item].type) {
          case 'contains':
            temp = contains(value, item, temp)
            break;
          case 'notContains':
            temp = notContains(value, item, temp)
            break;
          case 'equal':
            temp = equals(value, item, temp)
            break;
          case 'different':
            temp = notEquals(value, item, temp)
            break;
          case 'greaterNum':
            temp = greaterThan(value, item, temp)
            break;
          case 'lessNum':
            temp = lessThan(value, item, temp)
            break;
          case 'rangeNum':
            temp = range(value, item, temp)
            break;
          case 'null':
            temp = empty(item, temp)
            break;
          case 'notNull':
            temp = notEmpty(item, temp)
            break;
          case 'equalDate':
            temp = dateEqual(value, item, temp)
            break;
          case 'differentDate':
            temp = dateDifferent(value, item, temp)
            break;
          case 'greaterDate':
            temp = dateGreaterThan(value, item, temp)
            break;
          case 'lessDate':
            temp = dateLessThan(value, item, temp)
            break;
          case 'rangeDate':
            temp = dateRange(value, item, temp)
            break;
        }
      }
    }
    return temp
  }
  useEffect(() => {
    if (updateRenamed) {
      dispatchQueryPercistence({
        type: SET_QUERY_PERSISTANCE, payload: {
          columns: columns,
          filter: filter,
          filterList: filterList,
          sort: sort,
          querySelected: querySelected
        }
      })
      setUpdateRenamed(false)
    }
  }, [updateRenamed, querySelected])
  // cancel predefined copy modal
  const cancelCopyPredefinedFn = () => {
    setColumns(columnsCopy)
    setCopyPredefinedModal(false)
  }
  // extract all children from the query
  const getChildren = (nodes) => {
    const children = [];
    for (const node of nodes) {
      if (node.children) {
        children.push(...getChildren(node.children));
      } else {
        children.push(node);
      }
    }
    return children
  }
  // if label exists
  const labelExists = (label) => {
    const array = queryList
    const exists = array.find(item => item.label === label)
    if (exists) {
      // find in all the queries if the label contains the label text 
      const copyCount = array.filter(item => item.label.includes(label)).length
      if (copyCount > 0) {
        return label + ' (' + (copyCount) + ')'
      } else {
        return label
      }
    }
    return label
  }
  // if label exists add error to formik
  const labelExistsError = (label) => {
    const array = queryList
    const exists = array.find(item => item.label === label)
    if (exists) {
      // add error to formik
      return 'El nombre de la consulta ya existe'
    }
    return ''
  }
  const onSave = async () => {
    const isNew = findIndex(queryState.query, querySelected.key);
    if (querySelected.data.isPredefined === 1) {
      setCopyPredefinedModal(true)
      return
    }
    loadingObj.setLoading(true)
    if (isNew === null) {
      await handlerSave()
    } else {
      await handlerUpdate()
    }
    const tempPayload = JSON.parse(JSON.stringify(querySelected))
    // remove isNew property
    delete tempPayload.data.isNew
    dispatchQueryPercistence({
      type: SET_QUERY_PERSISTANCE, payload: {
        columns: columns,
        filter: filter,
        filterList: filterList,
        sort: sort,
        querySelected: tempPayload
      }
    })
    setSave(true)
    setUpdated(false)
    loadingObj.setLoading(false)
  }
  const handlerSave = async () => {
    if (columns.length > 0) {
      const node = findIndex(queries, querySelected.key);
      const position = querySelected.key.split('.')[1];
      const newFilter = saveSort()
      const body = {
        name: node.label,
        isFavorite: 0,
        isPredefined: 0,
        guideId: node.data.guideId,
        position: position,
      }
      body.properties = columns.map(column => {
        return {
          propertyId: column.propertyId,
          position: column.position,
          filter: JSON.stringify(newFilter[column.field]) || ''
        }
      })
      const data = await addQuery(body, node.data.guideId)
      const payload = {
        queryId: data.query.id,
        properties: data.properties
      }
      setIsNewQuery(false)
      dispatchQuery({ type: GET_QUERY_PROPERTIES_SUCCESS, payload: payload })
      await getQueries()
      setQuerySelected({ ...querySelected, data: { ...querySelected.data, isNew: false, queryId: data.query.id } })
      await executeQuery(data.query.id, true)
      setExecuteSave(false)
    } else {
      Toast('warning', 'No puede guardar una consulta sin campos')
    }
  }
  const handlerUpdate = async () => {
    if (columns.length > 0) {
      const node = findIndex(queries, querySelected.key);
      if (node.data.isPredefined === 1) {
        Toast('warning', 'No puede editar una consulta predefinida')
        loadingObj.setLoading(false)
        return;
      }
      const newFilter = saveSort()
      const body = {
        name: node.label,
        isFavorite: 0,
        isPredefined: 0,
        guideId: node.data.guideId,
        position: node.data.position,
      }
      body.properties = columns.map(column => {
        return {
          position: column.position,
          propertyId: column.propertyId,
          filter: JSON.stringify(newFilter[column.field]) || '',
          createdBy: column.createdBy,
          createdDate: column.createdDate
        }
      })
      const data = await updateQuery(body, node.data.queryId)
      await getQueryData(node.data.queryId)
      const payload = {
        queryId: node.data.queryId,
        properties: data.properties
      }
      dispatchQuery({ type: GET_QUERY_PROPERTIES_SUCCESS, payload: payload })
      const newQuery = updateField(data.query.name, node.key, queryState.query)
      dispatchQuery({ type: GET_QUERY_SUCCESS, payload: newQuery })
      setSave(true)
      //await getQueries()
    }
  }
  const handlerUpdateRenamed = async (node) => {
    try {
      loadingObj.setLoading(true)
      const body = {
        name: node.label,
        guideId: node.data.guideId,
      }
      await updateQuery(body, node.data.queryId)
      loadingObj.setLoading(false)
      return true
    } catch (error) {
      loadingObj.setLoading(false)
      Toast('error', error.message)
      return false
    }


  }
  const saveSort = () => {
    if (querySelected !== null) {
      const tags = sort[0].field
      let filterCopy = JSON.parse(JSON.stringify(filter || {}))
      Object.keys(filterCopy).forEach(key => {

        if (filterCopy[key] !== null && filterCopy[key].hasOwnProperty('sort')) {
          // delete sort
          delete filterCopy[key].sort
        }
      })
      if (tags) {

        if (filterCopy[tags]) filterCopy[tags].sort = sort[0].order
        else filterCopy[tags] = { sort: sort[0].order }

      }
      // re
      return filterCopy
    }
  }
  const updateName = async () => {
    const error = labelExistsError(editFormik.values.name)
    if (error !== '') {
      editFormik.setFieldError('name', error)
      return
    }
    const newQueries = updateField(editFormik.values.name, editFormik.values.id, queries)
    const node = findIndex(newQueries, editFormik.values.id);
    await handlerUpdateRenamed(node)
    setQueries(newQueries);
    const newQueriesWithOutNews = updateField(editFormik.values.name, editFormik.values.id, queryState.query)
    dispatchQuery({ type: GET_QUERY_SUCCESS, payload: newQueriesWithOutNews })
    if (querySelected?.key === editFormik.values.id) {
      setQuerySelected({ ...querySelected, label: editFormik.values.name })
      setUpdateRenamed(true)
    }
    setEdit(false)
    editFormik.resetForm();
    Toast('success', 'Consulta actualizada')
  }
  const updateField = (value, id, list) => {
    const newList = JSON.parse(JSON.stringify(list));
    const node = findIndex(newList, id);
    node.label = value
    return newList;
  }
  const setFilterColumns = (newColumns, entity, newFilter) => {
    newColumns.push(
      {
        field: entity.propertyName,
        header: entity.propertyName,
        width: { flexGrow: 1, flexBasis: '140px' },
        position: entity.propertyPosition,
        key: entity.propertyKey,
        propertyId: entity.propertyId,
        createdBy: entity.createdBy,
        createdDate: entity.createdDate,
        filterType: entity.filterType
      }
    );
    newFilter[entity.propertyName] = entity.filter !== '' ? JSON.parse(entity.filter) : '';
    const filterSort = JSON.parse(entity.filter)
    if (filterSort?.sort) {
      //setSort([{field: entity.propertyName, order: filterSort.sort}])
      return [{ field: entity.propertyName, order: filterSort.sort }]
    } else {
      //setSort([{field: '', order: '-1'}])
      return null
    }

  }
  // set all filters to empty
  const clearFilter = () => {
    const newFilter = {};
    columns.forEach(column => {
      newFilter[column.field] = '';
    }
    )
    setFilter(newFilter)
  }
  /**
   * A function that is called when a query is selected.
   */
  const onSelectQuery = async (query, contextOpen = false) => {
    if (querySelected?.data.isNew || updated) {
      setPreviousSelected(query)
      setDiscard(true)
      return;
    }
    const newColumns = []
    const newFilter = {}
    let newSort = [{ field: '', order: -1 }]
    if (query.key !== querySelected?.key) {
      const props = Object.keys(queryState.queryProperties)
      if (props.find(prop => prop == query.data.queryId) && !contextOpen && queryState.queryProperties[query.data.queryId][0]?.propertyKey !== undefined) {
        queryState.queryProperties[query.data.queryId].forEach(entity => {
          newSort = conditionalSort(setFilterColumns, newColumns, entity, newFilter, newSort);
        })
      } else {
        const response = await getQueryProperties(query.data.queryId)
        response.forEach(entity => {
          newSort = conditionalSort(setFilterColumns, newColumns, entity, newFilter, newSort);
        })
      }
      await getQueryData(query.data.queryId).then((response) => {
        if (response === null) {
          return
        } else {
          dispatchQueryPercistence({
            type: SET_QUERY_PERSISTANCE, payload: {
              columns: newColumns,
              filter: newFilter,
              filterList: [],
              sort: newSort,
              querySelected: query
            }
          })
          setSort(newSort)
          setUpdated(false)
          setQuerySelected(query)
          setFilter(newFilter)
          setColumnsCopy(newColumns)
          setColumns(newColumns)
          setFilterList([])
        }
      })

    }
  }
  const executeQuery = async (id, add) => {
    if (!add && querySelected.data.isNew) {
      await onSave()
    } else {
      if (updated && !querySelected.data.isNew) {
        await onSave()
      }
      await getQueryData(id).then((response) => {
        const data = JSON.parse(JSON.stringify(response))
        filterFn(data)
      })
    }
  }
  const copyPredefined = () => {
    const node = findIndex(queries, querySelected.key);
    handlerSaveAsQuery(node);
  }
  const selectedEntitiesFunction = (data) => {
    const result = {}
    data.forEach(entity => {
      result[entity.key] = true
    })
    setSelectedEntity(result)
  }
  // collapse function
  const onCollapse = (name) => {
    setCollapsed({ ...collapsed, [name]: !collapsed[name] });
  }
  // remove element from table
  const removeColumn = (field) => {
    const newColumns = columns.filter(column => column.field !== field);
    // reassign position of the columns
    newColumns.forEach((column, index) => {
      column.position = index;
    })
    setColumns(newColumns);
  }
  // add query to query list
  const handlerAddQuery = () => {
    // deep copy of queries
    console.log('handlerAddQuery', formik.values)
    const error = labelExistsError(formik.values.name)
    if (error !== '') {
      formik.setFieldError('name', error)
      return
    }

    const newQueries = JSON.parse(JSON.stringify(queries))
    const index = newQueries.findIndex(query => query.key === formik.values.fatherId)
    if (index === -1) {
      Toast('warning', 'No se encontro el padre')
      return
    }
    setIsNewQuery(true)
    // find the last element of the list
    const last = newQueries[index].children[newQueries[index].children.length - 1]
    // get key of the last element
    let key = last.key

    // split key to get the last number and add 1
    const newKey = parseInt(key.split('.')[1]) + 1
    newQueries[index].children.push({
      key: (formik.values.fatherId) + '.' + newKey,
      label: formik.values.name,
      data: {
        isPredefined: 0,
        sqlSentence: null,
        queryDescription: null,
        guideId: newQueries[index].key,
        isNew: true
      }
    })
    const payload = newQueries[index].children[newQueries[index].children.length - 1]
    dispatchQuery({ type: ADD_QUERY_STATE, payload })
    setQuerySelected(payload)
    setFilter({})
    setDropdown(null)
    setOpen(false)
    setQueries(newQueries)
    if (querySelected !== null) {
      setColumnsCopy([])
      setColumns([])
    }
    setSave(false)
    // reset form
    formik.resetForm()
    Toast('success', 'Consulta guardada con exito')
  }
  // save as query
  const handlerSaveAsQuery = async (data) => {
    console.log('handlerSaveAsQuery', data)
    const fatherId = data.key.split('.')[0]
    const name = labelExists(data.label + '_copy');
    const newData = {
      name: name,
      isFavorite: 0,
      isPredefined: 0,
      guideId: data.data.guideId,
      description: '',
      sqlSentence: '',
      fatherId: fatherId,
      id: data.data.queryId
    }
    saveFormik.setValues(newData)

    setSaveAs(true)
  }

  const saveAsQuery = async () => {
    console.log('saveAsQuery', saveFormik.values)
    const error = labelExistsError(saveFormik.values.name)
    if (error !== '') {
      saveFormik.setFieldError('name', error)
      return
    }
    const data = saveFormik.values
    const props = Object.keys(queryState.queryProperties)
    const newColumns = []
    const newFilter = {}
    // find query in queryState.queryProperties
    const newQueries = JSON.parse(JSON.stringify(queries))
    const index = newQueries.findIndex(query => query.key == saveFormik.values.fatherId)
    // get the last key of the query
    const lastKey = newQueries[index].children[newQueries[index].children.length - 1].key
    // sum 1 to the last key
    const newKey = parseInt(lastKey.split('.')[1]) + 1
    // set the new key
    const newKeyString = saveFormik.values.fatherId + '.' + newKey
    newQueries[index].children.push({
      key: newKeyString,
      label: saveFormik.values.name,
      data: {
        isPredefined: 0,
        sqlSentence: null,
        queryDescription: null,
        guideId: newQueries[index].key,
        isNew: true
      }
    })
    const payload = newQueries[index].children[newQueries[index].children.length - 1]
    dispatchQuery({ type: ADD_QUERY_STATE, payload })
    setQueries(newQueries)
    setQuerySelected(payload)
    let newSort = [{ field: '', order: -1 }]
    // find in props
    if (props.find(prop => prop == data.id)) {
      queryState.queryProperties[data.id].forEach(entity => {
        newSort = conditionalSort(setFilterColumns, newColumns, entity, newFilter, newSort);
      })
      if (querySelected.data.queryId === data.id) {
        setSort(sort)
        setFilter(filter)
        newSort = sort
        setColumns(columns)
      } else {
        setSort(newSort)
        setFilter(newFilter)
        setColumns(newColumns)
      }

    } else {
      const response = await getQueryProperties(data.id)
      response.forEach(entity => {
        newSort = conditionalSort(setFilterColumns, newColumns, entity, newFilter, newSort);
      })
      setSort(newSort)
      setFilter(newFilter)
      setColumns(newColumns)
    }
    const tempPayload = JSON.parse(JSON.stringify(payload))
    // remove isNew property
    tempPayload.data.isNew = false
    dispatchQueryPercistence({
      type: SET_QUERY_PERSISTANCE, payload: {
        columns: newColumns,
        filter: newFilter,
        filterList: [],
        sort: newSort,
        querySelected: tempPayload
      }
    })
    setExecuteSave(true)
    setSaveAs(false)
    saveFormik.resetForm()
  }
  /**
   * It deletes a query from the database and the state
   */
  const removeQuery = async (key) => {
    console.log('removeQuery', key)
    try {
      // if is new query
      loadingObj.setLoading(true)
      const ids = key.split('.')
      // use ids to find the query tree
      const newQueries = JSON.parse(JSON.stringify(queries))
      let nodes = newQueries[ids[0] - 1].children
      const data = findIndex(newQueries, key)
      // IF query is selected

      if (data.data.isNew) {
        nodes = deleteElement(key, nodes)
        newQueries[ids[0] - 1].children = nodes
        dispatchQuery({ type: ADD_QUERY_STATE, payload: newQueries })
        setQueries(newQueries)
        const newQueryState = JSON.parse(JSON.stringify(queryState.query))
        nodes = deleteElement(key, newQueryState[ids[0] - 1].children)
        newQueries[ids[0] - 1].children = nodes
        dispatchQuery({ type: GET_QUERY_SUCCESS, payload: newQueries })
        loadingObj.setLoading(false)
        setUpdated(false)
        setSave(true)
        Toast('success', 'Consulta eliminada con exito')
        return
      }
      if (data.data.isPredefined === 0) {
        const deleted = await deleteQuery(data.data.queryId)
        if (deleted) {
          nodes = deleteElement(key, nodes)
          newQueries[ids[0] - 1].children = nodes
          dispatchQuery({ type: ADD_QUERY_STATE, payload: newQueries })
          setQueries(newQueries)
          const newQueryState = JSON.parse(JSON.stringify(queryState.query))
          nodes = deleteElement(key, newQueryState[ids[0] - 1].children)
          newQueries[ids[0] - 1].children = nodes
          dispatchQuery({ type: GET_QUERY_SUCCESS, payload: newQueries })
          setUpdated(false)
          setSave(true)
          Toast('success', 'Consulta eliminada con exito')
        }
      } else {
        Toast('warning', 'No se puede eliminar una consulta predefinida')
      }
      if (querySelected !== null && querySelected.key === key) {
        dispatchQueryPercistence({ type: CLEAR_QUERY_PERSISTANCE })
      }
      loadingObj.setLoading(false)
    } catch (error) {
      loadingObj.setLoading(false)
    }
  }
  /**
   * It takes a key and a data array and returns a new array with the element with the key removed
   * @returns - if the element is not found, the original data is returned
   *     - if the element is found, the element is removed from the array and the new array is
   * returned
   *     - if the element is found and isPredefined === 1, the original data is returned
   */

  const resultChildrenValidation = (result, item, key) => {
    if (item.children && item.children.length > 0) {
      result.children = deleteElement(key, item.children)
    }
  }

  const deleteElement = (key, data) => {
    const result = JSON.parse(JSON.stringify(data))
    data.forEach((item, index) => {
      if (item.key === key) {
        // remove element from array if isPredefined !== 1
        if (item.data.isPredefined !== 1) {
          //if is selected clear the selected query
          if (selectedNodeKey === item.key) setSelectedNodeKey(null)

          if (querySelected && querySelected.key === item.key) {
            setQuerySelected(null)
            setColumns([])
          }
          //remove element from array
          result.splice(index, 1)
          return result
        }
      } else {
        resultChildrenValidation(result, item, key)
      }
    })
    // delete element from selectedEntity
    return result
  }
  //formik form
  const formik = useFormik({
    initialValues: {
      fatherId: '',
      name: '',
      description: '',
      sqlSentence: '',
      isPredefined: 0,
      id: ''
    },
    validationSchema: yup.object().shape({
      name: yup.string('')
        .required(QueryNames.QUERY_NAME_REQUIRED)
        .max(60, QueryNames.QUERY_NAME_MAX_LENGTH)
        .nullable(),
      fatherId: yup.string('').required(QueryNames.QUERY_CATEGORY_REQUIRED),
    }),
    onSubmit: handlerAddQuery
  })
  const editFormik = useFormik({
    initialValues: {
      name: '',
      id: ''
    },
    validationSchema: yup.object().shape({
      name: yup.string('')
        .required(QueryNames.QUERY_NAME_REQUIRED)
        .max(60, QueryNames.QUERY_NAME_MAX_LENGTH),
    }),
    onSubmit: updateName
  })
  const saveFormik = useFormik({
    initialValues: {
      fatherId: '',
      name: '',
      description: '',
      sqlSentence: '',
      isPredefined: 0,
      id: ''
    },
    validationSchema: yup.object().shape({
      name: yup.string('')
        .required(QueryNames.QUERY_NAME_REQUIRED)
        .max(60, QueryNames.QUERY_NAME_MAX_LENGTH)
    }),
    onSubmit: saveAsQuery
  })

  // header render
  const header = (column) => {
    return (
      <div className='w-full h-full flex justify-between'>
        <button className='mr-2' onClick={
          () => {
            removeColumn(column.field);
          }
        }><FontAwesomeIcon icon={faClose} /></button>
        {column.header}

      </div>
    )
  }
  // Drag and Drop
  // onDragover function
  const onDragOver = (e) => {
    e.preventDefault();
  }
  // onDrop function
  const onDrop = (e) => {
    e.preventDefault();
    const data = e.dataTransfer.getData('text/plain').split('/')
    // if columns is empty, set columns to data
    let position = 0;
    if (columns.length === 0) {
      position = columns.length + 1
      setColumns([...columns,
      {
        field: data[0],
        header: data[0],
        width: { flexGrow: 1, flexBasis: '150px' },
        position: position,
        key: data[1],
        propertyId: data[2],
        filterType: data[3]
      }
      ])
      return
    }
    if (!columns.find(column => column.field === data[0]) && data[0] !== 'b') {
      //drop cordinates
      const x = e.clientX;
      const y = e.clientY;
      let elem = document.elementFromPoint(x, y);
      // this line fixes a bug when the element is a div instead of a th element
      if (elem.tagName === 'DIV') {
        elem = elem.offsetParent
      }
      else if (elem.tagName === 'path') {
        elem = elem.parentElement.parentElement.offsetParent
      } else if (elem.tagName === 'svg') {
        elem = elem.parentElement.offsetParent
      }
      // cellIndex
      const cellIndex = elem.cellIndex;
      const centerX = elem.getBoundingClientRect().left + elem.getBoundingClientRect().width / 2;
      const isDroppedInTheRight = x > centerX;
      const newColumns = JSON.parse(JSON.stringify(columns))
      // cellIndex  == undefined add column to the end
      if (cellIndex === undefined) {
        position = columns.length + 1
        const cell = {
          field: data[0],
          header: data[0],
          width: { flexGrow: 1, flexBasis: '150px' },
          position: position,
          key: data[1],
          propertyId: data[2],
          filterType: data[3]
        }
        newColumns.push(cell)
      } else if (isDroppedInTheRight) {
        position = cellIndex + 1;
        const cell = {
          field: data[0],
          header: data[0],
          width: { flexGrow: 1, flexBasis: '150px' },
          position: position,
          key: data[1],
          propertyId: data[2],
          filterType: data[3]
        }
        newColumns.splice(cellIndex + 1, 0, cell)
      } else {
        position = cellIndex;
        const cell = {
          field: data[0],
          header: data[0],
          width: { flexGrow: 1, flexBasis: '150px' },
          position: position,
          key: data[1],
          propertyId: data[2],
          filterType: data[3]
        }
        newColumns.splice(cellIndex, 0, cell)
      }
      setColumns(reassignPosition(newColumns))
    }
  }
  const reassignPosition = (items) => {
    const newColumns = JSON.parse(JSON.stringify(items))
    newColumns.forEach((column, index) => {
      column.position = index
    })
    return newColumns
  }

  const onColsChange = (cols) => {
    const copy = JSON.parse(JSON.stringify(columns))
    const item1 = copy[cols.dragIndex];
    item1.position = cols.dropIndex;
    const item2 = copy[cols.dropIndex];
    item2.position = cols.dragIndex;
    // swap items
    copy[cols.dragIndex] = item2;
    copy[cols.dropIndex] = item1;
    setColumns(copy);
  }
  // open modal
  const openAddQueryModal = (data) => {
    formik.setFieldValue('fatherId', data.key)
    setOpen(true)
  }
  const handlerAccept = () => {
    setIsNewQuery(false)
    setDiscard(false)
    setQueries(queryState.query)
    const data = null
    dispatchQuery({ type: ADD_QUERY_STATE, data })
    setQuerySelected(null)
    setUpdated(false)
    setColumnsCopy([])
    setColumns([])
    setSave(true)
  }
  const handlerDiscard = () => {
    setAwaitTap(null)
    setDiscard(false)
    setSave(false)
  }
  // dialog to confirm you want to unSave query
  const onHide = () => {
    if (!querySelected && columns.length !== 0) {
      setColumns([])
    }
    formik.setFieldValue('fatherId', null)
    formik.setFieldValue('name', null)
    setDropdown(null)
    setOpen(false)
    setEdit(false)
    setSaveAs(false)
  }
  const handleExcelExport = async () => {
    if (querySelected?.data.isNew) {
      Toast('warning', getTranslation(t, QueryNames.QUERY_NOT_SAVED))
      return
    }
    if (Object.keys(filter).length === 0) {
      Toast('warning', getTranslation(t, QueryNames.QUERY_EXCEL_FILTER))
      return
    }
    // get name from companyInfo localStorage
    const companyInfo = JSON.parse(localStorage.getItem('companyInfo'))
    const printObj = { filters: filter, sort: sort[0], queryInfo: querySelected, companyName: companyInfo.name };

    //exportToExcel(printObj)

    await ToExcel(printObj, EntityToExport.QUERY, dispatchLoading);
  }
  //renders
  // header container
  const headerContainer = () => {
    return (
      <div className='w-full flex justify-between font-font-family-base'>
        <div className='p-1 flex items-center'>
          <h3 className='text-base'>{getTranslation(t, NavigationNames.INQUIRIES)}</h3>
          <p className=' text-xs px-8 '>{querySelected ? querySelected?.label : 'Seleccione una consulta'}</p>

        </div>
        <div className="flex justify-end h-full p-1">
          <CustomIconButton disabled={!querySelected} className='!m-1' toolTip='Ejecutar consulta' icon={faPlay}
            onClick={() => { executeQuery(querySelected?.data.queryId, false) }} />

          <CustomIconButton className='!m-1' toolTip='Guardar' onClick={() => { onSave() }} icon={faSave} />
          <CustomIconButton className='!m-1' toolTip={getTranslation(t, ToolBarNames.EXCEL)} onClick={() =>
            handleExcelExport()
          } icon={faFileExcel} />
        </div>

      </div>
    )
  }
  //context menu render
  const renderContextMenu = () => {
    return (
      <ContextMenu model={menu} ref={cm} onHide={() => setSelectedNodeKey(null)} />
    )
  }
  // render tree view
  const renderPanel = (name, icon, title = '', nodes = [], isDraggable = false, selected = null) => {

    const className = `w-full p-1 !text-xs !font-font-family-base bg-[#f8f9fa] flex justify-between truncate`
    return (
      <div
        key={name}
        className='flex flex-col w-[10%] border-l border-border'
      >
        <header className={className}>
          {name === 'left' ? title : null}
          <button className='flex items-center justify-center h-full' onClick={() => onCollapse(name)}>
            <FontAwesomeIcon className='pi text-[#6c757d]' icon={icon} />
          </button>
          {name === 'right' ? title : null}
        </header>
        <div className='flex-grow flex flex-col'>
          <TreeView
            nodes={nodes}
            isDraggable={isDraggable}
            selected={selected}
            selectionKeys={selectedEntity}
            selectionMode={"multiple"}
            metaKeySelection={false}
          />
        </div>
      </div>
    )
  }
  const renderQueries = () => {

    const className = `w-full p-1 !text-xs !font-font-family-base bg-[#f8f9fa] flex justify-between truncate`
    return (
      <div
        key={'left'}
        className='flex flex-col w-[10%] border-r border-border'
      >
        <header className={className}>
          {'Consultas'}
          <button className='flex items-center justify-center h-full' onClick={() => onCollapse('left')}>
            <FontAwesomeIcon className='pi text-[#6c757d]' icon={faArrowLeft} />
          </button>
        </header>
        <div className='flex-grow flex flex-col'>
          <TreeView
            disabled={querySelected?.data.isNew}
            nodes={queries}
            isDraggable={false}
            add={openAddQueryModal}
            onClickNode={onSelectQuery}
            selected={querySelected}
            contextMenu={renderContextMenu}
            nodeKey={selectedNodeKey}
            setNodeKey={setSelectedNodeKey}
            cm={cm}
            selectionKeys={querySelected?.key}
            selectionMode={"single"}
          />
        </div>
      </div>
    )
  }
  const renderTable = () => {
    return (
      <div id='divTable' className='h-full absolute left-0 right-0 overflow-x-auto '
        onDrop={(e) => onDrop(e)} onDragOver={(e) => onDragOver(e)}>
        <EvaluaTable
          value={filterList}
          style={{ height: 'calc(100vh - 9.7rem)' }}
          reorderableColumns
          onColReorder={(event) => {
            onColsChange(event);
          }}
          setSort={setSort}
          sort={sort}
          filterDisplay="row"
          emptyMessage={'sin resultados'}
          tableClassName='!text-[0.7rem] !border-border !border-y'
        >
          {columns && columns.map(column => (
            column.key ?
              <Column
                filterElement={
                  <FilterComponent
                    data={column}
                    filter={filter}
                    setFilter={setFilter}
                    type={column.filterType}
                  />
                }
                key={column.key}
                field={column.field}
                header={header(column)}
                sortable={true}
                style={column.width}
                filter
                showFilterMenu={false}
              />
              : <></>
          ))}
        </EvaluaTable>
      </div>
    )
  }
  // filter button render
  const renderFilterButton = () => {
    return (
      <div className='h-full border-r border-t border-border' hidden={querySelected === null}>
        <div className='h-[33.8px] border-b border-border bg-[#f8f9fa] ' />
        <button title={getTranslation(t, GenericsNames.CLEAN_FILTER)} className='' onClick={() => { clearFilter() }}  >
          <FontAwesomeIcon className='pi text-[#6c757d]' icon={faFilterSlash} />
        </button>
      </div>

    )
  }
  // collapse left and right panels
  const CollapsedPanel = ({ name, title, icon }) => {
    // div with center rotate text and icon arrow
    return (
      <div onClick={() => onCollapse(name)} className='flex flex-col w-[50px] h-full  items-center bg-[#f8f9fa] border-r border-l border-border cursor-pointer'>
        <button className='flex items-center justify-center w-full mt-4' >
          <FontAwesomeIcon className='pi text-[#6c757d]' icon={icon} />
        </button>
        <p className='transform -rotate-90 my-auto w-[200px] font-h1-font-family text-code-font-size'>
          {title}
        </p>
      </div>
    )
  }

  return (
    <>
      <div className='w-full h-full flex flex-col'>
        {headerContainer()}
        <div className='flex-grow flex'>
          {collapsed.left ?
            <CollapsedPanel name='left' title={'Consultas'} icon={faArrowRight} /> :
            renderQueries()
          }
          <div className='flex-grow  flex' >
            {renderFilterButton()}
            <div className='relative flex-grow'>
              {renderTable()}
            </div>
          </div>
          {collapsed.right ?
            <CollapsedPanel name='right' title={'Campos disponibles'} icon={faArrowLeft} /> :
            renderPanel('right', faArrowRight, 'Campos disponibles', entities, true)
          }
        </div>
      </div>
      {open ? <NewQueryDialog formik={formik} handler={formik.handleSubmit} open={open} setOpen={setOpen} dropdown={dropdown} onHide={onHide} /> : null}
      {edit ? <NewQueryDialog formik={editFormik} handler={editFormik.handleSubmit} open={edit} setOpen={setEdit} onHide={onHide} edit /> : null}
      {saveAs ? <NewQueryDialog formik={saveFormik} handler={saveFormik.handleSubmit} open={saveAs} setOpen={setSaveAs} onHide={onHide} copy /> : null}
      <DiscardDialog discard={discard} handlerAccept={handlerAccept} handlerDiscard={handlerDiscard} setDiscard={setDiscard} />
      <EvaluaAlertDialog
        open={deleteModal}
        setOpen={setDeleteModal}
        title={getTranslation(t, QueryNames.QUERY_DELETE_MODAL_TITLE)}
        message={getTranslation(t, QueryNames.QUERY_DELETE_MODAL_QUESTION)}
        onConfirmFunction={() => removeQuery(objectToDelete)}
        onCancelFunction={() => setDeleteModal(false)}
        agreeLabel={getTranslation(t, QueryNames.QUERY_DELETE)}
      />
      <EvaluaAlertDialog
        open={copyPredefinedModal}
        setOpen={setCopyPredefinedModal}
        title={getTranslation(t, QueryNames.QUERY_COPY_TITLE)}
        message={getTranslation(t, QueryNames.QUERY_COPY_NAME)}
        onConfirmFunction={() => copyPredefined()}
        onCancelFunction={() => cancelCopyPredefinedFn()}
        agreeLabel={getTranslation(t, GenericsNames.COPY)}
      />
    </>

  )
}


function conditionalSort(setFilterColumns, newColumns, entity, newFilter, newSort) {
  let temp = setFilterColumns(newColumns, entity, newFilter);
  if (temp !== null) {
    newSort = temp;
  }
  return newSort;
}

