import * as yup from 'yup';

import { GenericsNames, PositionNames, ZoneNames } from '../../../../translations';

import { regexDescription } from '../../../utils';

const ZoneSchema = yup.object().shape({
  scode: yup // licenseId: DataTypes.UUID,
    .string('')
    .required(ZoneNames.VALIDATOR_ID_REQUIRED)
    .matches(/^[0-9]+$/, ZoneNames.VALIDATOR_DIGIT_ALLOW)
    .min(4, ZoneNames.VALIDATOR_ID_REQUIRED_SIZE)
    .max(4, ZoneNames.VALIDATOR_ID_REQUIRED_SIZE)
    .test(
      'greaterThanZero',
      PositionNames.VALIDATOR_ID_GREATER_THAN_ZERO,
      (value) => parseInt(value, 10) > 0
    ),
  name: yup.string('')
  .required(ZoneNames.VALIDATOR_NAME_REQUIRED)
  .max(70, ZoneNames.VALIDATOR_NAME_REQUIRED_SIZE)  
  .matches(regexDescription, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS),
  //.matches(/^[0-9a-zñáéíóúü ]+$/i, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS),
});
export default ZoneSchema;
