import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNumber } from 'lodash';
import { Tree } from 'primereact/tree';
import React, { useState, useEffect } from 'react'
import './index.css'

export const TreeView = ({
  nodes,
  cm,
  selectionKeys,
  contextMenu,
  nodeKey,
  setNodeKey,
  add = null,
  onClickNode = () => null,
  _selected = null,
  isDraggable = false,
  disabled = false,
  ...props
}) => {
  const [selectedNode, setSelectedNode] = useState('');
  const [expandedKeys, setExpandedKeys] = useState({});
  const [flag, setFlag] = useState(false);
  const expandAll = () => {
    let _expandedKeys = {};
    for (let node of nodes) {
      expandNode(node, _expandedKeys);
    }

    setExpandedKeys(_expandedKeys);
  }
  useEffect(() => {
    if (!isDraggable && nodes.length > 0 && !flag) {
      console.log('isDraggable', isDraggable)
      setFlag(true)
      nodes && expandAll();
    }
  }
    , [nodes])
  const collapseAll = () => {
    setExpandedKeys({});
  }

  const expandNode = (node, _expandedKeys) => {
    if (node.children && node.children.length) {
      _expandedKeys[node.key] = true;

      for (let child of node.children) {
        expandNode(child, _expandedKeys);
      }
    }
  }

  const onDragStart = (node) => {
    setSelectedNode(node.label + '/' + node.key + '/' + node.data.propertyId + '/' + node.data.filterType);
  }

  const nodesTemplate = (node, options) => {
    //vamos a validar aqui
    let draggable = true;
    let container = <p className='text-[#5e5e5e]  '>{node.label}</p>
    if (node.children) {
      draggable = false;
      // if is second level reduce the font weight
      let fatherClass = '!font-bold text-sm'
      if (!isNumber(node.key)) {
        fatherClass = '!font-medium'
      }
      container = <p className={fatherClass}>{node.label}</p>
    }
    const classname = options.className + ' w-full h-full flex truncate py-1';

    for (const item of Object.entries(selectionKeys)) {
      if (item[0] === node.key) {
        draggable = false;
      }
    }

    return <span name={node.label} draggable={draggable} onDragStartCapture={() => onDragStart(node)} className={classname}>
      {container}
    </span>
  }

  const nodesTemplateNoDrag = (node, options) => {
    let container = <p className='text-[#5e5e5e]  '>{node.label}</p>
    if (node.children) {
      const styles = disabled ? 'opacity-40' : '';
      const button = add ? <button disabled={disabled} className=' text-white ' onClick={() => { add(node) }}><FontAwesomeIcon size='sm' className={`bg-[#75b6fc] p-1 mx-1  ${styles}`} icon={faPlus} /></button> : null;
      container = <div className='flex  text-center items-center'>
        <p className='font-bold w-3/4'>{node.label} </p>
        {button}
      </div>
    }
    const classname = `${options.className} w-full h-full`
    return <span name={node.label} className={classname} >
      {container}
    </span>
  }

  const selectNodes = (event) => {
    const node = findIndex(nodes, event.value);
    if (node != null && !node.children) {
      onClickNode(node)
    }
  }

  const selectContextMenu = (event) => {
    if (!setNodeKey) return
    if (!findIndex(nodes, event.value).children) {
      setNodeKey(event.value)
    }
  }

  const onContextMenu = (event) => {
    if (!setNodeKey) return
    if (!findIndex(nodes, event.node.key).children) {
      cm.current.show(event.originalEvent)
    }
  }

  return (
    <>
      {contextMenu ? contextMenu() : null}
      <div className="mx-2 flex justify-start">
        <button onClick={() => expandAll()} className='my-2 ml-2'>
          <FontAwesomeIcon size='sm' color='#5e5e5e' icon={faPlus} />
        </button>
        <button onClick={() => collapseAll()} className='my-2 ml-1'>
          <FontAwesomeIcon size='sm' color='#5e5e5e' icon={faMinus} />
        </button>
      </div>
      <div className='flex-grow relative'>
        <Tree
          expandedKeys={expandedKeys}
          onSelectionChange={e => selectNodes(e)}
          onToggle={e => setExpandedKeys(e.value)}
          dragdropScope={selectedNode}

          className='absolute top-0 bottom-0 w-full overflow-y-auto !text-xs !font-font-family-base !p-1 !border-none'
          value={nodes}
          nodeTemplate={isDraggable ? nodesTemplate : nodesTemplateNoDrag}
          contextMenuSelectionKey={nodeKey}
          onContextMenuSelectionChange={e => selectContextMenu(e)}
          onContextMenu={e => onContextMenu(e)}
          selectionKeys={selectionKeys}
          {...props}
        />
      </div>
    </>

  )
}
// find index in tree
export const findIndex = (nodes, value) => {
  for (let node of nodes) {
    if (node.key === value) {
      return node;
    }
    if (node.children) {
      const result = findIndex(node.children, value);
      if (result) {
        return result;
      }
    }
  }

  return null;
}