import React from 'react'

export const WorkArea = ({ toolbar, grid }) => {
  return (
    <div className='flex-grow h-full'>
      <div className="flex w-full h-full">
        <div className='flex w-full  h-full relative'>
          {toolbar && <div className='m-1'>{toolbar}</div>}
          {grid}
        </div>
      </div>
    </div>
  )
}