import * as React from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { CollaboratorsNames, GenericsNames, SurveyNames } from '../../translations';
import PropTypes from 'prop-types';
import { Input } from '../Input/Input';
import CustomDatePicker from '../Calendar/CustomDatePicker';
import { getTranslation, useCustomTranslation } from '../../hooks/useTranslations';
import { areaCodeMask } from '../../utils/utilsService';
import { CollaboratorGrid } from '../../containers/Catalogs/collaborators/Components/CollaboratorGrid';
import { BaseButton } from '../Buttons/BaseButton'
import { regexName } from '../../utils/utils';

const SurveyWizard = ({ formik, activeStep, onClickAddCollaborators,onRemoveCollaborator, edit=false, maximax }) => {
    // this is the hooks we need to bring  the information:: created   9/02/2022
    React.useEffect(() => {
        if (formik.values) {
            //console.log(formik.values)
            formik.validateForm()
        }
    }, [formik.values]);
    const { t } = useCustomTranslation();  
    const renderIdentificationFrequency = () => {
        return < div className='h-[50vh] px-3 pt-3 flex flex-col'>
             <div className='flex w-full gap-x-2' >
                <Input
                    important
                    type='scode'
                    id='scode'
                    name='scode'
                    placeholder={getTranslation(t, SurveyNames.NUMBER_SURVEY)}
                    value={formik.values.scode}
                    onChange={(event) => {
                        const paddingZero = areaCodeMask(event.target.value)
                        formik.setFieldValue('code', Number(event.target.value))
                        formik.setFieldValue('scode', paddingZero)
                      }}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.errors.scode)}
                    errorText={formik.touched.scode && getTranslation(t, formik.errors.scode)}
                />
                <Input
                    important
                    type='name'
                    id='name'
                    name='name'
                    placeholder={getTranslation(t, SurveyNames.NAME)}
                    value={formik.values.name}
                    onChange={(event) => {
                        // validation for name using regexName
                        if (regexName.test(event.target.value)) {
                        formik.setFieldValue('name', event.target.value)
                        }
                    }}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.errors.name)}
                    errorText={formik.touched.name && getTranslation(t, formik.errors.name)}
                />
            </div>
            <h2 className='mt-1' >
                {getTranslation(t, SurveyNames.EVALUATION_PERIOD)} 
            </h2>
            <div className='flex w-full gap-x-2' >
                <CustomDatePicker
                    important
                    formik={formik}
                    label={getTranslation(t, SurveyNames.START_DATE)}
                    name={'startDate'}
                    onChange={ (event) => {
                        formik.setFieldValue('startDate', event.target.value)
                    }}
                    labelClassName='text-small-font-size font-semibold font-input-font-family'
                    maxDate={formik.values?.endDate ? new Date(formik.values?.endDate) : null}
                    error={Boolean(formik.errors.startDate)}
                    errorText={formik.touched.startDate && getTranslation(t, formik.errors.startDate)}
                ></CustomDatePicker>
                <CustomDatePicker
                    important
                    labelClassName='text-small-font-size font-semibold font-input-font-family'
                    formik={formik}
                    label={getTranslation(t, SurveyNames.END_DATE)}
                    name={'endDate'}
                    minDateField={ formik.values?.startDate ? new Date(formik.values?.startDate) : null}
                    onChange={ (event) => {
                        formik.setFieldValue('endDate', event.target.value)
                        formik.validateField('endDate')
                    }}
                    error={Boolean(formik.errors.endDate)}
                    errorText={formik.touched.endDate && getTranslation(t, formik.errors.endDate)}
                ></CustomDatePicker>
            </div>
            
            {edit ?
                <>
                <span className='mt-1 !font-input-font-family flex flex-col flex-grow'>
                    <label className='text-small-font-size font-semibold ' htmlFor='conclusion'>{getTranslation(t, SurveyNames.CONCLUSION)}</label>
                    <InputTextarea 
                        className='w-full flex-grow'
                        maxLength={1024}
                        id='conclusion'
                        name='conclusion' 
                        style={{resize: 'none'}}
                        onChange={
                            (event) => {
                                formik.setFieldValue('conclusion', event.target.value)
                            }
                        }
                        value={formik.values.conclusion}
                        onBlur={formik.handleBlur}
                    />
                    <small className="conclusion-help">{`${formik.values.conclusion?.length || 0}/${1024}`}</small>
                </span>
                </>
            : null}
        </div>
    }

    const renderCollaboratorList = () => {
        return (
            <div className='px-3 pt-3'>      
                <BaseButton className='!my-1'  onClick={onClickAddCollaborators} label={getTranslation(t, GenericsNames.ADD)} />                         
                <CollaboratorGrid
                    rows={formik.values.collaborators ?? []}
                    onDelete={onRemoveCollaborator}
                    name={"collaboratorgrid"}
                    text={getTranslation(t, CollaboratorsNames.EMPTY_COLLABORATOR_ADD)}
                    showToolbar={false} 
                    style={ { height:'calc(50vh - 60px)' }}
                    >
                </CollaboratorGrid>
            </div>
        )
    }


    const renderStep = (step) => {
        switch (step) {
            case 0:
                return renderIdentificationFrequency();
            case 1:
                return renderCollaboratorList();
            default:
                return <></>;
        }
    };


    return (
        <div className='border-b border-border p-1 flex-grow'>
            {formik && renderStep(activeStep)}
        </div>
    );
};

SurveyWizard.propTypes = {
    formik: PropTypes.object,
    activeStep: PropTypes.number,
    onClickAddCollaborators:PropTypes.func.isRequired,  
    onRemoveCollaborator:PropTypes.func.isRequired 
}

export default SurveyWizard



