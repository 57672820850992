import PropTypes from 'prop-types';
import React from 'react';

const CompanyItem = ({item,className}) => {
    return (
        <div className={className}>
            <h1 className='font-font-family-base font-semibold' style={{color:'#003a70'}}>
                {item.taxID}
            </h1>
            <h2>
                {item.name}
            </h2>
            <h2 className="italic " data-toggle="tooltip" title={item.alias}>
                &nbsp;&nbsp;  {item.alias}
            </h2>   
            </div>
    );
};

CompanyItem.propTypes = {
    item:PropTypes.object,
    className:PropTypes.string
};

export default CompanyItem;