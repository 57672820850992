export const queryState = {
    query:[],
    entity:[],
    entityList:[],
    queryProperties:{
    },
    queryData:{},
    newQuery: null,
    loading: false,
    error: null,
    errorShow: false,
    save: true
}
export const queryPercistence = {
    columns: [],
    filter: {},
    filterList: [],
    sort: [],
    selectedEntity: null,
    querySelected: null
}