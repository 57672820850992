import { NavigationNames, SidebarNames } from './../translations/index';
import { faClipboardCheck, faClipboardList, faCog, faSearch, faWalking } from '@fortawesome/free-solid-svg-icons';
const routes = [
  {
    id: 'menus',
    title: 'menus',
    translate: SidebarNames.SidebarSurveys, // SidebarNames.SURVEYS,
    type: 'item',
    icon: faClipboardCheck,
    url: '/menus/surveys',
    childrends: [
      //{
      //surveys: [
      {
        id: 'guide-1',
        title: SidebarNames.MENU_GUIDE1,
        tapTitle: SidebarNames.GUIDE1,
        type: 'collapse',
        icon: 'alt_route',
        url: '/menus/surveys/guide-1',
        children: [
          {
            id: 'guide-1-survey',
            title: SidebarNames.SidebarSurveys,
            tapTitle: SidebarNames.SidebarSurveys,
            type: 'item',
            url: '/guide-1/survey/1',
          },
          /*  JCMG: Guía 1 no tiene ventana de resultados tiket 342347        {
                      id: 'guide-1-results',
                      title: SidebarNames.RESULTS,
                      tapTitle: SidebarNames.RESULTS,
                      type: 'item',
                      url: '/guide-1/results/1'
                    },*/
          {
            id: 'guide-1-questions',
            title: SidebarNames.QUESTIONS,
            tapTitle: SidebarNames.QUESTIONS,
            type: 'item',
            url: '/guide-1/questions/1'
          },
          {
            id: "guide-1-surveysCollaborators",
            tapTitle: SidebarNames.QUESTIONS_GIDE,
            url: '/guide-1/surveysCollaborators/1',
          },
          {
            id: "guide-1-score-ranges",
            tapTitle: SidebarNames.SCORE_RANGES,
            url: '/guide-1/score-ranges/guideId/1'
          },
        ]
      },
      {
        id: 'guide-2',
        title: SidebarNames.MENU_GUIDE2,
        tapTitle: SidebarNames.GUIDE2,
        type: 'collapse',
        icon: 'alt_route',
        url: '/menus/surveys/guide-2',
        children: [

          {
            id: 'guide-2-survey',
            title: SidebarNames.SidebarSurveys,
            tapTitle: SidebarNames.SidebarSurveys,
            type: 'item',
            url: '/guide-2/survey/2'
          },
          {
            id: 'guide-2-results',
            title: SidebarNames.RESULTS,
            tapTitle: SidebarNames.RESULTS,
            type: 'item',
            url: '/guide-2/results/2'
          },
          {
            id: 'guide-2-questions',
            title: SidebarNames.QUESTIONS,
            tapTitle: SidebarNames.QUESTIONS,
            type: 'item',
            url: '/guide-2/questions/2'
          },
          {
            id: "guide-2-surveysCollaborators",
            tapTitle: SidebarNames.QUESTIONS_GIDE,
            url: '/guide-2/surveysCollaborators/2',
          },
          {
            id: "guide-2-score-ranges",
            tapTitle: SidebarNames.SCORE_RANGES,
            url: '/guide-2/score-ranges/guideId/2'
          }
        ]
      },
      {
        id: 'guide-3',
        title: SidebarNames.MENU_GUIDE3,
        tapTitle: SidebarNames.GUIDE3,
        type: 'collapse',
        icon: 'alt_route',
        url: '/menus/surveys/guide-3',
        children: [

          {
            id: 'guide-3-survey',
            title: SidebarNames.SidebarSurveys,
            tapTitle: SidebarNames.SidebarSurveys,
            type: 'item',
            url: '/guide-3/survey/3'
          },
          {
            id: 'guide-3-results',
            title: SidebarNames.RESULTS,
            tapTitle: SidebarNames.RESULTS,
            type: 'item',
            url: '/guide-3/results/3'
          },
          {
            id: 'guide-3-questions',
            title: SidebarNames.QUESTIONS,
            tapTitle: SidebarNames.QUESTIONS,
            type: 'item',
            url: '/guide-3/questions/3'
          },
          {
            id: "guide-3-surveysCollaborators",
            tapTitle: SidebarNames.QUESTIONS_GIDE,
            url: '/guide-3/surveysCollaborators/3',
          },
          {
            id: "guide-3-score-ranges",
            tapTitle: SidebarNames.SCORE_RANGES,
            url: '/guide-3/score-ranges/guideId/3'
          }
        ]
      },
      // {
      //   id: 'own',
      //   title: SidebarNames.OWN,
      //   tapTitle: SidebarNames.OWN,
      //   type: 'collapse',
      //   icon: 'alt_route',
      //   url: '/menus/surveys/own',
      //   children: [

      //     {
      //       id: 'own-survey',
      //       title: SidebarNames.SidebarSurveys,
      //       tapTitle: SidebarNames.SidebarSurveys,
      //       type: 'item',
      //       url: '/own/survey/4'
      //     },
      //     {
      //       id: 'own-results',
      //       title: SidebarNames.RESULTS,
      //       tapTitle: SidebarNames.RESULTS,
      //       type: 'item',
      //       url: '/own/results/4'
      //     },
      //     {
      //       id: 'own-questions',
      //       title: SidebarNames.QUESTIONS,
      //       tapTitle: SidebarNames.QUESTIONS,
      //       type: 'item',
      //       url: '/own/questions/4'
      //     },
      //     {
      //       id: 'own-surveysCollaborators',
      //       tapTitle: SidebarNames.QUESTIONS_GIDE,
      //       url: '/own/surveysCollaborators/4'
      //     },
      //     {
      //       id: "own-score-ranges",
      //       tapTitle: SidebarNames.SCORE_RANGES,
      //       url: '/own/score-ranges/guideId/4'
      //     }
      //   ]
      // }
      // ]
      //    },
    ]
  },
  {
    id: 'catalogs',
    title: 'catalogs',
    translate: SidebarNames.SidebarCatalog, //SidebarNames.CATALOGS,
    type: 'item',
    icon: faClipboardList,
    url: '/catalogs',
    childrends: [
      {
        id: 'catalogs-collaborators',
        title: NavigationNames.COLLABORATORS,
        tapTitle: NavigationNames.COLLABORATORS,
        type: 'item',
        icon: 'supervisor_account',
        url: '/catalogs/collaborators'
      },
      // {
      //   id: 'periods',
      //   title: NavigationNames.PERIODS,
      //   tapTitle: NavigationNames.PERIODS,
      //   type: 'item',
      //   icon: 'event_note',
      //   url: '/menus/catalogs/periods'
      // },
      {
        id: 'catalogs-departments',
        title: NavigationNames.DEPARTMENTS,
        tapTitle: NavigationNames.DEPARTMENTS,
        type: 'item',
        icon: 'home_work',
        url: '/catalogs/departments'
      },
      {
        id: 'catalogs-positions',
        title: NavigationNames.POSTS,
        tapTitle: NavigationNames.POSTS,
        type: 'item',
        icon: 'assignment_ind',
        url: '/catalogs/positions'
      },
      {
        id: 'catalogs-zones',
        title: NavigationNames.ZONE,
        tapTitle: NavigationNames.ZONE,
        type: 'item',
        icon: 'group_work',
        url: '/catalogs/zones'
      },
      {
        id: 'catalogs-workCenters',
        title: NavigationNames.WORK_CENTER,
        tapTitle: NavigationNames.WORK_CENTER,
        type: 'item',
        icon: 'engineering',
        url: '/catalogs/workCenters'
      },
      {
        id: 'catalogs-evaluators',
        title: NavigationNames.EVALUATORS,
        tapTitle: NavigationNames.EVALUATORS,
        type: 'item',
        icon: 'history_edu',
        url: '/catalogs/evaluators'
      }
    ]
  },
  {
    id: 'queries',
    translate: SidebarNames.SidebarInquiries,// SidebarNames.INQUIRIES,
    type: 'item',
    icon: faSearch,
    url: '/queries',
    tapTitle: NavigationNames.INQUIRIES,
    title: NavigationNames.INQUIRIES
    /* badge: {
      title: 3,
      bg: 'rgb(255, 111, 0)',
      fg: '#FFFFFF',
    }, */
  },
  /*{
    id: 'FollowUp',
    title: 'Follow Up',
    translate: SidebarNames.SidebarFollowUp,// ,SidebarNames.FOLLOW_UP,
    type: 'item',
    icon: faWalking,
    url: '/menus/follow-up',
    childrends: []
  },*/
  {
    id: 'configuration',
    title: 'configuration',
    translate: SidebarNames.SidebarSettings,//SidebarNames.CONFIGURATION,
    type: 'toggle_on',
    icon: faCog,
    url: '/menus/configuration',
    childrends: [
      {
        id: 'configuration-company',
        title: NavigationNames.COMPANY,
        tapTitle: NavigationNames.COMPANY,
        type: 'item',
        icon: 'fa-window-flip',
        url: '/configuration/company',
        saveBeforeClose: true /* A flag that tells the app to save the data before closing the tab. */
      },
      {
        id: 'configuration-import',
        title: NavigationNames.IMPORT_DATA,
        tapTitle: NavigationNames.IMPORT_DATA,
        type: 'item',
        icon: 'fa-file-import',
        url: '/configuration/import'
      },
      // {
      //   id: 'configuration-migrator',
      //   title: NavigationNames.MIGRATOR,
      //   tapTitle: NavigationNames.MIGRATOR,
      //   type: 'item',
      //   icon: 'fa-file-import',
      //   url: '/configuration/migrator'
      // }
    ]
    /* badge: {
      title: 13,
      bg: 'rgb(9, 210, 97)',
      fg: '#FFFFFF',
    }, */
  }
]

export default routes;