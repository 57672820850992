import { EntityToExport, ToExcel, ToPdf } from '../../../../utils/exportService'
import { GenericsNames } from '../../../../translations'
/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react'
import { printGrid } from '../../../../utils/utils';
import { faPencilAlt, faPlusCircle, faTrashAlt, } from '@fortawesome/free-solid-svg-icons'
import { getTranslation, useCustomTranslation } from '../../../../hooks/useTranslations'
import { CollaboratorsNames } from './../../../../translations/index';
import { CollaboratorsObject } from '../../../../utils/Constanst/FormikConstanst/Collaborator/CollaboratorObject';
import { CollaboratorsSchema } from '../../../../utils/Constanst/FormikConstanst/Collaborator/CollaboratorsSchema';
//import { EvaluaTable } from 'app/common/component/EvaluaTable';
//import EvaluaTable from '../../../../Components/EvaluaTable';
import { Column } from 'primereact/column';
import { CustomIconButton } from '../../../../Components/Buttons/CustomIconButton';
import { EvaluaTable } from '../../../../Components/Table/EvaluaTable';
import { FilterMatchMode } from 'primereact/api'
import { StoreContext } from '../../../../business/Provider';
import { TableButton } from '../../../../Components/Buttons/TableButtons';
import ToolBarThreeColumns from '../../../../Components/Table/ToolBarThreeColumns';
import { useFormik } from 'formik';

import { Toast } from '../../../../Components/toast';


export const CollaboratorGrid = ({
  onEdit,
  onDelete,
  loading,
  rows,
  onAdd,
  selectionMode,
  selection,
  onSelectionChange,
  showToolbar = true,
  actionColumn = true,
  style = { height: 'calc(100vh - 106px)', width: 'calc(100vw - 92px)' },
  text,
  hasRelationWithPersonia,
  iNeedDisabled = false,
}) => {

  const { t } = useCustomTranslation()
  const { dispatchLoading } = useContext(StoreContext)
  const statusColumns = [
    { label: getTranslation(t, CollaboratorsNames.HIGH), value: 'A' },
    { label: getTranslation(t, CollaboratorsNames.SHORT), value: 'B' },
    { label: getTranslation(t, CollaboratorsNames.REENTRY), value: 'R' }
  ]

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [sort, setSort] = useState([{ field: '', order: -1 }])

  const onGlobalFilterChange = (e) => {

    const { value } = e.target;
    const _filters = { ...filters };
    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  }

  const formik = useFormik({
    initialValues: { ...CollaboratorsObject },
    validationSchema: CollaboratorsSchema,

  })


  /** Renders * */
  const exportColumns = [
    { title: getTranslation(t, GenericsNames.NAME), dataKey: 'fullName' },
    { title: getTranslation(t, GenericsNames.ZONE), dataKey: 'area' },
    { title: getTranslation(t, GenericsNames.DEPARTMENT), dataKey: 'department' },
    { title: getTranslation(t, GenericsNames.POSTS), dataKey: 'position' },
    { title: getTranslation(t, GenericsNames.WORK_CENTER), dataKey: 'workCenter' },
    { title: getTranslation(t, GenericsNames.ACTIVE), dataKey: 'isActive' },
    { title: getTranslation(t, GenericsNames.STATUS), dataKey: 'status' },];


  const handleExcelExport = async () => {
    const printObj = { ...filters, sort: sort[0] };

    if (rows.length > 0) {
      await ToExcel(printObj, EntityToExport.CATALOG_COLLABORATOR, dispatchLoading)
    } else {
      Toast('warning', getTranslation(t, GenericsNames.NO_EXPORT_DATA))
    }

  }
  const handleToPDF = async () => {
    const printObj = { ...filters };
    if (rows.length > 0) {
      return ToPdf(printObj, EntityToExport.CATALOG_COLLABORATOR, dispatchLoading)
    }
    else {
      Toast('warning', getTranslation(t, GenericsNames.NO_EXPORT_DATA))
    }

  }
  const renderHeader = () => (

    <ToolBarThreeColumns
      setValue={setGlobalFilterValue}
      setFilters={setFilters}
      rightChildren={
        <CustomIconButton
          className='!m-1'
          toolTip={hasRelationWithPersonia ? getTranslation(t, CollaboratorsNames.PERSONIA_ADD) : getTranslation(t, GenericsNames.ADD)}
          disabled={hasRelationWithPersonia}
          onClick={() => { onAdd() }}
          icon={faPlusCircle}
        />}
      value={globalFilterValue}
      onChange={onGlobalFilterChange}
      formik={formik}
      placeholder={getTranslation(t, GenericsNames.SEARCH)}
      onExcelClick={() => {
        handleExcelExport()
      }}
      onPDFClick={() => {
        handleToPDF()
      }}
      onPrintClick={() => {
        const printObj = { exportableColumns: exportColumns, rows: rows, title: getTranslation(t, CollaboratorsNames.TITLE), subTitle: '' };
        printGrid(printObj);
      }}
    >

    </ToolBarThreeColumns>
  );

  const statusTemplate = (rowData) => {
    return (
      <div>
        {statusColumns.find((_status) => _status.value === rowData.status)?.label}
      </div>
    )
  }


  const actionBodyTemplate = (rowData) => {
    return (
      <>
        {onEdit &&
          <TableButton
            onClick={() => onEdit(rowData)}
            icon={faPencilAlt}
            toolTip={getTranslation(t, GenericsNames.EDIT)}
          />}
        <TableButton
          onClick={() => onDelete(rowData)}
          icon={faTrashAlt}
          toolTip={hasRelationWithPersonia ? getTranslation(t, CollaboratorsNames.PERSONIA_DELETE) : getTranslation(t, GenericsNames.DELETE)}
          disabled={(hasRelationWithPersonia || (rowData.disableDelete && rowData.disableDelete === true))}
        />
      </>
    );
  }

  const isSelectable = (data) => {
    let isSelectable = true;
    if (iNeedDisabled) {
      if (data.email === null) {
        isSelectable = false;
      }
      if (data.departmentName === null) {
        isSelectable = false;
      }
      console.log(isSelectable, 'isSelectable')

    }
    return isSelectable;
  }

  const isRowSelectable = (event) => {
    console.log(event.data.email, 'event')
    console.log(event.data.departmentName, 'event')
    return isSelectable(event.data);

  }

  const rowClassName = (data) => {
    return isSelectable(data) ? '' : 'p-disabled';
  }

  return (
    <>
      {
        <div className='datatable-doc-demo'>
          <div className='card'>
            <EvaluaTable
              setSort={setSort}
              sort={sort}
              style={style}
              isDataSelectable={isRowSelectable}
              value={rows || []}
              sortField='name'
              header={showToolbar ? renderHeader() : null}
              filters={filters}
              //filterDisplay='row' // input search
              responsiveLayout='scroll'
              globalFilterFields={['fullName', 'area', 'department', 'position', 'workCenter', 'taxId', 'isActive']}
              emptyMessage={text}
              currentPageReportTemplate={getTranslation(t, CollaboratorsNames.GRID_PAGE_REPORT_TEMPLATE)}
              doubleClick={onEdit}
              rowClassName={rowClassName}
              // height={height}
              selectionMode={selectionMode}
              selection={selection}
              onSelectionChange={onSelectionChange}
              columnResizeMode="fit"
              loading={loading}
            >
              {selectionMode &&
                <Column
                  selectionMode="multiple"
                  //headerStyle={{ width: "5px" }}
                  style={{ flexGrow: 1, flexBasis: '100px' }}
                ></Column>

              }
              <Column
                field='fullName'
                header={getTranslation(t, GenericsNames.NAME)}
                sortable
                style={{ flexGrow: 1, flexBasis: '100px' }}
              />
              <Column
                field='taxId'
                style={{ flexGrow: 1, flexBasis: '100px' }}
                header={getTranslation(t, CollaboratorsNames.TAX_ID)}
                sortable
              />
              <Column
                field='area'
                header={getTranslation(t, GenericsNames.AREA)}
                sortable
                style={{ flexGrow: 1, flexBasis: '100px' }}

              />
              <Column
                field='department'
                header={getTranslation(t, GenericsNames.DEPARTMENT)}
                sortable
                style={{ flexGrow: 1, flexBasis: '100px' }}
              />
              <Column
                field='position'
                header={getTranslation(t, GenericsNames.POSITION)}
                sortable
                style={{ flexGrow: 1, flexBasis: '100px' }}
              />
              <Column
                field='workCenter'
                header={getTranslation(t, GenericsNames.WORK_CENTER)}
                sortable
                style={{ flexGrow: 1, flexBasis: '100px' }}
              />
              <Column
                field='status'
                header={getTranslation(t, GenericsNames.STATUS)}
                sortable
                style={{ flexGrow: 1, flexBasis: '100px' }}
                body={statusTemplate}
              />
              <Column
                header={getTranslation(t, GenericsNames.GRID_ACTION)} //Modal de editar encuesta
                headerStyle={{ width: '4rem', textAlign: 'center' }}
                bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                body={actionBodyTemplate}
                style={{ flexGrow: 1, flexBasis: '100px' }}
                hidden={!actionColumn}
              />
            </EvaluaTable>
          </div>
        </div>
      }
    </>
  )
}