
import { getTranslation, useCustomTranslation } from '../../hooks/useTranslations';

import PropTypes from 'prop-types';
import React from 'react';
import { getIn } from 'formik';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';

const CustomDatePicker = ({ formik, name, label, size, filterDate, minDateField, className, onChange, labelClassName, important, error=false, errorText='',...props }) => {
    let today = new Date();
    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });
    const { t } = useCustomTranslation();
    return (
        <>
             <div className={`  ${className ? `field col-12 md:col-4 ml-1 ${className}`  : 'flex flex-col w-full'}`}>
                        <label className={`text-small-font-size font-semibold flex ${labelClassName}`} htmlFor={name}>{label} <div hidden={!important} className='h-2 w-2 rounded-full bg-red ml-2 my-auto'></div></label>
                        <Calendar 
                            name={name}
                            className={`${(error && errorText ) ? 'p-invalid' : ''} `}
                            inputClassName={className ? '!border-none !outline-none !p-0 !text-base !font-input-font-family' : ''}
                            id={name} 
                            onHide={() => filterDate()} 
                            value={ formik.values[name] instanceof Date ? formik.values[name] : formik.values[name] === null ? null : new Date(formik.values[name]) }
                            onChange={onChange} 
                            locale="es" 
                            dateFormat='dd-MM-yy' 
                            minDate={minDateField || null}
                            showButtonBar
                            todayButtonClassName='hidden'
                            onClearButtonClick={() => formik.setFieldValue(name, null)}
                            
                            {...props}
                        />

                        {(error && errorText ) && <small htmlFor={name} className="p-error block !font-input-font-family">{errorText}</small>}
            </div>
            
        </>

    );
};
CustomDatePicker.defaultProps = {
    size: 6,
    filterDate : () => {},
    labelClassName: 'text-xs font-normal font-input-font-family',
    important: false,
    onChange: () => {}
}
CustomDatePicker.propTypes = {
    name: PropTypes.string.isRequired,
    formik: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    size: PropTypes.number,
    filterDate: PropTypes.func,
    labelClassName: PropTypes.string,
    className: PropTypes.string,
};

export default CustomDatePicker;