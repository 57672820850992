import moment from 'moment';
import Moment from 'react-moment';
import { convertDate } from './utils';
export const areaCodeMask = (value) => {
  return ('0000' + value).slice(-4);
};
export const IsNullOrEmpty = (str) => {
  return str === null || str === undefined || str.length === 0 || str === '';
}


export const DateBodyTemplate = (rowData, col) => {
  const value = convertDate(rowData[col.field])
  if (IsNullOrEmpty(rowData[col.field])) {
    return (
      <>
        {/* {/* <span className="p-column-title">{col.header}</span>                */}
        <span> {moment(value).format('DD/MM/YYYY')}</span>
      </>
    );
  }

  return (
    <>
      {/* <span className="p-column-title">{col.header}</span>                */}
      <span>
        {moment(value).format('DD/MM/YYYY')}
      </span>
    </>
  );
};
