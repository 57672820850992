import React, { useContext, useEffect, useState } from 'react'
import { GenericsNames } from '../../../../translations'
import { getTranslation, useCustomTranslation } from '../../../../hooks/useTranslations'

import { DialogWithTab } from '../../../../Components/Dialogs/DialogsWithTab'
import { renderAddressInputs, renderWorkCenterInputs } from './WorkCenterForms';
import useStates from '../../../../hooks/states/useStates';
import { StoreContext } from '../../../../business/Provider';
import UseCities from '../../../../hooks/states/UseCities';
import useSuburbs from '../../../../hooks/states/useSuburbs';

export const WorkCenterDialog = ({
  open,
  onClose,
  formik,
  onSave,
  title,
  evaluators,
  zipCodeFound,
  onZipSearch,
  suburbs,
  setZipCode,
  _municipalities,
  activeTab,
  setActiveTab
}) => {
  const { t } = useCustomTranslation();
  const context = useContext(StoreContext)
  const { getStatesCities } = useStates()
  const { getCities } = UseCities()
  const { getSuburbs } = useSuburbs()
  const [cities, setCities] = useState([])
  const [disable, setDisable] = useState(false)
  const [states, setStates] = useState([])
  const [suburb, setSuburb] = useState([])

  useEffect(() => {
    if (context.suburbsState.loading === false) {
      setSuburb(context.suburbsState.suburbs)
    }
  }, [context.suburbsState])

  useEffect(() => {
    if (context.citiesState.loading === false) {
      setCities(context.citiesState.cities)
    }
  }, [context.citiesState])

  useEffect(() => {
    if (context.statesState.loading === false) {
      setStates(context.statesState.states)
    }
  }, [context.statesState])

  useEffect(() => {
    // if important values are empty, disable the button
    if (formik.values.zipCode === '' ||
      formik.values.municipality === '' ||
      formik.values.suburb === '' ||
      formik.values.street === '' ||
      formik.values.state === '' ||
      formik.values.exteriorNumber === '' ||
      formik.values.scode === '' ||
      formik.values.name === '') {
      setDisable(true)
    } else {
      setDisable(false)
    }
  }, [formik.values])
  useEffect(() => {
    if (formik.values.zipCode !== '' && open) {
      onZipSearch(formik.values.zipCode)
    }
  }, [open])

  useEffect(() => {
    if (zipCodeFound === false && open) {
      if (formik.values.suburb &&
        formik.values.state &&
        formik.values.municipality &&
        formik.values.state !== '' &&
        formik.values.municipalities !== '' &&
        formik.values.suburb !== '') {
        getStatesCities(states, formik)
      }
    }
  }, [zipCodeFound])

  const items = [
    {
      textKey: 'Centro de trabajo', component: renderWorkCenterInputs(t, formik, evaluators),
      label: 'Centro de trabajo', icon: 'pi pi-fw pi-user',
      validationFields: ["scode", "name", "evaluatorId", "activities", "objective", "principalActivity", "isActive"]
    },
    {
      textKey: 'Domicilio', component: renderAddressInputs(t, formik, onZipSearch, setZipCode, states, suburbs, zipCodeFound, getCities, cities, getSuburbs, suburb),
      label: 'Domicilio', icon: 'pi pi-fw pi-file',
      validationFields: ["zipCode", "state", "municipality", "idSuburb", "street", "exteriorNumber", "interiorNumber"]
    },
  ]

  return (
    <DialogWithTab
      textSave={getTranslation(t, GenericsNames.SAVE)}
      textCancel={getTranslation(t, GenericsNames.CANCEL)}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      open={open}
      title={title}
      onClose={onClose}
      formik={formik}
      items={items}
      onSave={onSave}
      disabled={disable}
    />
  )
}