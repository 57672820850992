import React from 'react'

export const SideContainer = ({ children, className, title }) => {
    return (
        <div className={`h-full p-2 font-input-font-family flex flex-col gap-y-2 border-r border-border ${className}`}>
            <header>
                <h5 className='text-font-size-base font-font-family-base font-semibold text-center'>{title}</h5>
            </header>
            {children}
        </div>
    )
}