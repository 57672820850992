import { useEffect } from "react";
import { ERROR_SHOW } from "../business/constants";
import { Toast } from "../Components/toast";


export default function useError(data , dispatch) {
    useEffect(() => {
            if(!data.errorShow && data.error) {
                Toast('warning', data.error?.header, data.error?.body);
                dispatch({type: ERROR_SHOW});
            }    
    }
    , [data]);
    // specific  error
    const ToastError = (errorMessage, apiMessage='') => {
        Toast('warning', errorMessage, apiMessage);
    }
    return {ToastError};
} 