import * as yup from 'yup'

import { CompanyNames, GenericsNames, ZoneNames } from '../../../../translations'
import { regexDescription, regexRFC, regexName } from '../../../utils'

const CompanySchema = yup.object().shape({
  name: yup
    .string('')
    .required(CompanyNames.VALIDATOR_REQUIRED)
    // .min(4, 'El código postal debe tener al menos 5 caracteres')
    .max(254, CompanyNames.VALIDATOR_NAME_REQUIRED_SIZE)
    .matches(regexName, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS),
  taxID: yup
    .string()
    .required(CompanyNames.VALIDATOR_REQUIRED)
    .matches(/^([A-ZÑa-zñ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Za-z|\d]{3})$/, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS)
    .nullable(),
  zipCode: yup
    .string('')
    .required(CompanyNames.VALIDATOR_REQUIRED),
  state: yup
    .string('')
    .required(CompanyNames.VALIDATOR_REQUIRED),
  municipalty: yup
    .string('')
    .required(CompanyNames.VALIDATOR_REQUIRED),
  suburb: yup
    .string('')
    .required(CompanyNames.VALIDATOR_REQUIRED),
  street: yup
    .string('')
    .required(CompanyNames.VALIDATOR_REQUIRED),
  exterior: yup
    .string('')
    .required(CompanyNames.VALIDATOR_REQUIRED),
  logoFile: yup.mixed().test("InvalidSize", CompanyNames.LOGO_VALIDATOR, (value) => {

    if (value === 'InvalidSize') {
      return false;
    }
    return true;
  }),

})


export default CompanySchema