import React, { useState, useEffect, useRef, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { BreadCrumb } from '../Components/breadcrum'
import { NavBar } from '../Components/NavBar'
import { Sidebar } from '../Components/Sidebar'
import { ToastContainer } from 'react-toastify'
import { Dialog } from 'primereact/dialog';
import logo from '../assets/img/Logotipo.png'
import { Button } from 'primereact/button';
import "react-toastify/dist/ReactToastify.css";
import { ConfirmDialog } from 'primereact/confirmdialog';
import { LoadingContext } from '../context/LoadingContext'
import { useMsal } from '@azure/msal-react'
import useApi from '../hooks/api'
export const MainLayout = () => {
  // msalInstance
  // execute the keepAlive function every 5 minutes if concurretUser is true
  const [showPopover, setShowPopover] = useState(false)
  const [showCompanyInfo, setShowCompanyInfo] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [showCloseDialog, setShowCloseDialog] = useState(false)
  const refCloseProfileCard = useRef(null)
  const refCloseCompanyInfo = useRef(null)
  // execute the keepAlive function every 4.30 min 

  const { genericApiCall } = useApi();
  // useLoading 
  const loadingObj = useContext(LoadingContext)
  const baseUrl = `${window.REACT_APP_EVALUA_API_URI}/session`
  //const [userMenu, setUserMenu] = useState(null)
  const { instance } = useMsal()

  const signOff = async () => {
    loadingObj.setLoading(true)
    const result = await genericApiCall(baseUrl, 'DELETE', {})
    loadingObj.setLoading(false)
    if (result.status === 200) {
      // localStorage to string
      const url = instance.getConfiguration().auth.postLogoutRedirectUri + 'logout'
      // get redirect url
      const currentAccount = instance.getAllAccounts()[0]
      instance.logoutRedirect({
        account: currentAccount,
        postLogoutRedirectUri: url
      })
    } else {
      Toast('warning', error.message, "", error)
    }
    return null;
  }
  useEffect(() => {
    if (showPopover !== false) {
      const checkIfClickedOutside = (e) => {
        if (refCloseProfileCard.current && !refCloseProfileCard.current.contains(e.target)) {
          setShowPopover(false)
        }
      }
      window.addEventListener('mousedown', checkIfClickedOutside)
      return () => {
        window.removeEventListener('mousedown', checkIfClickedOutside)
      }
    }
  }, [showPopover])


  useEffect(() => {
    if (showCompanyInfo !== false) {
      const checkIfClickedOutside = (e) => {
        if (refCloseCompanyInfo.current && !refCloseCompanyInfo.current.contains(e.target)) {
          setShowCompanyInfo(false)
        }
      }
      window.addEventListener('mousedown', checkIfClickedOutside)
      return () => {
        window.removeEventListener('mousedown', checkIfClickedOutside)
      }
    }
  }, [showCompanyInfo])

  const renderFooter = () => {
    return (
      <div>
        <Button label="Ok" onClick={() => setShowDialog(false)} className="p-button-text" />

      </div>
    );
  }

  const renderdialog = () => {
    return (
      <Dialog header="Acerca de" visible={showDialog} style={{ width: '30vw' }} footer={renderFooter('displayBasic')} onHide={() => setShowDialog(false)}>
        <div className="flex w-full h-full flex-col justify-center items-center content-center">
          <div className='flex w-[125px] pl-1 justify-center item-center align-bottom m-auto'>
            <img className="object-scale-down w-full h-12" src={logo} alt="Logo" />
          </div>
          <p>{'Liberación: ' + window.REACT_APP_RELEASE_VERSION}</p>
          <p>{'Compilación: ' + window.REACT_APP_BUILD_VERSION}</p>
        </div>
      </Dialog>
    )
  }

  const renderFooterDialog = () => {
    return (
      <div>
        <Button label="SÍ, DESEO SALIR" icon="pi pi-check" onClick={() => signOff()} autoFocus />
        <Button label="NO, PERMANECER AQUÍ" icon="pi pi-times" onClick={() => setShowCloseDialog(false)} className="p-button-text" />
      </div>
    );
  }

  const closeDialog = () => {
    return (
      <ConfirmDialog
        footer={renderFooterDialog()}
        visible={showCloseDialog}
        onHide={() => setShowCloseDialog(false)}
        message="¿Estás seguro de que quieres terminar la sesión?"
        header="FINALIZAR SESIÓN"
        // acceptLabel='SÍ, DESEO SALIR'
        // rejectLabel='NO, PERMANECER AQUÍ'
        accept={() => signOff()}
        reject={() => setShowCloseDialog(false)}
      />
    )
  };
  return (
    <div className='flex h-screen w-full flex-col'>
      <NavBar
        showPopover={showPopover}
        setShowPopover={setShowPopover}
        refCloseProfileCard={refCloseProfileCard}
        refCloseCompanyInfo={refCloseCompanyInfo}
        showCompanyInfo={showCompanyInfo}
        setShowCompanyInfo={setShowCompanyInfo}
        setShowDialog={setShowDialog}
        closeFunction={setShowCloseDialog}
      />
      <div className='flex flex-grow bg-body-bg'>
        <Sidebar />
        <div className='flex-grow flex flex-col'>
          <div className='flex h-10 w-full bg-white flex-row  drop-shadow-md'>
            <BreadCrumb />

          </div>
          <div className='flex w-full flex-grow '>
            <div className="flex flex-row h-full w-full">
              <div className="flex flex-grow border border-white">
                <Outlet />
                {renderdialog()}
                {showCloseDialog && closeDialog()}
                <ToastContainer icon={null} autoClose={4000} hideProgressBar position={'bottom-right'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}