import { getTranslation, useCustomTranslation } from '../../hooks/useTranslations';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { GenericsNames } from '../../translations';
/* eslint-disable react/prop-types */
import React from 'react'

export const EvaluaAlertDialog = ({
  title,
  message,
  onCancelFunction,
  onConfirmFunction,
  open,
  setOpen,
  agreeLabel,
  agreeIcon,
  style={}
}) => {
  const { t } = useCustomTranslation()
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    if (onCancelFunction !== undefined) {
      onCancelFunction()
    }
  }
  const handleAccept = () => {
    setOpen(false)
    if (onConfirmFunction !== undefined) {
      onConfirmFunction()
    }
  }

  const renderFooter = () => {
    return (
      <div className='flex flex-row w-full h-full mt-10 justify-end'>        
        <Button
          className="p-button-text ml-6"
          label={getTranslation(t, GenericsNames.CANCEL)}
          icon="pi pi-times"
          onClick={() => handleClose()}
        />
        <Button
          type='submit'
          label={agreeLabel? agreeLabel : getTranslation(t, GenericsNames.SAVE)}
          icon={agreeIcon ? agreeIcon : "pi pi-check"}
          onClick={() => handleAccept()}
        />
      </div>
    );
  }
  return (
    <div>
      <Dialog
        visible={open}
        // TransitionComponent={Transition}
        // keepMounted
        header={title}
        onHide={() => handleClose()}
        aria-describedby='alert-dialog-slide-description'
        style={style}
      >

        {message}

        {renderFooter()}
      </Dialog>
    </div>
  )
}