import { GET_CITIES, GET_CITIES_ERROR, GET_CITIES_SUCCESS, ERROR_SHOW, CLEAR } from '../../constants';

export const cities = (state, actions) => {
    switch (actions.type) {
        case GET_CITIES:
            return {
                ...state,
                loading: true,
            }
        case GET_CITIES_ERROR:
            return {
                ...state,
                loading: false,
                error: actions.payload,
                errorShow: false
            }
        case GET_CITIES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                errorShow: false,
                cities: actions.payload
            }
        case ERROR_SHOW:
            return {
                ...state,
                errorShow: true
            }   
        case CLEAR:
            return {
                ...state,
                cities: []
            }
        default:
            return state;
    }
}