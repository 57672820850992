import React from 'react'
import { Dialog } from "primereact/dialog"
import { BaseButton } from "../../../Components/Buttons/BaseButton"
import { Input } from "../../../Components/Input/Input"
import { getTranslation, useCustomTranslation } from '../../../hooks/useTranslations'
import { GenericsNames, QueryNames } from '../../../translations'
import CustomDropdown from '../../../Components/Input/Dropdown'
import { regexName } from '../../../utils/utils'

/** 
 * @param {boolean} open - dialog state
 * @param {object} formik - formik state
 * @param {function} handler - function to execute on submit
 * @param {boolean} dropdown - show dropdown
 * @param {boolean} onHide - function to execute on hide dialog
 * @param {boolean} edit - change dialog title
 * @param {boolean} copy - change dialog title
*/
export const NewQueryDialog = ({ open, formik, handler, dropdown = null, onHide, edit = false, copy = false }) => {

    const { t } = useCustomTranslation()
    // if copy is true change the title to copy, otherwise change it to create or edit
    const editOrNew = edit ? t(QueryNames.QUERY_EDIT) : t(QueryNames.NEW_QUERY)
    const title = copy ? t(QueryNames.QUERY_COPY) : editOrNew

    return (
        <Dialog className='flex  w-2/5 overflow-hidden' header={getTranslation(t, title).toUpperCase()} visible={open} onHide={() => onHide()}>
            <Input
                important
                type='name'
                id='name'
                name='name'
                placeholder={getTranslation(t, QueryNames.QUERY_NAME)}
                defaultValue={formik.values.name || ''}
                onBlur={formik.handleChange}
                error={Boolean(formik.errors.name)}
                errorText={formik.touched.name && getTranslation(t, formik.errors.name)}
                keyfilter={regexName}
                
            />
            {
                dropdown ?
                    <CustomDropdown
                        name='fatherId'
                        value={formik.values.fatherId || undefined}
                        handler={formik.handleChange}
                        options={dropdown}
                        optionLabel="label"
                        optionValue="value"
                        label={getTranslation(t, QueryNames.QUERY_CATEGORY)}
                    />
                    : null
            }
            <BaseButton className='w-full !mt-2' label={getTranslation(t, GenericsNames.SAVE)} onClick={() => {
                handler()
            }
            } />
        </Dialog>
    )
}