
import { Dropdown } from 'primereact/dropdown';
import PropTypes from 'prop-types';
import React from 'react';
import { getTranslation, useCustomTranslation } from '../../hooks/useTranslations';
import { GenericsNames } from '../../translations';


const CustomDropdown = ({ value, name, label, handler, optionValue, optionLabel, className, options }) => {
    const {t} = useCustomTranslation();
    return (
        <>
             <div className={"field col-12 md:col-4 flex flex-col " + className}>
                <label className='text-xs font-normal font-input-font-family' htmlFor={name}>{label}</label>
                <Dropdown 
                    className={className ? `!border-none flex-grow !outline-none !p-0 !text-base !font-input-font-family` : ''}
                    name={name}
                    value={value}
                    onChange={(e) => handler(e)}
                    options={options}
                    optionLabel={optionLabel}
                    optionValue={optionValue}
                    label={label}
                    emptyMessage={<>{getTranslation(t, GenericsNames.NOT_AVAILABLE_OPTION)}</>}
                />
            </div>
            
        </>

    );
};

export default CustomDropdown;