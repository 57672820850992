import moment from "moment";
// filter contains have the data in the field and return the array
export const contains = (data, field, array) => {
    if(data === '' || data === undefined) return array;
    return array.filter(item => item[field]?.toLowerCase()?.includes(data.toLowerCase()));
}
// notContains have the data in the field and return the array
export const notContains = (data, field, array) => {
    if(data === '' || data === undefined) return array;
    return array.filter(item => !item[field]?.toLowerCase()?.includes(data.toLowerCase()));
}
// equals have the data in the field and return the array
export const equals = (data, field, array) => {
    if(data === '' || data === undefined) return array;
    // if is number convert item to number
    if(!isNaN(data)) {
        return array.filter(item => Number(item[field]) === Number(data));
    }
    return array.filter(item => item[field] == data);
}
// notEquals have the data in the field and return the array
export const notEquals = (data, field, array) => {
    if(data === '' || data === undefined || data === null) {
       //return filter data different to null undefined and empty string
        return array.filter(item => item[field] != null && item[field] != undefined && item[field] != '');
    }
    // if is number can parse to number
    if(!isNaN(data)) {
        return array.filter(item => Number(item[field]) != Number(data));
    }
    return array.filter(item => item[field] != data);
}
// empty have the data in the field and return the array
export const empty = (field, array) => {
    return array.filter(item => item[field] === '' || item[field] === null);
}
// notEmpty have the data in the field and return the array
export const notEmpty = (field, array) => {
    return array.filter(item => item[field] !== '' && item[field] !== null);
}
// range number have the data in the field and return the array
export const range = (data, field, array) => {
    const [min, max] = data.split('-');
    return array.filter(item => item[field] >= min && item[field] <= max && item[field] != null && item[field] != undefined);
}
// greaterThan number have the data in the field and return the array
export const greaterThan = (data, field, array) => {
    if(data === '' || data === undefined) return array;
    return array.filter(item => item[field] > data);
}
// lessThan number have the data in the field and return the array
export const lessThan = (data, field, array) => {
    if(data === '' || data === undefined) return array;
    return array.filter(item => item[field] < data);
}
// dates equal using moment exclude hours and minutes
export const dateEqual = (data, field, array) => {
    if(!moment(data, 'DD/MM/YYYY').isValid()) return array;
    return array.filter(item => moment(item[field], 'DD/MM/YYYY').isSame(moment(data, 'DD/MM/YYYY')));
}
//  diferent using moment exclude hours and minutes
export const dateDifferent = (data, field, array) => {
    return array.filter(item => !moment(item[field], 'DD/MM/YYYY').isSame(moment(data, 'DD/MM/YYYY')));
}
// range date using moment exclude hours and minutes
export const dateRange = (data, field, array) => {
    if(data === null || data === undefined) return array;
    const [min, max] = data.split('-');
    return array.filter(item => moment(item[field], 'DD/MM/YYYY').isBetween(moment(min, 'DD/MM/YYYY'), moment(max, 'DD/MM/YYYY'), undefined, '[]'));

}
// greaterThan date using moment exclude hours and minutes
export const dateGreaterThan = (data, field, array) => {
    
    if (data === null) {
        return array;
    }
    return array.filter(item => moment(item[field], 'DD/MM/YYYY').isAfter(moment(data, 'DD/MM/YYYY')));
}
// lessThan date using moment exclude hours and minutes
export const dateLessThan = (data, field, array) => {
    if (data === null) {
        return array;
    }
    return array.filter(item => moment(item[field], 'DD/MM/YYYY').isBefore(moment(data, 'DD/MM/YYYY')));
}