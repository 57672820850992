import { EntityToExport, ToExcel, ToPdf } from '../../../utils/exportService'
import { GenericsNames, ScoreRangeNames, SurveyNames, ToolBarNames } from '../../../translations';
import React, { useContext, useRef, useState } from 'react'
import { getTranslation, useCustomTranslation } from '../../../hooks/useTranslations';
import { CustomIconButton } from '../../../Components/Buttons/CustomIconButton';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import PropTypes from 'prop-types';
import { Row } from 'primereact/row';
import { StoreContext } from '../../../business/Provider';

import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { Toast } from '../../../Components/toast';


const ScoreRangesGrid = ({ onEdit, onDelete, loading, rows, guideId, onExcelClick,
  onPDFClick, }) => {

  const { t } = useCustomTranslation();
  const { dispatchLoading } = useContext(StoreContext);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: {
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });
  //const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [expandedRows, setExpandedRows] = useState(null);
  const onRowExpand = (event) => {

    console.log("Expand Data", event.data);
  };


  const onRowCollapse = (event) => {

  };


  const handleExcelExport = async () => {
    const printObj = { guideId: guideId, ...filters };

    rows.length > 0 ? await ToExcel(printObj, EntityToExport.GUIDE_EVALUATION_RANGES, dispatchLoading) : Toast('warning', getTranslation(t, GenericsNames.NO_EXPORT_DATA))

  }
  const handleToPDF = async () => {
    const printObj = { guideId: guideId, ...filters };
    rows.length > 0 ? await ToPdf(printObj, EntityToExport.GUIDE_EVALUATION_RANGES, dispatchLoading) : Toast('warning', getTranslation(t, GenericsNames.NO_EXPORT_DATA))
  }
  const renderHeader = () => (
    <>
      <div className="flex justify-end h-full p-1">
        <CustomIconButton
          toolTip={getTranslation(t, ToolBarNames.EXCEL)}
          onClick={() => handleExcelExport()}
          icon={faFileExcel}
          className='!m-1'
        />
        <CustomIconButton
          toolTip={getTranslation(t, ToolBarNames.PDF)}
          onClick={() => handleToPDF()}
          icon={faFilePdf}
          className='!m-1'
        />
      </div>

    </>

  );

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column rowSpan={2} colSpan={3} columnKey='test' field="conceptName"
        //header={"CATEGORY"}
        />
        <Column
          header={getTranslation(t, ScoreRangeNames.GRID_COLUMN_NULL)}
          alignHeader="center"
          colSpan={2}
        />
        <Column
          header={getTranslation(t, ScoreRangeNames.GRID_COLUMN_SLOW)}
          alignHeader="center"
          colSpan={2}
        />
        <Column
          header={getTranslation(t, ScoreRangeNames.GRID_COLUMN_MEDIUM)}
          alignHeader="center"
          colSpan={2}
        />
        <Column
          header={getTranslation(t, ScoreRangeNames.GRID_COLUMN_HIGH)}
          alignHeader="center"
          colSpan={2}
        />
        <Column
          header={getTranslation(t, ScoreRangeNames.GRID_COLUMN_CRITICAL)}
          alignHeader="center"
          colSpan={2}
        /><Column
          colSpan={1}
          header={getTranslation(t, ScoreRangeNames.GRID_COLUMN_ITEMS)}

          alignHeader="center"
          rowSpan={2}
          field="items"
        />
      </Row>
      <Row>
        <Column
          header={getTranslation(t, ScoreRangeNames.GRID_COLUMN_MIN)}

          alignHeader="center"
          colSpan={1}
          field="nullMinText"
        />
        <Column
          header={getTranslation(t, ScoreRangeNames.GRID_COLUMN_MAX)}

          alignHeader="center"
          colSpan={1}
          field="nullMaxText"
        />
        <Column
          header={getTranslation(t, ScoreRangeNames.GRID_COLUMN_MIN)}

          alignHeader="center"
          colSpan={1}
          field="lowMinText"
        />
        <Column
          header={getTranslation(t, ScoreRangeNames.GRID_COLUMN_MAX)}

          alignHeader="center"
          colSpan={1}
          field="lowMaxText"
        />
        <Column
          header={getTranslation(t, ScoreRangeNames.GRID_COLUMN_MIN)}

          alignHeader="center"
          colSpan={1}
          field="mediumMinText"
        />
        <Column
          header={getTranslation(t, ScoreRangeNames.GRID_COLUMN_MAX)}

          alignHeader="center"
          colSpan={1}
          field="mediumMaxText"
        />
        <Column
          header={getTranslation(t, ScoreRangeNames.GRID_COLUMN_MIN)}

          alignHeader="center"
          colSpan={1}
          field="highMinText"
        />
        <Column
          header={getTranslation(t, ScoreRangeNames.GRID_COLUMN_MAX)}

          alignHeader="center"
          colSpan={1}
          field="highMaxText"
        />
        <Column
          header={getTranslation(t, ScoreRangeNames.GRID_COLUMN_MIN)}

          alignHeader="center"
          colSpan={1}
          field="criticalMin"
        />
        <Column
          header={getTranslation(t, ScoreRangeNames.GRID_COLUMN_MAX)}

          alignHeader="center"
          colSpan={1}
          field="criticalMax"
        />
      </Row>
    </ColumnGroup>
  );
  /*if(data.typeName === "questionary"){
    tittlType={getTranslation(t, SurveyNames.QUESTIONARY_TITLE)}

  }
  else if(data.typeName=== "kf"){

  }*/
  var tittleType;
  const rowExpansionTemplate = (data) => {
    if (data.typeName === 'Questionary') {
      tittleType = getTranslation(t, SurveyNames.QUESTIONARY_TITLE)
    } else if (data.typeName === 'Category') {
      tittleType = getTranslation(t, GenericsNames.CATEGORY)
    } else if (data.typeName === 'Domain') {
      tittleType = getTranslation(t, GenericsNames.DOMAIN)
    } else if (data.typeName === 'Dimension') {
      tittleType = getTranslation(t, GenericsNames.DIMENSION)
    } else if (data.typeName === 'Question') {
      tittleType = getTranslation(t, GenericsNames.QUESTION)
    }

    return (
      <div className="orders-subtable ">
        <div className='mx-10'>

          <h5>{tittleType} </h5>
          <DataTable size="small" rowClassName=' !text-small-font-size' cellClassName=' !text-small-font-size' tableClassName='!text-small-font-size' value={data.scores} scrollable='false' className='z-0' headerColumnGroup={headerGroup}>

            <Column
              field="conceptName"
              header={data.type}
              style={{ flexBasis: '15%' }}
            />
            <Column
              field="nullMinText"
              colSpan={1}
              align="center"
            />
            <Column
              field="nullMaxText"
              colSpan={1}
              align="center"
            />
            <Column
              field="lowMinText"
              colSpan={1}
              align="center"
            />
            <Column
              field="lowMaxText"
              colSpan={1}
              align="center"
            />
            <Column
              field="mediumMinText"
              colSpan={1}
              align="center"
            />
            <Column
              field="mediumMaxText"
              colSpan={1}
              align="center"
            />
            <Column
              field="highMinText"
              colSpan={1}
              align="center"
            />
            <Column
              field="highMaxText"
              colSpan={1}
              align="center"
            />
            <Column
              field="criticalMinText"
              colSpan={1}
              align="center"
            />
            <Column
              field="criticalMaxText"
              colSpan={1}
              align="center"

            />
            <Column
              field="items"
              colSpan={1}
              align="center"
            />
          </DataTable>
        </div>
      </div>
    );
  };
  const header = renderHeader();
  const bodyTitleTemplate = (rowData) => {
    return <h4 className='text-sm font-semibold'>{rowData.title}</h4>;
  }

  return (
    <>
      {rows && (
        <div className="datatable-doc-demo " style={{ height: 'calc(100vh - 106px)', width: 'calc(100vw - 92px)' }}>
          <DataTable
            value={rows}
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            onRowExpand={onRowExpand}
            onRowCollapse={onRowCollapse}
            rowExpansionTemplate={rowExpansionTemplate}
            //dataKey="id"
            header={header}

            scrollable
            scrollHeight="calc(100vh - 179px)"
            className='!text-xs'
            emptyMessage={getTranslation(t, ScoreRangeNames.GRID_EMPTY)}
          >
            <Column expander style={{ width: '5%' }} />
            <Column
              field="title"
              header={<a className="text-sm text-[#0277bde6] font-bold ml-6"
                href="https://dof.gob.mx/nota_detalle.php?codigo=5541828&fecha=23%2f10%2f2018"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(e.target.href, '_blank');
                }}
              >Ver norma oficial 035</a>}
              style={{ minWidth: '95%' }}
              showFilterMenu={false}
              body={bodyTitleTemplate}
            />
          </DataTable>

        </div>
      )}
    </>
  );
};
ScoreRangesGrid.propTypes = {
  loading: PropTypes.bool,
  rows: PropTypes.array,
  onExcelClick: PropTypes.func,
  onPDFClick: PropTypes.func,
};
export default ScoreRangesGrid;
