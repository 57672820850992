
import { Dialog } from 'primereact/dialog';
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../../../business/Provider';
import { WizardDialog } from '../../../../Components/Wizard/WizardDialog';
import UseCities from '../../../../hooks/states/UseCities';
import useStates from '../../../../hooks/states/useStates';
import useSuburbs from '../../../../hooks/states/useSuburbs';
import { useCustomTranslation } from '../../../../hooks/useTranslations';
import { renderAddressInputs, renderWorkCenterInputs } from './WorkCenterForms';

export const WorkCenterWizard = ({ 
    title, 
    open, 
    onClose, 
    formik, 
    activeStep, 
    setActiveStep,
    evaluators,
    zipCodeFound,
    onZipSearch,
    suburbs,
    setZipCode
    }) => {
    const context = useContext(StoreContext)
    const {t} = useCustomTranslation()
    const { getStatesCities  } = useStates() 
    const { getCities } = UseCities()
    const { getSuburbs} = useSuburbs()
    const [cities, setCities] = useState([])
    const [suburb, setSuburb] = useState([])
    const [states, setStates] = useState([])
    useEffect(() => {
        if(context.suburbsState.loading === false) {
          setSuburb(context.suburbsState.suburbs)
        }
      }, [context.suburbsState])
      useEffect(() => {
        if (context.citiesState.loading === false) {
          setCities(context.citiesState.cities)
        }
      }, [context.citiesState])
      useEffect(() => {
        if (context.statesState.loading === false) {
          setStates(context.statesState.states)
        }
      }, [context.statesState])
      useEffect(() => {
        if(zipCodeFound === false && open) {
          if(formik.values.suburb && 
            formik.values.state &&
            formik.values.municipality &&
            formik.values.state !== '' && 
            formik.values.municipalities !== '' && 
            formik.values.suburb !== '') {
            getStatesCities(states, formik)
          }
        }
       } , [zipCodeFound])
  const steps = [
    {
      label: 'Centro de trabajo',
      title:'Centro de trabajo',
      validationFields: ["scode", "name", "evaluatorId", "activities", "objective", "principalActivity", "isActive"]
    },
    {
      label: 'Domicilio',
      title: 'Domicilio',
      validationFields: ["zipCode", "state", "municipality", "idSuburb", "street", "exteriorNumber", "interiorNumber"]
    }
  ];
  const handleNext = async (e) => {
    e.preventDefault();
    //Get All Errors
    const validationErrors = await formik.validateForm();
    //delete key from object if not exist in validationFields
    const errors = Object.keys(validationErrors).reduce((acc, key) => {
        if (steps[activeStep].validationFields.includes(key)) {
            acc[key] = validationErrors[key];
        }
        return acc;
    }, {});
    if (Object.keys(errors).length > 0) {
            //Filter the error that belongs to the step.
        if (steps[activeStep].validationFields) {
                //Execute Touch for validation fields.
            let validationFields = steps[activeStep].validationFields;
            validationFields.forEach(element => formik.setFieldTouched(element, true));
            //formik.setTouched(setNestedObjectValues(validationErrors, true));
        }
    } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
};
const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
};
const renderStep = (step) => {
    switch (step) {
        case 0:
            return renderWorkCenterInputs(t, formik, evaluators);
        case 1:
            return renderAddressInputs(t, formik, onZipSearch, setZipCode, states, suburbs, zipCodeFound, getCities, cities, getSuburbs, suburb);
        default:
            return <></>;
    }
};
    return (
        <>
        <Dialog 
            header={<p className='text-font-size-base text-[#5e5e5e] font-font-family-base font-bold'>{title.toUpperCase()}</p>} 
            visible={open} 
            modal 
            style={{ width: '70vw'}} 
            onHide={() => onClose()}
        >
            <WizardDialog
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                steps={steps}
                formik={formik}
                onNext={handleNext}
                onBack={handleBack}
                onClose={() => onClose()}
                handleBack={handleBack}
                handleNext={handleNext}
                open={open}
                title={title}   
            >
                <div className='border-b border-border p-1 flex-grow'>
                    {renderStep(activeStep)}
                </div>
            </WizardDialog>
           
          
        </Dialog>
        </>
      
    )
}

