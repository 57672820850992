import * as yup from 'yup';

import { GenericsNames, PositionNames, ZoneNames } from '../../../../translations';

import { EvaluatorNames } from './../../../../translations/index';
import { regexName, regexProfessionalId } from '../../../utils';

const EvaluatorSchema = yup.object().shape({
  scode: yup // licenseId: DataTypes.UUID,
    .string('')
    .required(ZoneNames.VALIDATOR_ID_REQUIRED)
    .matches(/^[0-9]+$/, ZoneNames.VALIDATOR_DIGIT_ALLOW)
    .min(4, ZoneNames.VALIDATOR_ID_REQUIRED_SIZE)
    .max(4, ZoneNames.VALIDATOR_ID_REQUIRED_SIZE)
    .test(
      'greaterThanZero',
      PositionNames.VALIDATOR_ID_GREATER_THAN_ZERO,
      (value) => parseInt(value, 10) > 0
    ),
  name: yup
    .string('')
    .required(ZoneNames.VALIDATOR_NAME_REQUIRED)
    // .min(4, 'El código postal debe tener al menos 5 caracteres')
    .max(100, EvaluatorNames.VALIDATOR_NAME_REQUIRED_SIZE)
    .matches(regexName, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS),
  profesionalId: yup
    .string('')
    .matches(regexProfessionalId, ZoneNames.VALIDATOR_DIGIT_ALLOW)
    .max(15, EvaluatorNames.VALIDATOR_DIGIT_PROFESIONAL_ID)
});
export default EvaluatorSchema;
