import { useContext, useEffect, useState } from 'react'
import { GET_SURVEY, GET_SURVEY_DETAIL, GET_SURVEY_ERROR, GET_SURVEY_RESULT, GET_SURVEY_SUCCESS } from '../../business/constants'
import { StoreContext } from '../../business/Provider'
import useApi from '../api'
import { Toast } from '../../Components/toast'

const useSurveysResult = (guideId) => {
  const baseUrl = `${window.REACT_APP_EVALUA_API_URI}/guide/${guideId}`

  const { genericApiCall } = useApi();
  const { dispatchSurveysResult, surveysResultState} = useContext(StoreContext);

  const getSurveyResults = async (surveyId, filter) => {
    try{
      dispatchSurveysResult({ type: GET_SURVEY })
      const result = await genericApiCall(`${window.REACT_APP_EVALUA_API_URI}/surveys/${surveyId}/results`, 'post', filter)
      if(result.status === 200) {
        const payload = {
          data : {...result.data.data.results, surveyId: surveyId},
          id : guideId
          }
        dispatchSurveysResult({ type: GET_SURVEY_RESULT, payload: payload })
      } else {
        let message = ''
        if (result.data) message = result.data.message
        else message = result.message
        dispatchSurveysResult({ type: GET_SURVEY_ERROR, payload: message })
      }
      return result
    } catch (error) {
      Toast('warning',error.message, "", error)
      dispatchSurveysResult({ type: GET_SURVEY_ERROR})
    }     
  }
  const getSurveyResultSurvey = async () => {
    try{
    dispatchSurveysResult({ type: GET_SURVEY })
    const result = await genericApiCall(`${baseUrl}/surveys`, 'GET')
    if(result.status === 200) {
      const payload = {
        data : result.data.data.surveys,
        guideId : guideId
      }
      dispatchSurveysResult({ type: GET_SURVEY_SUCCESS, payload: payload })
    } else {
      let message = ''
      if (result.data) message = result.data.message
      else message = result.message
      dispatchSurveysResult({ type: GET_SURVEY_ERROR, payload: message })
    }
    return result
  } catch (error) {
    Toast('warning',error.message, "", error)
    dispatchSurveysResult({ type: GET_SURVEY_ERROR})
  }     
  }

  return {
    getSurveyResults,
    getSurveyResultSurvey,
  }
}
export default useSurveysResult
