import {SET_IMPORT_ERROR_LIST,CLEAR_IMPORT_ERROR_LIST} from '../constants';

export const importErrorList = (state, action) => {
    switch (action.type) {
        case SET_IMPORT_ERROR_LIST:
            return {
                ...state,
                error: action.payload.errors,
                data: action.payload.data,
                show: false
            };
        case CLEAR_IMPORT_ERROR_LIST:
            return {
                ...state,
                error: [],
                data: null,
                show: true
            };
        default:
            return state;
    }

}