import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'primereact/button'
import React from 'react'
import { getTranslation, useCustomTranslation } from '../../hooks/useTranslations'
import { GenericsNames } from '../../translations'
import { BaseButton } from '../Buttons/BaseButton'
import { ButtonTextWithIcon } from '../Buttons/ButtonTextWithIcon'
import { StepsMenu } from './stepsMenu'

export const WizardDialog = ({children, open, title, onClose, formik, steps, activeStep, setActiveStep, handleNext, handleBack}) => {
    const { t } = useCustomTranslation();  
    return (
        <div className="grid grid-cols-12 gap-x-1 h-full shadow-sideMenu-box-shadow border border-border">
        <div className="col-span-2">
            <StepsMenu
                textSave={getTranslation(t, GenericsNames.SAVE)}
                textCancel={getTranslation(t, GenericsNames.CANCEL)}
                open={open}
                title={title}
                onClose={onClose}
                formik={formik}
                maxWidth={"lg"}
                steps={steps}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
            />
        </div>
        <div className="col-span-10 flex flex-col">
                {children}
              <div className='flex justify-end py-1 mr-2  gap-x-1'>
              {activeStep > 0 &&
                    <BaseButton type='submit' onClick={handleBack} label={getTranslation(t, GenericsNames.BACK)} icon="pi pi-angle-left"/>
                }
                {activeStep === steps.length - 1 ?
                    <BaseButton type='submit' onClick={formik.handleSubmit} label={getTranslation(t, GenericsNames.END)} icon="pi pi-check"/>
                    :
                    <BaseButton type='submit' onClick={handleNext} iconPos="right" label={getTranslation(t, GenericsNames.NEXT)} icon="pi pi-angle-right"/>
                }
                
            </div>
        </div>
    </div>
  
    )
}