import { CollaboratorForm, PersonalDataForm } from './CollaboratorForms';
import React, { useState } from 'react'

import { Dialog } from 'primereact/dialog';
import { WizardDialog } from '../../../../Components/Wizard/WizardDialog';
import { useCustomTranslation } from '../../../../hooks/useTranslations';

export const CollaboratorWizard = ({ 
    title, 
    open, 
    onClose, 
    formik, 
    activeStep, 
    setActiveStep,
    zones,
    departments,
    positions,
    workCenters
    }) => {
    const {t} = useCustomTranslation()
  const steps = [
    {
      label: 'Datos personales',
      title:'Datos personales',
      validationFields: ["name", "lastName", "surName", "taxId", "email"]
    },
    {
      label: 'Contratación',
      title: 'Contratación',
    }
  ];

  const handleNext = async (e) => {
    e.preventDefault();
    //Get All Errors
    const validationErrors = await formik.validateForm();
    //delete key from object if not exist in validationFields
    const errors = Object.keys(validationErrors).reduce((acc, key) => {
        if (steps[activeStep].validationFields.includes(key)) {
            acc[key] = validationErrors[key];
        }
        return acc;
    }, {});
    if (Object.keys(errors).length > 0) {
            //Filter the error that belongs to the step.
        if (steps[activeStep].validationFields) {
                //Execute Touch for validation fields.
            let validationFields = steps[activeStep].validationFields;
            validationFields.forEach(element => formik.setFieldTouched(element, true));
            //formik.setTouched(setNestedObjectValues(validationErrors, true));
        }
    } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
};
const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
};
const renderStep = (step) => {
    switch (step) {
        case 0:
            return PersonalDataForm(formik, t);
        case 1:
            return CollaboratorForm(formik, zones, departments, positions, workCenters, t);
        default:
            return <></>;
    }
};
    return (
        <>
        <Dialog 
            header={<p className='text-font-size-base text-[#5e5e5e] font-font-family-base font-bold'>{title.toUpperCase()}</p>} 
            visible={open} 
            modal 
            style={{ width: '70vw'}} 
            onHide={() => onClose()}
        >
            <WizardDialog
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                steps={steps}
                formik={formik}
                onNext={handleNext}
                onBack={handleBack}
                onClose={() => onClose()}
                handleBack={handleBack}
                handleNext={handleNext}
                open={open}
                title={title}   
            >
                <div className='border-b border-border p-1 flex-grow'>
                    {renderStep(activeStep)}
                </div>
            </WizardDialog>
           
          
        </Dialog>
        </>
      
    )
}

