import { GET_SUBURB, GET_SUBURB_ERROR, GET_SUBURB_SUCCESS, ERROR_SHOW, CLEAR } from '../../constants';

export const suburbs = (state, actions) => {
    switch (actions.type) {
        case GET_SUBURB:
            return {
                ...state,
                loading: true,
            }
        case GET_SUBURB_ERROR:
            return {
                ...state,
                loading: false,
                error: actions.payload,
                errorShow: false
            }
        case GET_SUBURB_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                errorShow: false,
                suburbs: actions.payload
            }
        case ERROR_SHOW:
            return {
                ...state,
                errorShow: true
            }
        case CLEAR:
            return {
                ...state,
                suburbs: []
            }
        default:
            return state;
    }
}