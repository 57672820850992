//constant map of types of files
export const fileTypes = {
    'COLLABORATOR': 1,
    'AREA': 2,
    'POSITION': 3,
    'WORK_CENTER': 4,
    'EVALUATOR': 5,
    'DEPARTMENT': 6,
    'GUIDE_1': 7,
    'GUIDE_2': 8,
    'GUIDE_3': 9,
}