import React, { useRef } from 'react';

import { CompanyNames } from '../../../translations';
import FileUpload from '../../../Components/FileUpload';
import { Input } from '../../../Components/Input/Input';
import PropTypes from 'prop-types';
import { SearchInput } from '../../../Components/SearchInput';
import { SelectComponent } from '../../../Components/Select';
import { firstOrDefault } from '../../../utils/arrayService';
import { getTranslation } from '../../../hooks/useTranslations';

const CompanyForm = ({
  formik,
  zipCodeFound,
  suburbs,
  onZipSearch,
  // setZipCode,
  //municipalities,
  states,
  searchCity,
  t,
  //getSuburbs,
  //getStatesCities
}) => {
  const fileUploadRef = useRef(null);
  const onClearHandler = async () => {
    formik.setFieldValue('thumbnail', '');
    formik.setFieldValue('logoFile', null);
    formik.setFieldValue('clearLogo', true);
  }
  const onUploadHandler = async (event) => {
    const reader = new FileReader();

    //Read the contents of Image File.
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function (file) {

      //Initiate the JavaScript Image object.
      const image = new Image();
      formik.setFieldTouched("logoFile", true)
      //Set the Base64 string return from FileReader as source.
      image.src = file.target.result;

      //Validate the File Height and Width.
      image.onload = function () {
        const height = this.height;
        const width = this.width;

        if (height > 500 || width > 500) {
          formik.setFieldValue('logoFile', 'InvalidSize')
          return false;
        }
        formik.setFieldValue('clearLogo', false);
        formik.setFieldValue('thumbnail', image.src);
        formik.setFieldValue('logoFile', event.target.files[0]);
        return true;
      };


    };
  }
  const renderInput = (
    id,
    name,
    placeholder,
    onChange,
    value,
    onBlur,
    error,
    errorText,
    type,
    important,
    rows,
    cols,
    disabled = false
  ) => {
    return (
      <div className="flex w-1/2 p-2">
        <Input
          id={id}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          error={error}
          errorText={errorText}
          type={type}
          important={important}
          rows={rows}
          cols={cols}
          disabled={disabled}
        />
      </div>
    )
  }
  const renderName = () => {
    return renderInput(
      'name',
      'name',
      getTranslation(t, CompanyNames.NAME),
      formik.handleChange,
      formik.values.name,
      (event) => {
        formik.handleBlur(event)
        formik.setFieldValue('name', event.target.value.trim())
      },
      formik.touched.name && Boolean(formik.errors.name),
      formik.touched.name && getTranslation(t, formik.errors.name),
      'text',
      true, null, null, true
    )
  }
  const renderRFC = () => {
    return renderInput(
      'taxID',
      'taxID',
      getTranslation(t, CompanyNames.RFC),
      formik.handleChange,
      formik.values.taxID,
      (event) => {
        formik.handleBlur(event)
        if (event.target.value) {
          formik.setFieldValue('taxID', event.target.value.toUpperCase().trim())
        }
      },
      formik.touched.taxID && Boolean(formik.errors.taxID),
      formik.touched.taxID && getTranslation(t, formik.errors.taxID),
      'text',
      true
    )
  }
  const renderAlias = () => {
    return renderInput(
      'alias',
      'alias',
      getTranslation(t, CompanyNames.ALIAS),
      formik.handleChange,
      formik.values.alias,
      (event) => {
        formik.handleBlur(event)
        formik.setFieldValue('alias', event.target.value.trim())
      },
      formik.touched.alias && Boolean(formik.errors.alias),
      formik.touched.alias && getTranslation(t, formik.errors.alias),
      'text',
      true, null, null, true
    )
  }

  const renderInputState = () => {
    return <div className="flex w-1/2 p-2">
      <Input
        id='state'
        name='state'
        placeholder={getTranslation(t, CompanyNames.STATE)}
        onChange={formik.handleChange}
        value={formik.values.state}
        onBlur={(event) => {
          formik.handleBlur(event)
          formik.setFieldValue('state', event.target.value.trim())
        }}
        error={formik.touched.state && Boolean(formik.errors.state)}
        errorText={formik.touched.state && getTranslation(t, formik.errors.state)}
        type='text'
        important={true}
        disabled={true}
      />
    </div>
  }

  /*  */
  // console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx', formik.values.state)
  // console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx', zipCodeFound)

  const renderStateSelected = () => {
    return states !== undefined && states.length > 0 ? <SelectComponent
      className={'flex flex-col p-2 w-1/2'}
      valueSelected={formik.values.state}
      labelText={getTranslation(t, CompanyNames.STATE)}
      ValuesOptions={states}
      handleChange={(e) => {
        formik.setFieldValue('state', e.target.value)
        // search state id in states
        const id = states.find(x => x.value === e.target.value).id
        searchCity(id)
      }}
      placeholderText={'...'}
      name={'state'}
      optionLabel={'label'}
      error={Boolean(formik.touched.state && formik.errors.state)}
      errorText={formik.touched.state && getTranslation(t, formik.errors.state)}
      important
    /> :
      <div className={'flex flex-col p-2 w-1/2'}>
        <Input
          id='state'
          name='state'
          placeholder={getTranslation(t, CompanyNames.STATE)}
          onChange={formik.handleChange}
          value={formik.values.state}
          onBlur={(event) => {
            formik.handleBlur(event)
            formik.setFieldValue('state', event.target.value.trim())
          }}
          error={formik.touched.state && Boolean(formik.errors.state)}
          errorText={formik.touched.state && getTranslation(t, formik.errors.state)}
          type='text'
          important={true}
          disabled={true}
        />
      </div>
  }

  const renderInformationRelatedToZipCode = () => {
    return (
      <>
        <div className="flex w-full flex-row">
          {renderInput(
            'municipality',
            'municipality',
            getTranslation(t, CompanyNames.MUNICIPALTY),
            formik.handleChange,
            formik.values.municipality,
            (event) => {
              formik.handleBlur(event)
              formik.setFieldValue('municipality', event.target.value.trim())
            },
            formik.touched.municipality && Boolean(formik.errors.municipality),
            formik.touched.municipality && getTranslation(t, formik.errors.municipality),
            'text',
            true
          )}
          {renderInput(
            'suburb',
            'suburb',
            getTranslation(t, CompanyNames.SUBURB),
            formik.handleChange,
            formik.values.suburb,
            (event) => {
              formik.handleBlur(event)
              formik.setFieldValue('suburb', event.target.value.trim())
            },
            formik.touched.suburb && Boolean(formik.errors.suburb),
            formik.touched.suburb && getTranslation(t, formik.errors.suburb),
            'text',
            true
          )}

        </div>
      </>
    )
  }
  const renderStates = () => {

    return (
      <>
        <div className="flex w-full flex-row">
          <div className="flex w-1/2 p-2">
            <Input
              id='municipality'
              name='municipality'
              placeholder={getTranslation(t, CompanyNames.MUNICIPALTY)}
              onChange={formik.handleChange}
              value={formik.values.municipality}
              onBlur={(event) => {
                formik.handleBlur(event)
                formik.setFieldValue('municipality', event.target.value.trim())
              }}
              error={formik.touched.municipality && Boolean(formik.errors.municipality)}
              errorText={formik.touched.municipality && getTranslation(t, formik.errors.municipality)}
              type='text'
              important={true}
            />
          </div>
          <SelectComponent
            className={'flex flex-col p-2 w-1/2'}
            valueSelected={formik.values.suburb}
            labelText={getTranslation(t, CompanyNames.SUBURB)}
            ValuesOptions={suburbs}
            handleChange={(e) => {
              const option = firstOrDefault('value', e.target.value, suburbs)
              formik.setFieldValue('suburb', option.label)
            }}
            placeholderText={'...'}
            name={'suburb'}
            optionLabel={'label'}
            error={Boolean(formik.touched.suburb && formik.errors.suburb)}
            errorText={formik.touched.suburb && getTranslation(t, formik.errors.suburb)}
            important
          />
        </div>
      </>

    )
  }



  const renderAddressInputs = () => {
    return (
      <>
        <div className="flex w-full flex-row">
          <div className='flex w-1/2 pl-2'>
            <SearchInput
              title={getTranslation(t, CompanyNames.CP)}
              name='zipCode'
              key='zipCode'
              onKeyPress={
                event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    // formik.handleBlur(event)
                    onZipSearch(formik.values.zipCode)
                  }
                }
              }
              onClickSearch={(e) => {
                // only numbers and 5 digits
                // formik.handleBlur(e)
                onZipSearch(formik.values.zipCode)
              }}
              value={formik.values.zipCode}
              onChange={(e) => {
                //pars to number
                const value = e.target.value.replace(/[^0-9]/g, '')
                // value is a number
                // if is empty string
                if ((Number(value) && value.length <= 5 && value !== '') || value.length === 0) {
                  formik.handleChange(e)
                }
              }
              }
              // onBlur={(e) => {
              //   formik.handleBlur(e)
              //   setZipCode(formik.values.zipCode)
              // }}
              error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
              errorText={formik.touched.zipCode && getTranslation(t, formik.errors.zipCode)}
              type='number'
              important
            />
          </div>
          {zipCodeFound && formik.values.state !== '' ? renderInputState() : renderStateSelected()}


        </div>
        {!zipCodeFound ? renderInformationRelatedToZipCode() : renderStates()}
        <div className="flex w-full flex-row">
          {renderInput(
            'street',
            'street',
            getTranslation(t, CompanyNames.STREET),
            formik.handleChange,
            formik.values.street,
            (event) => {
              formik.handleBlur(event)
              formik.setFieldValue('street', event.target.value.trim())
            },
            formik.touched.street && Boolean(formik.errors.street),
            formik.touched.street && getTranslation(t, formik.errors.street),
            'text',
            true
          )}
          {renderInput(
            'exteriorNumber',
            'exteriorNumber',
            getTranslation(t, CompanyNames.EXTERIOR),
            formik.handleChange,
            formik.values.exteriorNumber || '',
            (event) => {
              formik.handleBlur(event)
              formik.setFieldValue('exteriorNumber', event.target.value.trim())
            },
            formik.touched.exteriorNumber && Boolean(formik.errors.exteriorNumber),
            formik.touched.exteriorNumber && getTranslation(t, formik.errors.exteriorNumber),
            'text',
            true
          )}
          {renderInput(
            'interiorNumber',
            'interiorNumber',
            getTranslation(t, CompanyNames.INTERIOR),
            formik.handleChange,
            formik.values.interiorNumber || '',
            (event) => {
              formik.handleBlur(event)
              formik.setFieldValue('interiorNumber', event.target.value.trim())
            },
            formik.touched.interiorNumber && Boolean(formik.errors.interiorNumber),
            formik.touched.interiorNumber && getTranslation(t, formik.errors.interiorNumber),
            'text',
            false
          )}

        </div>
      </>
    )
  }
  const renderLogo = () => {
    return <div className="grid grid-cols-1 justify-items-center">
      {formik.values.thumbnail &&
        <img className="object-cover h-48 w-96 rounded border-2 border-sky-400" src={formik.values.thumbnail}></img>
      }
      <br />
      {formik.touched.logoFile && Boolean(formik.errors.logoFile) && <small className="p-error block">{getTranslation(t, formik.errors.logoFile)}</small>}
      <FileUpload onChange={onUploadHandler} accept="image/*" label={getTranslation(t, CompanyNames.LOGO)}
        allowClear={true} clearLabel={getTranslation(t, CompanyNames.CLEAN)} onClear={onClearHandler}>
      </FileUpload>
    </div>
  }
  return (
    <div>
      <div className="flex flex-row">
        {renderName()}
        {renderAlias()}
        {renderRFC()}
      </div>
      <div className="flexflex-row">
        {renderAddressInputs()}
      </div>
      <div className="flexflex-row">
        {renderLogo()}
      </div>
    </div>
  );
};

CompanyForm.propTypes = {
  formik: PropTypes.object,
  zipCodeFound: PropTypes.bool,
  suburbs: PropTypes.array,
  onZipSearch: PropTypes.func,
  setZipCode: PropTypes.func,
  municipalty: PropTypes.string,
  states: PropTypes.array,
  searchCity: PropTypes.func,
  t: PropTypes.func,
  getSuburbs: PropTypes.func
};

export default CompanyForm;
