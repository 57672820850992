import { GET_QUESTION, GET_QUESTION_ERROR, GET_QUESTION_SUCCESS, GET_QUESTION_EDIT } from '../../constants';

export const questions = (state, actions) => {
    switch (actions.type) {
        case GET_QUESTION:
            return {
                ...state,
                loading: true,
            }
        case GET_QUESTION_ERROR:
            return {
                ...state,
                loading: false,
                error: actions.payload,
                errorShow: false
            }
        case GET_QUESTION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                errorShow: false,
                questions: {
                    ...state.questions,
                    [actions.payload.id]: actions.payload.data
                },
            }
        case GET_QUESTION_EDIT:
            return {
                ...state,
                loading: false,
                error: null,
                errorShow: false,
                questions: {
                    ...state.questions,
                    [actions.payload.id]: actions.payload.data
                }
            }
        default:
            return state;
    }
}