import React, { useState, Fragment } from "react";
import routes from "../../routes";
import { SidebarItem } from "./sidebarItem";

export const Sidebar = () => {
  const [dataSecondSidebar, setDataSecondSidebar] = useState([])
  const [isSelect, setIsSelect] = useState(false);
  // open hook for each sidebar item
  const [open, setOpen] = useState(routes?.reduce((acc, item) => {
    acc[item.id] = false;
    return acc;
  }, {}))

  return (
    <ul
      className={`flex h-full w-[90px] z-50  bg-[#0277bde6] drop-shadow-2xl flex-col`}
    >
      {/*fontAwesomeIcon close*/}
      <li className="h-10" />
      {routes.map((item, keyItem) => {
        return <Fragment key={keyItem}>{SidebarItem(item, isSelect, setIsSelect, setDataSecondSidebar, dataSecondSidebar, open, setOpen)}</Fragment>
      })}
    </ul>
  );
};
