const WorkCenterObject = {
  id: undefined,
  code: '',
  name: '',
  zipCode: null,
  state: '',
  municipality: '',
  suburb: '',
  street: '',
  exteriorNumber: '',
  interiorNumber: '',
  evaluator: '',
  evaluatorId: undefined,
  isActive: true,
  principalActivity: '',
  objective: '',
  activities: '',
}

export default WorkCenterObject