import { IsNullOrEmpty } from './utilsService';

const borderTitle='border:none; background:#FFFFFF;'
const titleClass='mso-style-parent:style0;font-size:14.0pt;font-weight:700;text-align:center;'
const headerClass='padding-top: 5px;padding-bottom: 5px;padding-left: 5px;padding-right: 5px;mso-style-parent:style0;font-weight:700;border:.5pt solid #D9D9D9;	background:#D9D9D9;	mso-pattern:black none;  -webkit-print-color-adjust: exact !important;'
const bodyClass='mso-style-parent:style0;	border:.5pt solid #D9D9D9;-webkit-print-color-adjust: exact !important;';
const getTitleSection=(title,subTitle,colsize)=>{
    return `<tr style="${borderTitle} ${titleClass}"><td colspan="${colsize+2}">${title}</td></tr>`+
            `<tr style="${borderTitle} ${titleClass}"><td colspan="${colsize+2}">${subTitle}</td></tr>`+
           `<tr style="${borderTitle} ${titleClass}"><td height="4" colspan="${colsize+2}"></td></tr>`
}

const getHeadersSection=(columns)=>{
    let header = `<th width='10px' style=${titleClass}> </th>`; // Add empty Columns
    columns.forEach(function(column){
     header = header + `<th style='${headerClass}'>${column.title}</th>`
    });
    header = header + `<th width='10px'style=${titleClass}> </th>`; // Add empty Columns
    return `<tr>${header}</tr>`;
}
const getRowContent=(exportableColumns,row)=>{
    let rowContent = '';
    exportableColumns.forEach(function(column){ 
      rowContent=rowContent+ `<td>${IsNullOrEmpty(row[column.dataKey])?'':row[column.dataKey]}</td>`
     });
     return rowContent;  
  }
const getTableBody=(exportableColumns,rows)=>{
    let content = '';
    rows.forEach(function(row){
        content = content + `<tr style='${borderTitle}'><td></td>`+getRowContent(exportableColumns,row)+'<td></td></tr>';
      });      
    return content;
}

export const getTable=(printObj)=>{
    let titleSection= getTitleSection(printObj.title,printObj.subTitle,printObj.exportableColumns.length);
    let headerSection = getHeadersSection(printObj.exportableColumns);
    let content = getTableBody(printObj.exportableColumns,printObj.rows)
    return `<table style="width:100% ${borderTitle}" border=0 cellpadding=0 cellspacing=0 >${titleSection}${headerSection}${content}</table>`;
}