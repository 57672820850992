// import PropTypes from 'prop-types'
import './styles.css'

import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { btnDisable } from '../../assets/customStyles'

// comments about props
// onClick: function
// text: string (text to display)
// icon: html element (icon to display) ex: <FontAwesomeIcon icon={faPlus} />
// type: string (primary, secondary, success, info, warning, danger)
// disabled: boolean
export const TableButton = ({ onClick, icon, className, toolTip, disabled = false }) => {
    // switch the type of the button
    return (
        <Button disabled={disabled} title={toolTip} onClick={onClick} className={`!pointer-events-auto !border-0 ${btnDisable} !bg-transparent !text-[#50a7ffdb] hover:!bg-[#333333] hover:!text-white !rounded-full flex !p-2 hover:!shadow-table-button-box-shadow leading-6${className}`}>
            {icon !== null || icon !== undefined ? <FontAwesomeIcon className='text-sm' icon={icon}  /> : null}
        </Button>
    )
}

// TableButton.propTypes = {
//     onClick: PropTypes.func,
//     icon: PropTypes.element,
//     disabled: PropTypes.bool,
//     className: PropTypes.string,
//     toolTip: PropTypes.string
// }