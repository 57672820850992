import { GET_AREA, GET_AREA_ERROR, GET_AREA_SUCCESS } from '../../business/constants'
import { useContext, useEffect, useRef, useState } from 'react'

import { IsNullOrEmpty } from '../../utils/utilsService'
import { StoreContext } from '../../business/Provider'
import { Toast } from '../../Components/toast'
import axios from 'axios'
import { getLocalAccessToken } from '../../Auth/auth/token'
import { sortBy } from 'lodash'
import useApi from '../api'
import { getTranslation, useCustomTranslation } from '../useTranslations'
import { ZoneNames } from '../../translations'
import { LoadingContext } from '../../context/LoadingContext'

const useZone = () => {
  const baseUrl = `${window.REACT_APP_EVALUA_API_URI}/area`
  const [zones, setZones] = useState(undefined)
  const [loadingZones, setloadingZones] = useState(false)
  const [zonesOptions, setZonesOptions] = useState(undefined);
  const { genericApiCall } = useApi();
  const {t} = useCustomTranslation()
  const {setLoading} = useContext(LoadingContext);
  //import StoreContext
  const context = useContext(StoreContext);
  const getZoneOptions = (zonesResponse) => {
    
    const activeZones = zonesResponse.filter((object) => {
      return IsNullOrEmpty(object.deleteBy)
    })
    return sortBy(activeZones, [
      function (o) {
        return o.name
      }
    ]).map((item) => ({ label: item.name, value: item.id }))

  }

  const getZoneList = async () => {
    try {
      setloadingZones(true)
      const response = await getZones()
      // console.log('data Zone->', response.data)
      setZones(response.data.data.areas)
      setZonesOptions(getZoneOptions(response.data.data.areas));
      setloadingZones(false)
    } catch (error) {
      // console.log('error Zone->', error)
      setZones([])
      setloadingZones(false)
    }
  }

  const postZone = async (Zone) => {
    try{
      const result = await genericApiCall(baseUrl, 'POST', Zone)
      if (result.status === 200) {
        Toast('success', getTranslation(t, ZoneNames.SUCCESS_ADD))
        return result.data.data.area
      } else {
        let message = {
          body: '',
          header: getTranslation(t, ZoneNames.ERROR_ADD)
        }
        if(result.message) message.body = result.message
        context.dispatch({ type: GET_AREA_ERROR, payload: message })
        return undefined
      }
    } catch (error) {
      context.dispatch({ type: GET_AREA_ERROR})
      Toast('warning',error.message, "", error)
    }     
  }

  const getZones = async () => {
    try{
      context.dispatch({ type: GET_AREA })
      const result = await genericApiCall(baseUrl, 'GET')
      if (result.status === 200) {
        context.dispatch({ type: GET_AREA_SUCCESS, payload: result.data.data.areas })
      } else {
        let message = {
          body: '',
          header: 'Error'
        }
        if (result.message) message.body = result.message
        context.dispatch({ type: GET_AREA_ERROR, payload: message })

      }
      return result
    } catch (error) {
      context.dispatch({ type: GET_AREA_ERROR})
      Toast('warning',error.message, "", error)
    }     
  }
  const putZone = async (Zone) => {
    try{
      const result = await genericApiCall(baseUrl, 'PUT', Zone)
      if (result.status === 200) {
        Toast('success', getTranslation(t, ZoneNames.SUCCESS_UPDATE))
        return result.data.data.area
      } else {
        let message = {
          body: '',
          header: getTranslation(t, ZoneNames.ERROR_UPDATE)
        }
        if(result.message) message.body = result.message
        context.dispatch({ type: GET_AREA_ERROR, payload: message })
        return undefined
      }
    } catch (error) {
      context.dispatch({ type: GET_AREA_ERROR})
      Toast('warning',error.message, "", error)
    } 
  }

  const deleteZone = async (Zone) => {
    try{
      const result = await genericApiCall(baseUrl, 'DELETE', Zone)
      if (result.status === 200) {
        Toast('success', getTranslation(t, ZoneNames.SUCCESS_DELETE))
        return result.data.data
      } else {
        let message = {
          body: '',
          header: getTranslation(t, ZoneNames.ERROR_DELETE)
        }
        if(result.message) message.body = result.message
        context.dispatch({ type: GET_AREA_ERROR, payload: message })
        return undefined
      }
    } catch (error) {
      context.dispatch({ type: GET_AREA_ERROR})
      Toast('warning',error.message, "", error)
    }     
  }

  const getNextCode = async () => {
    try{
      setLoading(true)
      const result = await  genericApiCall(`${baseUrl}/next-code`, 'get')
      setLoading(false)
      return result
    } catch (error) {
      setLoading(false)
      Toast('warning',error.message, "", error)
    }     
  }
  //TODO resolver el problema de la carga de datos en el useEffect
  useEffect(() => {
    if (!context.areaState?.areas && context.areaState?.loading !== true && context.areaState?.error === null) {
      getZoneList()
    }
  }, [context.areaState])

  return {
    setZones,
    zones,
    loadingZones,
    postZone,
    putZone,
    deleteZone,
    getNextCode,
    getZones,
    getZoneList,
    zonesOptions
  }
}
export default useZone
