import { GET_SURVEY_COLLABORATOR, GET_SURVEY_COLLABORATOR_ERROR, GET_SURVEY_COLLABORATOR_SUCCESS } from '../../business/constants'
import { useContext, useState } from 'react'

import { StoreContext } from '../../business/Provider'
import { Toast } from '../../Components/toast'
import useApi from '../api'

const useQuestionnaireGuide = (guideId,) => {
  const baseUrl = `${window.REACT_APP_EVALUA_API_URI}/guide/${guideId}`
  const [collaborators, setCollaborators] = useState(undefined)
  const [loadingCollaborators] = useState(false)
  const { genericApiCall } = useApi();
  const { dispatchSurveyCollaborator } = useContext(StoreContext)
  const getCollaborators = async (surveyId) => {
    if (surveyId) {
      try {
        dispatchSurveyCollaborator({ type: GET_SURVEY_COLLABORATOR })
        const result = await genericApiCall(`${baseUrl}/survey/${surveyId}`, 'get')
        if (result.status === 200) {

          const payload = {
            data: result.data.data,
            guideId: guideId,
            surveyId: surveyId
          }
          dispatchSurveyCollaborator({ type: GET_SURVEY_COLLABORATOR_SUCCESS, payload: payload })
        } else {
          let message = ''
          if (result.data) message = result.data.message
          else message = result.message
          dispatchSurveyCollaborator({ type: GET_SURVEY_COLLABORATOR_ERROR, payload: message })
        }
        return result
      } catch (error) {
        Toast('warning', error.message, "", error)
        dispatchSurveyCollaborator({ type: GET_SURVEY_COLLABORATOR_ERROR })
      }
    }
  }
  const putCancelStatus = async (surveyId, collaboratorIds, surveyCollaboratorId) => {
    try {
      return genericApiCall(`${baseUrl}/survey/${surveyId}/collaborator/${surveyCollaboratorId}`, 'put', collaboratorIds)
    } catch (error) {
      Toast('warning', error.message, "", error)
    }
  }
  const postSendEmail = async (surveyId, collaboratorSurvey) => {
    try {
      return genericApiCall(`${baseUrl}/survey/${surveyId}/sendEmail`, 'post', collaboratorSurvey)
    } catch (error) {
      Toast('warning', error.message, "", error)
    }
  }
  const putShare = async (surveyId, collaboratorSurvey, surveyCollaboratorKey, collaboratorId) => {
    try {
      return genericApiCall(`${baseUrl}/survey/${surveyId}/collaborator/${collaboratorSurvey}/collaboratorKey/${surveyCollaboratorKey}/share/${collaboratorId}`, 'put')
    } catch (error) {

      Toast('warning', error.message, "", error)
    }
  }
  const deleteSurveyCollaborator = async (surveyId, collaboratorId) => {
    try {
      return genericApiCall(`${baseUrl}/survey/${surveyId}/collaborator/${collaboratorId}`, 'delete')
    } catch (error) {
      Toast('warning', error.message, "", error)
    }
  }
  const getCollaboratorsAnswers = async (surveyId, collaboratorId) => {
    try {
      return genericApiCall(`${baseUrl}/surveyCollaboratorAnswers/${collaboratorId}`, 'get')
    } catch (error) {
      Toast('warning', error.message, "", error)
    }
  }


  return {
    setCollaborators: setCollaborators,
    collaborators: collaborators,
    loadingCollaborators: loadingCollaborators,
    getCollaborators: getCollaborators,
    putCancelStatus: putCancelStatus,
    postSendEmail: postSendEmail,
    deleteSurveyCollaborator: deleteSurveyCollaborator,
    getCollaboratorsAnswers: getCollaboratorsAnswers,
    putShare: putShare
  }
}
export default useQuestionnaireGuide
