import { CollaboratorsNames, SurveyNames, DepartmentNames, EvaluatorNames, GenericsNames, PositionNames, WorkCenterNames, ZoneNames } from "../../translations";
import { regexDescription, regexName } from "../../utils/utils";
import * as yup from 'yup'
// not unique field value validation return { [index]: field } where index is the position of the field in the array
export const validateUnique = (array, field) => {
  let result = {}

  array.forEach((item, index) => {
    // foreach start in index position
    array.forEach((item2, index2) => {

      if (item[field] !== undefined && item2[field] !== undefined) {

        if (index !== index2 && item[field] == item2[field]) {
          result[index] = {
            field: [field],
            message: { [field]: [GenericsNames.REPEAT_ERROR] },
            position: index
          }
        }
      }
    }
      // foreach end in index position
    )

  }
  )

  return result
}

const CollaboratorsSchema = yup.array(yup.object().shape({
  name:
    yup
      .string('')
      .required(CollaboratorsNames.VALIDATOR_NAME_REQUIRED)
      .max(100, CollaboratorsNames.VALIDATOR_NAME_REQUIRED_SIZE)
      .matches(regexName, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS),
  lastName:
    yup
      .string('')
      .required(CollaboratorsNames.VALIDATOR_LASTNAME_REQUIRED)
      .min(1, CollaboratorsNames.VALIDATOR_NAME_REQUIRED_SIZE)
      .max(100, CollaboratorsNames.VALIDATOR_NAME_REQUIRED_SIZE)
      .matches(regexName, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS),
  surName:
    yup
      .string('')
      .max(100, CollaboratorsNames.VALIDATOR_NAME_REQUIRED_SIZE)
      .matches(regexName, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS)
      .nullable(),
  email:
    yup
      .string('')
      .email(CollaboratorsNames.VALIDATOR_EMAIL)
      .required(CollaboratorsNames.VALIDATOR_EMAIL_REQUIRED)
      .max(100, CollaboratorsNames.VALIDATOR_NAME_REQUIRED_SIZE),
  departmentId:
    yup
      .string('')
      .required(CollaboratorsNames.VALIDATOR_DEPARTMENT_REQUIRED)
      .nullable(),
  positionId:
    yup
      .string('')
      .nullable(),
  workCenterId:
    yup
      .string('')
      .nullable(),
  gender:
    yup
      .string(''),
  areaId:
    yup
      .string('')
      .nullable(),
  taxId:
    yup //taxID: DataTypes.STRING, //RFC
      .string()
      .required(CollaboratorsNames.VALIDATOR_TAX_ID_REQUIRED)
      .max(13, CollaboratorsNames.VALIDATOR_TAX_ID_SIZE)
      .matches(
        /^([A-zÑ\x26]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([A-z]|[0-9]){2}([A]|[0-9]){1})?$/,
        'RFC inválido'
      )
}))

const DepartmentsSchema = yup.array(yup.object().shape({
  scode: yup // licenseId: DataTypes.UUID,
    .string('')
    .required(ZoneNames.VALIDATOR_ID_REQUIRED)
    .matches(/^[0-9]+$/, PositionNames.VALIDATOR_DIGIT_ALLOW)
    .min(4, ZoneNames.VALIDATOR_ID_REQUIRED_SIZE)
    .max(4, ZoneNames.VALIDATOR_ID_REQUIRED_SIZE)
    .test(
      'greaterThanZero',
      PositionNames.VALIDATOR_ID_GREATER_THAN_ZERO,
      (value) => parseInt(value, 10) > 0
    ),
  name: yup
    .string('')
    .required(ZoneNames.VALIDATOR_NAME_REQUIRED)
    // .min(4, 'El código postal debe tener al menos 5 caracteres')
    .max(100, DepartmentNames.DepartmentNameValidation)
    .matches(regexDescription, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS),
}))
const AreaSchema = yup.array(yup.object().shape({
  scode: yup // licenseId: DataTypes.UUID,
    .string('')
    .required(ZoneNames.VALIDATOR_ID_REQUIRED)
    .matches(/^[0-9]+$/, ZoneNames.VALIDATOR_DIGIT_ALLOW)
    .min(4, ZoneNames.VALIDATOR_ID_REQUIRED_SIZE)
    .max(4, ZoneNames.VALIDATOR_ID_REQUIRED_SIZE)
    .test(
      'greaterThanZero',
      PositionNames.VALIDATOR_ID_GREATER_THAN_ZERO,
      (value) => parseInt(value, 10) > 0
    ).nullable(),
  name: yup.string('')
    .required(ZoneNames.VALIDATOR_NAME_REQUIRED)
    .max(70, ZoneNames.VALIDATOR_NAME_REQUIRED_SIZE)
    .matches(regexDescription, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS),
  //.matches(/^[0-9a-zñáéíóúü ]+$/i, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS),
}))
const PositionSchema = yup.array(yup.object().shape({
  scode: yup // licenseId: DataTypes.UUID,
    .string('')
    .required(PositionNames.VALIDATOR_ID_REQUIRED)
    .matches(/^[0-9]+$/, PositionNames.VALIDATOR_DIGIT_ALLOW)
    .min(4, PositionNames.VALIDATOR_ID_REQUIRED_SIZE)
    .max(4, PositionNames.VALIDATOR_ID_REQUIRED_SIZE)
    .test(
      'greaterThanZero',
      PositionNames.VALIDATOR_ID_GREATER_THAN_ZERO,
      (value) => parseInt(value, 10) > 0
    ),
  name: yup
    .string('')
    .required('POSITION_VALIDATOR_NAME_REQUIRED')
    .required(PositionNames.VALIDATOR_NAME_REQUIRED)
    // .min(4, 'El código postal debe tener al menos 5 caracteres')
    .max(150, PositionNames.VALIDATOR_NAME_REQUIRED_SIZE)
    .matches(regexDescription, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS),
}))

const EvaluatorSchema = yup.array(yup.object().shape({
  scode: yup // licenseId: DataTypes.UUID,
    .string('')
    .required(ZoneNames.VALIDATOR_ID_REQUIRED)
    .matches(/^[0-9]+$/, ZoneNames.VALIDATOR_DIGIT_ALLOW)
    .min(4, ZoneNames.VALIDATOR_ID_REQUIRED_SIZE)
    .max(4, ZoneNames.VALIDATOR_ID_REQUIRED_SIZE)
    .test(
      'greaterThanZero',
      PositionNames.VALIDATOR_ID_GREATER_THAN_ZERO,
      (value) => parseInt(value, 10) > 0
    ),
  name: yup
    .string('')
    .required(ZoneNames.VALIDATOR_NAME_REQUIRED)
    // .min(4, 'El código postal debe tener al menos 5 caracteres')
    .max(100, EvaluatorNames.VALIDATOR_NAME_REQUIRED_SIZE)
    .matches(regexName, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS),
  profesionalId: yup
    .string('')
    .matches(/^[0-9]+$/, ZoneNames.VALIDATOR_DIGIT_ALLOW)
    .max(15, EvaluatorNames.VALIDATOR_DIGIT_PROFESIONAL_ID)
}))

const SurveySchema = yup.array(yup.object().shape({
  name: yup.string('').required(SurveyNames.VALIDATOR_NAME_REQUIRED).max(100, SurveyNames.VALIDATOR_NAME_REQUIRED_SIZE),
  lastName: yup.string('').required(SurveyNames.VALIDATOR_NAME_REQUIRED).max(100, SurveyNames.VALIDATOR_NAME_REQUIRED_SIZE),
  email: yup.string('').required(SurveyNames.COLLABORATOR_INVALID).email(SurveyNames.EMAIL_INVALID).nullable(),
  departmentId: yup.string('').required(SurveyNames.DEPARTMENT_NOT_EXIST).nullable(),
  evaluationPeriod: yup.string('').required(SurveyNames.EVALUATION_PERIOD_REQUIRED).nullable(),
  period: yup.string('').required(SurveyNames.PERIOD_REQUIRED).nullable(),
}))

const WorkCenterSchema = yup.array(yup.object().shape({
  scode: yup // licenseId: DataTypes.UUID,
    .string('')
    .required(WorkCenterNames.VALIDATOR_ID_REQUIRED)
    .matches(/^[0-9]+$/, WorkCenterNames.VALIDATOR_DIGIT_ALLOW)
    .min(4, WorkCenterNames.VALIDATOR_ID_REQUIRED_SIZE)
    .max(4, WorkCenterNames.VALIDATOR_ID_REQUIRED_SIZE)
    .test(
      'greaterThanZero',
      PositionNames.VALIDATOR_ID_GREATER_THAN_ZERO,
      (value) => parseInt(value, 10) > 0
    ),
  name: yup
    .string('')
    .required(WorkCenterNames.VALIDATOR_NAME_REQUIRED)
    // .min(4, 'El código postal debe tener al menos 5 caracteres')
    .max(100, WorkCenterNames.VALIDATOR_NAME_REQUIRED_SIZE)
    .matches(regexDescription, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS),
  zipCode: yup // zipCode: DataTypes.INTEGER,
    .string('')
    .required(WorkCenterNames.VALIDATOR_ZIPCODE_REQUIRED)
    .nullable()
    .min(5, WorkCenterNames.VALIDATOR_ZIPCODE_MIN)
    .max(5, WorkCenterNames.VALIDATOR_ZIPCODE_MIN),
  state: yup // state: DataTypes.STRING,
    .string()
    .required(WorkCenterNames.VALIDATOR_STATE_REQUIRED),
  municipality: yup //  municipality: DataTypes.STRING,
    .string('')
    .required(WorkCenterNames.VALIDATOR_MUNICIPALITY_REQUIRED)
    .max(50, WorkCenterNames.VALIDATOR_MUNICIPALITY_MAX),
  street: yup // street: DataTypes.STRING,
    .string('').required(WorkCenterNames.VALIDATOR_STREE_REQUIRED)
    .max(100, WorkCenterNames.VALIDATOR_STREET_MAX),
  exteriorNumber: yup.string().max(30, WorkCenterNames.VALIDATOR_EXTERIOR_MAX).required(WorkCenterNames.VALIDATOR_EXTERIOR_REQUIRED),
  interiorNumber: yup.string().max(30, WorkCenterNames.VALIDATOR_INTERIOR_MAX),
  suburb: yup.string().max(50, WorkCenterNames.VALIDATOR_SUBURB_MAX).required(WorkCenterNames.VALIDATOR_SUBURB_REQUIRED),
  principalActivity: yup.string().max(255, WorkCenterNames.VALIDATOR_PRINCIPAL_ACTIVITY).nullable(),
  objective: yup.string().max(255, WorkCenterNames.VALIDATOR_PRINCIPAL_ACTIVITY).nullable(),
  activities: yup.string().max(255, WorkCenterNames.VALIDATOR_PRINCIPAL_ACTIVITY).nullable(),
  //evaluatorId: yup.string('').required(WorkCenterNames.VALIDATOR_EVALUATOR_ID),
  // responsableId:string.uuid('WORK_CENTER_VALIDATOR_RESPONSABLE_ID')
}))
// get error fields
const validateAndGetErrors = async (values, schema) => {
  try {
    await schema.validate(values, { abortEarly: false })
    return {}
  } catch (err) {
    const temp = {}
    err.inner.forEach((error) => {
      const data = error.path.split('.')
      temp[error.path] = {
        position: data[0].split('[')[1].split(']')[0],
        field: [...(temp[error.path] ? temp[error.path].field : []), data[1]],
        message: [...(temp[error.path] ? temp[error.path].message : []), error.message],
      }
    })
    // group errors by position
    const result = {}
    Object.keys(temp).forEach((key) => {
      const { position } = temp[key]
      result[position] = {
        field: [...(result[position] ? result[position].field : []), ...temp[key].field],
        message: {
          ...(result[position] ? result[position].message : {}),
          [temp[key].field[0]]: temp[key].message,
        }
      }
    })
    return result
  }
}
// validate object
export const validate = async (values, type) => {
  // values is array of objects
  switch (type) {
    case 1:
      return validUniq(values, CollaboratorsSchema, 'taxId')
    case 2:
      return validUniq(values, AreaSchema, 'scode')
    case 3:
      return validUniq(values, PositionSchema, 'scode')
    case 4:
      return validUniq(values, WorkCenterSchema, 'scode')
    case 5:
      return validUniq(values, EvaluatorSchema, 'scode')
    case 6:
      return validUniq(values, DepartmentsSchema, 'scode')
    case 7:
      return validUniq(values, SurveySchema, null)
    case 8:
      return validUniq(values, SurveySchema, null)
    case 9:
      return validUniq(values, SurveySchema, null)
    default:
      return {};
  }
}

async function validUniq(values, schema, uniq) {
  const tempError = await validateAndGetErrors(values, schema);
  const uniqError = uniq ? validateUnique(values, uniq) : {};
  const result = {};

  Object.keys(tempError).forEach((key) => {
    result[key] = tempError[key]
  }
  )
  Object.keys(uniqError).forEach((key) => {
    if (!result[key]) {
      result[key] = uniqError[key]
    } else {
      result[key].field = [...(result[key] ? result[key].field : []), ...uniqError[key].field]
      // result[key].message = [...(result[key] ? result[key].message  : []), ...uniqError[key].message]
      Object.keys(result[key].message).forEach((key2) => {
        // if exist key2 in result[key].message, then merge array
        if (result[key].message[key2] && uniqError[key].message[key2]) {
          result[key].message[key2] = [...result[key].message[key2], ...uniqError[key].message[key2]]
        } else if (!result[key].message[key2]) {
          result[key].message[key2] = uniqError[key].message[key2]
        }
      })
    }

  }
  )
  return result;
}
