import { sortBy } from 'lodash'
import { getImportOptions } from '../../../utils/utils'
import { IsNullOrEmpty } from '../../../utils/utilsService'
import { ERROR_SHOW, GET_EVALUATOR, GET_EVALUATOR_ERROR, GET_EVALUATOR_SUCCESS } from '../../constants'
const getEvaluatorOptions = (evaluators) => {
    const activeEvaluators = evaluators.filter((object) => {
      return IsNullOrEmpty(object.deleteBy)
    })
    return sortBy(activeEvaluators, [
      function (o) {
        return o.name
      }
    ]).map((item) => ({ label: item.name, value: item.id }))

  }
export const evaluator = (state, actions) => {
    switch (actions.type) {
        case GET_EVALUATOR:
            return {
                evaluators: state.evaluators,
                loading: true,
                error: null,
                errorShow: false,
                evaluatorOptions: state.evaluatorOptions
            }
        case GET_EVALUATOR_ERROR:
            return {
                evaluators: state.evaluators,
                loading: false,
                error: actions.payload,
                errorShow: false,
                evaluatorOptions: state.evaluatorOptions
            }
        case GET_EVALUATOR_SUCCESS:
            return {
                evaluators: actions.payload,
                importOptions: getImportOptions(actions.payload),
                loading: false,
                error: null,
                errorShow: false,
                evaluatorOptions: getEvaluatorOptions(actions.payload)
            }
        case ERROR_SHOW:
            return {
                evaluators: state.evaluators,
                loading: false,
                error: state.error,
                errorShow: true
            }
        default:
            return state;
    }
}