

import axios from 'axios';
import { getLocalAccessToken } from '../../Auth/auth/token';

const baseUrl = `${window.REACT_APP_EVALUA_API_URI}/search-collaborator`;

export const getSearchcollaborators = async (searchObj) => {
  try {
    // console.log("Obj Search",searchObj)
    let response = await searchcollaborators(searchObj);
    //console.log("respuesta de busqueda", response.data.data.collaborators)
    return response.data.data.collaborators;
  } catch (error) {
    // console.log('error search collaborators->', error);

  }
};

const searchcollaborators = async (filterParams) => {
  const accessToken = getLocalAccessToken();

  return axios.post(baseUrl, filterParams, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};


