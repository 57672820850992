import * as yup from 'yup'

import { DepartmentNames, GenericsNames, PositionNames, ZoneNames } from '../../../../translations'

import { regexDescription } from '../../../utils'

const DepartmentsSchema = yup.object().shape({
  scode: yup // licenseId: DataTypes.UUID,
    .string('')
    .required(ZoneNames.VALIDATOR_ID_REQUIRED)
    .matches(/^[0-9]+$/, PositionNames.VALIDATOR_DIGIT_ALLOW)
    .min(4, ZoneNames.VALIDATOR_ID_REQUIRED_SIZE)
    .max(4, ZoneNames.VALIDATOR_ID_REQUIRED_SIZE)
    .test(
      'greaterThanZero',
      PositionNames.VALIDATOR_ID_GREATER_THAN_ZERO,
      (value) => parseInt(value, 10) > 0
    ),
  name: yup
    .string('')
    .required(ZoneNames.VALIDATOR_NAME_REQUIRED)
    // .min(4, 'El código postal debe tener al menos 5 caracteres')
    .max(100, DepartmentNames.DepartmentNameValidation)
    .matches(regexDescription, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS),
  code: yup
    .number()
    .required(DepartmentNames.DepartmentCodeIsRequired)
    .min(1, DepartmentNames.DepartmentCodeValidation)

})
export default DepartmentsSchema
