import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

//axios
import axios from 'axios';

//azure imports 
import { EventType, PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig, loginRequest, uris } from './Auth/b2cService/b2cServiceConfig'

// idioms
import { I18nextProvider, initReactI18next } from 'react-i18next'
import i18next from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'

import global_es from './translations/es/global.json'
import global_en from './translations/en/global.json'
import { SurveysLayout } from './Layout/SurveysLayout';

const URLactual = window.location;
const newUrlActual = URLactual.toString();
const newUrlActualArray = newUrlActual.split('/');

const setConfigurationAndDoRendering = (data) => {
  msalConfig.auth.clientId = data.msalConfig.auth.clientId
  msalConfig.auth.authority = data.msalConfig.auth.authority
  msalConfig.auth.clientSecret = data.msalConfig.auth.clientSecret
  msalConfig.auth.knownAuthorities = data.msalConfig.auth.knownAuthorities
  msalConfig.auth.clientSecret = data.msalConfig.auth.clientSecret
  msalConfig.redirectUri = data.msalConfig.auth.redirectUri
  msalConfig.auth.postLogoutRedirectUri = data.ctiPortalURI

  msalConfig.ctiPortalURI = data.ctiPortalURI
  loginRequest.scopes = data.loginRequest.scopes
  uris.platformAccountUri = data.platformAccountURI
  localStorage.setItem('ctiPortalURI', JSON.stringify(data.ctiPortalURI))
  const msalInstance = new PublicClientApplication(msalConfig)
  msalInstance.enableAccountStorageEvents();
  msalInstance.addEventCallback((message) => {
    if (message.eventType === EventType.ACCOUNT_REMOVED) {
      // clear all storage
      localStorage.clear()
      sessionStorage.clear()
      window.location.href = data.ctiPortalURI
    }
  })

  i18next
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .init({
      interpolation: { escapeValue: false },
      lng: 'es-MX',
      resources: {
        'es-MX': {
          app: global_es
        },
        'en-US': {
          app: global_en
        }
      }
    })

  ReactDOM.render(
    // <Provider store={store}>
    <>
      {newUrlActualArray[3] !== undefined && newUrlActualArray[3] !== null && newUrlActualArray[3] !== 'cuestionarios' ?
        <MsalProvider instance={msalInstance}>
          <I18nextProvider i18n={i18next}>
            <App pca={msalInstance} />
          </I18nextProvider>
        </MsalProvider>
        : <I18nextProvider i18n={i18next}>
          <SurveysLayout />
        </I18nextProvider>
      }</>,

    // </Provider>,
    document.getElementById('root')
  )

  reportWebVitals()

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA  
  serviceWorker.unregister()
}

const initConfiguration = async () => {
  try {
    console.log('apiurl: ', window.REACT_APP_EVALUA_API_URI)
    const res = await axios.get(`${window.REACT_APP_EVALUA_API_URI}/configuration`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    setConfigurationAndDoRendering(res.data)
  } catch (error) {
    if (error.response) {
      console.log('Error al obtener la configuración', error.response.data.error)
    }
  }
}

initConfiguration()



