import { sortBy } from "lodash";
import { getImportOptions } from "../../../utils/utils";
import { IsNullOrEmpty } from "../../../utils/utilsService";
import { ERROR_SHOW, GET_POSITION, GET_POSITION_ERROR, GET_POSITION_SUCCESS } from "../../constants";
const getPositionsOptions = (positionResponse) => {
    const activePosition = positionResponse.filter((object) => {
      return IsNullOrEmpty(object.deleteBy)
    })
    return sortBy(activePosition, [
      function (o) {
        return o.name
      }
    ]).map((item) => ({ label: item.name, value: item.id }))

  }
export const position = (state, actions) => {
    switch (actions.type) {
        case GET_POSITION:
            return {
                ...state,
                loading: true,
            }
        case GET_POSITION_ERROR:
            return {
                positions: state.positions,
                loading: false,
                error: actions.payload,
                errorShow: false
            }
        case GET_POSITION_SUCCESS:
            return {
                positions: actions.payload,
                positionOptions: getPositionsOptions(actions.payload),
                importOptions: getImportOptions(actions.payload),
                loading: false,
                error: null,
                errorShow: false
            }
        case ERROR_SHOW:
            return {
                ...state,
                errorShow: true
            }
        default:
            return state;
    }
}