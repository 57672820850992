import React from 'react'
import { CheckBoxComponent } from '../../../../Components/CheckBox'
import { Input } from '../../../../Components/Input/Input'
import { SelectComponent } from '../../../../Components/Select'
import { getTranslation } from '../../../../hooks/useTranslations'
import { CollaboratorsNames, GenericsNames } from '../../../../translations'

const renderSelect = (labelText, options, formikData, formikLabel, important, t, iNeedEmptyValue) => {
  return (
    <SelectComponent
      className={'flex flex-col p-2 w-1/2'}
      valueSelected={options?.filter(item => item.id === formikData?.values[formikLabel])[0]}
      labelText={labelText}
      ValuesOptions={options}
      handleChange={(e) => {
        formikData.setFieldValue(formikLabel, e.target.value.id)
      }}
      placeholderText={'...'}
      name={formikLabel}
      optionLabel={'name'}
      error={formikData.touched[formikLabel] && Boolean(formikData?.errors[formikLabel])}
      errorText={
        formikData.touched[formikLabel] && getTranslation(t, formikData?.errors[formikLabel])
      }
      important={important}
      iNeedEmptyValue={iNeedEmptyValue}
    />
  )
}

const renderInput = (
  id,
  name,
  placeholder,
  onChange,
  value,
  onBlur,
  error,
  errorText,
  important
) => {
  return (
    <div className="flex w-1/2 p-2">
      <Input
        id={id}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        error={error}
        errorText={errorText}
        important={important}
      />
    </div>
  )
}


export const PersonalDataForm = (formik, t) => {

  const GENDER_LIST = [
    { id: '', name: '' },
    { id: 'F', name: getTranslation(t, GenericsNames.FEMININE) },
    { id: 'M', name: getTranslation(t, GenericsNames.MALE) }
  ]
  return (
    <>
      <div className="flex w-full flex-row">
        {renderInput(
          'name',
          'name',
          getTranslation(t, GenericsNames.NAMES),
          formik.handleChange,
          formik.values.name,
          (event) => {
            formik.handleBlur(event)
            formik.setFieldValue('name', event.target.value.trim())
          },
          formik.touched.name && Boolean(formik.errors.name),
          formik.touched.name && getTranslation(t, formik.errors.name),
          true
        )}
        {renderInput(
          'lastName',
          'lastName',
          getTranslation(t, CollaboratorsNames.lAST_NAME),
          formik.handleChange,
          formik.values.lastName,
          (event) => {
            formik.handleBlur(event)
            formik.setFieldValue('lastName', event.target.value.trim())
          },
          formik.touched.lastName && Boolean(formik.errors.lastName),
          formik.touched.lastName && getTranslation(t, formik.errors.lastName),
          true

        )}
      </div>
      <div className="flex w-full flex-row">
        {renderInput(
          'surName',
          'surName',
          getTranslation(t, CollaboratorsNames.SOR_NAME),
          formik.handleChange,
          formik.values.surName,
          (event) => {
            if (event.target.value !== '') {
              formik.handleBlur(event)
              formik.setFieldValue('surName', event.target.value.trim())
            } else {
              formik.setFieldValue('surName', null)
            }
          },
          formik.touched.name && Boolean(formik.errors.surName),
          formik.touched.name && getTranslation(t, formik.errors.surName),
          false
        )}
        {
          renderInput(
            'email',
            'email',
            getTranslation(t, GenericsNames.EMAIL),
            formik.handleChange,
            formik.values.email,
            (event) => {
              formik.handleBlur(event)
              formik.setFieldValue('email', event.target.value.trim())
            },
            formik.touched.email && Boolean(formik.errors.email),
            formik.touched.email && getTranslation(t, formik.errors.email),
            true
          )
        }
      </div>

      <div className="flex flex-row w-full ">
        {renderInput(
          'taxId',
          'taxId',
          getTranslation(t, CollaboratorsNames.TAX_ID),
          (event) => {
            //upper case
            event.target.value = event.target.value.toUpperCase()
            formik.handleChange(event)
          },
          formik.values.taxId,
          (event) => {
            formik.handleBlur(event)
            formik.setFieldValue('taxId', event.target.value.trim())
          },
          formik.touched.taxId && Boolean(formik.errors.taxId),
          formik.touched.taxId && getTranslation(t, formik.errors.taxId),
          true
        )}
        {
          renderSelect(
            getTranslation(t, GenericsNames.GENDER),
            GENDER_LIST,
            formik,
            'gender',
            false
          )
        }


      </div>

    </>
  )
}
export const CollaboratorForm = (formik, zones, departments, positions, workCenters, t) => {
  const STATUS_LIST = [
    { id: 'A', name: getTranslation(t, CollaboratorsNames.HIGH) },
    { id: 'B', name: getTranslation(t, CollaboratorsNames.SHORT) },
    { id: 'R', name: getTranslation(t, CollaboratorsNames.REENTRY) }
  ]
  return (
    <>
      <div className="flex flex-row w-full ">
        {
          renderSelect(
            getTranslation(t, GenericsNames.WORK_CENTER),
            workCenters,
            formik,
            'workCenterId',
            false,
            t,
            true
          )
        }
        {
          renderSelect(
            getTranslation(t, GenericsNames.AREA),
            zones,
            formik,
            'areaId',
            false,
            t,
            true
          )
        }

      </div>
      <div className="flex flex-row w-full ">
        {
          renderSelect(
            getTranslation(t, GenericsNames.DEPARTMENT),
            departments,
            formik,
            'departmentId',
            true,
            t,
            false
          )
        }
        {
          renderSelect(
            getTranslation(t, GenericsNames.POSITION),
            positions,
            formik,
            'positionId',
            false,
            t,
            true
          )
        }

      </div>
      <div className="flex flex-row w-full ">

        {
          renderSelect(
            getTranslation(t, CollaboratorsNames.STATUS),
            STATUS_LIST,
            formik,
            'status',
            false,
            t,
            false
          )
        }
      </div></>
  )
}