import { sortBy } from "lodash";
import { useContext } from "react";
import { GET_CITIES, GET_CITIES_ERROR, GET_CITIES_SUCCESS } from "../../business/constants";
import { StoreContext } from "../../business/Provider";
import { IsNullOrEmpty } from "../../utils/utilsService";
import useApi from "../api";
import { Toast } from '../../Components/toast'

const UseCities = () => {
    const { genericApiCall } = useApi();
    const context = useContext(StoreContext);
    const baseUrl = `${window.REACT_APP_EVALUA_API_URI}/cp/states`;
    const getStateOptions = (states) => {
        const activeEvaluators = states.filter((object) => {
          return IsNullOrEmpty(object.deleteBy);
        });
        return sortBy(activeEvaluators, [
          function (o) {
            return o.name;
          },
        ]).map((item) => ({ label: item.name, value: item.name, id: item.id }));
      };
    const getCities = async (state) => {
      try{
        context.dispatchCities({ type: GET_CITIES });
        const response = await genericApiCall(`${baseUrl}/${state}/cities`, 'get');
        if (response.status === 200) {
            context.dispatchCities({ type: GET_CITIES_SUCCESS, payload: getStateOptions(response.data.data.ciudades) });
        } else {
            context.dispatchCities({ type: GET_CITIES_ERROR, payload: response.data.message });
        }
        return response
      } catch (error) {
        Toast('warning',error.message, "", error)
        context.dispatchCities({ type: GET_CITIES_ERROR})
      } 
    }
    return { getCities };
}
export default UseCities;