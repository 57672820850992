import { GET_EVALUATOR, GET_EVALUATOR_ERROR, GET_EVALUATOR_SUCCESS } from '../../business/constants'
import { useContext, useEffect, useState } from 'react'

import { IsNullOrEmpty } from '../../utils/utilsService'
import { StoreContext } from '../../business/Provider'

//import { getLocalAccessToken } from 'app/auth/token'
import { sortBy } from 'lodash-es'
import useApi from '../api'
import { getTranslation, useCustomTranslation } from '../useTranslations'
import { EvaluatorNames } from '../../translations'
import { Toast } from '../../Components/toast'
import { LoadingContext } from '../../context/LoadingContext'

const useEvaluator = () => {
  const baseUrl = `${window.REACT_APP_EVALUA_API_URI}/evaluator`
  const {t} = useCustomTranslation()
  const [evaluator, setEvaluator] = useState(undefined)
  const [evaluatorOptions, setEvaluatorOptions] = useState(undefined)
  const [loadingEvaluator, setLoadingEvaluator] = useState(false)
  const {setLoading} = useContext(LoadingContext);
  const { genericApiCall } = useApi();
  const context = useContext(StoreContext);
  const getEvaluatorOptions = (evaluators) => {
    const activeEvaluators = evaluators.filter((object) => {
      return IsNullOrEmpty(object.deleteBy)
    })
    return sortBy(activeEvaluators, [
      function (o) {
        return o.name
      }
    ]).map((item) => ({ label: item.name, value: item.id }))

  }

  const getEvaluatorList = async () => {
    try {
      setLoadingEvaluator(true)
      const response = await getEvaluator()
      //console.log('data Evaluator->', response.data)
      setEvaluator(response.data.data.evaluators)
      setEvaluatorOptions(getEvaluatorOptions(response.data.data.evaluators))
      setLoadingEvaluator(false)
    } catch (error) {
      //console.log('error Evaluator->', error)
      setEvaluator([])
      setLoadingEvaluator(false)
    }
  }

  const postEvaluator = async (newEvaluator) => {
    try{
      const result = await genericApiCall(baseUrl, 'POST', newEvaluator)
      if (result.status === 200) {
        //console.log('result Evaluator->', result.data)
        Toast('success', getTranslation(t, EvaluatorNames.SUCCESS_ADD))
        return result.data.data.evaluator
      } else {
        let message = {
          body: '',
          header: getTranslation(t, EvaluatorNames.ERROR_ADD)
        }
        if (result.message) message.body = result.message
        context.dispatchEvaluator({ type: GET_EVALUATOR_ERROR, payload: message })
        return undefined
      }
    } catch (error) {
      Toast('warning',error.message, "", error)
      context.dispatchEvaluator({ type: GET_EVALUATOR_ERROR})
    }    

  }

  const getEvaluator = async () => {
    try{
      context.dispatchEvaluator({ type: GET_EVALUATOR })
      const result = await genericApiCall(baseUrl, 'GET')
      if(result.status === 200) {
        context.dispatchEvaluator({ type: GET_EVALUATOR_SUCCESS, payload: result.data.data.evaluators })
      } else {
        let message = {
          body: '',
          header: 'Error'
        }
        if (result.message) message.body = result.message
        context.dispatchEvaluator({ type: GET_EVALUATOR_ERROR, payload: message })
      }
      return result
    } catch (error) {
      Toast('warning',error.message, "", error)
      context.dispatchEvaluator({ type: GET_EVALUATOR_ERROR})
    }        
  }

  const putEvaluator = async (updateEvaluator) => {
    try{
      const result = await genericApiCall(baseUrl, 'PUT', updateEvaluator)
      if (result.status === 200) {
        //console.log('result Evaluator->', result.data)
        Toast('success', getTranslation(t, EvaluatorNames.SUCCESS_UPDATE))
        return result.data.data.evaluator
      } else {
        let message = {
          body: '',
          header: getTranslation(t, EvaluatorNames.ERROR_UPDATE)
        }
        if (result.message) message.body = result.message
        context.dispatchEvaluator({ type: GET_EVALUATOR_ERROR, payload: message })
        return undefined
      }
    } catch (error) {
      Toast('warning',error.message, "", error)
      context.dispatchEvaluator({ type: GET_EVALUATOR_ERROR})
    }        
  }

  const deleteEvaluator = async (dataToDeleteEvaluator) => {
    try{
      const result = await genericApiCall(baseUrl, 'DELETE', dataToDeleteEvaluator)
      if (result.status === 200) {
        //console.log('result Evaluator->', result.data)
        Toast('success', getTranslation(t, EvaluatorNames.SUCCESS_DELETE))
        return result.data.data
      } else {
        let message = {
          body: '',
          header: ''
        }
        if (result.message) message.header = result.message
        context.dispatchEvaluator({ type: GET_EVALUATOR_ERROR, payload: message })
        return undefined
      }
    } catch (error) {
      Toast('warning',error.message, "", error)
      context.dispatchEvaluator({ type: GET_EVALUATOR_ERROR})
    }        
  }

  const getNextCode = async () => {
    try{
      setLoading(true)
      const result = await  genericApiCall(`${baseUrl}/next-code`, 'get')
      setLoading(false)
      return result
    } catch (error) {
      setLoading(false)
      Toast('warning',error.message, "", error)
    }        
  }

  useEffect(() => {
    if(!context.evaluatorState?.evaluators && context.evaluatorState.loading === false && context.evaluatorState.error === null) {
      getEvaluator()
    }
  }, [context.evaluatorState])

  return {
    setEvaluator,
    evaluator,
    loadingEvaluator,
    postEvaluator,
    putEvaluator,
    deleteEvaluator,
    getNextCode,
    getEvaluator,
    evaluatorOptions,
    getEvaluatorList
  }
}
export default useEvaluator
