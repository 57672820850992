import './index.css';

import { QuestionaryCollaboratorsNames, SurveyNames, ToolBarNames, ResultsNames, GenericsNames } from '../../translations';
import { TabPanel, TabView } from 'primereact/tabview';
import { ToExcel, ToPdf, EntityToExport } from '../../utils/exportService';
import { faFileExcel, faFilePdf, faPrint } from '@fortawesome/free-solid-svg-icons';
import { getTranslation, useCustomTranslation } from '../../hooks/useTranslations';
import { Fieldset } from 'primereact/fieldset'
import { Column } from 'primereact/column';
import { CustomIconButton } from '../../Components/Buttons/CustomIconButton';
import { EvaluaTable } from '../../Components/Table/EvaluaTable';
import { FullLoading } from '../../Components/FullLoading';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react'
import { getResultTable } from '../../utils/htmlResultSurveyTable';
import { LoadingContext } from '../../context/LoadingContext'
import { useParams } from 'react-router-dom';
import useSurveyCollaboratorResult from '../../hooks/surveys/useSurveyCollaboratorResult';
import { areaCodeMask, IsNullOrEmpty } from '../../utils/utilsService';
import { StoreContext } from '../../business/Provider';
import { ERROR_SHOW } from '../../business/constants';
import { Toast } from '../../Components/toast';

const CollaboratorQuestionaryComponent = () => {
    const { guideName, idQuestionary, idSurveyCollaborator } = useParams();
    const { t } = useCustomTranslation();
    const { } = useSurveyCollaboratorResult(idQuestionary, idSurveyCollaborator);
    const loadingObj = useContext(LoadingContext);
    const { resultState, dispatchResult } = useContext(StoreContext);
    const [survResult, setSurvResult] = useState(null);
    const [guideNum, setGuideNum] = useState(null);

    useEffect(() => {
        if (idQuestionary && idSurveyCollaborator && resultState.results[idQuestionary + '-' + idSurveyCollaborator]) {
            setSurvResult(resultState.results[idQuestionary + '-' + idSurveyCollaborator]);
        }
        if (!IsNullOrEmpty(guideName)) {
            setGuideNum(guideName.replace("guide-", ""))
        }
    }, [resultState.results, guideName, idQuestionary, idSurveyCollaborator]);

    useEffect(() => {
        if (resultState.errorShow === false && resultState.error) {
            dispatchResult({ type: ERROR_SHOW, payload: resultState.error });
        }
    }, [resultState?.error]);

    const getResultObjectHtml = (output) => {
        const objResult = {
            headerBackgroundColor: "#E4E4E7", //TODO GET FROM THEME.          
            columns: [
                { title: getTranslation(t, SurveyNames.TABLE_RESULT_CATEGORY), dataKey: 'category', colorProperty: 'categoryScoreColor', rowSpanProperty: 'categoryRowSpan', textColor: 'categoryTextColor', textAlign: 'center' },
                { title: getTranslation(t, SurveyNames.TABLE_RESULT_CATEGORY_SCORE), dataKey: 'categoryScoreDescription', colorProperty: 'categoryScoreColor', rowSpanProperty: 'categoryRowSpan', textColor: 'categoryTextColor', textAlign: 'center' },
                { title: getTranslation(t, SurveyNames.TABLE_RESULT_CATEGORY_DOMAIN), dataKey: 'domain', priority: 2, rowSpanProperty: 'domainRowSpan', colorProperty: 'domainScoreColor', textColor: 'domainTextColor', textAlign: 'center' },
                { title: getTranslation(t, SurveyNames.TABLE_RESULT_CATEGORY_DOMAIN_SCORE), dataKey: 'domainScoreDescription', colorProperty: 'domainScoreColor', rowSpanProperty: 'domainRowSpan', textColor: 'domainTextColor', textAlign: 'center', },
                { title: getTranslation(t, SurveyNames.TABLE_RESULT_DIMENSION), dataKey: 'dimension' },
                { title: getTranslation(t, SurveyNames.TABLE_RESULT_TOTAL), dataKey: 'total', toFix: 4, textAlign: 'center' },
                { title: getTranslation(t, SurveyNames.TABLE_RESULT_QUESTION), dataKey: 'questions', textAlign: 'right' },
                { title: getTranslation(t, SurveyNames.TABLE_RESULT_ITEMS), dataKey: 'items', textAlign: 'right' },
                { title: getTranslation(t, SurveyNames.TABLE_RESULT_FACTOR), dataKey: 'factor', colorProperty: 'factorColor', toFix: 4, textAlign: 'center', textColor: 'factorTextColor' },
            ],
            rows: survResult?.summary,
            output: output
        }

        return getResultTable(objResult);

    }

    const exportToExcel = async () => {
        console.log("exportToExcel ", survResult);
        if (survResult?.questionary !== undefined && survResult?.questionary !== null && survResult?.questionary.length > 0) {
            try {
                loadingObj.setLoading(true)
                await ToExcel({ surveyId: idQuestionary, surveyCollaboratorId: idSurveyCollaborator }, guideNum == 1 ? EntityToExport.RESULT_BY_COLLABORATOR_G1 : EntityToExport.RESULT_BY_COLLABORATOR)
                loadingObj.setLoading(false)
            } catch (error) {
                loadingObj.setLoading(false)
                console.log(error);
            }
        } else {
            Toast('warning', getTranslation(t, GenericsNames.NO_EXPORT_DATA))
        }
    }

    const exportToPdf = async () => {
        if (survResult?.questionary !== undefined && survResult?.questionary !== null && survResult?.questionary.length > 0) {
            try {
                loadingObj.setLoading(true)
                await ToPdf({ surveyId: idQuestionary, surveyCollaboratorId: idSurveyCollaborator }, guideNum == 1 ? EntityToExport.RESULT_BY_COLLABORATOR_G1 : EntityToExport.RESULT_BY_COLLABORATOR)
                loadingObj.setLoading(false)
            } catch (error) {
                loadingObj.setLoading(false)
                console.log(error);
            }
        }
        else {
            Toast('warning', getTranslation(t, GenericsNames.NO_EXPORT_DATA))
        }
    }

    const headerTemplate = (rowData) => {
        return <h5 style={{ fontWeight: 'bold' }}>{rowData.SequenceWithSection}</h5>;
    }



    const renderGrid = () => {
        console.log('guidenum:', guideNum)
        if (guideNum > 1) {
            return <EvaluaTable
                value={survResult?.questionary}
                emptyMessage={getTranslation(t, QuestionaryCollaboratorsNames.GRID_EMPTY)}
                currentPageReportTemplate={getTranslation(t, QuestionaryCollaboratorsNames.GRID_PAGE_REPORT_TEMPLATE)}
                rowGroupHeaderTemplate={headerTemplate}
                groupRowsBy="SequenceWithSection"
                rowGroupMode="subheader"
                globalFilterFields={['SequenceWithSection', 'QuestionHtml', 'Always', 'AlwaysIsh', 'Sometimes', 'NeverIsh', 'Never', 'Yes', 'No']}
                resizableColumns
                scrollable
                style={{ height: 'calc(100vh - 389px)', width: 'calc(100vw - 100px)' }}
            >
                <Column
                    field='QuestionHtml'
                    header={getTranslation(t, QuestionaryCollaboratorsNames.QUESTION_NAME)}

                    body={(e) => {
                        return (
                            <div dangerouslySetInnerHTML={{ __html: e["QuestionHtml"] }}></div>
                        )
                    }}
                    style={{ flexGrow: 1, width: '50%' }}
                />
                <Column
                    field='Always'
                    header={getTranslation(t, QuestionaryCollaboratorsNames.ALWAYS)}
                    style={{ flexGrow: 1, width: '10%' }}
                />
                <Column
                    field='AlwaysIsh'
                    header={getTranslation(t, QuestionaryCollaboratorsNames.ALMOST_ALWAYS)}
                    style={{ flexGrow: 1, width: '10%' }}
                />
                <Column
                    field='Sometimes'
                    header={getTranslation(t, QuestionaryCollaboratorsNames.SOMETIMES)}
                    style={{ flexGrow: 1, width: '10%' }}
                />
                <Column
                    field='NeverIsh'
                    header={getTranslation(t, QuestionaryCollaboratorsNames.ALMOST_NEVER)}
                    style={{ flexGrow: 1, width: '10%' }}
                />
                <Column
                    field='Never'
                    header={getTranslation(t, QuestionaryCollaboratorsNames.NEVER)}
                    style={{ flexGrow: 1, width: '10%' }}
                />
            </EvaluaTable>
        }
        return <EvaluaTable
            value={survResult?.questionary}
            emptyMessage={getTranslation(t, QuestionaryCollaboratorsNames.GRID_EMPTY)}
            currentPageReportTemplate={getTranslation(t, QuestionaryCollaboratorsNames.GRID_PAGE_REPORT_TEMPLATE)}
            rowGroupHeaderTemplate={headerTemplate}
            groupRowsBy="SequenceWithSection"
            rowGroupMode="subheader"
            globalFilterFields={['SequenceWithSection', 'QuestionHtml', 'Always', 'AlwaysIsh', 'Sometimes', 'NeverIsh', 'Never', 'Yes', 'No']}
            resizableColumns
            scrollable
            style={{ height: 'calc(100vh - 389px)', width: 'calc(100vw - 100px)' }}
        >

            <Column
                field='QuestionHtml'
                header={getTranslation(t, QuestionaryCollaboratorsNames.QUESTION_NAME)}

                body={(e) => {
                    return (
                        <div dangerouslySetInnerHTML={{ __html: e["QuestionHtml"] }}></div>
                    )
                }}
                style={{ flexGrow: 1, width: '50%' }}
            />
            <Column
                field='Yes'
                header={getTranslation(t, QuestionaryCollaboratorsNames.YES)}
                style={{ flexGrow: 1, width: '10%' }}
            />
            <Column
                field='No'
                header={getTranslation(t, QuestionaryCollaboratorsNames.NO)}
                style={{ flexGrow: 1, width: '10%' }}
            />
        </EvaluaTable>
    }

    const renderHeaderSurvey = () => {
        return (
            <div className='grid gap-2 grid-cols-12 font-font-family-base p-1'>
                <div className='col-span-12'>
                    <div className='flex justify-center align-middle flex-row'>
                        <h1 className='font-semibold '>{survResult?.general.Title} </h1>
                    </div>
                    <div className='flex justify-center align-middle flex-row'>
                        <h1 className='font-semibold '>{JSON.parse(localStorage.getItem('companyInfo')).companyName} </h1>
                    </div>
                </div>
                <div className='col-span-3'>
                    <h1 className='font-semibold'> {getTranslation(t, SurveyNames.NUMBER_SURVEY)}</h1>
                    <h1> {areaCodeMask(survResult?.general.SurveyCode)}</h1>
                </div>
                <div className='col-span-3'>
                    <h1 className='font-semibold'> {getTranslation(t, SurveyNames.QUESTIONARY_TITLE)}</h1>
                    <h1> {survResult?.general.SCCode}</h1>
                </div>
                <div className='col-span-3'>
                    <h1 className='font-semibold'> {getTranslation(t, SurveyNames.APPLICATION_DATE)}</h1>
                    {/* <h1> <Moment format="MM/DD/YYYY">{survResult?.general.ApplicationDate}</Moment></h1> */}
                    <h1> {survResult?.general.ApplicationDate}</h1>
                </div>
                <div className='col-span-3'>
                    <h1 className='font-semibold'> {getTranslation(t, SurveyNames.COLLABORATOR)}</h1>
                    <h1> {survResult?.general.CollaboratorName}</h1>
                </div>
                <div className='col-span-3'>
                    <h1 className='font-semibold'> {getTranslation(t, SurveyNames.EMAIL)}</h1>
                    <h1> {survResult?.general.CollaboratorEmail}</h1>
                </div>
                <div className='col-span-3'>
                    <h1 className='font-semibold'> {getTranslation(t, SurveyNames.WORK_CENTER)}</h1>
                    <h1> {survResult?.general.WorkCenterText}</h1>
                </div>
                <div className='col-span-3'>
                    <h1 className='font-semibold'> {getTranslation(t, SurveyNames.DEPARTMENT)}</h1>
                    <h1> {survResult?.general.DepartmentText}</h1>
                </div>
                <div className='col-span-3'>
                    <h1 className='font-semibold'> {getTranslation(t, SurveyNames.ZONE)}</h1>
                    <h1> {survResult?.general.AreaText}</h1>
                </div>
                <div className='col-span-3'>
                    <h1 className='font-semibold'> {getTranslation(t, SurveyNames.POSITION)}</h1>
                    <h1> {survResult?.general.PositionText}</h1>
                </div>
                <div className='col-span-3'>

                </div>
                <div className='col-span-3'>
                    <CustomIconButton
                        toolTip={getTranslation(t, ToolBarNames.EXCEL)}
                        onClick={exportToExcel}
                        icon={faFileExcel}
                    />
                    <CustomIconButton toolTip={getTranslation(t, ToolBarNames.PDF)} onClick={exportToPdf} icon={faFilePdf} />

                </div>
            </div>
        )
    }

    const renderResult = () => {
        const bg = `${survResult?.general.ScoreColor || 'transparent'}`
        return (
            // <div className='grid grid-cols-1 gap-2 h-full '>
            <div className='p-4 flex-grow flex flex-col gap-4'>
                <div className='text-center'>
                    <p>{getTranslation(t, SurveyNames.RESULT_VALUE)}</p>
                </div>

                <div className='flex w-full justify-center '>
                    <div style={{
                        background: bg,
                    }} className={`w-1/3 h-12  flex text-center items-center justify-center border border-border text-black`}>
                        {survResult?.general.GeneralScoreText}
                    </div>
                </div>

                <Fieldset legend={getTranslation(t, ResultsNames.RECOMMENDATION)} className=' border border-border p-2 relative flex-grow '>
                    <p>{survResult?.general.ScoreDescription}</p>
                </Fieldset>

            </div>)
    }

    const renderResultTable = (output) => {

        return <div className=' overflow-y-auto absolute top-0 bottom-0 w-full p-1' dangerouslySetInnerHTML={{ __html: getResultObjectHtml(output) }}></div>;
    }

    return (
        <div className='p-1 relative w-full h-full flex flex-col' padding={1} margin={1} >
            {survResult &&
                <>
                    {renderHeaderSurvey()}

                    {guideNum > 1 ?
                        <TabView className='flex-grow flex flex-col' >
                            <TabPanel className='' header={getTranslation(t, SurveyNames.RESULT)}>
                                {renderResult()}
                            </TabPanel>
                            <TabPanel contentClassName='h-full relative' header={getTranslation(t, SurveyNames.SUMMARY)}>

                                {renderResultTable('html')}

                            </TabPanel>
                            <TabPanel header={getTranslation(t, SurveyNames.QUESTIONARY_TITLE)}>
                                {renderGrid()}
                            </TabPanel>
                        </TabView>
                        : renderGrid()}

                </>
            }
            <FullLoading show={resultState.loading} />
        </div >
    );

};
CollaboratorQuestionaryComponent.propTypes = {
    guideName: PropTypes.string.isRequired,
    idQuestionary: PropTypes.string.isRequired,
    idSurveyCollaborator: PropTypes.string.isRequired
};

export default CollaboratorQuestionaryComponent;