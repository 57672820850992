
import React from 'react'
import logo from '../../assets/img/LogotipoLanding.png';

export const ErrorComponentValidation = ({ messageSurveyValidation }) => {
  //close windows actual


  return (
    <div className='flex w-[80%] h-[80%] lg:w-3/5 xl:w-3/5 2xl:w-3/5 lg:h-3/5 xl:h-3/5 2xl:h-3/5 bg-white shadow-2xl divide-y-2 absolute'>
      <div className='flex flex-col w-full h-full'>
        <div className='hidden lg:flex xl:flex 2xl:flex h-1/5 w-full'>
          <div className="w-2/12   h-full items-center content-center justify-center ">
            <img width="100px" className="object-scale-down m-8" src={logo} alt="Logo" />
          </div>
        </div>
        <div className='flex h-full lg:h-3/5 xl:h-3/5 2xl:h-3/5 w-full text-center justify-center content-center items-center lg:p-48 xl:p-48 2xl:p-48'>
          <p className='font-bold text:xs lg:text-base xl:text-base 2xl:text-base'>{messageSurveyValidation}</p>
        </div>
        {/* "Scripts may not close windows that were not opened by script." 
        https://stackoverflow.com/questions/19761241/window-close-and-self-close-do-not-close-the-window-in-chrome*/}
        {/* <div className='flex h-1/5 w-full content-center justify-center items-center'>
          <ButtonTextWithIcon
            onClick={() => {
              // window.opener = null;
              // window.open('', '_self');
              window.close();
            }}
            title={'Cerrar'}
            type='primary'
            //disabled={!enableButton}
            Icon={<FontAwesomeIcon icon={faClose} title={'edit'} />}
            position={'left'}
          />
        </div> */}
      </div>

    </div>
  )
}