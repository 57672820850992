import { DEFAULT_PAGE, GRID_PAGES, ROWS_PER_PAGE } from '../../utils/Constanst/evaluaTable/index'
import React, { useContext, useState } from 'react'

import { DataTable } from 'primereact/datatable'
import PropTypes from 'prop-types'
import { StoreContext } from '../../business/Provider'


// eslint-disable-next-line
export const EvaluaTable = ({ children, style, loading = false, sort, setSort, doubleClick, tableClassName, ...props }) => {
  const { loadingState } = useContext(StoreContext)
  const [first, setFirst] = useState(DEFAULT_PAGE)
  const [rowsNumber, setRowsNumber] = useState(ROWS_PER_PAGE)
  const [dataSort] = useState(sort !== undefined ? [...sort] : [])

  return (
    <div style={style}>
      <DataTable
        onRowEditComplete={(e) => { console.log('e', e) }}
        first={first}
        rows={rowsNumber}
        paginatorClassName='!border-t !border-border !justify-start !text-xs'
        //rowHover
        id={'testId'}
        removableSort
        paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown '
        //filterDisplay='row' // input search
        rowsPerPageOptions={GRID_PAGES}

        resizableColumns
        columnResizeMode='fit'
        sortOrder={1}
        paginator
        scrollable
        scrollHeight="flex"
        scrollDirection="both"
        stripedRows
        tableClassName={tableClassName}
        rowClassName='hover:!bg-[#3b82f633]'
        size="small"

        onRowDoubleClick={(e) => {
          if (doubleClick) {
            doubleClick(e.data)
          }
        }
        }
        loading={loading || loadingState}
        onSort={(e) => {
          if (e.multiSortMeta.length === 0) {
            setSort(dataSort)
          } else {
            setSort(e.multiSortMeta)
          }
        }}
        multiSortMeta={sort}
        sortMode="multiple"

        {...props}
        currentPageReportTemplate="Total de registros {totalRecords}"
      >
        {children}
      </DataTable>
    </div>
  )
}
EvaluaTable.defaultProps = {
  first: DEFAULT_PAGE,
  rows: ROWS_PER_PAGE,
  paginatorTemplate: 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
  resizableColumns: true,
  columnResizeMode: 'fit',
  sortOrder: 1,
  paginator: true,
  scrollable: true,
  scrollHeight: 'flex',
  scrollDirection: 'both',
  stripedRows: true,
  tableClassName: '!text-xs !border-border !border',
  rowClassName: 'hover:!bg-[#3b82f633]',
  style: { height: 'calc(100vh - 106px)', width: 'calc(100vw - 92px)' },
  doubleClick: () => { }
}
EvaluaTable.propTypes = {
  first: PropTypes.number,
  rows: PropTypes.number,
  paginatorTemplate: PropTypes.string,
  resizableColumns: PropTypes.bool,
  columnResizeMode: PropTypes.string,
  sortOrder: PropTypes.number,
  paginator: PropTypes.bool,
  scrollable: PropTypes.bool,
  scrollHeight: PropTypes.string,
  scrollDirection: PropTypes.string,
  stripedRows: PropTypes.bool,
  tableClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  style: PropTypes.object,
  doubleClick: PropTypes.func,
}
