import React, { useContext } from 'react'
import { ConfirmDialog } from 'primereact/confirmdialog';
import { NavigationContext } from '../../context/navigationContext';

/* A function that returns a component. */
export const ConfirmDialogBeforeSave = ({ message, actionAccept }) => {

  const navigation = useContext(NavigationContext)
  return (
    <ConfirmDialog
      visible={navigation.showValidationAlertSave}
      onHide={() => navigation.setShowValidationAlertSave(false)}
      message={message}
      header="Confirmación" icon="pi pi-exclamation-triangle"
      accept={actionAccept}
      acceptLabel="Aceptar"
      rejectLabel='Cancelar'
      /* A function that is called when the user clicks on the cancel button. */
      reject={() => {
        navigation.setShowValidationAlertSave(false)
        navigation.removeTap(navigation.urlStage)
      }}
    />
  )
}