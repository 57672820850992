import React from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { DEFAULT_PAGE, GRID_PAGES, ROWS_PER_PAGE } from '../../../../../utils/Constanst/evaluaTable';

export const ZoneTableComponent = ({
  style,
  ...props
}) => {
  const columns = [
    { field: 'code', header: 'Code' },
    { field: 'name', header: 'Name' },
  ];

  const onCellEditComplete = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    // switch (field) {
    //     case 'quantity':
    //     case 'price':
    //         if (isPositiveInteger(newValue))
    //             rowData[field] = newValue;
    //         else
    //             event.preventDefault();
    //         break;

    //     default:
    //         if (newValue.trim().length > 0)
    //             rowData[field] = newValue;
    //         else
    //             event.preventDefault();
    //         break;
    // }
  }

  const cellEditor = (options) => {
    if (options.field === 'price')
      return priceEditor(options);
    else
      return textEditor(options);
  }

  const priceEditor = (options) => {
    return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} mode="currency" currency="USD" locale="en-US" />
  }

  const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
  }


  return (
    <div className='datatable-doc-demo'>
      <div className='card'>
        {/* <div style={style}> */}
        <DataTable
          onRowEditComplete={(e) => { console.log('e', e) }}
          value={[]}
          editMode="cell"
          className="flex !w-full !h-full"
          size="small"
          responsiveLayout="scroll"
          rowClassName='hover:!bg-[#3b82f633]'
          columnResizeMode='fit'
          paginator
          sortOrder={1}
          removableSort
          scrollHeight="flex"
          scrollDirection="both"
          rowsPerPageOptions={GRID_PAGES}
          {...props}
          currentPageReportTemplate="Total de registros {totalRecords}"
          paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown '
        >
          {
            columns.map(({ field, header }) => {
              return <Column key={field} field={field} header={header} style={{ width: '50%' }} body={field === 'price' && priceBodyTemplate}
                editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
            })
          }
        </DataTable>
      </div>
    </div>
  )
}
ZoneTableComponent.defaultProps = {
  first: DEFAULT_PAGE,
  rows: ROWS_PER_PAGE,
  paginatorTemplate: 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
  resizableColumns: true,
  columnResizeMode: 'fit',
  sortOrder: 1,
  paginator: true,
  scrollable: true,
  scrollHeight: 'flex',
  scrollDirection: 'both',
  stripedRows: true,
  tableClassName: '!text-xs !border-border !border',
  rowClassName: 'hover:!bg-[#3b82f633]',
  style: { height: 'calc(100vh - 106px)', width: 'calc(100vw - 92px)' },
  doubleClick: () => { }
}