import { getTranslation, useCustomTranslation } from '../../hooks/useTranslations';

import { Dropdown } from 'primereact/dropdown';
import { GenericsNames } from '../../translations';
import React from 'react'

export const SelectComponent = ({
  className,
  ValuesOptions,
  valueSelected,
  handleChange,
  placeholderText,
  optionLabel,
  optionValue,
  labelText,
  error,
  errorText,
  important,
  name,
  disabled = false,
  iNeedEmptyValue = false,
  onHide = () => { },

}) => {
  // console.log('valueSelected ===========================', valueSelected)
  const { t } = useCustomTranslation();
  const newOptions = iNeedEmptyValue ? [{ id: '', name: '...', code: '' }].concat(ValuesOptions) : ValuesOptions;
  return (
    <div className={className}>
      <label className='text-small-font-size font-input-font-family font-semibold flex'>{labelText} <div hidden={!important} className='h-2 w-2 rounded-full bg-red ml-2 my-auto'></div></label>
      <Dropdown
        name={name}
        value={valueSelected}
        options={newOptions}
        onChange={handleChange}
        optionLabel={optionLabel}
        optionValue={optionValue}
        placeholder={placeholderText}
        className={`w-full !rounded-none !text-font-size-base ${error ? "p-invalid" : ''}`}
        emptyMessage={<>{getTranslation(t, GenericsNames.NOT_AVAILABLE_OPTION)}</>}
        onHide={onHide}
        disabled={disabled}
      // optionGroupLabel="label"
      // optionGroupChildren="items"
      //optionGroupTemplate={groupedItemTemplate}
      />
      {error && <small id={`${name}-${errorText}`} className="p-error block">{errorText}</small>}
    </div>
  )
}
