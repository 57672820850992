import { sortBy } from 'lodash'
import { getImportOptions } from '../../../utils/utils'
import { IsNullOrEmpty } from '../../../utils/utilsService'
import { ERROR_SHOW, GET_WORK_CENTER, GET_WORK_CENTER_ERROR, GET_WORK_CENTER_SUCCESS } from '../../constants'
const getWorkCenterOptions = (workCenterResponse) => {
    const activeworkCenterResponse = workCenterResponse.filter((object) => {
      return IsNullOrEmpty(object.deleteBy)
    })
    return sortBy(activeworkCenterResponse, [
      function (o) {
        return o.name
      }
    ]).map((item) => ({ label: item.name, value: item.id }))

  }
export const workCenter = (state, actions) => {
    switch (actions.type) {
        case GET_WORK_CENTER:
            return {
                ...state,
                loading: true,
            }
        case GET_WORK_CENTER_ERROR:
            return {
                workCenter: state.workCenter,
                loading: false,
                error: actions.payload,
                errorShow: false
            }
        case GET_WORK_CENTER_SUCCESS:
            return {
                workCenter: actions.payload,
                workCenterOptions: getWorkCenterOptions(actions.payload),
                importOptions: getImportOptions(actions.payload),
                loading: false,
                error: null,
                errorShow: false
            }
        case ERROR_SHOW:
            return {
                ...state,
                errorShow: true
            }
        default:
            return state;
    }
}