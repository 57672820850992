import {GET_QUERY, GET_QUERY_ERROR, GET_QUERY_SUCCESS, GET_ENTITY_SUCCESS, ADD_QUERY_STATE, ADD_QUERY_STATE_SAVE, ERROR_SHOW, GET_QUERY_PROPERTIES, ADD_QUERY_STATE_SAVE_SUCCESS, GET_QUERY_PROPERTIES_SUCCESS, GET_QUERY_PROPERTIES_ERROR, GET_QUERY_DATA_SUCCESS} from '../constants';

export const query = (state, action) => {
    switch (action.type) {
        case GET_QUERY:
            return {
                ...state,
                loading: true,
                error: null,
                errorShow: false
            };
        case GET_QUERY_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                errorShow: false
            };
        case GET_QUERY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                errorShow: false,
                query: action.payload
            };
        case GET_ENTITY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                errorShow: false,
                entity: action.payload.entity,
                entityList: action.payload.entityList
            };
        case ADD_QUERY_STATE:
            return {
                ...state,
                newQuery: action.payload,
                save: false
            };
        case ADD_QUERY_STATE_SAVE:
            return {
                ...state,
                loading: true
            };
        case ADD_QUERY_STATE_SAVE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                errorShow: false,
                save: true,
                query: action.payload
            };
        case ERROR_SHOW:
            return {
                ...state,
                errorShow: true
            };
        case GET_QUERY_PROPERTIES:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_QUERY_PROPERTIES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                queryProperties: {
                    ...state.queryProperties,
                    [action.payload.queryId]: action.payload.properties
                }
            };
        case GET_QUERY_PROPERTIES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case GET_QUERY_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                errorShow: false,
                queryData: {
                    ...state.queryData,
                    [action.payload.queryId]: action.payload.data
                }
            };
        default:
            return state;
    }
}