import React from 'react';
import { faPlus, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { getTranslation, useCustomTranslation } from '../../../hooks/useTranslations';

import { CheckBoxComponent } from '../../../Components/CheckBox';
import CompanyItem from './companyItem';
import { CustomIconButton } from '../../../Components/Buttons/CustomIconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GenericsNames } from '../../../translations';
import { InputText } from 'primereact/inputtext';

const CompanyRelatedList = ({
	companies = [],
	setCompanies,
	onClickRemove,
	onClickAdd,
	onSearchChange,
	searchValue,
	modalMode,
	iNeedSearch
}) => {
	const { t } = useCustomTranslation()
	const renderSearch = () => {
		return <div className="grid grid-cols-12 border-b-2 w-full">
			<div className={modalMode ? 'text-left col-span-12' : 'text-left col-span-11'}>
				{iNeedSearch ? <div className="flex justify-start flex-grow">
					<span className="p-input-icon-left w-full h-full !rounded-none border-r border-border">
						<FontAwesomeIcon className='pi' icon={faSearch} />
						<InputText
							className='w-full h-full !rounded-none !border-0'
							value={searchValue}
							onChange={(e) => { onSearchChange(e.target.value) }}
							placeholder={getTranslation(t, GenericsNames.SEARCH)}
						/>
					</span>
				</div> : null}
			</div>
			<div className="text-left col-span-1 justify-center flex content-end">
				{!modalMode &&
					<CustomIconButton toolTip={getTranslation(t, GenericsNames.ADD)}
						icon={faPlus}
						className='!m-1'
						onClick={() => { onClickAdd() }} />
				}
			</div>
		</div>
	}
	const renderCompanyItem = (item, index) => {

		return (
			<div className="flex flex-row w-full h-20 " key={index}>
				<div className="text-left flex h-full w-[10%]  ">
					<img style={{ width: '80px', display: 'block' }}
						src={"https://testdevuserkeyscti.blob.core.windows.net/img/mod-09bf71d1-aab0-42e5-b983-8208265b5930.png"} ></img>

				</div>
				<CompanyItem item={item} className="text-left w-[82%] h-full mx-1">

				</CompanyItem>
				<div className="text-left w-[8%] h-full justify-center flex content-end">
					{!modalMode ?
						<CustomIconButton toolTip={getTranslation(t, GenericsNames.DELETE)}
							icon={faTrash}
							className='!m-1 !h-1/2'
							onClick={() => { onClickRemove(item) }} />
						:
						<CheckBoxComponent
							className="flex w-1/2 p-2 content-center items-center"
							value={item.selected}
							text={''}
							handleChange={(e) => {
								let companiesCopy = [...companies];
								companiesCopy[index].selected = e.checked;
								setCompanies(companiesCopy);
							}
							}
							name={"isActive"}
						/>
					}

				</div>
			</div>)
	}
	return (
		<div className='flex flex-col w-full h-full '>
			<div className="flex h-[6%] w-full  ">
				{renderSearch()}

			</div>
			<div className="flex h-[94%] w-full   ">
				<div className="flex w-full h-full relative">
					<div className='flex absolute left-0 right-0 top-0 bottom-0 overflow-auto flex-col'>
						{
							companies && companies.length > 0 &&
							companies.map((company, index) => {
								return renderCompanyItem(company, index)
							})
						}
					</div>
				</div>


			</div>
		</div>
	);
};



export default CompanyRelatedList;