import React from 'react'
import { CreateDialog } from '../../../../Components/Dialogs/CreateDialog';
import { getTranslation, useCustomTranslation } from '../../../../hooks/useTranslations';
import { GenericsNames } from '../../../../translations';
import { Input } from './../../../../Components/Input/Input';
import { areaCodeMask } from '../../../../utils/utilsService';

export const PositionDialog = ({
  open,
  onClose,
  formik,
  onSave,
  title
}) => {
  const { t } = useCustomTranslation();

  const renderInput = (
    id,
    name,
    placeholder,
    onChange,
    value,
    onBlur,
    error,
    errorText,
    type,
    important
  ) => {
    return (
      <div className="flex w-full p-2">
        <Input
          id={id}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          error={error}
          errorText={errorText}
          type={type}
          important={important}
        />
      </div>
    )
  }

  const renderForm = () => {
    return (
      <>
        {renderInput(
          'scode',
          'scode',
          getTranslation(t, GenericsNames.CODE),
          formik.handleChange,
          formik.values.scode,
          (event) => {
            const paddingZero = areaCodeMask(event.target.value)
            formik.setFieldValue('scode', paddingZero)
          },
          formik.touched.scode && (formik.errors.scode),
          formik.touched.scode && getTranslation(t, formik.errors.scode),
          'number',
          true
        )}
        {renderInput(
          'name',
          'name',
          getTranslation(t, GenericsNames.NAME),
          formik.handleChange,
          formik.values.name,
          (event) => {
            formik.handleBlur(event)
            formik.setFieldValue('name', event.target.value.trim())
          },
          formik.touched.name && Boolean(formik.errors.name),
          formik.touched.name && getTranslation(t, formik.errors.name),
          'text',
          true
        )}

      </>
    )
  }
  return (
    <CreateDialog
      textSave={getTranslation(t, GenericsNames.SAVE)}
      textCancel={getTranslation(t, GenericsNames.CANCEL)}
      open={open}
      title={title}
      onClose={onClose}
      formik={formik}
      onSave={onSave}
      formComponet={renderForm()}
    />
  )
}