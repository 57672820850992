export const sortByDecending = (property) => {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    let result = a[property] > b[property] ? -1 : a[property] < b[property] ? 1 : 0;
    return result * sortOrder;
  };
};
export const sortBy = (property) => {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    let result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

export const firstIndex = (key, value, arrayToFilter) => {
  const index = arrayToFilter.findIndex((object) => object[key] === value);
  if (index === undefined) {
    return undefined;
  }
  return index;
};
export const filterBySingleKey = (key,value,arrayToFilter) => {    
  return arrayToFilter.filter(object=> object[key]===value);
}
export const filterBySingleKeyNotEquals = (key,value,arrayToFilter) => {    
  return arrayToFilter.filter((object) => {
    return object[key] !== value;
  });
}
export const firstOrDefault=(key,value,arrayToFilter) => {    
  let index=arrayToFilter.findIndex(object=> object[key]===value);
  if(index===undefined){
      return undefined;
  }
  return arrayToFilter[index];
}
export const removeDuplicateBySingleKey = (key,withDuplicates) => {    
  return withDuplicates.filter((value,index,array)=> array.findIndex(t => (t[key] === value[key])) === index);
}
