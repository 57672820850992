import { Form, Formik, setNestedObjectValues } from 'formik';
import React, { Children } from 'react';

import { Button } from 'primereact/button';
import PropTypes from 'prop-types';

const PageForm = (
    {
        children,
        onClose,
        formik,
        title,
        textSave,
        textCancel,
        onSave,
        disabled = false,
        hidden = false,
    }) => {

    const checkValidationsFields=async ()=>{
        const validationErrors = await formik.validateForm();
        if (Object.keys(validationErrors).length > 0) {
          //Filter the error that belongs to the step.
            formik.setTouched(setNestedObjectValues(validationErrors, true))
            return true;
        }
        return false;
      }

    const executeSubmit=async()=>{
        const hasError=await checkValidationsFields();
        if(!hasError){         
          // console.log('onSave', formik.values);
          if (onSave) {
            onSave(formik.values);
          } else {
            formik.handleSubmit();
          }
          
        }
    }
    const renderFooter = () => {
        return (
          <div className='float-right space-x-2'>      
            {!hidden &&
              <Button  className="p-button-text" label={textCancel} icon="pi pi-times" onClick={() => onClose()} autoFocus />
            }  
            <Button disabled={disabled } type='submit' label={textSave} icon="pi pi-check" onClick={executeSubmit}
            />
          </div>
        );
      }

    return (
        <>
        <div className='w-full'>
            <Formik
            initialValues={formik.values}
            onSubmit={(e) => {
                //e.preventDefault();
                //Overrides Formik, uggly fix for multiple formiks. TODO check later.
                if (onSave) {
                  onSave(formik.values);
                } else {
                  formik.handleSubmit();
                }
            }}
            >
            <Form>
                {children}
                {renderFooter()}
            </Form>

            </Formik>
        </div>
      </>
    );
};

PageForm.propTypes = {
    children:PropTypes.node,
    formik: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func,
    title: PropTypes.string.isRequired,
    textSave: PropTypes.string,
    textCancel: PropTypes.string.isRequired,
    maxWidth: PropTypes.string,
};

export default PageForm;