import React, { useRef, useEffect } from 'react'
import { RadioButton } from 'primereact/radiobutton';
export const SurveyItem = ({ item, index, sectionId, indexSection, validateForm, sendData, questionType, setValueRadio, isSelect, questionaryId }) => {

  const html = item.questionNumber.trim() !== '' ? `${item.questionNumber}.- ${item.questionHtml}` : item.questionHtml
  const fontBold = item.questionNumber.trim() !== '' ? 'font-bold' : 'font-bold'
  const bg = validateForm === true && sendData === true && item.questionValue === '' ? ' bg-red  opacity-75 font-bold ' : ''

  const selection_itemRef = useRef(null)

  useEffect(() => {
    if (isSelect === item.questionId) {
      selection_itemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [isSelect]);

  const renderRadioButton = (text, indexRadio, itemRadio, response, sectionIdRadio, indexSectionRadio, bgRadio) => {

    const renderValidation = item.questionTypeId === 1 ? <label htmlFor={response.optionValueId} className={"font-bold " + bgRadio}>{text}</label> : null
    return (
      <div
        key={indexRadio}
        className="flex h-full w-1/5  flex-col content-center items-center justify-center    "
      >
        <div className="flex flex-col  md:flex-col lg:flex-col  xl:flex-col  2xl:flex-col  content-center items-center justify-center ">
          {questionaryId > 1 ? renderValidation : null}
          <label htmlFor={response.optionValueId} className={"flex text-xxs mr-1 font-semibold s:text-xxs  md:hidden lg:hidden xl:hidden 2xl:hidden " + bgRadio}>{text}</label>
          <RadioButton
            className={'bg-red-500'}
            style={{ backGroundColor: 'red' }}
            id={response.optionValueId}
            value={response.optionValueId}
            name={item.questionNumber}
            onChange={(e) => setValueRadio(e, itemRadio, sectionIdRadio, indexSectionRadio, response.value)}
            checked={response.optionValueId === item.questionValue}
          />
        </div>
      </div>
    );
  };

  const isCousin = (numero) => {
    if (numero % 2 == 0) {
      return true
    }
    return false;
  }
  const value = (index + 1)
  //console.log('------------------------------------', value)
  const bgColor = isCousin(value) ? '' : validateForm === true && sendData === true && item.questionValue === '' ? 'bg-red  opacity-75 font-bold' : 'bg-gray-100'
  //console.log('------------------------------------', bgColor)
  return (
    <div
      key={index}
      className={"flex flex-col  md:flex-row  lg:flex-row  xl:flex-row 2xl:flex-row  w-full content-center justify-center items-center border-b-2   md:py-2 lg:py-2 xl:py-2 2xl:py-2 border-zinc-400   " + bgColor + bg}
      ref={selection_itemRef}
    >
      {/* <div className={'flex w-full h-10 ' + bgColor}>

      </div> */}
      <div
        className={"flex w-full text-xs  md:w-[55%] lg:w-[55%] xl:w-[55%] 2xl:w-[55%]  flex-col justify-start md:justify-center  lg:justify-center  xl:justify-center  2xl:justify-center  content-center text-xxs  md:text-sm lg:text-sm xl:text-base 2xl:text-base  " + fontBold + bg}
        dangerouslySetInnerHTML={{
          __html: html + '.',
        }}
      ></div>
      <div className={"flex w-full md:w-[45%] lg:w-[45%] xl:w-[45%] 2xl:w-[45%] h-full  flex-row  rounded-lg "}>
        {item.responses.map((response, indexResponse) => {
          //  const validation = response.value === item.questionValue ? true : false

          return renderRadioButton(response.htmlText, indexResponse, item, response, sectionId, indexSection, bg);
        })}
      </div>

    </div >
  )
}