import React, { useContext, useEffect, useState } from 'react'
import { useFormik } from 'formik'

//components
import { EvaluaAlertDialog } from '../../../Components/Dialogs/EvaluaAlertDialog'
import { WorkArea } from '../../../Components/WorkArea'
import { WorkCenterDialog } from './Components/WorkCenterDialog'
import { WorkCenterGrid } from './Components/WorkCenterGrid'
import { WorkCenterNames } from './../../../translations/index';
import { WorkCenterWizard } from './Components/WorkcenterWizard'

//utils, hooks and services
import useReload from '../../../hooks/useReload'
import useError from '../../../hooks/useErrors'
import useEvaluator from '../../../hooks/elevator/useElevator'
import useWorkCenters from './../../../hooks/WorkCenters/useWorkCenters';
import WorkCenterObject from '../../../utils/Constanst/FormikConstanst/WorkCenter/WorkCennterObject'
import WorkCenterSchema from '../../../utils/Constanst/FormikConstanst/WorkCenter/WorkCenterSchema'
import { firstIndex, firstOrDefault } from '../../../Auth/arrayService';
import { getZipCodes } from '../../../Auth/contpaqService';
import { getTranslation, useCustomTranslation } from '../../../hooks/useTranslations'
import { GenericsNames } from '../../../translations'
import { GET_CITIES_SUCCESS, GET_WORK_CENTER_SUCCESS } from '../../../business/constants'
import { IsNullOrEmpty } from '../../../utils/utilsService'
import { LoadingContext } from '../../../context/LoadingContext'
import { StoreContext } from '../../../business/Provider'

export const WorkCenters = () => {

  const context = useContext(StoreContext);
  const { t } = useCustomTranslation()
  const { getEvaluator } = useEvaluator()
  //states and hooks
  const [activeTab, setActiveTab] = useState(0);
  const [evaluator, setEvaluator] = useState([])
  const [evaluatorOptions, setEvaluatorOptions] = useState([])
  const [municipalities, setMunicipalities] = useState([])
  const [zipCodeFound, setZipCodeFound] = useState(null)
  const [open, setOpen] = useState(false)
  const [openWizard, setOpenWizard] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [suburbs, setSuburbs] = useState([])
  const [titlePopUp, setTitlePopUp] = useState('')
  const [workCenters, setWorkCenters] = useState([])
  const [zipCode, setZipCode] = useState(0)
  const {
    deleteWorkCenter,
    getNextCode,
    getWorkCenters,
    postWorkCenter,
    putWorkCenter,
  } = useWorkCenters()

  useError(context.workCenterState, context.dispatchWorkCenter)
  /** Reload **/
  useReload([getWorkCenters, getEvaluator])
  const loadingObj = useContext(LoadingContext)

  // region [ZipCode Search]

  useEffect(() => {
    if (context.workCenterState?.workCenter) {
      setWorkCenters(context.workCenterState.workCenter)
    }
  }, [context.workCenterState])
  useEffect(() => {
    if (context.evaluatorState?.evaluators) {
      setEvaluator(context.evaluatorState.evaluators)
      setEvaluatorOptions(context.evaluatorState.evaluatorOptions)
    }
  }, [context.evaluatorState])

  useEffect(() => {
    if (zipCode !== 0) {
      handleZipSearch(zipCode)
    }
  }, [zipCode]);

  /**
   * It takes a zip code value, makes an API call to the backend, and if the response is not undefined,
   * it sets the formik values to the response values
   */
  const handleZipSearch = async (zipCodeValue) => {
    const response = await getZipCodes(zipCodeValue);
    console.log(response)
    if (response === undefined) {
      setZipCodeFound(false)
      formik.setFieldValue('municipality', '')
      formik.setFieldValue('state', '')
      formik.setFieldValue('suburb', '')
    } else {
      response.municipality ? formik.setFieldValue('municipality', response.municipality) : formik.setFieldValue('municipality', '')
      response.state ? formik.setFieldValue('state', response.state) : formik.setFieldValue('state', '')
      setSuburbs(response.suburbs)
      setZipCodeFound(true)
      setMunicipalities(response.municipality)
    }
  }

  const toggleConfirmation = () => {
    setOpenConfirmation(!openConfirmation)
  }

  const toggleModal = () => {
    setOpen(!open)
  }

  const toggleWizard = () => {
    setOpenWizard(!openWizard)
  }

  /**
   * It resets the form, clears any errors, clears the cities list, sets the zip code to null, closes the
   * modal, and closes the wizard
   */
  const handleClose = () => {
    formik.resetForm()
    formik.setErrors({})
    context.dispatchCities({ type: GET_CITIES_SUCCESS, payload: [] })
    setZipCodeFound(null)
    setOpen(false)
    setOpenWizard(false)
  }

  const addWorkCenter = async (WorkCenter) => {
    return postWorkCenter(WorkCenter)
  }

  const updateWorkCenter = async (WorkCenter) => {
    return putWorkCenter(WorkCenter)
  }

  /**
   * It deletes a work center from the database and updates the work center list in the state
   */
  const deleteWorkCenterGrid = async () => {
    try {
      loadingObj.setLoading(true);
      toggleConfirmation()
      const result = await handleDeleteWorkCenter(formik.values)
      if (result !== undefined) {
        const filteredWorkCenter = workCenters.filter(
          (WorkCenter) => WorkCenter.id !== formik.values.id
        )
        context.dispatchWorkCenter({ type: GET_WORK_CENTER_SUCCESS, payload: filteredWorkCenter })
        setWorkCenters(filteredWorkCenter)
      }
      loadingObj.setLoading(false);
    } catch (error) {
      loadingObj.setLoading(false);
    }
  }

  const handleDeleteWorkCenter = async (values) => {
    return deleteWorkCenter(values)
  }

  const addOrUpdateWorkCenter = async (
    values,
    { resetForm, _setErrors, setStatus, setSubmitting }
  ) => {
    try {
      loadingObj.setLoading(true);
      let response;
      const isNew = IsNullOrEmpty(values.id)
      values.code = parseInt(values.scode)
      if (isNew) {
        response = await addWorkCenter(values)
      } else {
        response = await updateWorkCenter(values)
      }
      loadingObj.setLoading(false);

      if (response !== undefined) {
        handleSaveFunction(isNew, response)
        resetForm()
        setStatus({ success: true })
        setSubmitting(false)
      }
    } catch (err) {
      setStatus({ success: false })
      loadingObj.setLoading(false);
      setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: { ...WorkCenterObject },
    validationSchema: WorkCenterSchema,
    onSubmit: addOrUpdateWorkCenter
  })

  const handleOnAdd = async () => {

    setTitlePopUp(getTranslation(t, WorkCenterNames.DIALOG_ADD))
    setActiveTab(0)
    setZipCodeFound(false)
    const response = await getNextCode()
    const nextCode = response.data
    const newWorkCenter = { ...WorkCenterObject }
    newWorkCenter.scode = nextCode
    newWorkCenter.code = nextCode
    formik.setValues({ ...newWorkCenter })

    toggleWizard()
  }
  const handleOnEdit = (row) => {
    setActiveTab(0)
    setZipCodeFound(false)
    const item = firstOrDefault('id', row.evaluatorId, evaluator)
    if (item !== undefined) {
      row.evaluator = item.name
    } else {
      row.evaluator = ''
    }
    setTitlePopUp(getTranslation(t, WorkCenterNames.DIALOG_EDIT))

    formik.setValues(row)
    toggleModal()
  }

  const handleOnDelete = (row) => {
    formik.setValues(row)
    toggleConfirmation()
  }
  const handleSaveFunction = (isNew, item) => {
    addOrUpdateGrid(isNew, item, workCenters)
    setOpen(false)
    setOpenWizard(false)
  }

  const findWorkCenterIndex = (data, item) => {
    return firstIndex('id', item.id, data)
  }
  const addOrUpdateGrid = (isNew, item, data) => {
    const WorkCenterCopy = [...workCenters]
    if (item) {
      if (isNew) WorkCenterCopy.push(item)
      else {
        const pos = findWorkCenterIndex(data, item)
        WorkCenterCopy[pos] = item
      }
    }
    context.dispatchWorkCenter({ type: GET_WORK_CENTER_SUCCESS, payload: WorkCenterCopy })
    setWorkCenters(WorkCenterCopy)
  }
  return (
    <>
      <EvaluaAlertDialog
        title={getTranslation(t, GenericsNames.CONFIRM)}
        message={getTranslation(t, WorkCenterNames.CONFIRM_MESSAGE_DELETE)}
        open={openConfirmation}
        setOpen={toggleConfirmation}
        cancelLabel={getTranslation(t, GenericsNames.CANCEL)}
        agreeLabel={getTranslation(t, GenericsNames.ACCEPT)}
        onConfirmFunction={deleteWorkCenterGrid}
      />
      <WorkCenterDialog
        title={titlePopUp}
        formik={formik}
        open={open}
        onClose={handleClose}
        evaluators={evaluatorOptions}
        zipCodeFound={zipCodeFound}
        onZipSearch={handleZipSearch}
        suburbs={suburbs}
        municipalities={municipalities}
        setZipCode={setZipCode}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <WorkCenterWizard
        title={titlePopUp}
        formik={formik}
        open={openWizard}
        onClose={handleClose}
        evaluators={evaluatorOptions}
        zipCodeFound={zipCodeFound}
        onZipSearch={handleZipSearch}
        suburbs={suburbs}
        municipalities={municipalities}
        setZipCode={setZipCode}
        activeStep={activeTab}
        setActiveStep={setActiveTab}
      />
      <WorkArea grid={
        <WorkCenterGrid
          onEdit={handleOnEdit}
          onDelete={handleOnDelete}
          loading={context.workCenterState.loading}
          rows={workCenters}
          handleOnAdd={handleOnAdd}

        />
      }
      />

    </>
  )
}