// create context store for all useReducer
import React, { createContext, useReducer } from 'react';
import {area} from './reducers/catalogs/area';
import { position } from './reducers/catalogs/position';
import { department } from './reducers/catalogs/department';
import { evaluator } from './reducers/catalogs/evaluator';
import {workCenter} from './reducers/catalogs/workCenter';
import areaInitialState from './initialStates/area';
import positionInitialState from './initialStates/position';
import departmentInitialState from './initialStates/department';
import evaluatorInitialState from './initialStates/evaluator';
import workCenterInitialState from './initialStates/workCenter';
import collaboratorInitialState from './initialStates/collaborator';
import surveysInitialState from './initialStates/survey';
import surveyCollaboratorInitialState from './initialStates/surveyCollaborator';
import surveysResultInitialState from './initialStates/surveyResults';
import questionsInitialState from './initialStates/questions';
import scoreRangesInitialState from './initialStates/scoreRanges';
import resultInitialState from './initialStates/results';
import {queryState as queryInitialState, queryPercistence as queryPercistenceInitialState} from './initialStates/query';
import companyInitialState from './initialStates/company';
import { initialStates, initialCities, initialSuburbs } from './initialStates/location';
import importInitialState from './initialStates/import';
import importErrorListState from './initialStates/importError';
import { states } from './reducers/catalogs/states';
import { cities } from './reducers/catalogs/cities';
import { suburbs } from './reducers/catalogs/suburbs';
import { collaborator } from './reducers/catalogs/collaborator';
import { surveys } from './reducers/surveys';
import { surveyCollaborator } from './reducers/surveyCollaborator';
import { surveysResult } from './reducers/surveysResult';
import { loading } from './reducers/loading';
import { questions } from './reducers/surveys/questions';
import { scoreRanges } from './reducers/surveys/scoreRanges';
import { result } from './reducers/surveys/results';
import { query } from './reducers/queries';
import { company } from './reducers/company';
import { queryPercistence } from './reducers/queryPersistance';
import { getImport } from './reducers/import';
import { importErrorList } from './reducers/importError';


export const StoreContext = createContext({});
export const StoreProvider = ({ children }) => {
    //UseReducer
    const [areaState, dispatch] = useReducer(area, areaInitialState)
    const [positionState, dispatchPosition] = useReducer(position, positionInitialState)
    const [departmentState, dispatchDepartment] = useReducer(department, departmentInitialState)
    const [evaluatorState, dispatchEvaluator] = useReducer(evaluator, evaluatorInitialState)
    const [workCenterState, dispatchWorkCenter] = useReducer(workCenter, workCenterInitialState)
    const [statesState, dispatchStates] = useReducer(states, initialStates)
    const [citiesState, dispatchCities] = useReducer(cities, initialCities)
    const [suburbsState, dispatchSuburbs] = useReducer(suburbs, initialSuburbs)
    const [collaboratorState, dispatchCollaborator] = useReducer(collaborator, collaboratorInitialState)
    const [surveysState, dispatchSurveys] = useReducer(surveys, surveysInitialState)
    const [surveyCollaboratorState, dispatchSurveyCollaborator] = useReducer(surveyCollaborator, surveyCollaboratorInitialState)
    const [surveysResultState, dispatchSurveysResult] = useReducer(surveysResult, surveysResultInitialState)
    const [loadingState, dispatchLoading] = useReducer(loading, false)
    const [questionsState, dispatchQuestions] = useReducer(questions, questionsInitialState)
    const [scoreRangesState, dispatchScoreRanges] = useReducer(scoreRanges, scoreRangesInitialState)
    const [resultState, dispatchResult] = useReducer(result, resultInitialState)
    const [queryState, dispatchQuery] = useReducer(query, queryInitialState)
    const [companyState, dispatchCompany] = useReducer(company, companyInitialState)
    const [queryPercistenceState, dispatchQueryPercistence] = useReducer(queryPercistence, queryPercistenceInitialState)
    const [importState, dispatchImport] = useReducer(getImport, importInitialState)
    const [importErrorState, dispatchImportError] = useReducer(importErrorList, importErrorListState)
    // provider value
    return ( <StoreContext.Provider value={{ 
        areaState, 
        dispatch,
        positionState,
        dispatchPosition,
        departmentState,
        dispatchDepartment,
        evaluatorState,
        dispatchEvaluator,
        workCenterState,
        dispatchWorkCenter,
        statesState,
        dispatchStates,
        citiesState,
        dispatchCities,
        suburbsState,
        dispatchSuburbs,
        collaboratorState,
        dispatchCollaborator,
        surveysState,
        dispatchSurveys,
        surveyCollaboratorState,
        dispatchSurveyCollaborator,
        surveysResultState,
        dispatchSurveysResult,
        loadingState,
        dispatchLoading,
        questionsState,
        dispatchQuestions,
        scoreRangesState,
        dispatchScoreRanges,
        resultState,
        dispatchResult,
        queryState,
        dispatchQuery,
        companyState,
        dispatchCompany,
        queryPercistenceState,
        dispatchQueryPercistence,
        importState,
        dispatchImport,
        importErrorState,
        dispatchImportError
    }}
    displayName="General Store"
    >
        {children}
    </StoreContext.Provider> );
}