import { useContext, useEffect, useState } from 'react';
import { EvaluaTable } from '../../../Components/Table/EvaluaTable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { getTranslation, useCustomTranslation } from '../../../hooks/useTranslations';
import { columnsSw } from './columns';
import { StoreContext } from '../../../business/Provider';
import { CollaboratorsNames, GenericsNames } from "../../../translations";
import { regexName } from '../../../utils/utils';
import SidePanel from './sidePanel';
import { SET_IMPORT_ERROR_LIST } from '../../../business/constants';

export const Table = ({ data, importType, setData, error }) => {
	const [sort, setSort] = useState([{ field: '', order: 1 }]);
	const context = useContext(StoreContext);
	const { t } = useCustomTranslation();
	const [columns, setColumns] = useState([]);
	useEffect(() => {
		if (importType?.value) setColumns(columnsSw(importType.value))
	}, [importType])
	const GENDER_LIST = [
		{ value: '', label: '' },
		{ value: 'F', label: getTranslation(t, GenericsNames.FEMININE) },
		{ value: 'M', label: getTranslation(t, GenericsNames.MALE) }
	]

	const STATUS_LIST = [
		{ value: 'A', label: getTranslation(t, CollaboratorsNames.HIGH) },
		{ value: 'B', label: getTranslation(t, CollaboratorsNames.SHORT) },
		{ value: 'R', label: getTranslation(t, CollaboratorsNames.REENTRY) }
	]
	const CONDITION_LIST = [
		{ value: true, label: 'Activo' },
		{ value: false, label: 'Inactivo' },
	]
	const textEditor = (options) => {
		return <InputText type="text" className='!w-full  p-inputtext-sm' defaultValue={options.value}
			onKeyDown={
				(e) => {
					// on enter, save the value
					if (e.key === 'Enter') {
						handlerEditorText(options.rowIndex, e.target.value, options.field)
					}
				}
			}
			onBlur={(e) => handlerEditorText(options.rowIndex, e.target.value, options.field)} />;

	}
	const handlerEditorText = (rowIndex, value, field) => {
		const copyData = [...data];
		//use regexName
		if (!regexName.test(value)) {
			return;
		}
		if (field === 'scode') {
			value = ('000' + value).slice(-4)
		} else if (field === 'taxId') {
			value = '' + value
			value = value.toUpperCase()
		}
		copyData[rowIndex][field] = value;
		setData(copyData);
	}
	const handlerEditor = (rowIndex, value, field) => {
		const copyData = [...data];
		copyData[rowIndex][field] = value;
		setData(copyData);
	}
	const dropEditor = (options, drop) => {
		let opc = findField(drop)
		return (
			<Dropdown
				className='!w-full p-inputtext-sm'
				value={options.value}
				placeholder={'Seleccione una opción'}
				emptyMessage={'No hay datos'}
				options={opc}
				optionLabel="name"
				optionValue="value"
				onChange={(e) =>
					handlerEditor(options.rowIndex, e.value, options.field)
				} />
		);
	}
	const statusTemplate = (rowData, list, dataKey) => {
		// find index of the value in the list
		const index = data.findIndex(item => JSON.stringify(item) === JSON.stringify(rowData));
		const [color, errorText] = errorCol(index, dataKey)
		// extract the value from the errorText and concat by , . errorText is a array of objects the key is the field and the value is the error
		const value = errorText && errorText[dataKey] ? errorText[dataKey].map(item => getTranslation(t, item)).join(', ') : ''
		return (
			<div className={`${color} h-full w-full p-2`} title={value}>
				{list.find((_status) => _status.value === rowData[dataKey])?.label}
			</div>
		)
	}
	const dataTemplate = (rowData, dataKey) => {
		// find index of the value in the list
		const index = data.findIndex(item => JSON.stringify(item) === JSON.stringify(rowData));
		const [color, errorText] = errorCol(index, dataKey)
		// extract the value from the errorText and concat by , . errorText is a array of objects the key is the field and the value is the error
		const value = errorText && errorText[dataKey] ? errorText[dataKey].map(item => getTranslation(t, item)).join(', ') : ''
		return (
			<>
				<div className={`${color} t h-full w-full p-2`} title={value}>
					{rowData[dataKey]}
				</div>
			</>

		)
	}
	const arrayToString = (array, quantity) => {
		if (!quantity) quantity = array.length
		return array.slice(0, quantity).join(', ')
	}
	const errorCols = (rowData, dataKey) => {
		let invalid = arrayToString(rowData[dataKey]?.invalid)
		let obligatory = arrayToString(rowData[dataKey].obligatory)
		let notExist = arrayToString(rowData[dataKey].notExist)
		// add ... if the array is bigger than 3
		const action = () => {
			// concat invalid and obligatory
			const errors = [...rowData[dataKey].invalid, ...rowData[dataKey].obligatory, ...rowData[dataKey].notExist]
			context.dispatchImportError({ type: SET_IMPORT_ERROR_LIST, payload: { errors: errors, data: rowData['email'] } })
		}
		return (
			<div className='w-full h-full p-2' onClick={() => action()}>
				<p className='truncate ml-3'>{invalid || obligatory || notExist}</p>
			</div>
		)
	}
	const findField = (field) => {
		switch (field) {
			case 1:
				return GENDER_LIST
			case 2:
				return STATUS_LIST
			case 3:
				return context.departmentState.importOptions
			case 4:
				return context.workCenterState.importOptions
			case 5:
				return context.positionState.importOptions
			case 6:
				return context.areaState.importOptions
			case 7:
				return context.evaluatorState.importOptions
			case 8:
				return CONDITION_LIST
			default:
				return []
		}
	}
	const errorCol = (rowIndex, field) => {
		const color = error[rowIndex]?.field.find(item => item === field) ? 'bg-[#AF060630]' : ''
		let errorText = null
		if (color !== '') errorText = error[rowIndex]?.message
		return [color, errorText]
	}
	function bodySw(e, column) {
		if (column?.drop) {
			return statusTemplate(e, findField(column?.drop), column.dataKey)
		} else if (column?.dataKey === 'errors') {
			return errorCols(e, column.dataKey)
		} else {
			return dataTemplate(e, column.dataKey)
		}

	}
	const editorSw = (options, column) => {
		if (column?.drop) {
			return dropEditor(options, column.drop)
		} else {
			return textEditor(options)
		}
	}
	return (
		<EvaluaTable
			value={data}
			style={{ height: 'calc(100vh - 200px)' }}
			editMode="cell"
			setSort={setSort}
			sort={sort}
		>
			{columns?.map((column, index) => {
				return <Column

					key={index}
					field={column.dataKey}
					header={column.title}
					editor={column?.dataKey === 'errors' ? null : (options) => editorSw(options, column)}
					style={{ width: column.width || '150px', padding: '0px' }}
					body={(e) => bodySw(e, column)}
				/>
			})}
		</EvaluaTable>

	);
}