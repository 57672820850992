import { GET_SURVEY, GET_SURVEY_ERROR, GET_SURVEY_SUCCESS, GET_SURVEY_DETAIL, GET_SURVEY_RESULT, FILTER } from '../constants'

export const surveysResult = (state, action) => {
    switch (action.type) {
        case GET_SURVEY:
            return {
                ...state,
                loading: true,
            }
        case GET_SURVEY_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                errorShow: true
            }
        case GET_SURVEY_SUCCESS:
            return {
                ...state,
                loading: false,
                surveys: action.payload.data,
                guideId: action.payload.guideId,
                error: null,
                errorShow: false
            }
        case GET_SURVEY_DETAIL:
            return {
                ...state,
                loading: false,
                surveysDetails: action.payload,
                error: null,
                errorShow: false
            }
        case GET_SURVEY_RESULT:
            return {
                ...state,
                loading: false,
                surveysResults: {
                    ...state.surveysResults,
                    [action.payload.id]: action.payload.data
                },
                selected: action.payload.id,
                error: null,
                errorShow: false
            }
        case FILTER:
            return {
                ...state,
                loading: false,
                filters: {
                    ...state.filters,
                    [action.payload.id]: action.payload.data
                }
            }
        default:
            return state
    }
}