import { Checkbox } from 'primereact/checkbox'
import React from 'react'
export const CheckBoxComponent = ({ name, value, handleChange, text, className }) => {
  return (
    <div className={className}>

      <Checkbox
        inputId={name}
        // name="isActive"
        checked={value}
        onChange={handleChange}
      />
      <label className='ml-2'>{text}</label>
    </div>
  )
}