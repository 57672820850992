import React, { useRef, useState, useEffect } from 'react'
import { Input } from '../../../Components/Input/Input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import { OverlayPanel } from 'primereact/overlaypanel';
import { BaseButton } from '../../../Components/Buttons/BaseButton';
import { getTranslation, useCustomTranslation } from '../../../hooks/useTranslations';
import { QueryNames } from '../../../translations';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { addLocale } from 'primereact/api';
import './index.css'
import { Toast } from '../../../Components/toast';
import { regexName } from './../../../utils/utils';
/* A functional component that takes in data, filter, setFilter, and type as props. */
export const FilterComponent = ({data, filter, setFilter, type}) => {
    /* Setting the locale to Spanish, and setting the state of the filterType, range, and dates. */
    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });
    const {t} = useCustomTranslation()
    const [filterType, setFilterType] = useState('')
   /* The above code is creating a reference to the DOM element. */
    const op = useRef(null);
    const [range, setRange] = useState({
        min: null,
        max: null
    })
    const [dates, setDates] = useState(null)
   /* Checking if the filter is set and if it is, it is setting the filterType to the filter type. */
    useEffect(() => {

        if (filter[data.field]) {
            if(filterType === 'rangeNum'){
                    const ranges = filter[data.field].value.split('-')
                    setRange({
                        min: ranges[0],
                        max: ranges[1]
                    })
            } else if(filterType === 'rangeDate'){
                    const array = filter[data.field].value.split('-')     
                    setDates( new Date(array[0]), new Date(array[1]))
            }
            setFilterType(filter[data.field].type)
        } else {
            switch (type) {
                case 'STRING':
                    setFilterType('contains')
                    break;
                case 'NUMBER':
                    setFilterType('equal')
                    break;
                case 'DATE':
                    setFilterType('equalDate')
                    break;
            }
        }
          
    }
    , [filter])
    /* Checking if the filterType is not empty or undefined. If it is not, it is checking if the
    filter[data.field] exists. If it does, it is calling the handleChange function with the
    filter[data.field].value as a parameter. If it does not, it is calling the handleChange function
    with an empty string as a parameter. */
    useEffect(() => {
        if (filterType !== '' && filterType !== undefined) {
            if(filter[data.field]) {
                handleChange(filter[data.field].value)
            } else {
                handleChange('')
            }
        } 
    }, [filterType])
    /**
     * When the user changes the value of the input, update the filter object with the new value.
     */
    const handleChange = (e) => {
        // if type is NUMBER only allow numbers and '.' and withe space
        if (type === 'NUMBER') {
            if (!e.match(/^[0-9.]+$/) && e !== '') {
                return
            }
        }
        setFilter({
            ...filter,
            [data.field]: {
                ...filter[data.field],
                value: e,
                type: filterType,
                field: data.field
            }
        })
    }
    /* Creating a menu object with 3 properties: string, number, and date. Each property has an array
    of objects. */
    const menu = {
        STRING: [
            { label: getTranslation(t, QueryNames.FILTER_CONTAINS), onclick: () => setFilterType('contains'), key: 'contains' },
            { label: getTranslation(t, QueryNames.FILTER_NOT_CONTAINS), onclick: () => setFilterType('notContains') , key: 'notContains' },
            { label: getTranslation(t, QueryNames.FILTER_EQUAL), onclick: () => setFilterType('equal') , key: 'equal' },
            { label: getTranslation(t, QueryNames.FILTER_DIFFERENT), onclick: () => setFilterType('different') , key: 'different' },
            { label: getTranslation(t, QueryNames.FILTER_NULL), onclick: () =>  setFilterType('null') , key: 'null' },
            { label: getTranslation(t, QueryNames.FILTER_NOT_NULL), onclick: () => setFilterType('notNull') , key: 'notNull' },
        ],
        NUMBER: [
            { label: getTranslation(t, QueryNames.FILTER_EQUAL), onclick: () => setFilterType('equal')  , key: 'equal' },
            { label: getTranslation(t, QueryNames.FILTER_DIFFERENT), onclick: () => setFilterType('different') , key: 'different' },
            { label: getTranslation(t, QueryNames.FILTER_GREATER), onclick: () => setFilterType('greaterNum') , key: 'greaterNum' },
            { label: getTranslation(t, QueryNames.FILTER_LESS), onclick: () => setFilterType('lessNum') , key: 'lessNum' },
            { label: getTranslation(t, QueryNames.FILTER_NULL), onclick: () => setFilterType('null') , key: 'null' },
            { label: getTranslation(t, QueryNames.FILTER_NOT_NULL), onclick: () => setFilterType('notNull') , key: 'notNull' },
        ],
        DATE: [
            { label: getTranslation(t, QueryNames.FILTER_EQUAL), onclick: () => setFilterType('equalDate') , key: 'equalDate' },
            { label: getTranslation(t, QueryNames.FILTER_DIFFERENT), onclick: () => setFilterType('differentDate') , key: 'differentDate' },
            { label: getTranslation(t, QueryNames.FILTER_GREATER), onclick: () => setFilterType('greaterDate') , key: 'greaterDate' },
            { label: getTranslation(t, QueryNames.FILTER_LESS), onclick: () => setFilterType('lessDate') , key: 'lessDate' },
            { label: getTranslation(t, QueryNames.FILTER_NULL), onclick: () => setFilterType('null') , key: 'null' },
            { label: getTranslation(t, QueryNames.FILTER_NOT_NULL), onclick: () => setFilterType('notNull') , key: 'notNull' },
        ]
    }
    /* A function that returns a button. */
    const renderButton = ( title, onClick, key ) => {
        return(
            <BaseButton key={key} className={`w-full !mt-2 p-button-sm   ${filterType !== key ? 'p-button-outlined': ''}`} label={title} onClick={onClick}/>
        )
    }
 /* render range number */
    const renderRangeNumber = () => {
        return(
            <div>
                <div className="flex items-center !mt-2  justify-center align-middle">
                    <Input  className="w-full  p-inputtext-sm" value={ range.min || '' } onChange={ (e) => setRange({...range, min: e.target.value})}/>
                     -
                    <Input className="w-full p-inputtext-sm" value={ range.max || '' } onChange={ (e) => setRange({...range, max: e.target.value})}/>
                    <div className='flex-grow ml-2'>
                        <BaseButton className="w-full p-button-sm p-button-outlined" label={getTranslation(t, QueryNames.FILTER_RANGE)} onClick={() => {
                            // if one of the values is empty, return 
                            if (!range.min || !range.max || range.min === '' || range.max === '') {
                                Toast('warning', 'Seleccione un rango válido')
                                return
                            }
                            setFilterType('rangeNum')
                            setFilter({
                                ...filter,
                                [data.field]: {
                                    ...filter[data.field],
                                    value: range.min + '-' + range.max,
                                    type: 'rangeNum',
                                    field: data.field
                                }
                            })
                            op.current.toggle(false)
                        }}/>
                    </div>
                   
                </div>
              
            </div>
          
        )
    }
  /* Rendering a calendar component and a button. */
    const renderRangeDate = () => {
        return(
                <div className="flex items-center !mt-2  justify-center align-middle">
                    <Calendar locale="es" dateFormat="dd/mm/yy"  showButtonBar  id="range" value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" readOnlyInput />
                    <div className='flex-grow ml-2'>
                        <BaseButton className="w-full p-button-sm p-button-outlined" label={getTranslation(t, QueryNames.FILTER_RANGE)} onClick={() => {
                            const min = dates === null ? null : moment(dates[0]).format('DD/MM/YYYY')
                            const max = dates === null ? null :moment(dates[1]).format('DD/MM/YYYY')
                            if(min === 'Invalid date' || max === 'Invalid date') {
                                Toast('warning', 'Seleccione un rango válido')
                                return
                            }
                            setFilterType('rangeDate')
                            const value = dates === null ? null : min + '-' + max 
                            setFilter({
                                ...filter,
                                [data.field]: {
                                    ...filter[data.field],
                                    value: value,
                                    type: 'rangeDate',
                                    field: data.field
                                }
                            })
                            op.current.toggle(false)
                        }}/>
                    </div>
                </div>
        )
    }

    return (
        <>
            <div className='flex w-full justify-between '>
                {
                    filterType !== 'rangeDate' && type === 'DATE' ?  
                    <Calendar  
                        className='filters'
                        locale="es"  
                        id="basic"  
                        dateFormat="dd/mm/yy" 
                        name={data.field}
                        value={filter[data.field]?.value? new Date (filter[data.field]?.value) : null}
                        onChange={(e) => {
                            handleChange(e.target.value)
                        }}                      
                        showButtonBar
                        onClearButtonClick={
                            () => {
                                setFilter({
                                    ...filter,
                                    [data.field]: {
                                        ...filter[data.field],
                                        value: '',
                                        type: filterType,
                                        field: data.field
                                    }
                                })
                            }
                        }
                      /> 
                    :                 
                    <Input
                    className=' filters !text-[0.7rem] !h-full'
                    name={data.field}
                    value={filter[data.field]?.value || ''}
                    onChange={(e) => {
                        handleChange(e.target.value)
                    }}
                    onkeydown={(e) => {
                        if (e.key === 'Enter') {
                            setFilter({ ...filter,
                                [data.field]: {
                                    ...filter[data.field],
                                    value: '',
                                    type: filterType,
                                    field: data.field
                                }})
                                return
                        }
                    }}
                    disabled={filterType === 'null' || filterType === 'notNull' || filterType === 'rangeDate'}
                    keyfilter={regexName}
                />
                }
                <button className='pl-2'  onClick={(e) => op.current.toggle(e)}>
                    <FontAwesomeIcon size='2x' icon={faEllipsisV} />
                </button>
            </div>
            <OverlayPanel ref={op} id="overlay_panel">
                {
                    menu[type].map((item, index) => {
                        return (
                            <div key={index}>
                                {renderButton(item.label, item.onclick, item.key)}
                            </div>
                        )
                    }
                    )
                }
                {
                    type === 'NUMBER' && renderRangeNumber()
                }
                {
                    type === 'DATE' && renderRangeDate()
                }
            </OverlayPanel>
        </>
        
    )
}