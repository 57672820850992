import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { NavigationContext } from '../../context/navigationContext';
import { getTranslation, useCustomTranslation } from '../../hooks/useTranslations';
import { ButtonDropDown } from '../Buttons/ButtonDropdown';
import { SubSidebar } from '../SubSidebar';
export const SidebarItem = (item, isSelectValue, setIsSelect, setDataSecondSidebar, dataSecondSidebar, open, setOpen) => {
	const navigation = useContext(NavigationContext)
	const { t } = useCustomTranslation();

	const color = isSelectValue === item?.url ? "!text-white" : " !text-white";
	//set open false on de-focus
	const ref = useRef(null);

	const [defocus, setDefocus] = useState(false);

	useEffect(() => {
		const handleClick = (e) => {
			if (ref?.current && !ref.current?.contains(e.target)) {
				setDefocus(true);
			}
		}
		document.addEventListener('mousedown', handleClick);
		return () => {
			document.removeEventListener('mousedown', handleClick);
		}
	}, []);

	useEffect(() => {
		if (defocus) {
			if (open[item?.id]) {
				setOpen({ ...open, [item?.id]: false });
			}
			setDefocus(false);
		}
	}, [defocus]);

	const handlerClose = () => {
		setOpen({ ...open, [item?.id]: false });
	}
	return (
		<li className='relative h-[90px] flex ' ref={ref}>
			<SubSidebar subSidebarData={dataSecondSidebar} current={open[item?.id]} close={handlerClose} />
			<ButtonDropDown
				id="dropdownDefault" data-dropdown-toggle="dropdown"
				keyItem={item?.id}
				onClick={() => {
					if (item?.childrends !== undefined) {
						setIsSelect(item?.url);
						setDataSecondSidebar(item);
						// set item.id to true and others to false
						const newOpen = Object.assign({}, open);
						// all items to false
						Object.keys(newOpen).forEach(key => {
							if (key !== item?.id) {
								newOpen[key] = false;
							} else {
								newOpen[key] = !newOpen[key];
							}
						}
						);
						setOpen(newOpen);
					} else {
						console.log('item.url', item);
						navigation.selectTap(item.url, '', {})
						setIsSelect(item?.url);
					}
				}}
				className={`!justify-center flex-grow !font-h3-font-family !border-none hover:!bg-menu-bg-default ${open[item?.id] ? '!bg-menu-bg-default' : '!bg-transparent'}`}
			>
				<div className={`flex h-full w-full  flex-col `}>
					<div className="flex w-full m-auto  items-center content-center justify-center flex-col ">
						<FontAwesomeIcon className={`${color}`} size='2x' icon={item?.icon} />
						<p className={`${color} text-xs`}>{getTranslation(t, item?.translate)}</p>
					</div>
				</div>

			</ButtonDropDown>
		</li>
	);
};