import { sortBy } from "lodash";
import { getImportOptions } from "../../../utils/utils";
import { IsNullOrEmpty } from "../../../utils/utilsService";
import { ERROR_SHOW, GET_AREA, GET_AREA_ERROR, GET_AREA_SUCCESS } from "../../constants";
const getZoneOptions = (zonesResponse) => {
    
    const activeZones = zonesResponse.filter((object) => {
      return IsNullOrEmpty(object.deleteBy)
    })
    return sortBy(activeZones, [
      function (o) {
        return o.name
      }
    ]).map((item) => ({ label: item.name, value: item.id }))

  }
export const area=(state, actions) => {
    switch (actions.type) {
        case GET_AREA:
            return {
                    areas: state.areas,
                    loading: true,
                    error: null,
                    errorShow: false
            }
        case GET_AREA_ERROR:
            return {
                    areas: state.areas,
                    loading: false,
                    error: actions.payload,
                    errorShow: false
            }
        case GET_AREA_SUCCESS:
            return {
                    areas: actions.payload,
                    areasOptions: getZoneOptions(actions.payload),
                    importOptions: getImportOptions(actions.payload),
                    loading: false,
                    error: null,
                    errorShow: false
            }
        case ERROR_SHOW:
            return {
                    areas: state.areas,
                    loading: false,
                    error: state.error,
                    errorShow: true
            }
        default:
            return state;
    }
}