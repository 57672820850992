import CollaboratorQuestionaryComponent from '../../containers/collaboratorQuestionary/CollaboratorQuestionaryComponent';
import React from 'react'
import { useLocation } from 'react-router-dom';
export const CollaboratorQuestionary = () => {
  const location = useLocation();
  const urlPositions=location.pathname.split('/')
  const gudename=1
  const idQuestionary=3;
  const idSurveyCollaborator=4;
  
  return (
    <CollaboratorQuestionaryComponent guideName={urlPositions[gudename]} idQuestionary={urlPositions[idQuestionary]} idSurveyCollaborator={urlPositions[idSurveyCollaborator]} />
  )
}
