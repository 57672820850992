import { useContext, useEffect, useState } from 'react'
import { Toast } from '../../Components/toast'
import useApi from '../api'
import { StoreContext } from '../../business/Provider'
import { GET_QUESTION, GET_QUESTION_ERROR, GET_QUESTION_SUCCESS } from '../../business/constants'

const useQuestions = (questionaryId) => {
  const baseUrl = `${window.REACT_APP_EVALUA_API_URI}/guide`
  const [questions, setQuestions] = useState(undefined)
  const { genericApiCall } = useApi();
  const [loadingQuestions, setLoadingQuestions] = useState(false)
  const  {questionsState, dispatchQuestions} = useContext(StoreContext)
  const getQuestionsList = async () => {
    try {
      setLoadingQuestions(true)
      const response = await getQuestions()
      const { questionary } = response.data.data
      setQuestions(questionary)
      setLoadingQuestions(false)
    } catch (error) {
      setQuestions([])
      setLoadingQuestions(false)
    }
  }

  const getQuestions = async () => {
    try{
      dispatchQuestions({ type: GET_QUESTION })
      const result =await genericApiCall(`${baseUrl}/${questionaryId}/questions`, 'get')
      if( result.status === 200) {
        const payload = {
          id:  questionaryId,
          data: result.data.data.questionary
        }
        dispatchQuestions({ type: GET_QUESTION_SUCCESS, payload: payload })
      }
      else {
        let message = ''
        if (result.data) message = result.data.message
        else message = result.message  
        dispatchQuestions({ type: GET_QUESTION_ERROR, payload: message })
      }
      return result
    } catch (error) {
      Toast('warning',error.message, "", error)
      dispatchQuestions({ type: GET_QUESTION_ERROR})
    }  
  }

  useEffect(() => {
    // check if questionsState contains the key { 1 : [], 2 : [] }
    const contain = Object.keys(questionsState.questions).includes(questionaryId)
    if(!contain && questionsState.loading === false && questionsState.error === null) {
      getQuestions()
    }
  }, [questionaryId])
  

  return {
    getQuestions,
    questions,
    loadingQuestions
  }
}
export default useQuestions
