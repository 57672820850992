import * as yup from 'yup'

export const searchObj = {
    collaboratorName:[],
    zone:[],
    department:[],
    position:[],
    workCenter:[],
    status:["A"],    
  }

