import { ERROR_SHOW, GET_POSITION_SUCCESS } from '../../../business/constants'
import { GenericsNames, PositionNames } from '../../../translations'
import React, { useContext, useEffect, useState } from 'react'
import { getTranslation, useCustomTranslation } from '../../../hooks/useTranslations'

import { CompanyRelatedContext } from '../../../context/CompanyRelatedContext';
import { EvaluaAlertDialog } from '../../../Components/Dialogs/EvaluaAlertDialog'
import { FullLoading } from '../../../Components/FullLoading'
import { IsNullOrEmpty } from '../../../utils/utilsService'
import { LoadingContext } from '../../../context/LoadingContext'
import { PositionDialog } from './components/PositionDialog'
import { PositionGrid } from './components/PositionGrid'
import PositionObject from './../../../utils/Constanst/FormikConstanst/Position/PositionObject';
import PositionSchema from '../../../utils/Constanst/FormikConstanst/Position/PositionSchema'
import { SUCCESS } from './../../../utils/Constanst/ResponseStatus/index';
import { StoreContext } from '../../../business/Provider'
import { Toast } from '../../../Components/toast'
import { WorkArea } from '../../../Components/WorkArea'
import { firstIndex } from '../../../utils/arrayService';
import useError from '../../../hooks/useErrors'
import { useFormik } from 'formik'
import usePosition from '../../../hooks/position/usePosition'
import useReload from '../../../hooks/useReload'

export const Positions = () => {
  const {
    loadingPositions,
    postPosition,
    putPosition,
    deletePosition,
    getNextCode,
    getPositionList,
    getPositions
  } = usePosition()
  const context = useContext(StoreContext);
  /** Reload **/
  useReload([getPositions])
  useError(context.positionState, context.dispatchPosition)
  const { t } = useCustomTranslation()
  const [open, setOpen] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [titlePopUp, setTitlePopUp] = useState('')
  const [positions, setPositions] = useState([])
  const [company, setCompany] = useState(null);
  const {relatedCompanies}= useContext(CompanyRelatedContext);
  useEffect(() => {
    if (context.positionState?.positions) {
      setPositions(context.positionState?.positions)
    }
  }, [context.positionState])
  useEffect(() => {
    if (!company) {
      setCompany(JSON.parse(localStorage.getItem('companyInfo')))
    }
  }, [company])

  const loadingObj= useContext(LoadingContext)
  // region [Confirmation]
  const toggleConfirmation = () => {
    setOpenConfirmation(!openConfirmation)
  }
  // endregion
  // region [Dialog Methods]
  const toggleModal = () => {
    setOpen(!open)
  }
  const handleClose = (value) => {
    formik.resetForm()
    formik.setErrors({})
    toggleModal()
  }

  const addPosition = async (Position) => {

      const response = await postPosition(Position)
      return response
  
  }

  const updatePosition = async (Position) => {

      const response = await putPosition(Position)

      return response
  
  }
  const deletePositionGrid = async () => {
    try {
      loadingObj.setLoading(true);
      toggleConfirmation()
      const result = await deletePositionHandler(formik.values)
      if (result !== undefined) {
        const filteredPosition = positions.filter((Position) => Position.id !== formik.values.id)
        context.dispatchPosition({ type: GET_POSITION_SUCCESS, payload: filteredPosition })
        setPositions(filteredPosition)
      }
      loadingObj.setLoading(false);
    } catch (error) {
      loadingObj.setLoading(false);
    }
   
  }
  const deletePositionHandler = async (values) => {
    const Position = values
    const response = await deletePosition(Position)
    return response
  }
  const addOrUpdatePosition = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      loadingObj.setLoading(true);
      let response;
      const isNew = IsNullOrEmpty(values.id)
      values.code = parseInt(values.scode) 
      if (isNew) {
        response = await addPosition(values)
      } else {
        response = await updatePosition(values)
      }
      loadingObj.setLoading(false);
      if (response !== undefined) {
        handleSaveFunction(isNew, response)
        resetForm()
        setStatus({ success: true })
        setSubmitting(false)
      }
    } catch (err) {
      console.error(err)
      loadingObj.setLoading(false);
      setStatus({ success: false })
      setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: { ...PositionObject },
    validationSchema: PositionSchema,
    onSubmit: addOrUpdatePosition
  })

  const handleOnAdd = async (value) => {    
    setTitlePopUp(getTranslation(t, PositionNames.DIALOG_ADD))
    const response = await getNextCode()
    const nextCode = response.data
    const newPosition = { ...PositionObject }
    newPosition.scode = nextCode
    newPosition.code = nextCode
    formik.setValues({ ...newPosition })    
    toggleModal()
  }
  const handleOnEdit = (row) => {
    setTitlePopUp(getTranslation(t, PositionNames.DIALOG_EDIT))
    formik.setValues(row)
    toggleModal()
  }
  const handleOnDelete = (row) => {
    formik.setValues(row)
    toggleConfirmation()
  }
  const handleSaveFunction = (isNew, item) => {
    addOrUpdateGrid(isNew, item, positions)
    toggleModal()
  }
  // endregion
  // region [Grid Method]
  const findPositionIndex = (data, item) => {
    return firstIndex('id', item.id, data)
  }
  const addOrUpdateGrid = (isNew, item, data) => {
    const PositionCopy = [...positions]
    if (item) {
      if (isNew) PositionCopy.push(item)
      else {
        const pos = findPositionIndex(data, formik.values)
        PositionCopy[pos] = formik.values
      }
    }
    context.dispatchPosition({ type:  GET_POSITION_SUCCESS, payload: PositionCopy })
    setPositions(PositionCopy)
  }
  return (
    <div className='flex flex-grow w-full flex-row'>
      <EvaluaAlertDialog
        title={getTranslation(t, GenericsNames.CONFIRM)}
        message={getTranslation(t, PositionNames.CONFIRM_MESSAGE_DELETE)}
        open={openConfirmation}
        setOpen={toggleConfirmation}
        cancelLabel={getTranslation(t, GenericsNames.CANCEL)}
        agreeLabel={getTranslation(t, GenericsNames.ACCEPT)}
        onConfirmFunction={deletePositionGrid}
      />
      <PositionDialog
        formik={formik}
        onClose={handleClose}
        open={open}
        title={titlePopUp}
        textCancel={getTranslation(t, GenericsNames.CANCEL)}
        textSave={getTranslation(t, GenericsNames.SAVE)}
        id='PositionDialog'
        name='PositionDialog'
      />
      <WorkArea
        grid={
          <>
            
            <PositionGrid
              handleOnAdd={handleOnAdd}
              onEdit={handleOnEdit}
              onDelete={handleOnDelete}
              rows={positions}
              loading={context.positionState.loading}
              hasRelationWithPersonia={relatedCompanies && relatedCompanies.length>0}
            />
          </>}
      />

    </div>
  )
}