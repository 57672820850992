import React, { useEffect, useState } from 'react';

import { Card } from 'primereact/card';
import CompanyRelatedList from './companyRelatedList';
import PropTypes from 'prop-types';
import useCompanies from '../../../hooks/Company/useCompany';
import useCurrentCompanies from '../../../hooks/Company/useCurrentCompany';

const IntegrationCompany = ({ onClickAdd, associatedCompanies, setAssociatedCompanies, onClickRemove, onSearchAssociation, iNeedSearch = true }) => {
	// const { currentCompany } = useCurrentCompanies()


	const renderTitle = () => {
		return <div className={'border-b-4 w-full h-full flex flex-col justify-center items-center'}>
			<header>
				<h5 className='text-font-size-base font-font-family-base font-semibold text-left'>{'Empresas relacionadas con la empresa actual'}</h5>
			</header>
			{/* <h6 className='text-font-size-base font-font-family-base text-left'>{currentCompany?.name} <span className='italic'>{currentCompany?.alias}</span></h6> */}
		</div>
	}
	const renderBody = () => {
		return <CompanyRelatedList key='currentCompaniesRelated'
			companies={associatedCompanies}
			setCompanies={setAssociatedCompanies}
			modalMode={false}
			onClickAdd={onClickAdd}
			onClickRemove={onClickRemove}
			onSearchChange={onSearchAssociation}
			iNeedSearch={iNeedSearch}
		>
		</CompanyRelatedList>
	}
	return (
		<div className='flex flex-col w-full h-full ' >
			<div className="flex w-full h-[8%] "> {renderTitle()}</div>
			<div className="flex w-full h-[92%]">
				{renderBody()}
			</div>


		</div>
	);
};

IntegrationCompany.propTypes = {
	onClickAdd: PropTypes.func,
	associatedCompanies: PropTypes.array,
	setAssociatedCompanies: PropTypes.func,
	onClickRemove: PropTypes.func,
	onSearchAssociation: PropTypes.func
};

export default IntegrationCompany;