import { useContext, useEffect, useState } from 'react';
import { GET_RESULT, GET_RESULT_ERROR, GET_RESULT_SUCCESS } from '../../business/constants';
import { StoreContext } from '../../business/Provider';
import useApi from '../api';
import { Toast } from '../../Components/toast'

const useSurveyCollaboratorResult = (surveyId, idSurveyCollaborator) => {
  
  const [survResult, setSurvResult] = useState(undefined);
  const [loadingSurvResult, setloadingsSurvResult] = useState(false);
  const { genericApiCall } = useApi();
  const { resultState, dispatchResult } = useContext(StoreContext);
  const getSurveyCollaboratorResult = async () => {
    try {
      setloadingsSurvResult(true);
      const response = await getSurveyResults(surveyId, idSurveyCollaborator);

      setSurvResult(response.data.data);
      setloadingsSurvResult(false);
    } catch (error) {
      Toast('warning',error.message, "", error)
      setSurvResult({general:{}});
      setloadingsSurvResult(false);
    }
  };

  const getSurveyResults = async () => {
    try{
      dispatchResult({ type: GET_RESULT });
      const result = await genericApiCall(`${window.REACT_APP_EVALUA_API_URI}/surveys/${surveyId}/results/${idSurveyCollaborator}`, 'get');
      if (result.status === 200) {
        const payload = {
          id: surveyId+"-"+idSurveyCollaborator,
          data: result.data.data
        };
        dispatchResult({ type: GET_RESULT_SUCCESS, payload: payload });
      }
      else {
        let message = '';
        if (result.data) message = result.data.message;
        else message = result.message;
        dispatchResult({ type: GET_RESULT_ERROR, payload: message });
      }
      return result;
    } catch (error) {
      Toast('warning',error.message, "", error)
      dispatchResult({ type: GET_RESULT_ERROR})
    } 
  };

  useEffect(() => {
    // check if questionsState contains the key { 1 : [], 2 : [] }
    const contain = Object.keys(resultState.results).includes(surveyId+"-"+idSurveyCollaborator);
    if (surveyId && idSurveyCollaborator && resultState.loading === false && !resultState.error && !contain) {
      getSurveyResults();
    }
  }, [surveyId, idSurveyCollaborator]);

  return {
    setSurvResult,
    survResult,
    loadingSurvResult,
    getSurveyResults,
    
  };
};
export default useSurveyCollaboratorResult;
