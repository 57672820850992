import * as yup from 'yup'

import { GenericsNames, SurveyNames } from '../../../translations/index'

export const SurveySchema = yup.object().shape({
  scode: yup //
  .string('')
  .required(SurveyNames.VALIDATOR_CODE_REQUIRED)
  .matches(/^[0-9]+$/, SurveyNames.VALIDATOR_DIGIT_ALLOW)
  .min(4, SurveyNames.VALIDATOR_CODE_REQUIRED_SIZE)
  .max(4, SurveyNames.VALIDATOR_CODE_REQUIRED_SIZE)
  .test(
    'greaterThanZero',
    GenericsNames.VALIDATOR_ID_GREATER_THAN_ZERO,
    (value) => parseInt(value, 10) > 0
  ),
  // number: yup //
  // .string('')
  // .required(SurveyNames.VALIDATOR_CODE_REQUIRED),
  name: yup.string('').required(SurveyNames.VALIDATOR_NAME_REQUIRED).max(100, SurveyNames.VALIDATOR_NAME_REQUIRED_SIZE),
  startDate: yup.date().nullable().required(SurveyNames.VALIDATOR_STARTDATE_REQUIRED),
  endDate: yup.date().nullable().required(SurveyNames.VALIDATOR_ENDDATE_REQUIRED)
})
