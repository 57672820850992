import React, { Fragment, useContext, useEffect, useState } from 'react'
import { getTranslation, useCustomTranslation } from '../../hooks/useTranslations';

import CompanyItem from '../../containers/Company/Components/companyItem';
import { CompanyRelatedContext } from '../../context/CompanyRelatedContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IsNullOrEmpty } from '../../utils';
import { StoreContext } from '../../business/Provider';
import { ToolBarNames } from '../../translations';
import { Tooltip } from 'primereact/tooltip';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import useCompanies from '../../hooks/Company/useCompany';
import useReload from '../../hooks/useReload';
import { useSelector } from 'react-redux';

export const CompanyInfo = ({
  refCloseCompanyInfo,
  showCompanyInfo,
  setShowCompanyInfo }) => {
  const { companyState } = useContext(StoreContext);
  const { t } = useCustomTranslation()
  const user = useSelector(({ auth }) => auth.user)
  // const [showCompanyInfo, setShowCompanyInfo] = useState(false);
  const { currentCompany } = useCompanies()
  const { relatedCompanies } = useContext(CompanyRelatedContext)
  const getRFCFromSession = () => {
    const sessionStateStorage = sessionStorage.getItem('sessionState');
    const sessionObj = JSON.parse(sessionStateStorage);
    document.title = sessionObj ? (sessionObj?.rfc || '') + " | " + ' CONTPAQi® Evalua' : 'CONTPAQi® Evalua';
    return sessionObj?.rfc || '';
  };
  //useReload([getRelatedCompanies])
  const onGetRelatedCompanies = async () => {


    setShowCompanyInfo(!showCompanyInfo);
  }
  const renderCompanyRelatedItemsNavBar = () => {
    return (
      <div className='flex w-[30px] h-16 justify-around'>
        <div className='flex h-full w-5/12' title={getTranslation(t, ToolBarNames.COMPANY_RELATED)}>
          <Fragment key={'companyRelated'}>
            <div key={'companyRelated'} onClick={onGetRelatedCompanies} className={`tooltip-companyRelated flex-grow flex text-[#5e5e5e]  items-center content-center justify-center cursor-pointer hover:text-[#0a6ebd] hover:bg-[#5e5e5e1e]`}>
              <FontAwesomeIcon icon={faLink} />
            </div>
          </Fragment>
        </div>
      </div>
    )
  }
  useEffect(async () => {

    if (!IsNullOrEmpty(companyState?.companies)) {
      localStorage.setItem('companyInfo', JSON.stringify(companyState?.companies));
      window.idzCustomData.RFC = companyState?.companies.taxID;
      window.idzCustomData.Razon_social = companyState?.companies.name;
      window.idzCustomData.cust_nombre = companyState?.companies.alias;
      window.idzCustomData.logged_in = true;
      window.idzCustomData.Estado = companyState?.companies.state
      window.idzCustomData.extID = companyState?.companies.id
      window.idzCustomData.cust_url = window.location.href
      window.idzCustomData.cust_email = user?.data?.email

      document.title = (companyState?.companies.taxID || '') + " | " + ' CONTPAQi® Evalua';
    } else {
      document.title = 'CONTPAQi® Evalua';
    }
  }, [companyState?.companies]);

  return (
    < >
      <div className='flex flex-col h-full w-4  font-h2-alt-font-family text-secondary-contrast-color-text pt-3'>
        {currentCompany && relatedCompanies && relatedCompanies.length > 0 ? renderCompanyRelatedItemsNavBar() : <></>}
      </div>
      <div ref={refCloseCompanyInfo} className='flex flex-col h-full w-full  font-h2-alt-font-family text-secondary-contrast-color-text pt-3 ml-2'>
        <div className="flex w-full font-bold  text-[#01b0f3] text-small-font-size ">
          {/*<p className='leading-3'>{getRFCFromSession()}</p>*/}
          <p className='leading-3'>{companyState?.companies?.taxID ? companyState?.companies.taxID : getRFCFromSession()}</p>
        </div>
        <div title={currentCompany?.name} className="flex w-full  text-sm truncate">
          {currentCompany ? currentCompany.name : ''}
        </div>
        <div className="flex w-full  text-xs">
          {currentCompany ? currentCompany.alias : ''}
        </div>
      </div>
      {showCompanyInfo ? <ul className='flex min-w-max flex-col pt-1 absolute top-[4rem] divide-y rounded-md bg-dropdown-bg-default drop-shadow-2xl  '>
        {relatedCompanies && relatedCompanies.map((item, index) => {
          return <li key={index}>

            {/* <ButtonDropDown key={index} className='h-12 w-full p-2 !font-h3-font-family' title={item.text}  />  */}

            <CompanyItem item={item} className="mx-1">

            </CompanyItem>
          </li>
        })}
      </ul> : <></>}
    </>
  )
}