import { EvaluatorNames, GenericsNames } from '../../../translations';
import React, { useContext, useEffect, useState } from 'react'
import { getTranslation, useCustomTranslation } from './../../../hooks/useTranslations';

import { EvaluaAlertDialog } from './../../../Components/Dialogs/EvaluaAlertDialog';
import { EvaluatorDialog } from './Components/EvaluatorDialog';
import { EvaluatorGrid } from './Components/EvaluatorGrid';
import EvaluatorObject from '../../../utils/Constanst/FormikConstanst/Evaluator/EvaluatorObject';
import EvaluatorSchema from '../../../utils/Constanst/FormikConstanst/Evaluator/EvaluatorSchema';
import { FullLoading } from './../../../Components/FullLoading/index';
import { IsNullOrEmpty } from './../../../utils/utilsService';
import { LoadingContext } from '../../../context/LoadingContext';
import { SUCCESS } from './../../../utils/Constanst/ResponseStatus/index';
import { Toast } from './../../../Components/toast/index';
import { WorkArea } from './../../../Components/WorkArea/index';
import { firstIndex } from '../../../utils/arrayService';
import useEvaluator from './../../../hooks/elevator/useElevator';
import { useFormik } from 'formik'
import useReload from './../../../hooks/useReload';
import { StoreContext } from '../../../business/Provider';
import { ERROR_SHOW, GET_EVALUATOR_SUCCESS } from '../../../business/constants';
import useError from '../../../hooks/useErrors';

export const Evaluators = () => {
  const { t } = useCustomTranslation()
  const {
    loadingEvaluator,
    postEvaluator,
    putEvaluator,
    deleteEvaluator,
    getNextCode,
    getEvaluatorList,
    getEvaluator
  } = useEvaluator()
  /** Reload **/
  const context = useContext(StoreContext);
  const {} =useError(context.evaluatorState, context.dispatchEvaluator)
  useReload([getEvaluator])
  const [openConfirmation, setOpenConfirmation] = useState(false)
  //const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = useState(false)
  const loadingObj= useContext(LoadingContext)
  const [titlePopUp, setTitlePopUp] = useState('')
  const [evaluator, setEvaluator] = useState([])
  useEffect (() => {
    if (context.evaluatorState?.evaluators) {
      setEvaluator(context.evaluatorState?.evaluators)
    }
  }, [context.evaluatorState])
  // const [globalFilterValue, setGlobalFilterValue] = useState('')
  const toggleConfirmation = () => {
    setOpenConfirmation(!openConfirmation)
  }
  const toggleModal = () => {
    setOpen(!open)
  }

  const handleClose = (value) => {
    toggleModal()
    formik.resetForm()
    formik.setErrors({})
  }

  

  const addOrUpdateEvaluator = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      loadingObj.setLoading(true);
      let response
      const isNew = IsNullOrEmpty(values.id)
      values.code = parseInt(values.scode) 
      if (isNew) {
        response = await addEvaluator(values)
      } else {
        response = await updateEvaluator(values)
      }
      loadingObj.setLoading(false);
      if (response !== undefined) {
        handleSaveFunction(isNew, response)
        resetForm()
        setStatus({ success: true })
        setSubmitting(false)
      }
    } catch (err) {
      console.error(err)
      loadingObj.setLoading(false);
      setStatus({ success: false })
      setSubmitting(false)
    }
  }
  const handleOnDelete = (row) => {
    formik.setValues(row)
    toggleConfirmation()
  }

  const formik = useFormik({
    initialValues: { ...EvaluatorObject },
    validationSchema: EvaluatorSchema,
    onSubmit: addOrUpdateEvaluator
  })

  const handleOnAdd = async (value) => {
    
    setTitlePopUp(getTranslation(t, EvaluatorNames.DIALOG_ADD))
    const response = await getNextEvaluatorCode()
    const nextCode = response.data
    const newEvaluator = { ...EvaluatorObject }
    newEvaluator.scode = nextCode
    newEvaluator.code = nextCode
    await formik.setValues({ ...newEvaluator })
    
    setOpen(true)
  }
  const handleSaveFunction = (isNew, item) => {
    addOrUpdateGrid(isNew, item, evaluator)
    toggleModal()
  }
  const handleOnEdit = (row) => {
    setTitlePopUp(getTranslation(t, EvaluatorNames.DIALOG_EDIT))
    formik.setValues(row)
    toggleModal()
  }

  const addOrUpdateGrid = (isNew, item, data) => {
    const evaluatorCopy = [...evaluator]
    if (item) {
      if (isNew) evaluatorCopy.push(item)
      else {
        const pos = findEvaluatorIndex(data, item)
        evaluatorCopy[pos] = item
      }
    }
    context.dispatchEvaluator({ type: GET_EVALUATOR_SUCCESS, payload: evaluatorCopy })
    setEvaluator(evaluatorCopy)
  }

  const findEvaluatorIndex = (data, item) => {
    return firstIndex('id', item.id, data)
  }



  const updateEvaluator = async (item) => {
      const response = await putEvaluator(item)  
      return response
  }






  const addEvaluator = async (item) => {
 
      const response = await postEvaluator(item)
      
      return response
  }

  const getNextEvaluatorCode = async () => {
    try {
      return await getNextCode()
    } catch (error) {
      console.log(error)
      
      return ''
    }
  }

  const deleteEvaluatorGrid = async () => {
    try {
      loadingObj.setLoading(true);
      toggleConfirmation()
      const result = await removeEvaluator(formik.values)
      if (result !== undefined) {
        const filteredEvaluator = evaluator.filter((ev) => ev.id !== formik.values.id)
        context.dispatchEvaluator({ type: GET_EVALUATOR_SUCCESS, payload: filteredEvaluator })
        setEvaluator(filteredEvaluator)
      }
      loadingObj.setLoading(false);
    } catch (error) {
      loadingObj.setLoading(false);
    }
   
  }
  const removeEvaluator = async (evaluatorToRemove) => {
    
    const response = await deleteEvaluator(evaluatorToRemove)
    return response
  }

  return (
    <div className='flex flex-grow w-full flex-row'>
      <EvaluaAlertDialog
        title={getTranslation(t, GenericsNames.CONFIRM)}
        setOpen={toggleConfirmation}
        open={openConfirmation}
        message={getTranslation(t, EvaluatorNames.CONFIRM_MESSAGE_DELETE)}
        cancelLabel={getTranslation(t, GenericsNames.CANCEL)}
        agreeLabel={getTranslation(t, GenericsNames.ACCEPT)}
        onConfirmFunction={deleteEvaluatorGrid}
      />
      <EvaluatorDialog
        formik={formik}
        onClose={handleClose}
        open={open}
        title={titlePopUp}
        textCancel={getTranslation(t, GenericsNames.CANCEL)}
        textSave={getTranslation(t, GenericsNames.SAVE)}
        id='ZoneDialog'
        name='ZoneDialog'
      />
      <WorkArea grid={
        <>
          <EvaluatorGrid
            onEdit={handleOnEdit}
            onDelete={handleOnDelete}
            loading={context.evaluatorState.loading}
            rows={evaluator}
            handleOnAdd={handleOnAdd}
          />
        </>
      } />
    </div>
  )
}