import { sortBy } from 'lodash';
import { IsNullOrEmpty } from '../../../utils/utilsService';
import { GET_COLLABORATOR, GET_COLLABORATOR_ERROR, GET_COLLABORATOR_SUCCESS, ERROR_SHOW } from '../../constants';
const getCollaboratorOptions = (collaboratorResponse) => {
    const activecollaborator = collaboratorResponse.filter((object) => {
      return IsNullOrEmpty(object.deleteBy)
    })
    return sortBy(activecollaborator, [
      function (item) { return item.name; },
      function (item) { return item.lastName; },
      function (item) { return item.surName; },
    ]).map((item) => ({ label: `${item.name} ${item.lastName} ${(item.surName)===null ? '' : item.surName} `, value: item.collaboratorId }))
  }
export const collaborator = (state, actions) => {
    switch (actions.type) {
        case GET_COLLABORATOR:
            return {
                ...state,
                loading: true,
            }
        case GET_COLLABORATOR_ERROR:
            return {
                collaborators: state.collaborators,
                collaboratorsOptions: state.collaboratorsOptions,
                loading: false, 
                error: actions.payload,
                errorShow: false
            }
        case GET_COLLABORATOR_SUCCESS:
            return {
                collaborators: actions.payload,
                collaboratorsOptions: getCollaboratorOptions(actions.payload),
                loading: false,
                error: null,
                errorShow: false
            }
        case ERROR_SHOW:
            return {
                ...state,
                errorShow: true
            }
        default:
            return state;
    }
}
