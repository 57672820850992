import React, { useContext, useState } from 'react'
import { EntityToExport, ToExcel, ToPdf } from '../../../../utils/exportService';
import { GenericsNames, WorkCenterNames } from './../../../../translations/index';
import { faPencilAlt, faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { getTranslation, useCustomTranslation } from '../../../../hooks/useTranslations';

import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { CustomIconButton } from '../../../../Components/Buttons/CustomIconButton';
import { EvaluaTable } from '../../../../Components/Table/EvaluaTable';
import { FilterMatchMode } from 'primereact/api';
import { TableButton } from '../../../../Components/Buttons/TableButtons';
import ToolBarThreeColumns from '../../../../Components/Table/ToolBarThreeColumns';
import WorkCenterObject from '../../../../utils/Constanst/FormikConstanst/WorkCenter/WorkCennterObject';
import WorkCenterSchema from '../../../../utils/Constanst/FormikConstanst/WorkCenter/WorkCenterSchema';
import { useFormik } from 'formik'
import { StoreContext } from '../../../../business/Provider';
import { Toast } from '../../../../Components/toast';

export const WorkCenterGrid = ({
  onEdit,
  onDelete,
  loading,
  rows,
  handleOnAdd,
  selectionMode,
  selection,
  onSelectionChange,
}) => {

  const { t } = useCustomTranslation()
  const { dispatchLoading } = useContext(StoreContext)
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: {
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
    },
    scode: {
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }]
    },
    zipCode: {
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
    },
    isActive: { value: null, matchMode: FilterMatchMode.EQUALS }
  })
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [sort, setSort] = useState([{ field: '', order: -1 }])
  const onGlobalFilterChange = (e) => {
    const { value } = e.target
    const _filters = { ...filters }
    _filters.global.value = value
    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const formik = useFormik({
    initialValues: { ...WorkCenterObject },
    validationSchema: WorkCenterSchema,
    //onSubmit: addOrUpdateDepartments
  })
  const handleExcelExport = async () => {
    const printObj = { ...filters, sort: sort[0] };

    //exportToExcel(printObj)
    rows.length > 0 ? await ToExcel(printObj, EntityToExport.CATALOG_WORKCENTER, dispatchLoading) : Toast('warning', getTranslation(t, GenericsNames.NO_EXPORT_DATA))

  }

  const handleToPDF = async () => {
    const printObj = { ...filters };
    rows.length > 0 ? await ToPdf(printObj, EntityToExport.CATALOG_WORKCENTER, dispatchLoading) : Toast('warning', getTranslation(t, GenericsNames.NO_EXPORT_DATA))
  }
  const renderHeader = () => (

    <ToolBarThreeColumns
      setValue={setGlobalFilterValue}
      setFilters={setFilters}
      rightChildren={
        <CustomIconButton
          className='!m-1'
          toolTip={getTranslation(t, GenericsNames.ADD)}
          onClick={() => { handleOnAdd() }}
          icon={faPlusCircle}
        />}
      value={globalFilterValue}
      onChange={onGlobalFilterChange}
      formik={formik}
      placeholder={getTranslation(t, GenericsNames.SEARCH)}
      onExcelClick={() => {
        handleExcelExport()
      }}
      onPDFClick={() => {
        handleToPDF()
      }}
    >

    </ToolBarThreeColumns>
  );
  const checkboxTemplate = (rowData) => {
    return (
      <div>
        <Checkbox disabled checked={rowData.isActive} value={rowData.isActive} />
      </div>
    )
  }



  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <TableButton
          onClick={() => onEdit(rowData)}
          icon={faPencilAlt}
          toolTip={getTranslation(t, GenericsNames.EDIT)}
        />
        <TableButton
          onClick={() => onDelete(rowData)}
          icon={faTrashAlt}
          toolTip={getTranslation(t, GenericsNames.DELETE)}
        />
      </>
    )
  }

  return (
    <>
      {
        <div className='datatable-doc-demo'>
          <div className='card'>
            <EvaluaTable
              sort={sort}
              setSort={setSort}
              value={rows}
              sortField='name'
              header={renderHeader()}
              filters={filters}
              //filterDisplay='row' // input search
              responsiveLayout='scroll'
              globalFilterFields={['name', 'scode', 'zipCode', 'principalActivity', 'objective', 'activities', 'isActive']}
              emptyMessage={getTranslation(t, WorkCenterNames.EMPTY_WORK_CENTER)}
              currentPageReportTemplate={getTranslation(t, WorkCenterNames.GRID_PAGE_REPORT_TEMPLATE)}

              doubleClick={onEdit}
              // height={height}
              selectionMode={selectionMode}
              selection={selection}
              onSelectionChange={onSelectionChange}
              columnResizeMode="fit"
              loading={loading}
            >
              {selectionMode &&
                <Column
                  selectionMode="multiple"
                  //headerStyle={{ width: "5px" }}
                  style={{ flexGrow: 1, flexBasis: '100px' }}
                ></Column>

              }
              <Column
                field='scode'
                header={getTranslation(t, WorkCenterNames.GRID_COLUMN_KEY)}
                sortable
                style={{ flexGrow: 1, flexBasis: '100px' }}
              />
              <Column
                field='name'
                header={getTranslation(t, WorkCenterNames.GRID_COLUMN_NAME)}
                sortable
                style={{ flexGrow: 1, flexBasis: '100px' }}

              />
              <Column
                field='zipCode'
                header={getTranslation(t, WorkCenterNames.GRID_COLUMN_ZIPCODE)}
                sortable
                style={{ flexGrow: 1, flexBasis: '100px' }}
              />
              <Column
                field='principalActivity'
                header={getTranslation(t, WorkCenterNames.GRID_COLUMN_PRINCIPAL_ACTIVITY)}
                sortable
                style={{ flexGrow: 1, flexBasis: '100px' }}
              />
              <Column
                field='objective'
                header={getTranslation(t, WorkCenterNames.GRID_COLUMN_OBJECTIVE)}
                sortable
                style={{ flexGrow: 1, flexBasis: '100px' }}
              />
              <Column
                field='activities'
                style={{ flexGrow: 1, flexBasis: '100px' }}
                header={getTranslation(t, WorkCenterNames.GRID_COLUMN_ACTIVITIES)}
                sortable
              />
              <Column
                field='isActive'
                header={getTranslation(t, GenericsNames.ACTIVE)}
                sortable
                style={{ flexGrow: 1, flexBasis: '100px' }}
                body={checkboxTemplate}
              />
              <Column
                header={getTranslation(t, WorkCenterNames.GRID_ACTION)}
                headerStyle={{ width: '4rem', textAlign: 'center' }}
                bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                body={actionBodyTemplate}
                style={{ flexGrow: 1, flexBasis: '100px' }}
              />
            </EvaluaTable>
          </div>
        </div>
      }
    </>
  )
}