import React, { useState } from 'react'

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';

// import PropTypes from 'prop-types';

export const CreateDialog = ({
  onClose,
  open,
  formik,
  title,
  textSave,
  textCancel,
  onSave,
  formComponet,
  sendDataLoading,
  setDisabledOnSave,
  disabledSave = false,
  disabledOnSave = false,
}) => {

  const renderFooter = () => {
    return (
      <div>
        <Button className="p-button-text" label={textCancel} icon="pi pi-times" onClick={() => onClose()} autoFocus />
        <Button disabled={disabledSave || disabledOnSave} type='submit' label={textSave} icon="pi pi-check" onClick={() => {
          // console.log('onSave', formik.values);
          if (onSave) {
            onSave(formik.values);
          } else {
            formik.handleSubmit();
          }
          setDisabledOnSave(true);
        }}
        />
      </div>
    );
  }

  const loadingComponent = () => {
    return (
      <div className='absolute opacity-50 z-50 flex w-full top-0 bottom-0 items-center justify-center '>
        <div className={'flex w-full h-full flex-col items-center justify-center p-24'}>
          <p className='text-13 sm:text-20 mb-16'>{'Enviando información'}</p>
          <ProgressSpinner mode="indeterminate" className='flex w-full h-8' ></ProgressSpinner>
        </div>
      </div>
    )
  }
  return (

    <Dialog header={title.toUpperCase()} visible={open} maximizable modal style={{ width: '50vw' }} className={'flex !h-full'} footer={renderFooter()} onHide={() => onClose()}>

      {sendDataLoading ? loadingComponent() : null}
      {formComponet}


    </Dialog>

  )
}

// CreateDialog.propTypes = {
//   open: PropTypes.bool.isRequired,
//   formik: PropTypes.object.isRequired,
//   onClose: PropTypes.func.isRequired,
//   onSave: PropTypes.func,
//   title: PropTypes.string.isRequired,
//   textSave: PropTypes.string,
//   textCancel: PropTypes.string.isRequired,
//   maxWidth: PropTypes.string,
//   // childrenComponent: PropTypes.func
// };
