import { GET_COLLABORATOR, GET_COLLABORATOR_ERROR, GET_COLLABORATOR_SUCCESS } from '../../business/constants';
import { getTranslation, useCustomTranslation } from '../useTranslations';
import { useContext, useEffect, useState } from 'react'

import { CollaboratorsNames } from '../../translations';
import { IsNullOrEmpty } from '../../utils/index';
import { StoreContext } from '../../business/Provider';
import { Toast } from '../../Components/toast';
import { sortBy } from 'lodash';
import useApi from '../api';

const useCollaborators = () => {
  const baseUrl = `${window.REACT_APP_EVALUA_API_URI}/collaborator`
  const [collaborators, setCollaborators] = useState(undefined)
  const [collaboratorsOptions, setCollaboratorsOptions] = useState(undefined)
  const [loadingCollaborators, setLoadingCollaborators] = useState(false)
  const { t } = useCustomTranslation()
  // CALL CUSTOM HOOK USEAPI
  const { genericApiCall } = useApi();
  const context = useContext(StoreContext);
  const getCollaboratorOptions = (collaboratorResponse) => {
    const activecollaborator = collaboratorResponse.filter((object) => {
      return IsNullOrEmpty(object.deleteBy)
    })

    return sortBy(activecollaborator, [
      function (item) { return item.name; },
      function (item) { return item.lastName; },
      function (item) { return item.surName; },
    ]).map((item) => ({ label: `${item.name} ${item.lastName} ${item.surName}`, value: item.collaboratorId }))
  }
  const getCollaboratorList = async () => {
    try {
      setLoadingCollaborators(true)
      const response = await getCollaborators()
      const { collaborators } = response.data.data

      setCollaborators(collaborators)

      setCollaboratorsOptions(getCollaboratorOptions(collaborators))
      setLoadingCollaborators(false)
    } catch (error) {
      console.log(error)
      Toast('warning', error.message, "", error)
      setCollaborators([])
      setLoadingCollaborators(false)
    }
  }

  const postCollaborators = async (Collaborators) => {
    try {
      
      const result = await genericApiCall(baseUrl, 'POST', Collaborators)
      if (result.status === 200) {
        Toast('success', getTranslation(t, CollaboratorsNames.SUCCESS_ADD))
        return result.data.data.collaborator
      } else {
        let message = {
          body: '',
          header: getTranslation(t, CollaboratorsNames.ERROR_ADD)
        }
        if (result.message) message.body = result.message

        context.dispatchCollaborator({ type: GET_COLLABORATOR_ERROR, payload: message })
        return undefined
      }
    } catch (error) {
      Toast('warning', error.message, "", error)
      context.dispatchCollaborator({ type: GET_COLLABORATOR_ERROR })
    }
  }
  const getCollaborators = async () => {
    try {
      context.dispatchCollaborator({ type: GET_COLLABORATOR })
      const result = await genericApiCall(baseUrl, 'GET')
      if (result.status === 200) {
        context.dispatchCollaborator({ type: GET_COLLABORATOR_SUCCESS, payload: result.data.data.collaborators })
      } else {
        let message = {
          body: '',
          header: 'Error'
        }
        if (result.message) message.body = result.message
        context.dispatchCollaborator({ type: GET_COLLABORATOR_ERROR, payload: message })
      }
      return result
    } catch (error) {
      Toast('warning', error.message, "", error)
      context.dispatchCollaborator({ type: GET_COLLABORATOR_ERROR })
    }

  }
  const putCollaborators = async (Collaborators) => {
    try {
      const result = await genericApiCall(baseUrl, 'PUT', Collaborators)
      if (result.status === 200) {
        Toast('success', getTranslation(t, CollaboratorsNames.SUCCESS_UPDATE))
        return result.data.data.collaborator
      } else {
        let message = {
          body: '',
          header: getTranslation(t, CollaboratorsNames.ERROR_UPDATE)
        }
        if (result.message) message.body = result.message
        context.dispatchCollaborator({ type: GET_COLLABORATOR_ERROR, payload: message })
        return undefined
      }
    } catch (error) {
      Toast('warning', error.message, "", error)
      context.dispatchCollaborator({ type: GET_COLLABORATOR_ERROR })
    }

  }

  const deleteCollaborators = async (Collaborators) => {
    try {
      const result = await genericApiCall(baseUrl, 'DELETE', Collaborators)
      if (result.status === 200) {
        Toast('success', getTranslation(t, CollaboratorsNames.SUCCESS_DELETE))
        return result.data.data
      } else {
        let message = {
          body: '',
          header: ''
        }
        if (result.message) message.header = result.message
        context.dispatchCollaborator({ type: GET_COLLABORATOR_ERROR, payload: message })
        return undefined
      }
    } catch (error) {
      Toast('warning', error.message, "", error)
      context.dispatchCollaborator({ type: GET_COLLABORATOR_ERROR })
    }

  }

  const getNextCode = async () => {
    try {
      return genericApiCall(`${baseUrl}/next-code`, 'get')
    } catch (error) {
      Toast('warning', error.message, "", error)
    }
  }

  useEffect(() => {
    if (!context.collaboratorState?.collaborators && context.collaboratorState.loading === false && context.collaboratorState.error === null) {
      getCollaborators()
    }
  }, [context.collaboratorState])

  return {
    setCollaborators,
    collaborators,
    loadingCollaborators,
    postCollaborators,
    putCollaborators,
    deleteCollaborators,
    getNextCode,
    getCollaborators,
    collaboratorsOptions,
    getCollaboratorList
  }
}
export default useCollaborators