const CompanyObject = {
    name: undefined,
    id:0,
    name:'',
    alias:'',
    zipCode:'',
    state:'',
    municipality:'',
    street:'',
    exteriorNumber:null,
    interiorNumber:null,    
    suburb:'',
    logo:'',
    logoFile:null,
    taxID:'',
    rfc:'',
    thumbnail:'',
    clearLogo:false 
  }
  
  export default CompanyObject