import { GET_SURVEY_COLLABORATOR, GET_SURVEY_COLLABORATOR_ERROR, GET_SURVEY_COLLABORATOR_SUCCESS } from '../constants';

export const surveyCollaborator = (state, actions) => {
    switch (actions.type) {
        case GET_SURVEY_COLLABORATOR:
            return {
                ...state,
                loading: true,
            }
        case GET_SURVEY_COLLABORATOR_ERROR:
            return {
                ...state,
                loading: false,
                error: actions.payload,
                errorShow: false
            }
        case GET_SURVEY_COLLABORATOR_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                errorShow: false,
                surveyCollaborator: actions.payload.data,
                guideId: actions.payload.guideId,
                surveyId: actions.payload.surveyId
            }
        default:
            return state;
    }
}