import { CollaboratorsNames, GenericsNames, SurveyNames } from '../../translations';
import React, { useContext, useEffect, useState } from 'react'
import { firstIndex, removeDuplicateBySingleKey } from '../../utils/arrayService';
import { getTranslation, useCustomTranslation } from '../../hooks/useTranslations';
import CollaboratorSelectionDialog from './collaboratorSelectionDialog';
import { Dialog } from 'primereact/dialog'
import PropTypes from 'prop-types';
import SurveyWizard from './survayWizard';
import { WizardDialog } from './WizardDialog';
import { searchObj } from '../../utils/Constanst/survey/constants';
import useCollaborators from '../../hooks/Collaborator/useCollaborators';

import { useFormik } from 'formik';
import usePosition from '../../hooks/position/usePosition';
import useWorkCenters from '../../hooks/WorkCenters/useWorkCenters';
import useZone from '../../hooks/zone/useZone';
import { StoreContext } from '../../business/Provider';
import useDepartments from '../../hooks/Departaments/useDepartaments';

export const SurveyDialog = ({ title, open, onClose, formik, activeStep, setActiveStep, selectedCollaborators, setSelectedCollaborators, collaboratorsSearh, setColloboratorSearch }) => {
  const { t } = useCustomTranslation();
  const [openAddCollaborator, setOpenCollaborator] = useState(false)
  const {
    areaState,
    positionState,
    departmentState,
    workCenterState,
    collaboratorState
  } = useContext(StoreContext);

  const { getZones } = useZone();
  const { getDepartment } = useDepartments();
  const { getPositions } = usePosition();
  const { getWorkCenters } = useWorkCenters();
  const { getCollaborators } = useCollaborators();

  useEffect(() => {
    if (open === true) {
      getZones()
      getDepartment()
      getPositions()
      getWorkCenters()
      getCollaborators()
    }
  }, [open])
  const statusOptions = [
    { value: 'A', label: getTranslation(t, CollaboratorsNames.HIGH) },
    { value: 'R', label: getTranslation(t, CollaboratorsNames.REENTRY) }
  ]
  const steps = [
    {
      label: getTranslation(t, SurveyNames.STEP_1),
      title: getTranslation(t, SurveyNames.IDENTIFICATION_FREQUENCY),
      validationFields: ["number", "name", "startDate", "endDate"]
    },
    {
      label: getTranslation(t, SurveyNames.STEP_2),
      title: getTranslation(t, SurveyNames.COLLABORATOR_LIST),
    }
  ];


  const addCollaborators = async () => {
    if (selectedCollaborators && selectedCollaborators.length > 0) {
      //Concatenate Arrays
      let collaborators = [...formik.values.collaborators, ...selectedCollaborators];
      let collaboratorsWithOutDupes = removeDuplicateBySingleKey('collaboratorId', collaborators);
      formik.setFieldValue('collaborators', collaboratorsWithOutDupes);
    }
    toggleAddCollaboratorDialog(false);
  }
  const formikSearch = useFormik({
    initialValues: { ...searchObj },
    //validationSchema: searchSchema,
  })
  const toggleAddCollaboratorDialog = (flag) => {
    setOpenCollaborator(flag)
  }
  const onRemoveCollaborator = (row) => {
    console.log(row)
    let collaborators = formik.values.collaborators;
    const index = firstIndex('collaboratorId', row.collaboratorId, collaborators);
    if (index > -1) {
      collaborators.splice(index, 1); // 2nd parameter means remove one item only
    }
    formik.setFieldValue('collaborators', collaborators);
    formikSearch.resetForm();

  }
  const handleNext = async (e) => {
    e.preventDefault();
    //Get All Errors
    const validationErrors = await formik.validateForm();

    if (Object.keys(validationErrors).length > 0) {
      //Filter the error that belongs to the step.
      if (steps[activeStep].validationFields) {
        //Execute Touch for validation fields.
        let validationFields = steps[activeStep].validationFields;
        validationFields.forEach(element => formik.setFieldTouched(element, true));
        //formik.setTouched(setNestedObjectValues(validationErrors, true));
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <>
      <CollaboratorSelectionDialog
        open={openAddCollaborator}
        onAdCollaborators={addCollaborators}
        onClose={() => toggleAddCollaboratorDialog(false)}
        rows={collaboratorsSearh}
        setRows={setColloboratorSearch}
        formik={formikSearch}
        selectedCollaborators={selectedCollaborators ?? []}
        setSelectedCollaborators={e => setSelectedCollaborators(e.value)}
        zones={areaState.areasOptions}
        departments={departmentState.departmentsOptions}
        positions={positionState.positionOptions}
        workCenters={workCenterState.workCenterOptions}
        statuses={statusOptions}
        collaborators={collaboratorState.collaboratorsOptions}
        collaboratorsListAssign={formik.values.collaborators}
      ></CollaboratorSelectionDialog>
      <Dialog
        header={<p className='text-font-size-base text-[#5e5e5e] font-font-family-base font-bold'>{title.toUpperCase()}</p>}
        visible={open}
        modal
        style={{ width: '70vw' }}
        onHide={() => onClose()}
      >
        <WizardDialog
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          steps={steps}
          formik={formik}
          onNext={handleNext}
          onBack={handleBack}
          onClose={() => onClose()}
          handleBack={handleBack}
          handleNext={handleNext}
          open={open}
          title={title}
        >
          <SurveyWizard
            edit={title === getTranslation(t, SurveyNames.EDIT_SURVEY)}
            formik={formik}
            activeStep={activeStep}
            onClickAddCollaborators={() => toggleAddCollaboratorDialog(true)}
            onRemoveCollaborator={onRemoveCollaborator}
          />
        </WizardDialog>


      </Dialog>
    </>

  )
}

SurveyDialog.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  formik: PropTypes.object.isRequired,
  activeStep: PropTypes.number,
  setActiveStep: PropTypes.func,
  selectedCollaborators: PropTypes.array,
  setSelectedCollaborators: PropTypes.func,
  collaboratorsSearh: PropTypes.array,
  setColloboratorSearch: PropTypes.func
};
