import { GET_RESULT, GET_RESULT_ERROR, GET_RESULT_SUCCESS } from '../../constants';

export const result = (state, action) => {
    switch (action.type) {
        case GET_RESULT:
            return {
                ...state,
                loading: true,
            }
        case GET_RESULT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                errorShow: false
            }
        case GET_RESULT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                errorShow: false,
                results: {
                    ...state.results,
                    [action.payload.id]: action.payload.data
                },
            }
        default:
            return state;
    }
}
