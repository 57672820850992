import "primeicons/primeicons.css";
import './index.css';
import './assets/tailwind.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';

import {
  AuthenticatedTemplate,
  MsalProvider,
  useMsalAuthentication
} from '@azure/msal-react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { useEffect } from 'react';
import { loginRequest, msalConfig } from './Auth/b2cService/b2cServiceConfig';

import { Auth } from './Auth/auth';
import { Buffer } from 'buffer';
import { CompanyRelatedProvider } from "./context/CompanyRelatedContext";
import History from './RouterFiles/@history';
import { InteractionType } from '@azure/msal-browser';
import { LoadingProvider } from "./context/LoadingContext";
import { MainLayout } from './Layout/Main';
import { NavigationProvider } from './context/navigationContext';
import { Provider } from 'react-redux';
import { ReloadProvider } from './context/ReloadProvider';
import { StoreProvider } from "./business/Provider";
import WrapperApp from "./WrapperApp";
import axios from 'axios';
import routes from './routes/routes';
import store from './store/index';

function App({ pca }) {

  window.Buffer = Buffer;
  const { login, error } = useMsalAuthentication(InteractionType.Silent, loginRequest);
  const getQueryVariable = (variable) => {
    const query = window.location.search.substring(1);
    const vars = query.replace('#state', '&state').split('&');

    for (const vrItem of vars) {
      const pair = vrItem.split('=');
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return '';
  };

  if (localStorage.getItem('accessToken') === null) {
    localStorage.removeItem('currentTap')
  }

  useEffect(() => {
    const currentPath = window.location.pathname;
    if(currentPath === '/evaluaLogout') return
    if (error) {
      login(InteractionType.Redirect, loginRequest);
      window.localStorage.clear()
    } else {
      // get state from master portal, if the state is not present, will do a redirection to master portal.
      const stateQueryvariable = getQueryVariable('state');
      var state = '';
      if (stateQueryvariable !== '') {
        //compare instance id from session storage with instance id from master portal
        const sessionStateStorage = JSON.parse(sessionStorage.getItem('sessionState'));
        state = JSON.parse(Buffer.from(stateQueryvariable, 'base64').toString());
        state.userAgent = window.navigator.userAgent.toString();
        const sessionStateJson = state
        console.log(sessionStateJson)
        if (sessionStateJson?.instanceId !== sessionStateStorage?.instanceId) {
          //remove session storage
          sessionStorage.removeItem('sessionState');
          window.localStorage.clear()
          console.log('reset')
        }
      }
      if (!sessionStorage.sessionState) {
        //get current path
        if ((!stateQueryvariable || stateQueryvariable.length === 0)) {
          window.location.replace(msalConfig.ctiPortalURI);
        } else {
          state = JSON.parse(Buffer.from(stateQueryvariable, 'base64').toString());
          state.userAgent = window.navigator.userAgent.toString();

          const sessionStateJson = JSON.stringify(state);
          //clear sessionStorage sessionState
          sessionStorage.setItem('sessionState', sessionStateJson);

          // set as default header the sessionState
          axios.defaults.headers.common = {
            ...axios.defaults.headers.common,
            sessionState: sessionStateJson,
          };
          console.log('Session State has been register', sessionStateJson);
        }
      } else {
        const sessionStateStorage = sessionStorage.getItem('sessionState');

        // set as default header the sessionState
        axios.defaults.headers.common = {
          ...axios.defaults.headers.common,
          sessionState: sessionStateStorage,
        };

      }
    }
  }, [error, login]);
  

  return (

    <>
      <AuthenticatedTemplate>
        <Provider store={store}>
          <ReloadProvider>
            <Auth>
              <MsalProvider instance={pca}>
                <LoadingProvider>
                  <CompanyRelatedProvider>
                    <WrapperApp>
                      <BrowserRouter history={History}>
                        <NavigationProvider>
                          <StoreProvider>
                            <Routes>
                              <Route exact path="/" element={<MainLayout />}>
                                {routes}
                              </Route>
                            </Routes>
                          </StoreProvider>
                        </NavigationProvider>
                      </BrowserRouter>
                    </WrapperApp>
                  </CompanyRelatedProvider>
                </LoadingProvider>
              </MsalProvider>
            </Auth>
          </ReloadProvider>
        </Provider>
      </AuthenticatedTemplate>
    </>
  );
}

export default App;
