import React, { Fragment, useContext } from 'react'
import { faQuestion, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { getTranslation, useCustomTranslation } from '../../hooks/useTranslations';

import { CompanyInfo } from './../CompanyInfo/index';
import { Divider } from '../Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProfileCard } from './../ProfileCard/Index';
import { ReloadContext } from './../../context/ReloadProvider';
import { ToolBarNames } from '../../translations';
//import { Tooltip } from 'primereact/tooltip';
import { faWindowFlip } from '@fortawesome/pro-regular-svg-icons';
import logo from '../../assets/img/Logotipo.png'
import { useMsal } from '@azure/msal-react';

export const NavBar = ({
  showPopover,
  setShowPopover,
  refCloseProfileCard,
  refCloseCompanyInfo,
  showCompanyInfo,
  setShowCompanyInfo,
  setShowDialog,
  closeFunction
}) => {

  const { setReload } = useContext(ReloadContext)
  const onHelpAction = () => {
    const buttton = document.getElementsByClassName('inmplayer-trigger');
    return buttton[0].click();
  };
  // msaal instance
  const { t } = useCustomTranslation()
  const { instance } = useMsal();
  const onChangeCompany = () => {
    // get from local storage or from user profile if not exists
    const redirect = localStorage.getItem('ctiPortalURI') || instance.getConfiguration().auth.postLogoutRedirectUri;
    //delete all " from the url
    const url = redirect.replace(/\"/g, '');
    console.log(url)
    if (redirect) {

      // open the url in a new tab
      window.open(url, '_blank');
    }
  };

  const onRefreshAction = () => setReload(true)


  const items = [
    {
      id: 1,
      title: ToolBarNames.HELP,
      action: onHelpAction,
      icon: faQuestion
    },
    {
      id: 2,
      title: ToolBarNames.COMPANY,
      action: onChangeCompany,
      icon: faWindowFlip
    },
    {
      id: 3,
      title: ToolBarNames.REFRESH,
      action: onRefreshAction,
      icon: faSyncAlt
    },
  ]


  const renderIconsItemsNavBar = (item, key, action, icon) => {
    return (
      <div title={getTranslation(t, item.title)} className='flex h-full w-1/3' key={key}>
        {/* <Tooltip target={`.tooltip-${key}`} position='bottom'>
          {getTranslation(t, item.title)}
        </Tooltip> */}
        <div key={key} onClick={action} className={`tooltip-${key} flex-grow flex text-[#5e5e5e]  items-center content-center justify-center cursor-pointer hover:text-[#0a6ebd] hover:bg-[#5e5e5e1e]`}>
          <FontAwesomeIcon icon={icon} />
        </div>
      </div>

    )
  }

  return (
    <div className='z-50 w-full h-16  bg-white drop-shadow-md '>
      <div className="flex h-full w-full flex-row justify-between">
        <div className='flex w-4/5 flex-row h-full border'>
          <div className='flex w-[10%] pl-1 justify-center item-center align-bottom m-auto'>
            <img className="object-scale-down w-full h-12" src={logo} alt="Logo" />
          </div>
          <Divider className='mx-1' />
          <div className='flex h-full w-[90%] truncate '>
            <CompanyInfo
              refCloseCompanyInfo={refCloseCompanyInfo}
              showCompanyInfo={showCompanyInfo}
              setShowCompanyInfo={setShowCompanyInfo}
            />
          </div>
        </div>
        <div className='flex w-1/5 justify-around'>
          <div className='flex h-full w-5/12 border-border border-x'>
            {items.map((item, key) => {
              return (renderIconsItemsNavBar(item, key, item.action, item.icon))
            })}
          </div>
          <div className='flex h-full w-7/12  items-center content-center justify-center p-2   '>
            <ProfileCard
              showPopover={showPopover}
              setShowPopover={setShowPopover}
              refCloseProfileCard={refCloseProfileCard}
              setShowDialog={setShowDialog}
              closeFunction={closeFunction}
            />

          </div>
        </div>


      </div>
    </div>
  )
}