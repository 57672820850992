export const ERROR_SHOW = 'ERROR_SHOW';
export const CLEAR = 'CLEAR';
export const LOADING_ON = 'LOADING_ON';
export const LOADING_OFF = 'LOADING_OFF';
export const FILTER = 'FILTER';
//areaConstants
export const GET_AREA = 'GET_AREA';
export const GET_AREA_ERROR = 'GET_AREA_ERROR';
export const GET_AREA_SUCCESS = 'GET_AREA_SUCCESS';

//positionConstants
export const GET_POSITION = 'GET_POSITION';
export const GET_POSITION_ERROR = 'GET_POSITION_ERROR';
export const GET_POSITION_SUCCESS = 'GET_POSITION_SUCCESS';

//departmentConstants
export const GET_DEPARTMENT = 'GET_DEPARTMENT';
export const GET_DEPARTMENT_ERROR = 'GET_DEPARTMENT_ERROR';
export const GET_DEPARTMENT_SUCCESS = 'GET_DEPARTMENT_SUCCESS';

//evaluatorConstants
export const GET_EVALUATOR = 'GET_EVALUATOR';
export const GET_EVALUATOR_ERROR = 'GET_EVALUATOR_ERROR';
export const GET_EVALUATOR_SUCCESS = 'GET_EVALUATOR_SUCCESS';

//workCenterConstants
export const GET_WORK_CENTER = 'GET_WORK_CENTER';
export const GET_WORK_CENTER_ERROR = 'GET_WORK_CENTER_ERROR';
export const GET_WORK_CENTER_SUCCESS = 'GET_WORK_CENTER_SUCCESS';

export const GET_STATES = 'GET_STATES';
export const GET_STATES_ERROR = 'GET_STATES_ERROR';
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';

export const GET_CITIES = 'GET_CITIES';
export const GET_CITIES_ERROR = 'GET_CITIES_ERROR';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';

export const GET_SUBURB = 'GET_SUBURB';
export const GET_SUBURB_ERROR = 'GET_SUBURB_ERROR';
export const GET_SUBURB_SUCCESS = 'GET_SUBURB_SUCCESS';

//collaboratorConstants
export const GET_COLLABORATOR = 'GET_COLLABORATOR';
export const GET_COLLABORATOR_ERROR = 'GET_COLLABORATOR_ERROR';
export const GET_COLLABORATOR_SUCCESS = 'GET_COLLABORATOR_SUCCESS';

//surveyConstants
export const GET_SURVEY = 'GET_SURVEY';
export const GET_SURVEY_ERROR = 'GET_SURVEY_ERROR';
export const GET_SURVEY_SUCCESS = 'GET_SURVEY_SUCCESS';
export const GET_SURVEY_DETAIL = 'GET_SURVEY_DETAIL_SUCCESS';
export const GET_SURVEY_RESULT = 'GET_SURVEY_RESULT_SUCCESS';
export const GET_SURVEY_UPDATE = 'GET_SURVEY_UPDATE';
export const UPDATE_ADDITIONAL_DATA = 'UPDATE_ADDITIONAL_DATA';


//surveyCollaboratorConstants
export const GET_SURVEY_COLLABORATOR = 'GET_SURVEY_COLLABORATOR';
export const GET_SURVEY_COLLABORATOR_ERROR = 'GET_SURVEY_COLLABORATOR_ERROR';
export const GET_SURVEY_COLLABORATOR_SUCCESS = 'GET_SURVEY_COLLABORATOR_SUCCESS';

//questionConstants
export const GET_QUESTION = 'GET_QUESTION';
export const GET_QUESTION_ERROR = 'GET_QUESTION_ERROR';
export const GET_QUESTION_SUCCESS = 'GET_QUESTION_SUCCESS';
export const GET_QUESTION_EDIT = 'GET_QUESTION_EDIT';

// scoreRangeConstants
export const GET_SCORE_RANGE = 'GET_SCORE_RANGE';
export const GET_SCORE_RANGE_ERROR = 'GET_SCORE_RANGE_ERROR';
export const GET_SCORE_RANGE_SUCCESS = 'GET_SCORE_RANGE_SUCCESS';


// resultConstants

export const GET_RESULT = 'GET_RESULT';
export const GET_RESULT_ERROR = 'GET_RESULT_ERROR';
export const GET_RESULT_SUCCESS = 'GET_RESULT_SUCCESS';

// queryConstants
export const GET_QUERY = 'GET_QUERY';
export const GET_QUERY_ERROR = 'GET_QUERY_ERROR';
export const GET_QUERY_SUCCESS = 'GET_QUERY_SUCCESS';
export const ADD_QUERY_STATE = 'ADD_QUERY_STATE';
export const ADD_QUERY_STATE_SAVE = 'ADD_QUERY_STATE_SAVE';
export const ADD_QUERY_STATE_SAVE_ERROR = 'ADD_QUERY_STATE_SAVE_ERROR';
export const ADD_QUERY_STATE_SAVE_SUCCESS = 'ADD_QUERY_STATE_SAVE_SUCCESS';
export const GET_ENTITY = 'GET_ENTITY';
export const GET_ENTITY_ERROR = 'GET_ENTITY_ERROR';
export const GET_ENTITY_SUCCESS = 'GET_ENTITY_SUCCESS';
export const GET_QUERY_PROPERTIES = 'GET_QUERY_PROPERTIES';
export const GET_QUERY_PROPERTIES_ERROR = 'GET_QUERY_PROPERTIES_ERROR';
export const GET_QUERY_PROPERTIES_SUCCESS = 'GET_QUERY_PROPERTIES_SUCCESS';
export const GET_QUERY_DATA_SUCCESS = 'GET_QUERY_DATA_SUCCESS';
// companyConstants
export const GET_COMPANY = 'GET_COMPANY';
export const GET_COMPANY_ERROR = 'GET_COMPANY_ERROR';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
// queryPercistanceConstants
export const SET_QUERY_PERSISTANCE = 'SET_QUERY_PERSISTANCE';
export const CLEAR_QUERY_PERSISTANCE = 'CLEAR_QUERY_PERSISTANCE';
// importConstants
export const GET_IMPORT = 'GET_IMPORT';
export const GET_IMPORT_ERROR = 'GET_IMPORT_ERROR';
export const GET_IMPORT_SUCCESS = 'GET_IMPORT_SUCCESS';
export const GET_IMPORT_RESET = 'GET_IMPORT_RESET';
// importError
export const SET_IMPORT_ERROR_LIST = 'SET_IMPORT_ERROR_LIST';
export const CLEAR_IMPORT_ERROR_LIST = 'CLEAR_IMPORT_ERROR_LIST';