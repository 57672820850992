import React, { useContext } from 'react';

import { FullLoading } from './Components/FullLoading';
import { LoadingContext } from './context/LoadingContext';

const WrapperApp = ({children}) => {
    const {loading}= useContext(LoadingContext)
    //show={!loading?false:loading}
    //console.log("Loading",loading)
    //!loading?false:loading
    return (
        <>
             <FullLoading zIndex={9999} show={!loading?false:loading} />
             {children}
        </>
    );
};

export default WrapperApp;