import React from 'react'
import { Dialog } from "primereact/dialog"
import { BaseButton } from "../../../Components/Buttons/BaseButton"
import { getTranslation, useCustomTranslation } from '../../../hooks/useTranslations'
import { GenericsNames, QueryNames } from '../../../translations'
export const DiscardDialog = ({ discard, setDiscard, handlerAccept, handlerDiscard }) => {
    const {t} = useCustomTranslation()
    return (
        <Dialog visible={discard} header={getTranslation(t, QueryNames.QUERY_DISCARD_CHANGES)} onHide={() => setDiscard(false)}>
            <div className='flex gap-2'>
                <BaseButton className='w-full !mt-2' label={getTranslation(t, GenericsNames.ACCEPT)} onClick={() => handlerAccept()} />
                <BaseButton className='w-full !mt-2' label={getTranslation(t, GenericsNames.CANCEL)} onClick={() => { handlerDiscard()}} />
            </div>
        </Dialog>
    )
}