import { GET_COMPANY, GET_COMPANY_ERROR, GET_COMPANY_SUCCESS } from '../constants';

export const company = (state, actions) => {
    switch (actions.type) {
        case GET_COMPANY:
            return {
                ...state,
                loading: true,
                error: null,
                errorShow: false
            };
        case GET_COMPANY_ERROR:
            return {
                ...state,
                loading: false,
                error: actions.payload,
                errorShow: true
            };
        case GET_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                errorShow: false,
                companies: actions.payload
            };
        default:
            return state;
    }
}