import { GET_DEPARTMENT, GET_DEPARTMENT_ERROR, GET_DEPARTMENT_SUCCESS } from '../../business/constants';
import { useContext, useEffect, useState } from 'react'

import { IsNullOrEmpty } from './../../utils/index';
import { StoreContext } from '../../business/Provider';
//import { IsNullOrEmpty } from 'app/services/utilsService'
import axios from 'axios'
import { getLocalAccessToken } from '../../Auth/auth/token';
//import { getLocalAccessToken } from 'app/auth/token'
import { sortBy } from 'lodash'
import useApi from '../api';
import { getTranslation, useCustomTranslation } from '../useTranslations';
import { DepartmentsNames } from '../../translations';
import { Toast } from '../../Components/toast';
import { LoadingContext } from '../../context/LoadingContext';

const useDepartments = () => {
  const {setLoading} = useContext(LoadingContext);
  const baseUrl = `${window.REACT_APP_EVALUA_API_URI}/department`
  const [departments, setDepartments] = useState(undefined)
  const [departmentsOptions, setDepartmentsOptions] = useState(undefined)
  const [loadingDepartments, setLoadingEvaluator] = useState(false)
  const { genericApiCall } = useApi();
  const {t} = useCustomTranslation();
  const context = useContext(StoreContext);
  const getDepartmentsOptions = (Departments) => {
    const activeDepartments = Departments.filter((object) => {
      return IsNullOrEmpty(object.deleteBy)
    })
    return sortBy(activeDepartments, [
      function (o) {
        return o.name
      }
    ]).map((item) => ({ label: item.name, value: item.id }))
  }

  const getDepartmentList = async () => {
    try {
      setLoadingEvaluator(true)
      const response = await getDepartment()
      //console.log('data Departments->', response.data)
      setDepartments(response.data.data.departments)
      setDepartmentsOptions(getDepartmentsOptions(response.data.data.departments))
      setLoadingEvaluator(false)
    } catch (error) {
      //console.log('error Departments->', error)
      setDepartments([])
      setLoadingEvaluator(false)
    }
  }

  const postDepartment = async (evaluator) => {
    try{
      const result = await genericApiCall(baseUrl, 'POST', evaluator)
      if (result.status === 200) {
        Toast('success', getTranslation(t, DepartmentsNames.SUCCESS_ADD))
        return result.data.data.department
      } else {
        let message = {
          body: '',
          header: getTranslation(t, DepartmentsNames.ERROR_ADD)
        }
        if(result.message) message.body = result.message 
        context.dispatchDepartment({ type: GET_DEPARTMENT_ERROR, payload: message })
        return undefined
      }
    } catch (error) {
      Toast('warning',error.message, "", error)
      context.dispatchDepartment({ type: GET_DEPARTMENT_ERROR})
    }
  }

  const getDepartment = async () => {
    context.dispatchDepartment({ type: GET_DEPARTMENT })
    try{
      const result = await genericApiCall(baseUrl, 'GET')
      if (result.status === 200) {
        context.dispatchDepartment({ type: GET_DEPARTMENT_SUCCESS, payload: result.data.data.departments })
      } else {
        let message = {
          body: '',
          header: 'Error'
        }
        if(result.message) message.body = result.message 
        context.dispatchDepartment({ type: GET_DEPARTMENT_ERROR, payload: message })
      }
      return result
    } catch (error) {
      Toast('warning',error.message, "", error)
      context.dispatchDepartment({ type: GET_DEPARTMENT_ERROR})
    }
  }

  const putDepartment = async (departmentsData) => {
    try{
      const result = await genericApiCall(baseUrl, 'PUT', departmentsData)
      if (result.status === 200) {
        Toast('success', getTranslation(t, DepartmentsNames.SUCCESS_UPDATE))
        return result.data.data.department
      } else {
        let message = {
          body: '',
          header: getTranslation(t, DepartmentsNames.ERROR_UPDATE)
        }
        if(result.message) message.body = result.message
        context.dispatchDepartment({ type: GET_DEPARTMENT_ERROR, payload: message })
        return undefined
      }
    } catch (error) {
      Toast('warning',error.message, "", error)
      context.dispatchDepartment({ type: GET_DEPARTMENT_ERROR})
    }
  }

  const deleteDepartment = async (department) => {
    try{
      const result = await genericApiCall(baseUrl, 'DELETE', department)
      if (result.status === 200) {
        Toast('success', getTranslation(t, DepartmentsNames.SUCCESS_DELETE))
        return result.data.data
      } else {
        let message = {
          body: '',
          header: getTranslation(t, DepartmentsNames.ERROR_DELETE)
        }
        if(result.message) message.body = result.message
        context.dispatchDepartment({ type: GET_DEPARTMENT_ERROR, payload: message })
        return undefined
      }
    } catch (error) {
      Toast('warning',error.message, "", error)
      context.dispatchDepartment({ type: GET_DEPARTMENT_ERROR})
    }
  }

  const getNextCode = async () => {
    try{
      setLoading(true)
      const result = await  genericApiCall(`${baseUrl}/next-code`, 'get')
      setLoading(false)
      return result
    } catch (error) {
      setLoading(false)
      Toast('warning',error.message, "", error)
    }
  }

  useEffect(() => {
    if (!context.departmentState?.departments && context.departmentState.loading !== true && context.departmentState.error === null) {
      getDepartment()
    }
  }, [context.departmentState])

  return {
    setDepartments,
    departments,
    loadingDepartments,
    getDepartmentList,
    getDepartment,
    getNextCode,
    postDepartment,
    putDepartment,
    deleteDepartment,
    departmentsOptions
  }
}
export default useDepartments
