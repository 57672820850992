import { useEffect, useState } from 'react'
import { Toast } from '../../Components/toast'

const useCurrentCompanies = () => {

  const [currentCompany, setCurrentCompany] = useState(undefined)

  const getCurrentCompany = async () => {
      try {
        const sessionStateStorage = localStorage.getItem('companyInfo');
        const sessionObj = JSON.parse(sessionStateStorage);
        setCurrentCompany(sessionObj);
      } catch (error) {
        Toast('warning',error.data.data.message, "", error)
        setCurrentCompany({})
        setLoadingCompanies(false)
      }   
  }


  useEffect(() => {
 
    if(!currentCompany){
        getCurrentCompany()
    }
  }, [currentCompany])

  return {  
    currentCompany
  }
}
export default useCurrentCompanies
