import React, { Fragment } from 'react'
import { SubSidebarItem } from '../SubSidebarItem'

export const SubSidebar = ({ subSidebarData, current, close}) => {
  const { childrends} = subSidebarData
  // console.log('subSidebarData', subSidebarData)
  const validationFlexOrHidden = current && childrends?.length > 0 ? 'flex' : 'hidden'
 
  // console.log('flexOrHidden', flexOrHidden)

  const subSidebarItem = (item, key) => {
    return (<SubSidebarItem item={item} keyData={key} close={close} />)

  }
  const renderSidebarData = childrends?.length > 0 ? childrends.map((item, i) => {
    return (<Fragment key={i}>{subSidebarItem(item, i)}</Fragment>)
  }) : null

  return (<div className={"w-[224px] bg-menu-bg-default  absolute left-[90px] z-50 " + validationFlexOrHidden}>
    <ul className='flex flex-col w-[224px] h-full'>
      {subSidebarData !== undefined ?
        renderSidebarData
        : null}
    </ul>
  </div>)
}