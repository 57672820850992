import { CollaboratorsNames, GenericsNames } from '../../../translations';
import { GET_COLLABORATOR_SUCCESS } from '../../../business/constants';
import React, { useContext, useEffect, useState } from 'react'
import { getTranslation, useCustomTranslation } from './../../../hooks/useTranslations';

import { CollaboratorGrid } from './Components/CollaboratorGrid';
import { CollaboratorWizard } from './Components/CollaboratorWizard';
import { CollaboratorsDialog } from './Components/CollaboratorDialog';
import { CollaboratorsObject } from '../../../utils/Constanst/FormikConstanst/Collaborator/CollaboratorObject';
import { CollaboratorsSchema } from '../../../utils/Constanst/FormikConstanst/Collaborator/CollaboratorsSchema';
import { CompanyRelatedContext } from '../../../context/CompanyRelatedContext';
import { EvaluaAlertDialog } from '../../../Components/Dialogs/EvaluaAlertDialog';
import { IsNullOrEmpty } from '../../../utils';
import { LoadingContext } from '../../../context/LoadingContext';
import { StoreContext } from '../../../business/Provider';
import { WorkArea } from '../../../Components/WorkArea';
import useCollaborators from '../../../hooks/Collaborator/useCollaborators';
import useDepartments from '../../../hooks/Departaments/useDepartaments';
import useError from '../../../hooks/useErrors';
import { useFormik } from 'formik'
import usePosition from '../../../hooks/position/usePosition';
import useReload from '../../../hooks/useReload';
import useWorkCenters from '../../../hooks/WorkCenters/useWorkCenters';
import useZone from '../../../hooks/zone/useZone';

export const Collaborator = () => {
  const { t } = useCustomTranslation()
  const {
    loadingCollaborators,
    postCollaborators,
    putCollaborators,
    deleteCollaborators,
    getCollaborators
  } = useCollaborators()
  const { getZones } = useZone();
  const { getDepartment } = useDepartments();
  const { getPositions } = usePosition();
  const { getWorkCenters } = useWorkCenters();
  const { collaboratorState, areaState, departmentState, workCenterState, positionState, dispatchCollaborator } = useContext(StoreContext);
  const [collaborators, setCollaborators] = useState([]);
  const [zones, setZones] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [workCenters, setWorkCenters] = useState([]);
  const [positions, setPositions] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  /** Reload **/
  useReload([getCollaborators])


  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [openWizard, setOpenWizard] = useState(false)
  const [titlePopUp, setTitlePopUp] = useState('')
  const loadingObj = useContext(LoadingContext);
  const { relatedCompanies } = useContext(CompanyRelatedContext);
  const { } = useError(collaboratorState, dispatchCollaborator);
  useEffect(() => {
    //console.log('collaborators',collaboratorState)
    if (collaboratorState.loading === false && collaboratorState.collaborators?.length > 0) {
      setCollaborators(collaboratorState.collaborators);
    }
  }, [collaboratorState])

  useEffect(() => {
    //console.log('area State',areaState)
    if (areaState.loading === false && areaState.areas?.length > 0) {
      setZones(areaState.areas);
    }
  }, [areaState])

  useEffect(() => {
    if (departmentState.loading === false && departmentState.departments?.length > 0) {
      setDepartments(departmentState.departments);
    }
  }, [departmentState])

  useEffect(() => {
    if (workCenterState.loading === false && workCenterState.workCenter?.length > 0) {
      setWorkCenters(workCenterState.workCenter);
    }
  }, [workCenterState])

  useEffect(() => {
    if (positionState.loading === false && positionState.positions?.length > 0) {
      setPositions(positionState.positions);
    }
  }, [positionState])

  const handleClose = () => {
    formik.resetForm()
    formik.setErrors({})
    setOpenDialog(false)
    setOpenWizard(false)
  }
  const toggleModal = () => setOpenDialog(!openDialog)
  const toggleWizard = () => setOpenWizard(!openWizard)
  const toggleConfirmation = () => setOpenConfirmation(!openConfirmation)


  const handleSaveFunction = (isNew, item) => {
    getCollaborators()
    setOpenDialog(false)
    setOpenWizard(false)
  }

  const addCollaborators = async (Collaborators) => {
    return postCollaborators(Collaborators);
  }

  const updateCollaborators = async (Collaborators) => {
    const body = Collaborators
    body.id = Collaborators.collaboratorId
    console.log('body ===========================', body)
    return putCollaborators(body)
  }

  const addOrUpdateDepartments = async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
    try {
      loadingObj.setLoading(true);
      let response;
      const isNew = IsNullOrEmpty(values.collaboratorId)
      if (isNew) {
        response = await addCollaborators(values)
      } else {
        response = await updateCollaborators(values)
      }
      loadingObj.setLoading(false);
      if (response !== undefined) {
        handleSaveFunction(isNew, response)
        resetForm()
        setStatus({ success: true })
        setSubmitting(false)
      }
    } catch (err) {
      console.error(err)
      loadingObj.setLoading(false);
      setStatus({ success: false })
      setSubmitting(false)
    }
  }

  const handleDeleteCollaborator = async (values) => {
    return await deleteCollaborators(values)
  }
  const deleteWorkCenterGrid = async () => {
    try {
      loadingObj.setLoading(true);
      toggleConfirmation()
      const result = await handleDeleteCollaborator(formik.values)
      if (result !== undefined) {
        const filteredCollaborator = collaborators.filter(
          (CollaboratorItem) => CollaboratorItem.collaboratorId !== formik.values.collaboratorId
        )
        setCollaborators(filteredCollaborator)
        dispatchCollaborator({ type: GET_COLLABORATOR_SUCCESS, payload: filteredCollaborator })
      }
      loadingObj.setLoading(false);
    } catch (error) {
      loadingObj.setLoading(false);
    }

  }
  const formik = useFormik({
    initialValues: { ...CollaboratorsObject },
    validationSchema: CollaboratorsSchema,
    onSubmit: addOrUpdateDepartments
  })

  const handleOnEdit = (row) => {
    setTitlePopUp(getTranslation(t, CollaboratorsNames.DIALOG_EDIT))
    setActiveTab(0)
    formik.setValues(row)
    toggleModal()
  }

  const handleOnDelete = (row) => {
    formik.setValues(row)
    toggleConfirmation()
  }
  const handleOnAdd = () => {
    setTitlePopUp(getTranslation(t, CollaboratorsNames.DIALOG_ADD))

    setActiveTab(0)
    const newCollaborators = { ...CollaboratorsObject }
    formik.setValues({ ...newCollaborators })

    toggleWizard()
  }

  return (
    <>
      <EvaluaAlertDialog
        title={getTranslation(t, GenericsNames.CONFIRM)}
        message={getTranslation(t, CollaboratorsNames.CONFIRM_MESSAGE_DELETE)}
        open={openConfirmation}
        setOpen={toggleConfirmation}
        cancelLabel={getTranslation(t, GenericsNames.CANCEL)}
        agreeLabel={getTranslation(t, GenericsNames.ACCEPT)}
        onConfirmFunction={deleteWorkCenterGrid}
      />
      <CollaboratorWizard
        title={titlePopUp}
        open={openWizard}
        onClose={handleClose}
        activeStep={activeTab}
        setActiveStep={setActiveTab}
        formik={formik}
        departments={departments}
        zones={zones}
        workCenters={workCenters}
        positions={positions}
      />
      <CollaboratorsDialog
        title={titlePopUp}
        formik={formik}
        open={openDialog}
        onClose={handleClose}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        departments={departments}
        zones={zones}
        workCenters={workCenters}
        positions={positions}
        hasRelationWithPersonia={relatedCompanies && relatedCompanies.length > 0}
      // title={titlePopUp}
      />
      <WorkArea grid={
        <>
          <CollaboratorGrid
            text={getTranslation(t, CollaboratorsNames.EMPTY_COLLABORATOR)}
            onEdit={handleOnEdit}
            onDelete={handleOnDelete}
            loading={collaboratorState.loading}
            rows={collaborators}
            onAdd={handleOnAdd}
            hasRelationWithPersonia={relatedCompanies && relatedCompanies.length > 0}
          />
        </>} />

    </>
  )
}