import React, { useContext, useEffect, useState } from 'react'
import { CollaboratorsNames, GenericsNames, NavigationNames, QuestionaryCollaboratorsNames, SurveyCollaboratorsNames, SurveyNames, SurveysQuestionsNames } from "../../translations";
import { EntityToExport, ToExcel, ToPdf } from "../../utils/exportService";
import { faEnvelope, faEye, faPenAlt, faShare, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { getTranslation, useCustomTranslation } from "../../hooks/useTranslations";
import { useLocation, useParams } from 'react-router-dom';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Column } from 'primereact/column';
import CustomDropdown from '../../Components/Input/Dropdown';
import { CustomIconButton } from '../../Components/Buttons/CustomIconButton';
import { EvaluaAlertDialog } from "../../Components/Dialogs/EvaluaAlertDialog";
import { EvaluaTable } from '../../Components/Table/EvaluaTable';
import { FilterMatchMode } from 'primereact/api';
import { LoadingContext } from "../../context/LoadingContext";
import { NavigationContext } from '../../context/navigationContext';
import { SUCCESS } from '../../utils/Constanst/ResponseStatus';
import { StoreContext } from "../../business/Provider";
import { SurveyDialog } from '../../Components/Wizard/surveyDialog';
import { SurveyObject } from "../Survey/constants/SurveyObject";
import { SurveySchema } from "../Survey/constants/SurveySchema";
import { TableButton } from '../../Components/Buttons/TableButtons';
import { Toast } from "../../Components/toast";
import ToolBarThreeColumns from '../../Components/Table/ToolBarThreeColumns';
import useDepartments from "../../hooks/Departaments/useDepartaments";
import { useFormik } from "formik";
import useGuideSurveys from "../../hooks/surveys/useGuideSurveys";
import useQuestionnaireGuide from "../../hooks/surveys/useQuestionnaireGuide";
import useReload from '../../hooks/useReload';
import useWorkCenters from "../../hooks/WorkCenters/useWorkCenters";
import { convertDate } from "../../utils/utils";
import moment from "moment";

export const SurveyCollaboratorComponent = () => {
  const {
    departmentState,
    workCenterState,
    surveyCollaboratorState,
    surveysState,
    dispatchLoading } = useContext(StoreContext)

  const { guideId } = useParams();
  const navigation = useContext(NavigationContext)
  const { t } = useCustomTranslation();
  const location = useLocation();
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [collaboratorsList, setCollaboratorsList] = useState([]);
  const [update, setUpdate] = useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [sort, setSort] = useState([{ field: '', order: -1 }])
  const [titlePopUp, setTitlePopUp] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCollaborators, setSelectedCollaborators] = React.useState(null)
  const [collaboratorsSearh, setColloboratorSearch] = useState([])
  const [openDialogView, setOpenDialogView] = useState(false);
  const [uri, setUri] = useState('');
  const [toSend, setToSend] = useState([]);
  const [initialValue, setInitialValue] = useState();
  const [filterList, setFilterList] = useState(null);
  const [sendEmailResponse, setSendEmailResponse] = useState(null);
  const [selectFilter, setSelectFilter] = useState({
    status: 0,
    department: getTranslation(t, QuestionaryCollaboratorsNames.ALL),
    WorkArea: getTranslation(t, QuestionaryCollaboratorsNames.ALL),
  });
  const [rowDataValue, setRowDataValue] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [survey, setSurvey] = useState(null);
  const [filterOpc, setFilterOpc] = useState({
    status: [
      { label: getTranslation(t, QuestionaryCollaboratorsNames.ALL), value: 0 },
      { label: getTranslation(t, QuestionaryCollaboratorsNames.RESPONDED), value: 1 },
      { label: getTranslation(t, QuestionaryCollaboratorsNames.CANCEL), value: 2 },
      { label: getTranslation(t, QuestionaryCollaboratorsNames.SEND), value: 3 },
      { label: getTranslation(t, QuestionaryCollaboratorsNames.COMPLETED), value: 4 },
    ],
    department: [
      { name: getTranslation(t, QuestionaryCollaboratorsNames.ALL), value: 0 },
    ],
    workArea: [
      { name: getTranslation(t, QuestionaryCollaboratorsNames.ALL), value: 0 },
    ],
  });

  const toggleModal = () => setOpenDialog(!openDialog);
  const [company, setCompany] = useState(null);
  const { getCollaborators, putCancelStatus, postSendEmail, deleteSurveyCollaborator, putShare } = useQuestionnaireGuide(guideId);
  const { getSurveyDetails, putSurveys } = useGuideSurveys(guideId);
  const { getDepartment } = useDepartments();
  const { getWorkCenters } = useWorkCenters();
  const [departments, setDepartments] = useState([]);
  const [workCenters, setWorkCenters] = useState([]);
  const [attemp, setAttemp] = useState(1);
  const loadingObj = useContext(LoadingContext)
  const BAJA = 'B';


  useEffect(() => {
    if (departments.length === 0 && attemp < 3) {
      getDepartment()
      setAttemp(attemp + 1)
    }
  }, [departments])

  useEffect(() => {
    if (departmentState?.departments) {
      setDepartments(departmentState?.departments)
    }
  }, [departmentState])

  useEffect(() => {
    if (workCenterState?.workCenter) {
      setWorkCenters(workCenterState?.workCenter)
    }
  }, [workCenterState])
  useEffect(() => {
    if (surveyCollaboratorState?.loading === false) {
      setCollaboratorsList(surveyCollaboratorState?.surveyCollaborator)
    }
  }, [surveyCollaboratorState])
  useEffect(() => {
    if (location.state?.id) {
      setInitialValue(location.state);
      localStorage.setItem('questionaryGuide-' + guideId, JSON.stringify(location.state));
      //SET COMPANY FROM companyName IN LOCALSTORAGE
      setCompany(JSON.parse(localStorage.getItem('companyInfo')));
    } else {
      const localStorageData = localStorage.getItem('questionaryGuide-' + guideId);
      setCompany(JSON.parse(localStorage.getItem('companyInfo')));
      if (localStorageData) {
        setInitialValue(JSON.parse(localStorageData));
      }
    }
  }, [guideId]);

  useEffect(() => {
    if (sendEmailResponse && sendEmailResponse.status === SUCCESS) {
      Toast('success', getTranslation(t, SurveysQuestionsNames.surveyMessageSuccess))
      getList();

      setUpdate(false);
    } else if (sendEmailResponse && sendEmailResponse.status === 'error') {
      Toast('warning', getTranslation(t, SurveysQuestionsNames.surveyMessageError))
    }
  }, [sendEmailResponse]);

  useEffect(() => {
    if (departments) {
      const departmentsList = [...departments, ...filterOpc.department];
      setFilterOpc({ ...filterOpc, department: departmentsList });
    }
  }, [departments]);

  useEffect(() => {
    if (workCenters) {
      const workCentersList = [...workCenters, ...filterOpc.workArea];
      setFilterOpc({ ...filterOpc, workArea: workCentersList });
    }
  }, [workCenters]);

  useEffect(() => {
    if ((initialValue && surveyCollaboratorState?.surveyId !== initialValue.id) || surveyCollaboratorState?.guideId !== guideId) {
      getList();
    }
  }, [initialValue]);
  useEffect(() => {
    if (surveysState?.loading === false && initialValue && surveysState?.surveys[guideId]) {
      const id = initialValue?.id;
      const index = surveysState?.surveys[guideId].findIndex(surveyValue => surveyValue.id === id);
      setSurvey(surveysState.surveys[guideId][index])
    }
  }, [surveysState, initialValue]);

  useEffect(() => {
    if (update) {
      getList();
      setUpdate(false);
    }
  }, [update]);

  useEffect(() => {
    if (collaboratorsList) {
      filterListFunc(collaboratorsList);
    }
  }, [selectFilter, collaboratorsList]);

  const getList = async () => {
    try {
      setCollaboratorsList(null);
      setToSend(null);
      await getCollaborators(initialValue?.id);
    } catch (error) {
      console.log(error);
    }
  };

  useReload([getCollaborators, getList, getWorkCenters, getDepartment]);
  const statusTemplate = (e) => {
    const cancelStatus =
      e.cancelDate === null
        ? getTranslation(t, QuestionaryCollaboratorsNames.COMPLETED)
        : getTranslation(t, QuestionaryCollaboratorsNames.CANCEL);
    const answerStatus = e.answerDate === null ? getTranslation(t, QuestionaryCollaboratorsNames.RESPONDED) : cancelStatus;
    const status = e.sentDate === null ? getTranslation(t, QuestionaryCollaboratorsNames.SEND) : answerStatus;
    return <div>{status}</div>;
  };

  const onGlobalFilterChange = (e) => {
    const { value } = e.target;
    const _filters = { ...filters };
    _filters.global.value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setSelectFilter({
      ...selectFilter,
      [name]: value,
    });
  };
  const filterListFunc = (list) => {
    let filterListTemp = list;
    if (selectFilter.status !== '') {
      switch (selectFilter.status) {
        case 1:
          filterListTemp = filterListTemp.filter((e) => e.answerDate === null && e.sentDate !== null);
          break;
        case 2:
          filterListTemp = filterListTemp.filter((e) => e.cancelDate !== null);
          break;
        case 3:
          filterListTemp = filterListTemp.filter((e) => e.sentDate === null && e.answerDate === null);
          break;
        case 4:
          filterListTemp = filterListTemp.filter((e) => e.sentDate !== null && e.answerDate !== null && e.cancelDate === null);
      }
    }
    if (selectFilter.department !== '') {
      if (selectFilter.department !== getTranslation(t, QuestionaryCollaboratorsNames.ALL)) {
        filterListTemp = filterListTemp.filter((e) => e.departmentName === selectFilter.department);
      }
    }
    if (selectFilter.WorkArea !== '') {
      if (selectFilter.WorkArea !== getTranslation(t, QuestionaryCollaboratorsNames.ALL)) {
        filterListTemp = filterListTemp.filter((e) => e.workcenterName === selectFilter.WorkArea);
      }
    }
    if (list.length === filterListTemp.length) {
      setFilterList(null);
    } else {
      setFilterList(filterListTemp);
    }
  };
  //Este toast no era necesario ya que se hace desde useGuideSurveys.js por eso se duplicaba 
  const updateSurvey = async (Survey) => {
    loadingObj.setLoading(true);
    const response = await putSurveys(Survey)
    await getCollaborators(Survey.id);
    loadingObj.setLoading(false);
    return response
  }

  const handlerSendEmail = async (survey) => {
    if (collaboratorsList.length > 0) {
      let response = null
      loadingObj.setLoading(true);
      try {
        if (survey === null) {
          response = await postSendEmail(initialValue.id, toSend);
          loadingObj.setLoading(false);
          setSendEmailResponse(response.data);
        } else {
          const name = survey.name + ' ' + survey.lastName;

          if (survey.email === null || survey.email === '') {
            const message = 'El colaborador ' + name + '  no tiene email, debe capturarlo antes de enviar la encuesta'
            Toast('warning', message)
          } else if (survey.departmentId === null || survey.departmentId === '') {
            const message = 'El colaborador ' + name + '  no tiene departamento, debe capturarlo antes de enviar la encuesta'
            Toast('warning', message)
          } else {
            response = await postSendEmail(initialValue.id, [survey]);
            loadingObj.setLoading(false);
            setSendEmailResponse(response.data);
          }
        }

      } catch (error) {
        loadingObj.setLoading(false);
      }
    } else {
      Toast('warning', getTranslation(t, GenericsNames.DONT_SEND_EMAIL))
    }
  };
  const setHeaderValues = (values) => {
    let surveyCopy = survey;
    surveyCopy.surveyCode = values.scode;
    surveyCopy.name = values.name;
    surveyCopy.startDate = values.startDate;
    surveyCopy.endDate = values.endDate;
    surveyCopy.conclusion = values.conclusion;
    setSurvey(surveyCopy);

  };
  const addOrUpdateSurvey = async (values, { resetForm, _setErrors, setStatus, setSubmitting }) => {
    loadingObj.setLoading(true);
    try {
      let response;
      response = await updateSurvey(values);
      if (response !== undefined) {

        resetForm();
        setStatus({ success: true });
        setSubmitting(false);
        setOpenDialog(false);
        setHeaderValues(values);
      }
      loadingObj.setLoading(false);
    } catch (err) {
      setStatus({ success: false });
      setSubmitting(false);
      loadingObj.setLoading(false);
    }
  }
  const formik = useFormik({
    initialValues: { ...SurveyObject },
    validationSchema: SurveySchema,
    onSubmit: addOrUpdateSurvey,
  });
  const handleOnEdit = async (row) => {
    setTitlePopUp(getTranslation(t, SurveyNames.EDIT_SURVEY))
    setActiveStep(0);
    setSelectedCollaborators(null)
    setColloboratorSearch([])
    const response = await getSurveyDetails(row.id)
    const surveyDetails = response
    surveyDetails.searchStartDate = null;
    surveyDetails.searchEndDate = null;
    await formik.setValues(surveyDetails)
    toggleModal()
  };
  const handleClose = () => {
    formik.setErrors({})
    formik.resetForm()
    setOpenDialog(false)
  }
  const handlerDeleteorCancel = async (row) => { //Este metodo funciona para borrar colaborador
    const ids = [row.collaboratorId];
    if (row.answerDate === null) {
      await deleteSurveyCollaborator(row.surveyId, ids);
      setUpdate(true);
    } else {
      await putCancelStatus(row.surveyId, { collaboratorIds: ids }, row.surveyCollaboratorId);
      setUpdate(true);
    }
  };

  const tittle = `${initialValue?.guideName}`;
  const nameCompany = `${company?.name}`;


  //convert 07/08/2022 to date object

  const valueStartDate = convertDate(survey?.startDate);
  const valueEndDate = convertDate(survey?.endDate);



  const mainHeader = () => {
    return (
      <div className='w-full h-32 font-font-family-base text-sm mt-2 flex flex-col'>
        <div className=" flex justify-center">
          <p className='font-semibold flex uppercase'>{tittle}</p>
        </div>
        <div className=" flex justify-center">
          <p className='font-semibold flex uppercase'>{nameCompany}</p>
        </div>
        <div className="flex justify-between pr-1">
          <div className="flex">
            <p className='font-semibold pl-3'>Clave de encuesta:</p>
            <p className='font-base pl-2'> {initialValue?.surveyCode} </p>
            <p className='font-semibold pl-3'>Nombre de encuesta:</p>
            <p className='font-base pl-2'> {survey?.name} </p>
            <p className='font-semibold pl-3'>Fecha Inicio:</p>
            <p className='font-base pl-2'>{moment(valueStartDate).format('DD/MM/YYYY')}</p>
            <p className='font-semibold pl-3'>Fecha fin:
            </p>
            <p className='font-base pl-2'>{moment(valueEndDate).format('DD/MM/YYYY')}</p>
          </div>
          <CustomIconButton
            toolTip={getTranslation(t, GenericsNames.EDIT)}
            icon={faPenAlt}
            onClick={() => handleOnEdit(initialValue)}
            size="small"
          />
        </div>
        <div className="w-full flex-grow relative">
          <div className="w-full absolute top-0 bottom-0 overflow-y-auto ">
            <div className="flex">
              <p className='font-semibold flex px-3'>
                {`${getTranslation(t, SurveyNames.CONCLUSION)}:`}
              </p>
              <p className="font-base">{survey?.conclusion || <span className='opacity-60'> {getTranslation(t, SurveyNames.WITHOUT_CONCLUSION)}</span>}</p>
            </div>
          </div>

        </div>
      </div>
    );
  };
  const renderFilter = () => {
    return (
      <div className='flex w-full'>
        <CustomDropdown
          name="status"
          value={selectFilter.status}
          handler={handleFilterChange}
          options={filterOpc.status}
          optionLabel="label"
          optionValue="value"
          className='border-r border-border flex-grow'
          label={getTranslation(t, SurveyCollaboratorsNames.STATUS)}
        />
        <CustomDropdown
          name="department"
          value={selectFilter.department}
          handler={handleFilterChange}
          options={filterOpc.department}
          optionLabel="name"
          optionValue="name"
          className='border-r border-border flex-grow'
          label={getTranslation(t, GenericsNames.DEPARTMENT)}
        />
        <CustomDropdown
          name="WorkArea"
          value={selectFilter.WorkArea}
          handler={handleFilterChange}
          options={filterOpc.workArea}
          optionLabel="name"
          optionValue="name"
          className=' flex-grow'
          label={getTranslation(t, GenericsNames.WORK_CENTER)}
        />

      </div>
    )
  };
  const size = {
    middle: `col-span-4  md:col-span-4 lg:col-span-4 2xl:col-span-4`,
    left: `col-span-4  md:col-span-4 lg:col-span-4 2xl:col-span-4`,
    right: `col-span-2  md:col-span-2 lg:col-span-2 2xl:col-span-2`,
  }
  const handleExcelExport = async () => {
    const printObj = { ...filters, sort: sort[0] };
    printObj.surveyId = initialValue?.id;
    collaboratorsList.length > 0 ? await ToExcel(printObj, EntityToExport.GUIDE_SURVEYS_COLLABORATORS, dispatchLoading) : Toast('warning', getTranslation(t, GenericsNames.NO_EXPORT_DATA))
  }
  const handlePDFExport = async () => {
    const printObj = { ...filters, sort: sort[0] };
    printObj.surveyId = initialValue?.id;
    collaboratorsList.length > 0 ? await ToPdf(printObj, EntityToExport.GUIDE_SURVEYS_COLLABORATORS, dispatchLoading) : Toast('warning', getTranslation(t, GenericsNames.NO_EXPORT_DATA))
  }
  const renderHeader = () => (
    <>
      <ToolBarThreeColumns
        size={size}
        value={globalFilterValue}
        setValue={setGlobalFilterValue}
        setFilters={setFilters}
        onChange={onGlobalFilterChange}
        placeholder={getTranslation(t, GenericsNames.SEARCH)}
        onExcelClick={() => {
          handleExcelExport();
        }}
        onPDFClick={() => {
          handlePDFExport()
        }}
        rightChildren={
          <CustomIconButton
            disabled={toSend?.length === 0 || !toSend}
            toolTip={getTranslation(t, GenericsNames.SEND_EMAIL)}
            onClick={() => handlerSendEmail(null)}
            icon={faEnvelope}
            className='!m-1'
          />
        }
        leftChildren={renderFilter()}
      ></ToolBarThreeColumns></>
  );
  const handlerCopy = async (rowData) => {
    // copy to clipboard
    try {
      loadingObj.setLoading(true);
      const url = await putShare(rowData.surveyId, rowData.surveyCollaboratorId, rowData.surveycollaboratorkey, rowData.collaboratorId)
      loadingObj.setLoading(false);
      setUri(url.data.data.Link)
      setOpenDialogView(true)
    } catch (error) {
      Toast('error', error.message);
      loadingObj.setLoading(false);
    }
  };
  const onCopy = async () => {
    navigator.clipboard.writeText(uri);
    loadingObj.setLoading(true);
    await getCollaborators(initialValue.id);
    loadingObj.setLoading(false);
    Toast('success', getTranslation(t, GenericsNames.COPIED));
  }
  const goToCollaboratorQuestionary = (rowData) => {

    const title = `${getTranslation(t, NavigationNames.RESULTS)} ${rowData.SurveyCollaboratorCode}`;
    navigation.selectTap(`/guide-${guideId}/collaboratorQuestionary/${rowData.surveyId}/${rowData.surveyCollaboratorId}`, {}, title);
  }


  const actionBodyTemplate = (rowData) => {
    return (
      <>

        {rowData.answerDate !== null ? (
          <TableButton
            onClick={() => { goToCollaboratorQuestionary(rowData) }}
            icon={faEye}
            toolTip={getTranslation(t, GenericsNames.VIEW)}
          />
        ) : null}
        {rowData.answerDate === null ? (
          <TableButton
            onClick={() => {
              handlerSendEmail(rowData);
            }}
            icon={faEnvelope}
            toolTip={rowData.status === BAJA ? getTranslation(t, SurveyCollaboratorsNames.FIRED) : getTranslation(t, GenericsNames.SEND_EMAIL)}
            disabled={rowData.status === BAJA}
          />
        ) : null}
        {rowData.answerDate === null ? (
          <TableButton
            onClick={() => handlerCopy(rowData)}
            icon={faShare}
            toolTip={rowData.status === BAJA ? getTranslation(t, SurveyCollaboratorsNames.FIRED) : getTranslation(t, GenericsNames.SHARE)}
            disabled={rowData.status === BAJA}
          />
        ) : null}
        <TableButton
          onClick={() => {
            setRowDataValue(rowData);
            setShowAlert(true);
          }}
          icon={faTrashAlt}
          toolTip={getTranslation(t, GenericsNames.DELETE)}
        />
      </>
    );
  };

  const renderDataTable = () => (
    <EvaluaTable
      value={filterList === null ? collaboratorsList : filterList}
      sortField="name"
      header={renderHeader()}
      loading={workCenterState.loading || departmentState.loading || surveyCollaboratorState.loading || surveysState.loading}
      filters={filters}
      responsiveLayout="scroll"
      globalFilterFields={['surveyId', 'name', 'departmentName', 'workcenterName', 'email', 'status']}
      emptyMessage={getTranslation(t, CollaboratorsNames.GRID_EMPTY)}
      currentPageReportTemplate={getTranslation(t, CollaboratorsNames.GRID_PAGE_REPORT_TEMPLATE)}
      resizableColumns
      columnResizeMode="fit"
      style={{ height: 'calc(100vh - 15.3rem)', width: 'calc(100vw - 92px)' }}
      selection={toSend}
      selectionMode="checkbox"
      onSelectionChange={e => setToSend(e.value)}
      isDataSelectable={(rowData) => rowData.data.answerDate === null}
      setSort={setSort}
      sort={sort}
    >
      <Column selectionMode="multiple" style={{ minWidth: '10px' }}></Column>
      <Column
        headerClassName='hover:!text-success !border-border !border-r'
        style={{ flexGrow: 1, flexBasis: '100px' }}
        field="SurveyCollaboratorCode"
        header={getTranslation(t, SurveyCollaboratorsNames.QUESTIONNAIRE_ID)}
        sortable />
      <Column
        headerClassName='hover:!text-success !border-border !border-r'
        style={{ flexGrow: 1, flexBasis: '200px' }}
        body={(e) => <div> {`${e.name} ${e.lastName} ${e.surName === null ? '' : e.surName}`}</div>}
        field="name"
        header={getTranslation(t, SurveyCollaboratorsNames.COLLABORATOR_NAME)}
        sortable
      />
      <Column
        headerClassName='hover:!text-success !border-border !border-r'
        style={{ flexGrow: 1, flexBasis: '100px' }}
        field="departmentName"
        header={getTranslation(t, SurveyCollaboratorsNames.DEPARTMENT)}
        sortable />
      <Column
        headerClassName='hover:!text-success !border-border !border-r'
        style={{ flexGrow: 1, flexBasis: '100px' }}
        field="workcenterName"
        header={getTranslation(t, SurveyCollaboratorsNames.WORK_CENTER)}
        sortable />
      <Column
        headerClassName='hover:!text-success !border-border !border-r'
        style={{ flexGrow: 1, flexBasis: '100px' }}
        field="email"
        header={getTranslation(t, SurveyCollaboratorsNames.EMAIL)}
        sortable />
      <Column
        headerClassName='hover:!text-success !border-border !border-r'
        style={{ flexGrow: 1, flexBasis: '50px' }}
        field="status"
        body={(e) => statusTemplate(e)}
        header={getTranslation(t, SurveyCollaboratorsNames.STATUS)}
        sortable
      />

      {guideId == 1 && //esta columna solo debe mostrarse para la gía 1
        <Column
          headerClassName='hover:!text-success !border-border !border-r'
          style={{ flexGrow: 1, flexBasis: '50px' }}
          field='response'
          header={getTranslation(t, SurveyNames.RESPONSE)}
          align='center'
        />
      }

      <Column
        style={{ flexGrow: 1, flexBasis: '50px' }}
        header={getTranslation(t, SurveyNames.GRID_ACTION)} //Este es el que funciona
        headerStyle={{ width: '4rem', textAlign: 'center' }}
        bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
        body={actionBodyTemplate}
      />
    </EvaluaTable>
  );


  const renderAlert = () => {
    return (
      <ConfirmDialog
        visible={showAlert}
        onHide={() => setShowAlert(false)}
        message="¿Estás seguro que quieres eliminar el colaborador?"
        header="Alerta"
        icon="pi pi-exclamation-triangle"
        accept={() => handlerDeleteorCancel(rowDataValue)}
        reject={() => setShowAlert(false)}
        acceptLabel="Si"
      />
    )
  }


  return (
    <div className='w-full h-full relative'>
      {showAlert ? renderAlert() : null}
      {mainHeader()}
      {renderDataTable()}
      {
        openDialog ? (
          <SurveyDialog
            title={titlePopUp}
            formik={formik}
            open={openDialog}
            onClose={handleClose}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            selectedCollaborators={selectedCollaborators}
            setSelectedCollaborators={setSelectedCollaborators}
            collaboratorsSearh={collaboratorsSearh}
            setColloboratorSearch={setColloboratorSearch}
          />
        )
          : null
      }

      <EvaluaAlertDialog
        title={getTranslation(t, GenericsNames.COPY)}
        setOpen={setOpenDialogView}
        open={openDialogView}
        message={
          <input disabled value={uri} className='w-full' />
        }
        cancelLabel={getTranslation(t, GenericsNames.CANCEL)}
        agreeLabel={getTranslation(t, GenericsNames.COPY)}
        agreeIcon="pi pi-copy"
        onConfirmFunction={onCopy}
        style={{ width: '70vw' }}
      />
    </div>
  );
};
