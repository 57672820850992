import React, { useContext, useEffect, useState } from 'react'
import CustomDropdown from '../../Components/Input/Dropdown'
import { getTranslation, useCustomTranslation } from '../../hooks/useTranslations'
import { ImportNames } from '../../translations'
import { fileTypes } from './const'
import { Toast } from '../../Components/toast'
import useApi from '../../hooks/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import { LoadingContext } from '../../context/LoadingContext'
import { CompanyRelatedContext } from '../../context/CompanyRelatedContext';
import { Wizard } from './components/wizard'
import { Table } from './components/table'
import { validate } from './validations'
import useDepartments from '../../hooks/Departaments/useDepartaments'
import useZone from '../../hooks/zone/useZone'
import usePosition from '../../hooks/position/usePosition'
import useWorkCenters from '../../hooks/WorkCenters/useWorkCenters'
import { StoreContext } from '../../business/Provider'
import useEvaluator from '../../hooks/elevator/useElevator'
import { downloadFile } from '../../utils/utils'
import useCollaborators from '../../hooks/Collaborator/useCollaborators'
import { CLEAR_IMPORT_ERROR_LIST, GET_IMPORT_RESET, GET_IMPORT_SUCCESS } from '../../business/constants'
export const Import = () => {
  const { t } = useCustomTranslation();
  // dropdown to select the type of import
  const { setLoading } = useContext(LoadingContext)
  const baseUrl = `${window.REACT_APP_EVALUA_API_URI}/import`;
  const { relatedCompanies } = useContext(CompanyRelatedContext);
  const { genericApiCall } = useApi();
  const { getDepartment } = useDepartments()
  const { getZoneList } = useZone()
  const { getPositions } = usePosition()
  const { getWorkCenters } = useWorkCenters()
  const { getEvaluator } = useEvaluator()
  const { getCollaborators } = useCollaborators()
  const context = useContext(StoreContext);
  const [importType, setImportType] = useState(null)
  const [importTypeOptions, setImportTypeOptions] = useState([])
  const [file, setFile] = useState(null)
  const [errors, setErrors] = useState({})
  const [fileName, setFileName] = useState(null)
  const [activeStep, setActiveStep] = useState(0)
  const [data, setData] = useState([{ name: 'test' }])
  const [dataErrors, setDataErrors] = useState({})
  const [valueImport, setValueImport] = useState({ value: false, label: getTranslation(t, ImportNames.IMPORT_TEXT) })
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    //for any value in object fileTypes, set the value to the key
    const keys = Object.keys(fileTypes)
    const values = Object.values(fileTypes)
    const tempImportTypeOptions = keys.map((key, index) => {
      return {
        value: values[index],
        label: getTranslation(t, ImportNames[key])
      }
    })
    tempImportTypeOptions.sort((a, b) => {
      return a.label.localeCompare(b.label)
    });
    setImportTypeOptions(tempImportTypeOptions)
  }, [])
  useEffect(() => {
    if (isNotNull(context.importState.import) && importType === null) {
      // copy the state to the local state changing memory reference
      setData([...context.importState.import])
      setImportType({ ...context.importState.importType })
      setActiveStep(1)
    }
  }, [context.importState.import])
  const executeGet = async (type) => {
    switch (type) {
      case fileTypes.AREA:
        await getZoneList()
        break
      case fileTypes.COLLABORATOR:
        await getCollaborators()
        break
      case fileTypes.DEPARTMENT:
        await getDepartment()
        break
      case fileTypes.EVALUATOR:
        await getEvaluator()
        break
      case fileTypes.POSITION:
        await getPositions()
        break
      case fileTypes.WORK_CENTER:
        await getWorkCenters()
        break
    }
  }
  // function to evaluate if is not null or undefined
  const isNotNull = (value) => {
    return value !== null && value !== undefined
  }
  // function to get the id using the scode
  const getIdFromState = (data, state) => {
    if (state) {
      return state.find(evaluator => evaluator.code == data)?.id || null
    } else if (data) {
      return null
    }
  }
  const getEmailFromState = (data, state) => {
    if (state) {
      return state.find(evaluator => evaluator.email == data)?.email || null
    } else if (data) {
      return null
    }
  }
  const errorNotExists = (type) => {
    const errorMessage = (error) => {
      return `El ${error} no existe`
    }
    switch (type) {
      case fileTypes.AREA:
        return errorMessage('area')
      case fileTypes.COLLABORATOR:
        return errorMessage('colaborador')
      case fileTypes.DEPARTMENT:
        return errorMessage('departamento')
      case fileTypes.EVALUATOR:
        return errorMessage('evaluador')
      case fileTypes.POSITION:
        return errorMessage('puesto')
      case fileTypes.WORK_CENTER:
        return errorMessage('centro de trabajo')
    }
  }
  const changeCodeToId = (list) => {
    let tempError = []
    let error = false
    const setError = (data, type) => {
      if (!data) {
        tempError.push(errorNotExists(type))
      }
    }
    const result = list.map(item => {
      const newItem = { ...item }
      if (isNotNull(newItem.departmentId) && newItem.departmentId.length === 4) {

        newItem.departmentId = getIdFromState(newItem.departmentId, context.departmentState.departments)
        setError(newItem.departmentId, fileTypes.DEPARTMENT)
      }
      if (isNotNull(newItem.areaId) && newItem.areaId.length === 4) {
        newItem.areaId = getIdFromState(newItem.areaId, context.areaState.areas)
        setError(newItem.areaId, fileTypes.AREA)
      }
      if (isNotNull(newItem.positionId) && newItem.positionId.length === 4) {
        newItem.positionId = getIdFromState(newItem.positionId, context.positionState.positions)
        setError(newItem.positionId, fileTypes.POSITION)
      }
      if (isNotNull(newItem.workCenterId) && newItem.workCenterId.length === 4) {
        newItem.workCenterId = getIdFromState(newItem.workCenterId, context.workCenterState.workCenter)
        setError(newItem.workCenterId, fileTypes.WORK_CENTER)
      }
      if (isNotNull(newItem.evaluatorId) && newItem.evaluatorId.length === 4) {
        newItem.evaluatorId = getIdFromState(newItem.evaluatorId, context.evaluatorState.evaluators)
        setError(newItem.evaluatorId, fileTypes.EVALUATOR)
      }
      if (isNotNull(newItem.email) && importType.value !== fileTypes.COLLABORATOR) {
        const email = getEmailFromState(newItem.email, context.collaboratorState.collaborators)
        setError(email, fileTypes.COLLABORATOR)
      }
      if (newItem.errors) {
        newItem.errors.notExist = tempError
        tempError = []
        if (newItem.errors.invalid.length > 0 || newItem.errors.obligatory.length > 0 || newItem.errors.notExist.length > 0) {
          error = true
        }
      }

      return newItem
    }
    )
    return [result, error]
  }
  const handleNext = async (e) => {
    e.preventDefault();
    let flag = true;
    if (activeStep === 0) {
      flag = await importData()
    }
    if (flag) setActiveStep((prevActiveStep) => prevActiveStep + 1);
    else return
  };

  const handleBack = () => {
    setDisabled(false)
    context.dispatchImportError({ type: CLEAR_IMPORT_ERROR_LIST })
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    context.dispatchImport({ type: GET_IMPORT_RESET })
  };
  const mergeErrors = (list, errorList) => {
    //obj to array
    const errors = Object.entries(errorList)
    //add errors to list
    errors.forEach(error => {
      if (list[error[0]]) {
        const errorMessages = Object.values(error[1].message)
        errorMessages.forEach(element => {
          element.forEach(message => {
            list[error[0]].errors.obligatory.push(getTranslation(t, message))
          })
        });

      }
    }
    )
    return list
  }
  // call api to import data
  const importData = async () => {
    try {
      if (file) {
        setLoading(true)
        const result = await genericApiCall(`${baseUrl}`, 'POST', { buffer: file, type: importType.value, step: activeStep })
        const data = JSON.parse(JSON.stringify(result.data.data))
        const old = JSON.parse(JSON.stringify(result.data.data))
        console.log(old)
        const error = await revalidateData(data, importType.value)
        const [temp, hasErrors] = changeCodeToId(data)
        mergeErrors(temp, error)
        setDataErrors(error)
        setData(temp)

        context.dispatchImport({
          type: GET_IMPORT_SUCCESS, payload: {
            old: old,
            import: [...temp],
            importType: { ...importType }
          }
        })
        Toast('success', getTranslation(t, ImportNames.IMPORT_DATA_SUCCESS), result.data.data.message)
        setLoading(false)
        return true
      } else {
        Toast('warning', 'Es necesario seleccionar un archivo', '')
        return false
      }
    } catch (error) {
      console.log('error => ', error)
      Toast('warning', getTranslation(t, ImportNames.IMPORT_ERROR), error.message)
      setLoading(false)
      return false
    }
  }
  // revalidate data
  const revalidateData = async (list, type) => {
    return validate(list, type)
  }
  const resetErrors = () => {
    const newData = JSON.parse(JSON.stringify(data))
    const old = JSON.parse(JSON.stringify(context.importState.old))

    newData.forEach((item, index) => {
      item.errors = old[index].errors
    })
    return newData
  }
  const handleSubmit = async () => {
    const newData = resetErrors()
    const error = await revalidateData(newData, importType.value)
    const [temp, hasErrors] = changeCodeToId(newData)
    if (Object.keys(error).length === 0 && !hasErrors) {
      try {
        setLoading(true)
        const result = await genericApiCall(`${baseUrl}`, 'POST', { type: importType.value, step: activeStep, json: data, value: valueImport.value })
        await executeGet(importType.value)
        Toast('success', getTranslation(t, ImportNames.IMPORT_SUCCESS), result.data.data.message)
        setLoading(false)
        reset()
        return true
      } catch (error) {
        // error message have ;
        if (error.message.includes(';')) {
          //split error message by ;
          const errorArray = error.message.split(';')
          //get the first error
          const errorString = errorArray[0]
          //get the error action
          const errorAction = errorArray[1]
          if (errorAction === 'back') {
            handleBack()
          }
          Toast('warning', getTranslation(t, ImportNames.IMPORT_ERROR), errorString)
        } else {
          Toast('warning', getTranslation(t, ImportNames.IMPORT_ERROR), error.message)
        }
        setLoading(false)
        return false
      }

    } else {
      const newTemp = mergeErrors([...temp], error)
      setDataErrors(error)
      setData(newTemp)
    }
  }
  const reset = () => {
    context.dispatchImport({ type: GET_IMPORT_RESET })
    setActiveStep(0)
    setData([{ name: 'test' }])
    setDataErrors({})
    setFile(null)
    setFileName(null)
    setImportType(null)
    setDisabled(false)
  }
  // file to base64 and set to file
  const fileToBase64 = (e) => {
    const fileData = e.target.files[0]
    const reader = new FileReader();
    // check if is a correct file extension
    if (fileData.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || fileData.type === 'application/vnd.ms-excel') {
      reader.readAsDataURL(fileData);
      reader.onload = () => {
        // only save base64 string
        setFile(reader.result.split(',')[1])
        setFileName(fileData.name)
        setErrors({})
      }
    } else {
      setFile(null)
      setErrors({ ...errors, file: getTranslation(t, ImportNames.FILE_WRONG_FORMAT) })
    }
  }
  // input to select the file excel
  const renderInputFile = () => {
    return (
      <div className='font-font-family-base'>
        <label className='p-2 bg-[#75b6fc] border-[#0d89ec] text-white hover:cursor-pointer' htmlFor='file'>
          <FontAwesomeIcon className='pr-1' icon={faFile} />
          {getTranslation(t, ImportNames.FILE_UPLOAD_TITLE)}
        </label>
        <p className='text-small-font-size pt-2'>{fileName}</p>
        <input id='file' type='file' className='hidden' accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' onChange={(e) => fileToBase64(e)} />
        {errors.file && <small className="p-error block text-small-font-size">{errors.file}</small>}
      </div>
    )
  }
  const options = [
    { value: true, label: getTranslation(t, ImportNames.IMPORT_REPLACE_TEXT) },
    { value: false, label: getTranslation(t, ImportNames.IMPORT_TEXT) },
  ]
  const downloadTemplate = () => {
    return downloadFile(importType.value)
  }

  const renderButtonDowloadFile = () => {
    return (
      <div className='font-font-family-base' onClick={() => downloadTemplate()}>
        <label className='p-2 bg-[#75b6fc] border-[#0d89ec] text-white hover:cursor-pointer' >
          <FontAwesomeIcon className='pr-1' icon={faFile} />
          {getTranslation(t, ImportNames.DOWNLOAD_BUTTON)}
        </label>
      </div>
    )
  }

  const renderImportSecondForm = () => {
    return <>
      {importType !== null && importType?.value !== 7 && importType?.value !== 8 && importType?.value !== 9 ?
        <CustomDropdown
          label={getTranslation(t, ImportNames.REPLACE_TITLE)}
          options={options}
          value={valueImport?.value}
          handler={setValueImport}
          optionLabel={'label'}
          optionValue={'value'}
          name='valueImport'
        /> : null
      }
      <div className=' flex'>
        {importType?.value === 7 || importType?.value === 8 || importType?.value === 9 ?
          <div className=' flex flex-col flex-grow'>
            <p className='font-bold text-xs'> {'Las registros ya existentes de las guías no se importarán'} </p>
          </div> : null}
      </div>
      <div className='display: inline-flex'>
        {importType && (
          <div>
            {renderInputFile()}
          </div>
        )}
        {importType && (
          <div className='ml-2'>
            {renderButtonDowloadFile()}
          </div>
        )}
      </div>
    </>
  }
  const renderForm = () => {


    return (
      <section className='p-8 gap-8 flex flex-col flex-grow'>
        {relatedCompanies && relatedCompanies.length > 0 ?
          <p className='font-bold text-xs'> {'Si desea agregar colaboradores, departamentos o puestos debe hacerlo desde Personia'} </p> : null}
        <CustomDropdown
          label={getTranslation(t, ImportNames.DROPDOWN_TITLE)}
          options={importTypeOptions}
          value={importType?.value}
          handler={setImportType}
          optionLabel={'label'}
          optionValue={'value'}
          name='importType'
        />

        {relatedCompanies &&
          relatedCompanies.length > 0 &&
          (importType?.value === 1 ||
            importType?.value === 3 ||
            importType?.value === 6) ?
          null :
          renderImportSecondForm()
        }
      </section>
    )
  }
  const steps = [
    { label: 'Seleccionar tipo de importación', title: 'Seleccionar tipo de importación' },
    { label: 'Validacion de datos', title: 'Validación de datos' }
  ]
  // render selected step of wizard using switch
  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return renderForm()
      case 1:
        return <Table data={data} setData={setData} importType={importType} error={dataErrors} />
      default:

        return null
    }
  }

  return (
    <div className='w-full flex flex-col h-full'>
      <header className='border-border border-b p-2 w-full font-font-family-base'>
        <h2>
          {getTranslation(t, ImportNames.IMPORT_TITLE)}
        </h2>
      </header>
      <Wizard
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        handleNext={handleNext}
        handleBack={handleBack}
        handleSubmit={handleSubmit}
        disabled={
          (relatedCompanies &&
            relatedCompanies.length > 0 &&
            (importType?.value === 1 ||
              importType?.value === 3 ||
              importType?.value === 6)) || disabled
        }
      >
        {renderStep()}
      </Wizard>
    </div>
  )
}