import React, { useState } from 'react'
import { CollaboratorsNames, DepartmentsNames, GenericsNames, PositionNames, QuestionaryCollaboratorsNames, SurveyNames, WorkCenterNames, ZoneNames } from '../../translations';
import { getTranslation, useCustomTranslation } from '../../hooks/useTranslations';
import { BaseButton } from '../Buttons/BaseButton';
import { CollaboratorGrid } from '../../containers/Catalogs/collaborators/Components/CollaboratorGrid';
import { Dialog } from 'primereact/dialog';
import { MultiSelectComponent } from '../Input/multiSelect';
import PropTypes from 'prop-types';
import { getSearchcollaborators } from '../../hooks/surveys/useCollaboratorsSearch';


const CollaboratorSelectionDialog = ({
	formik,
	collaboratorsListAssign,
	open,
	onClose,
	selectedCollaborators,
	setSelectedCollaborators,
	setRows,
	workCenters,
	onAdCollaborators,
	zones = [],
	departments = [],
	positions = [],
	rows = [],
	statuses = [],
	collaborators = [],
}) => {
	const [loading, setLoading] = useState(false);
	const { t } = useCustomTranslation();
	const [notFound, setNotFound] = useState(false);
	const getArrayValues = (objArray) => {
		if (Array.isArray(objArray)) {
			return objArray.map(catalog => (catalog));
		}
		return [];
	}
	const onSearch = async () => {
		try {
			const dataCollaborators = [] //collaboratorsListAssign
			setLoading(true);
			let collaboratorsDataAwait = await getSearchcollaborators(formik.values);
			collaboratorsDataAwait.map((c) => {
				const index = collaboratorsListAssign.findIndex((item) => item.collaboratorId === c.collaboratorId)
				if (index === -1) {
					dataCollaborators.push(c)
				}
			})
			setNotFound(collaboratorsDataAwait.length === 0);
			const newData = []
			dataCollaborators.forEach(c => { if (c.status !== 'B') newData.push(c) });
			setRows(newData);
			setLoading(false);
		} catch (err) {
			console.error(err)
			setRows([])
			setLoading(false);
		}
	}

	const renderMultiselectCollaborators = () => {
		return (
			<MultiSelectComponent
				title={getTranslation(t, CollaboratorsNames.TITLE)}
				id='collaborator'
				name='collaborator'
				options={collaborators ?? []}
				value={formik.values.collaborator ?? []}
				onChange={(e) => formik.setFieldValue('collaborator', getArrayValues(e.value) || [])}
				// onOpen={formik.handleBlur}
				display="chip"
				maxSelectedLabels={3}
				filter={true}
				showClear={true}
				error={Boolean(formik.touched.collaborator && formik.errors.collaborator)}
				helperText={formik.touched.collaborator && getTranslation(t, formik.errors.collaborator)}
				placeholder={getTranslation(t, QuestionaryCollaboratorsNames.ALL)}
			/>
		)
	}

	const renderMultiSelectZones = () => {
		return (
			<MultiSelectComponent
				id='zone'
				name='zone'
				options={zones ?? []}
				value={formik.values.zone ?? []}
				onChange={(e) => formik.setFieldValue('zone', getArrayValues(e.value) || [])}
				// onOpen={formik.handleBlur}
				display="chip"
				maxSelectedLabels={3}
				title={getTranslation(t, ZoneNames.TITLE)}
				error={Boolean(formik.touched.zone && formik.errors.zone)}
				helperText={formik.touched.zone && getTranslation(t, formik.errors.zone)}
				placeholder={getTranslation(t, QuestionaryCollaboratorsNames.ALL)}
			/>
		)
	}

	const renderMultiSelectDepartments = () => {
		return (
			<MultiSelectComponent
				id='department'
				name='department'
				options={departments ?? []}
				value={formik.values.department ?? []}
				onChange={(e) => formik.setFieldValue('department', getArrayValues(e.value) || [])}
				// onOpen={formik.handleBlur}
				display="chip"
				maxSelectedLabels={3}
				title={getTranslation(t, DepartmentsNames.DIALOG_TITLE)}
				error={Boolean(formik.touched.department && formik.errors.department)}
				helperText={formik.touched.department && getTranslation(t, formik.errors.department)}
				placeholder={getTranslation(t, QuestionaryCollaboratorsNames.ALL)}
			/>
		)
	}

	const renderMultiSelectPositions = () => {
		return (
			<MultiSelectComponent
				id='position'
				name='position'
				options={positions ?? []}
				value={formik.values.position ?? []}
				onChange={(e) => formik.setFieldValue('position', getArrayValues(e.value) || [])}
				// onOpen={formik.handleBlur}
				display="chip"
				maxSelectedLabels={3}
				title={getTranslation(t, PositionNames.TITLE)}
				error={Boolean(formik.touched.position && formik.errors.position)}
				helperText={formik.touched.position && getTranslation(t, formik.errors.position)}
				placeholder={getTranslation(t, QuestionaryCollaboratorsNames.ALL)}
			/>
		)
	}

	const renderMultiSelectWorkCenters = () => {

		return (
			<MultiSelectComponent
				id='workCenter'
				name='workCenter'
				options={workCenters ?? []}
				value={formik.values.workCenter ?? []}
				onChange={(e) => formik.setFieldValue('workCenter', getArrayValues(e.value) || [])}
				// onOpen={formik.handleBlur}
				display="chip"
				maxSelectedLabels={3}
				title={getTranslation(t, WorkCenterNames.TITLE)}
				error={Boolean(formik.touched.workCenter && formik.errors.workCenter)}
				helperText={formik.touched.workCenter && getTranslation(t, formik.errors.workCenter)}
				placeholder={getTranslation(t, QuestionaryCollaboratorsNames.ALL)}
			/>
		)
	}

	const renderMultiSelectStatuses = () => {
		return (
			<MultiSelectComponent
				id='status'
				name='status'
				options={statuses ?? []}
				value={formik.values.status ?? []}
				onChange={(e) => formik.setFieldValue('status', getArrayValues(e.value) || [])}
				// onOpen={formik.handleBlur}
				display="chip"
				maxSelectedLabels={3}
				title={getTranslation(t, CollaboratorsNames.STATUS)}
				error={Boolean(formik.touched.status && formik.errors.status)}
				helperText={formik.touched.status && getTranslation(t, formik.errors.status)}
				placeholder={getTranslation(t, QuestionaryCollaboratorsNames.ALL)}
			/>
		)
	}
	const renderButtonSearch = () => {
		return (
			<BaseButton
				className='!my-2'
				onClick={onSearch}
				label={getTranslation(t, GenericsNames.SEARCH)}
			/>
		)
	}

	const renderFilterSection = () => {
		return (
			<div className='col-span-3 flex flex-col border-b border-border px-3 border-r'>
				<p className='text-[#2196F3] font-font-family-base font-semibold'>{getTranslation(t, GenericsNames.FILTER)}</p>
				<div className='flex-grow relative'>
					<div className='absolute top-0 bottom-0 overflow-y-auto w-full'>
						{renderMultiselectCollaborators()}
						{renderMultiSelectZones()}
						{renderMultiSelectDepartments()}
						{renderMultiSelectPositions()}
						{renderMultiSelectWorkCenters()}
						{renderMultiSelectStatuses()}
					</div>
				</div>
				{renderButtonSearch()}
			</div>
		)

	}

	const collaboratorGrid = () => {
		return (
			<CollaboratorGrid
				text={notFound ? getTranslation(t, CollaboratorsNames.EMPTY_COLLABORATOR_NOT_FOUND) : getTranslation(t, CollaboratorsNames.EMPTY_COLLABORATOR_FILTER)}
				rows={rows}
				onDelete={() => { console.log("Delete Encuestas") }}
				name={"collaboratorselectgrid"}
				height="400px"
				selectionMode="checkbox"
				selection={selectedCollaborators}
				onSelectionChange={setSelectedCollaborators}
				isDataSelectable={true}
				showToolbar={false}
				actionColumn={false}
				loading={loading}
				style={{ height: 'calc(50vh)' }}
				iNeedDisabled
			/>
		)
	}

	return (
		<Dialog
			visible={open}
			header={<p className='text-font-size-base text-[#5e5e5e] font-font-family-base font-bold'>{getTranslation(t, SurveyNames.SELECT_collaborator).toUpperCase()}</p>}
			onHide={onClose}
			resizable={false}
			style={{ width: '70vw' }}
		>
			<div className='h-full w-full flex flex-col border border-border'>
				<div className='grid grid-cols-12 flex-grow'>
					{renderFilterSection()}
					<div className='col-span-9'>
						{collaboratorGrid()}
					</div>
				</div>
				<div className='flex justify-end py-1 border-b border-border gap-x-1'>
					<BaseButton
						onClick={onClose}
						type="button"
						className="p-button-text"
						label={getTranslation(t, GenericsNames.CANCEL)}
					/>
					<BaseButton
						onClick={onAdCollaborators}
						type="button"
						label={getTranslation(t, GenericsNames.SAVE)}
					/>
				</div>
			</div>
		</Dialog>
	);
};


CollaboratorSelectionDialog.propTypes = {
	rows: PropTypes.array,
	setRows: PropTypes.func,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	formik: PropTypes.object.isRequired,
	selectedCollaborators: PropTypes.array.isRequired,
	setSelectedCollaborators: PropTypes.func.isRequired,
	zones: PropTypes.array,
	departments: PropTypes.array,
	positions: PropTypes.array,
	workCenters: PropTypes.array,
	statuses: PropTypes.array,
	collaborators: PropTypes.array,
	onAdCollaborators: PropTypes.func
};

export default CollaboratorSelectionDialog;