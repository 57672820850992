import { InteractionStatus } from '@azure/msal-browser'
import { loginRequest } from './b2cServiceConfig'
import axios from 'axios';

// eslint-disable-next-line class-methods-use-this
const b2cInit = (success) => {
  success(true)
}

// eslint-disable-next-line class-methods-use-this
const b2cGetToken = async ({ instance, accounts, inProgress }) => {
  try {
    const account = accounts[0] || {}
    let atsResponse

    if (account && inProgress === InteractionStatus.None) {
      atsResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account
      })

      localStorage.setItem('accessToken', atsResponse.accessToken);

      axios.defaults.headers.common = {
        ...axios.defaults.headers.common,
        //Authorization: atsResponse.accessToken,
      }; 
      
    }

    return {
      account: {
        idToken: {
          given_name: atsResponse.account.idTokenClaims.given_name,
          email: atsResponse.account.idTokenClaims.email
        }
      }
    }

  } catch (error) {
    console.log('b2cGetToken token error', error)
    await instance.acquireTokenRedirect({
      ...loginRequest
    })
    return ''
  }
}

// eslint-disable-next-line class-methods-use-this
const b2cSignOut = (instance) => instance.logoutRedirect()

export { b2cInit, b2cGetToken, b2cSignOut }
