import { GET_SURVEY, GET_SURVEY_ERROR, GET_SURVEY_SUCCESS, GET_SURVEY_DETAIL, GET_SURVEY_RESULT, GET_SURVEY_UPDATE, FILTER, ERROR_SHOW } from '../constants'

export const surveys = (state, action) => {
    switch (action.type) {
        case GET_SURVEY:
            return {
                ...state,
                loading: true,
            }
        case GET_SURVEY_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                errorShow: false
            }
        case GET_SURVEY_SUCCESS:
            return {
                ...state,
                loading: false,
                surveys: {
                    ...state.surveys,  
                   [action.payload.guideId]:   action.payload.data
                },
                guideId: action.payload.guideId,
                error: null,
                errorShow: false
            }
        case GET_SURVEY_DETAIL:
            return {
                ...state,
                loading: false,
                //surveysDetails: action.payload,
                error: null,
                errorShow: false
            }
        case GET_SURVEY_RESULT:
            return {
                ...state,
                loading: false,
                surveysResults: action.payload,
                error: null,
                errorShow: false
            }
        case GET_SURVEY_UPDATE:
            return {
                ...state,
                loading: false,
                surveys: {
                    ...state.surveys,
                    [action.payload.guideId]: action.payload.data
                },
                error: null,
                errorShow: false
            }
        case FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    filters: {  
                        ...state.filter.filters,
                        [action.payload.guideId]:action.payload.filters
                    },
                    filterList:{
                        ...state.filter.filterList,
                        [action.payload.guideId]:action.payload.filterList
                        }
                }
            }
        case ERROR_SHOW:
            return {
                ...state,
                errorShow: true
            }
        default:
            return state
    }
}