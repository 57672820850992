import React from 'react'
import { toast } from 'react-toastify'

const INVALID_COMPANY_STATUS = 410


// restrict type to primary, secondary, success, info, warning, danger
export const Toast = (type = 'success', title, subTitle='', error=undefined) => {
    const toastSuccess = `!border !border-${type} !font-h3-font-family !rounded-xl`;
    const finalSubTitle = error?.status == INVALID_COMPANY_STATUS ? `Se redireccionará al diálogo de selección de empresa en unos segundos.${subTitle}` : subTitle;
    const container = (
        <div className="">
            <p className='!text-success !text-font-size-base '>{title}</p>
            <p className='!text-black !text-progress-font-size'>{finalSubTitle}</p>
        </div>
    )
    var autoCloseValue = error?.status == INVALID_COMPANY_STATUS ? 5000 : undefined;
    var toastLocationValue = error?.status == INVALID_COMPANY_STATUS ? toast.POSITION.TOP_CENTER : undefined;
    

    const onCloseHandler = () => {

        if(error?.status == INVALID_COMPANY_STATUS){
        
            const redirect = localStorage.getItem('ctiPortalURI');
            //delete all " from the url
            const url = redirect.replace(/\"/g, '');
            console.log(url)
            if (redirect) {
                // open the url in a new tab
                window.location.replace(url);
            }
        }
      };

    toast(container, {
        className: toastSuccess,
        autoClose: autoCloseValue,
        position: toastLocationValue,
        onClose:onCloseHandler
    })
}
