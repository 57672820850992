import React, { useEffect, useState } from 'react'

export const ReloadContext = React.createContext()
export const ReloadProvider = ({ children }) => {
  const [reload, setReload] = useState(false)
  useEffect(() => {
    reload && setTimeout(() => setReload(false), 1000)
  }, [reload])

  return (
    <ReloadContext.Provider
      value={{ reload, setReload }}
    >
      {children}
    </ReloadContext.Provider>
  )
}
