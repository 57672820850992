import { GenericsNames, ResultsNames, SurveyNames, ToolBarNames } from '../../translations'
import React, { useContext, useEffect, useState } from 'react'
import { capitalizeWords, convertDate, findNestedObj, gateDateFormated } from '../../utils/utils'
import { faFileExcel, faFilePdf, faFilter } from '@fortawesome/free-solid-svg-icons'
import { getTranslation, useCustomTranslation } from '../../hooks/useTranslations'
import { useLocation, useParams } from 'react-router-dom'
import { ToExcel, ToPdf, EntityToExport } from '../../utils/exportService'

import { CustomIconButton } from '../../Components/Buttons/CustomIconButton'
import { Fieldset } from 'primereact/fieldset';
import { MultiSelectComponent } from '../../Components/Input/multiSelect'
import { NavigationContext } from '../../context/navigationContext'
import { SelectComponent } from '../../Components/Select'
import { SideContainer } from '../../Components/sideContainer'
import { Toast } from '../../Components/toast'
import { LoadingContext } from '../../context/LoadingContext'
import routes from '../../routes'
import useCollaborators from '../../hooks/Collaborator/useCollaborators'

import { useFormik } from 'formik'
import useGuideSurveys from '../../hooks/surveys/useGuideSurveys'
import { StoreContext } from '../../business/Provider'
import useSurveysResult from '../../hooks/surveys/useSurveyResult'
import { faFilterSlash } from '@fortawesome/pro-light-svg-icons'
import { FILTER } from '../../business/constants'
import moment from 'moment'

export const Results = () => {
  const { guideId } = useParams();
  // get body from url using react-router-dom
  const location = useLocation();
  const context = useContext(StoreContext)
  // navigation context
  const navigation = useContext(NavigationContext)
  const { t } = useCustomTranslation()
  const tittle = findNestedObj(routes, 'tapTitle', navigation.currentTap?.father)
  // getSurveys
  const {
    getSurveyResults
  } = useSurveysResult(guideId)
  const { } = useGuideSurveys(guideId)
  const { } = useCollaborators()

  const [surveys, setSurvey] = useState([]);
  const [surveyIdState, setSurveyIdState] = useState(null);
  const [collaboratorsArray, setCollaboratorsArray] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [selectSurvey, setSelectSurvey] = useState({
    base: null,
    detail: null
  });
  const loadingObj = useContext(LoadingContext);

  useEffect(() => {
    const contain = Object.keys(context.surveysResultState.surveysResults).includes(guideId)
    if (contain) {
      const TempSurveyIdState = context.surveysResultState.surveysResults[guideId].surveyId
      setSelectSurvey({
        base: context.surveysState.surveys[guideId]?.find(survey => survey.id === TempSurveyIdState),
        detail: context.surveysResultState.surveysResults[guideId]
      })
      setSurveyIdState(TempSurveyIdState)
      const filter = context.surveysResultState.filters[guideId]
      filter ? formik.setValues(filter) : formik.setValues({
        workCenters: [],
        areas: [],
        departments: [],
        positions: [],
        collaborators: []
      })
    } else {
      setSelectSurvey({
        base: null,
        detail: null
      })
      formik.setValues({
        workCenters: [],
        areas: [],
        departments: [],
        positions: [],
        collaborators: []
      })
    }
  }, [guideId, location])

  useEffect(() => {
    if (context.collaboratorState.loading === false && context.collaboratorState.collaborators?.length > 0) {
      setCollaborators(context.collaboratorState.collaborators);
    }
  }, [context.collaboratorState])

  useEffect(() => {
    const contain = Object.keys(context.surveysState.surveys).includes(guideId)
    if (context.surveysState.loading === false && contain) {
      setSurvey(context.surveysState.surveys[guideId]);
    }
  }, [context.surveysState.loading, guideId])

  useEffect(() => {
    const temp = location.state?.surveyId
    if (temp !== surveyIdState && temp !== undefined) {

      setSurveyIdState(temp)
    }
  }, [location])

  useEffect(() => {
    if (collaborators) {
      //add full name to the collaborator
      const collaboratorsWithFullName = collaborators.map(collaborator => {
        return {
          ...collaborator,
          fullName: capitalizeWords(`${collaborator.name} ${collaborator.lastName}`)
        }
      })
      setCollaboratorsArray(collaboratorsWithFullName)
    }
  }, [collaborators])

  useEffect(() => {
    if (surveyIdState && location.state?.surveyId) {
      getSurveys()
      resetForm()
    }
  }, [surveyIdState, surveys, location])

  const getSurveys = async (filt = {}, surveyId = null) => {
    loadingObj.setLoading(true)
    const id = surveyId || surveyIdState
    filt.surveyId = id
    const result = await getSurveyResults(id, filt)
    // validate if result.status === 200
    if (result.status === 200) {
      setSelectSurvey({
        base: surveys?.find(survey => survey.id === id),
        detail: result.data.data.results
      })

    } else {
      Toast('warning', 'Error', result.message)
    }
    loadingObj.setLoading(false)
  }
  const formik = useFormik({
    initialValues: {
      workCenters: [],
      areas: [],
      departments: [],
      positions: [],
      collaborators: []
    },
    onSubmit: () => {
      formik.resetForm();
    }
  });
  const getFilter = () => {
    return {
      surveyId: surveyIdState,
      areaList: formik.values.areas,
      departmentList: formik.values.departments,
      workcenterList: formik.values.workCenters,
      positionList: formik.values.positions,
      collaboratorList: formik.values.collaborators
    }
  }

  const handleExcelExport = async () => {
    if (surveyIdState !== null) {
      try {
        loadingObj.setLoading(true)
        await ToExcel(getFilter(), EntityToExport.RESULT_BY_SURVEY)
        loadingObj.setLoading(false)
      } catch (err) {
        loadingObj.setLoading(false)
      }
      //Aqui debe traer los resultados generales de la encuesta
    } else {
      Toast('warning', 'Error', 'No se puede exportar a EXCEL, no hay encuesta seleccionada')
    }
  }

  const handlePdfExport = async () => {
    if (surveyIdState !== null) {
      try {
        loadingObj.setLoading(true)
        await ToPdf(getFilter(), EntityToExport.RESULT_BY_SURVEY)
        loadingObj.setLoading(false)
      } catch (err) {
        loadingObj.setLoading(false)
      }
    } else {
      Toast('warning', 'Error', 'No se puede exportar a PDF, no hay encuesta seleccionada')
    }
  }

  const resetForm = () => {
    formik.resetForm();
    const payload = {
      id: guideId,
      data: {}
    }
    context.dispatchSurveysResult({ type: FILTER, payload: payload })
  }

  const renderFilters = () => {
    return (
      <>
        <MultiSelectComponent
          placeholder='TODOS'
          options={context.workCenterState.workCenter}
          optionLabel="name"
          optionValue="id"
          value={formik.values.workCenters}
          name="workCenters"
          onChange={formik.handleChange}
          title={getTranslation(t, SurveyNames.WORK_CENTER)}
          showClear={false}
        />
        <MultiSelectComponent
          placeholder='TODOS'
          options={context.areaState.areas}
          value={formik.values.areas}
          name="areas"
          optionLabel="name"
          optionValue="id"
          onChange={formik.handleChange}
          title={getTranslation(t, SurveyNames.ZONE)}
          showClear={false}
        />
        <MultiSelectComponent
          placeholder='TODOS'
          options={context.departmentState.departments}
          value={formik.values.departments}
          name="departments"
          optionLabel="name"
          optionValue="id"
          onChange={formik.handleChange}
          title={getTranslation(t, SurveyNames.DEPARTMENT)}
          showClear={false}
        />
        <MultiSelectComponent
          placeholder='TODOS'
          options={context.positionState.positions}
          optionLabel="name"
          optionValue="id"
          value={formik.values.positions}
          name="positions"
          onChange={formik.handleChange}
          title={getTranslation(t, SurveyNames.POSITION)}
          showClear={false}
        />
        <MultiSelectComponent
          placeholder='TODOS'
          options={collaboratorsArray}
          optionLabel="fullName"
          optionValue="collaboratorId"
          value={formik.values.collaborators}
          name="collaborators"
          onChange={formik.handleChange}
          title={getTranslation(t, GenericsNames.COLLABORATOR)}
          showClear={false}
        />
        <div className='flex justify-center'>
          <CustomIconButton toolTip={getTranslation(t, GenericsNames.FILTER)}
            icon={faFilter}
            className='!m-1'
            onClick={() => {
              const data = getFilter()
              const payload = {
                id: guideId,
                data: formik.values
              }
              context.dispatchSurveysResult({ type: FILTER, payload: payload })
              getSurveys(data)
            }} />
          <CustomIconButton
            icon={faFilterSlash}
            className='!m-1'
            toolTip='Limpiar Filtros'
            onClick={() => {
              resetForm()
              getSurveys()
            }} />
          <CustomIconButton
            toolTip={getTranslation(t, ToolBarNames.EXCEL)}
            onClick={() => { handleExcelExport(); }}
            disabled={!selectSurvey.detail?.TotalResponses || selectSurvey.detail?.TotalResponses === 0}
            icon={faFileExcel}
            className='!m-1'
          />
          <CustomIconButton
            toolTip={getTranslation(t, ToolBarNames.PDF)}
            onClick={() => { handlePdfExport(); }}
            disabled={!selectSurvey.detail?.TotalResponses || selectSurvey.detail?.TotalResponses === 0}
            icon={faFilePdf}
            className='!m-1'
          />
        </div>
      </>
    )
  }

  const renderHeaderContent = () => {
    const valueStartDate = convertDate(selectSurvey?.base?.startDate);
    const valueEndDate = convertDate(selectSurvey?.base?.endDate);
    return (
      <div className=' flex w-full p-2  border-border h-16'>
        <div className='flex w-full h-full flex-row '>
          <div className="flex h-full w-[10%] ">
            <SelectComponent
              className={'w-full h-full flex'}
              ValuesOptions={surveys}
              optionLabel="scode"
              optionValue={'id'}
              valueSelected={surveyIdState}
              name="surveyId"
              handleChange={(e) => {
                setSurveyIdState(e.value)
                resetForm()
                getSurveys({}, e.value)

              }}
            />
          </div>

          <div className='flex h-full w-[35%]  items-center text-center  p-2'>
            <p>{selectSurvey.base?.name}</p>
          </div>
          <div className='flex h-full w-[55%] text-center  justify-start  p-2'>
            <p>{getTranslation(t, ResultsNames.SINCE)}:
              <span className='px-2 font-light'>
                {selectSurvey.base?.startDate ? moment(valueStartDate, 'DD/MM/YYYY').format('DD/MM/YYYY') : '--/--/----'}
              </span>
              {getTranslation(t, ResultsNames.TO)}:
              <span className='px-2 font-light'>
                {selectSurvey.base?.endDate ? moment(valueEndDate, 'DD/MM/YYYY').format('DD/MM/YYYY') : '--/--/----'}
              </span>
            </p>
          </div>
        </div>
      </div>
    )
  }

  const content = () => {
    const bg = `${selectSurvey.detail?.ScoreColor || 'transparent'}`

    return (
      <div className='w-full h-full p-2 gap-2 flex flex-col'>
        {renderHeaderContent()}
        {
          surveyIdState ?
            <div className='p-2 border border-border flex-grow flex flex-col gap-2'>
              <div className='text-center'>
                <p>{getTranslation(t, ResultsNames.GENERAL_RESULT)}</p>
              </div>
              <div className='flex w-full justify-center '>
                <div style={{
                  background: bg,
                }} className={`w-1/3 h-12  flex text-center items-center justify-center border border-border text-black`}>
                  {selectSurvey.detail?.GeneralScoreText}
                </div>
              </div>
              <Fieldset legend={getTranslation(t, ResultsNames.RECOMMENDATION)} className=' border border-border p-1 relative flex-grow'>
                <div className='absolute top-0 bottom-0 overflow-y-auto !font-font-family-base m-4'>
                  <p>{selectSurvey.detail?.ScoreDescription}</p>
                </div>
              </Fieldset>
              <Fieldset legend={getTranslation(t, ResultsNames.CONCLUSION)} className='border p-1 border-border relative flex-grow '>
                <div className='m-4 absolute top-0 bottom-0 overflow-y-auto !font-font-family-base'>
                  <p> {selectSurvey.detail?.Conclusion}</p>
                </div>

              </Fieldset>
            </div>
            :
            <div className='p-4 border border-border flex-grow flex flex-col gap-4 justify-center font-h1-font-family text-center text-h1-font-size '>
              <span className='opacity-3'>{getTranslation(t, ResultsNames.SELECT_SURVEY)}</span>
            </div>
        }
      </div>
    )
  }
  return (
    <div className='w-full h-full flex-col flex !font-font-family-base relative'>
      <header className=' border-b border-border p-2  text-center'>
        <h5 className='text-h5-font-size '>
          {tittle ? getTranslation(t, tittle.title) : ' '}
        </h5>
        <p className='text-small-font-base '>
          {getTranslation(t, ResultsNames.RESULT)}
        </p>
      </header>
      <div className='flex flex-grow'>
        <SideContainer className='w-[240px]' title={getTranslation(t, ResultsNames.REPORT)} >
          {renderFilters()}
        </SideContainer>
        <div className='w-full'>
          {content()}
        </div>
      </div>
    </div>
  )
}