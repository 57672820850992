import React, { useEffect, useState } from 'react';
import { faEdit, faLock } from '@fortawesome/free-solid-svg-icons';
import { getTranslation, useCustomTranslation } from './../../hooks/useTranslations';

import { AlertComponentModal } from '../Modal';
import { ButtonTextWithIcon } from '../Buttons/ButtonTextWithIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from '../Input/Input';
import { SelectComponent } from '../Select';
import { SurveysQuestionsNames } from '../../translations';
import { customValidationInput } from '../../utils/utils';
import { orderAlphabetically } from '../../utils/arrayService';

export const ValidationComponent = ({ setStep, collaboratorData, departaments }) => {
  const { t } = useCustomTranslation();
  const [data, setData] = useState({
    lastName: '',
    departament: '',
  });

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  const [dataInputNameIsCorrect, setDataInputNameIsCorrect] = useState(false);
  const [messageName, setMessageName] = useState('');
  const [dataInputDepartmentIsCorrect, setDataInputDepartmentIsCorrect] = useState(false);
  const [messageDepartment, setMessageDepartment] = useState('');
  const [showAlert, setShowAlert] = useState(false);


  useEffect(() => {

    if (
      data.lastName !== '' &&
      data.departament !== '' &&
      acceptTerms === true
    ) {
      setEnableButton(true);
    } else {
      setEnableButton(false);
    }
  }, [data, acceptTerms]);



  const handleChangeGeneral = (e) => {

    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };


  const validationInputNameForm = (e) => {
    return customValidationInput(e, setDataInputNameIsCorrect, setMessageName, 'Apellido', collaboratorData?.lastName);

  };

  const validationInputDepartmentForm = (value) => {
    return customValidationInput(
      value,
      setDataInputDepartmentIsCorrect,
      setMessageDepartment,
      'Departamento',
      collaboratorData?.departmentName,
    );
  };


  const validationButton = () => {
    if (messageDepartment === '' && messageName === '' && acceptTerms === true) {
      setStep(2)
    } else {
      setShowAlert(true)
    }
  }

  const newDepartments = orderAlphabetically(departaments, 'name');

  return (
    <>
      <div className="flex w-[95%] h-[80%]   lg:h-3/5 xl:w-3/5 xl:h-3/5 bg-white shadow-2xl divide-y-2 absolute ">
        {showAlert === true ?
          <AlertComponentModal
            showAlert={showAlert}
            actionButton={() => setShowAlert(false)}
            validations={[messageDepartment, messageName]}
            buttonActionText='Aceptar'
            type={1}
          /> : null}

        <div className="w-full h-full flex flex-col divide-y-2">
          <div className="h-[10%] lg:h-1/6 xl:h-1/6 2xl:h-1/6 w-full flex flex-row ">
            <div className="flex w-full content-center items-center justify-center">
              <h1 className="font-bold uppercase text-center text-xs sm:text-lg md:text-sm lg:text-lg xl:text-lg 2xl:text-xl">{getTranslation(t, SurveysQuestionsNames.SurveyPrivacyNotice)}</h1>
            </div>
          </div>
          <div className="h-[90%] lg:h-5/6 xl:h-5/6 2xl:h-5/6 xw-full flex flex-col p-2 divide-y-1 lg:p-6 x:p-6 2xl:p-6 ">
            <div className="flex w-full h-[40%] lg:h-1/2 xl:h-1/2 2xl:h-1/2   flex-col">
              <div className="flex w-full h-[45%] lg:h-3/5 xl:h-3/5 2xl:h-3/5 content-center flex-col justify-center items-center">
                <div className='flex flex-row content-center items-center'>
                  <FontAwesomeIcon icon={faLock} title={'lock'} />
                  <p className='text-xs sm:text-lg md:text-sm lg:text-lg xl:text-lg 2xl:text-xl font-bold ml-6'>{getTranslation(t, SurveysQuestionsNames.SurveyPrivacy)}</p>
                </div>

                <p className='text-xs sm:text-lg md:text-sm lg:text-sm xl:text-sm 2xl:text-sm justify-center'>
                  {getTranslation(t, SurveysQuestionsNames.SurveyTherms)}
                </p>
              </div>
              <div className="flex w-full h-[30%] lg:h-1/5 xl:h-1/5 2xl:h-1/5 content-end items-end  align-middle">
                <div className='flex content-center items-center flex-row mt-2'>
                  <input type="checkbox" checked={acceptTerms} onChange={() => setAcceptTerms(!acceptTerms)} />
                  <p className="font-bold ml-6 lg:text-lg xl:text-lg 2xl:text-lg">Acepto</p>
                </div>
              </div>
              <div className="flex w-full h-[25%] lg:h-1/5 xl:h-1/5 2xl:h-1/5 content-center justify-center items-center text-xs lg:text-base xl:text-base 2xl:text-base  ">{getTranslation(t, SurveysQuestionsNames.SurveySegurityQuestions)}</div>
            </div>
            <div className="flex w-full h-[60%] lg:h-1/2 xl:h-1/2 2xl:h-1/2 flex-col items-center content-center justify-center">
              <div className="flex w-full h-2/3  items-center content-center justify-center flex-col">
                <div className="flex w-full h-full flex-wrap justify-between items-center content-center   ">
                  <div className="flex w-full h-1/2 lg:h-full xl:h-full 2xl:h-full  lg:w-1/2 xl:w-1/2 2xl:w-1/2  p-2">
                    <Input
                      // title="Nombre"
                      type="text"
                      name="lastName"
                      onChange={handleChangeGeneral}
                      value={data.lastName}
                      placeholder="Apellido Paterno"
                      //  className={classItem}
                      onBlur={(e) => validationInputNameForm(e.target.value)}
                      error={dataInputNameIsCorrect}
                      important
                    />
                  </div>
                  <SelectComponent
                    className={'flex flex-col h-1/2 lg:h-full xl:h-full 2xl:h-full p-2 w-full  lg:w-1/2 xl:w-1/2 2xl:w-1/2 border-red'}
                    valueSelected={newDepartments?.filter(item => item.name === data.departament)[0]}
                    labelText={'Departamento'}
                    ValuesOptions={newDepartments}
                    handleChange={(e) => {
                      validationInputDepartmentForm(e.value.name)
                      setData({
                        ...data,
                        departament: e.value.name
                      })
                    }}
                    placeholderText={'...'}
                    name={'departament'}
                    optionLabel={'name'}

                    error={dataInputDepartmentIsCorrect}

                    important
                  />


                </div>
              </div>
              <div className="flex w-full h-[20%] lg:h-1/3 xl:h-1/3 2xl:h-1/3   flex-row items-start content-center justify-center    ">
                <div className="flex w-40 lg:w-1/2 xl:w-1/2 2xl:w-1/2 h-full p-2 justify-center content-center items-center">
                  <ButtonTextWithIcon
                    onClick={() => enableButton ? validationButton() : null}
                    title={'Comenzar'}
                    type='primary'
                    disabled={!enableButton}
                    Icon={<FontAwesomeIcon icon={faEdit} title={'edit'} />}
                    position={'left'}
                  />

                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </>
  );
};
