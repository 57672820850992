
import { GET_IMPORT, GET_IMPORT_ERROR, GET_IMPORT_RESET, GET_IMPORT_SUCCESS} from "../constants";

export const getImport = (state, action) => {
    switch (action.type) {
        case GET_IMPORT:
            return {
                ...state,
                loading: true,
                error: null,
                errorShow: false
            };
        case GET_IMPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                import: action.payload.import,
                importType: action.payload.importType,
                old: action.payload.old
            };
        case GET_IMPORT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                errorShow: true
            };
        case GET_IMPORT_RESET:
            return {
                ...state,
                loading: false,
                error: null,
                errorShow: false,
                import: null,
                importType: null,
                old: null
            };
        default:
            return state;
    }
}