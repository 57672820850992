import React, { useEffect, useState } from "react";


import useCompanies from "../hooks/Company/useCompany";

export const CompanyRelatedContext = React.createContext();
export const CompanyRelatedProvider = ({ children }) => {
    const [relatedCompanies, setCompanyRelated] = useState(undefined);
    const [reload, setReload] = useState(true);

    const { getRelatedCompanies } = useCompanies();
    const updateRelatedCompanies = (relatedCom) => {
        setCompanyRelated(relatedCom);
        setReload(true)
    }
    useEffect(async () => {
        if (relatedCompanies === undefined && reload) {
            let response = await getRelatedCompanies()
            setCompanyRelated(response.companiesRelated);
            setReload(false);
        }
    }, [reload]);

    return (
        <CompanyRelatedContext.Provider
            value={{ reload, setReload, relatedCompanies, updateRelatedCompanies }}
        >
            {children}
        </CompanyRelatedContext.Provider>
    )
}
