const ObjSidebar = 'Sidebar';
export const SidebarNames = {
  SidebarSurveys: `${ObjSidebar}.sidebarSurveys`,
  SidebarCatalog: `${ObjSidebar}.sidebarCatalog`,
  SidebarSettings: `${ObjSidebar}.sidebarSettings`,
  SidebarInquiries: `${ObjSidebar}.sidebarInquiries`,
  SidebarFollowUp: `${ObjSidebar}.sidebarFollowUp`,
  QUESTIONS_GIDE: `${ObjSidebar}.QUESTIONS_GIDE`,
  CONFIGURATION: `${ObjSidebar}.CONFIGURATION`,
  GUIDE1: `${ObjSidebar}.GUIDE1`,
  MENU_GUIDE1: `${ObjSidebar}.MENU_GUIDE1`,
  GUIDE2: `${ObjSidebar}.GUIDE2`,
  MENU_GUIDE2: `${ObjSidebar}.MENU_GUIDE2`,
  GUIDE3: `${ObjSidebar}.GUIDE3`,
  OWN: `${ObjSidebar}.OWN`,
  MENU_GUIDE3: `${ObjSidebar}.MENU_GUIDE3`,
  QUESTIONS: `${ObjSidebar}.QUESTIONS`,
  RESULTS: `${ObjSidebar}.RESULTS`,
  COLLABORATORS: `${ObjSidebar}.COLLABORATORS`,
  PERIODS: `${ObjSidebar}.PERIODS`,
  DEPARTMENTS: `${ObjSidebar}.DEPARTMENTS`,
  POSTS: `${ObjSidebar}.POSTS`,
  ZONE: `${ObjSidebar}.ZONE`,
  WORK_CENTER: `${ObjSidebar}.WORK_CENTER`,
  EVALUATORS: `${ObjSidebar}.EVALUATORS`,
  SCORE_RANGES: `${ObjSidebar}.SCORE_RANGES`
}



const ObjSettingsPanel = 'SettingsPanel'
export const SettingsPanelNames = {
  MY_PROFILE: `${ObjSettingsPanel}.MY_PROFILE`,
  LOGOUT: `${ObjSettingsPanel}.LOGOUT`,
  ROLE_USER: `${ObjSettingsPanel}.ROLE_USER`,
  LANGUAGE_TOGGLE: `${ObjSettingsPanel}.LANGUAGE_TOGGLE`,
  FULLSCREEN_TOGGLE: `${ObjSettingsPanel}.FULLSCREEN_TOGGLE`,
  FONT_SIZE: `${ObjSettingsPanel}.FONT_SIZE`,
  THEME_SETTINGS: `${ObjSettingsPanel}.THEME_SETTINGS`,
  THEME_COLOR_SCHEMES: `${ObjSettingsPanel}.THEME_COLOR_SCHEMES`,
  THEME_COLOR_DESCRIPTION: `${ObjSettingsPanel}.THEME_COLOR_DESCRIPTION`,
  NOTIFICATION_DISMISS_ALL: `${ObjSettingsPanel}.NOTIFICATION_DISMISS_ALL`,
  NOTIFICATION_PANEL_TITLE: `${ObjSettingsPanel}.NOTIFICATION_PANEL_TITLE`,
  NOTIFICATION_EMPTY: `${ObjSettingsPanel}.NOTIFICATION_EMPTY`,
  NOTIFICATION_TITLE: `${ObjSettingsPanel}.NOTIFICATION_TITLE`
}
const ObjNavigation = 'Navigation'
export const NavigationNames = {
  QUESTIONS_GIDE: `${ObjNavigation}.QUESTIONS_GIDE`,
  SURVEYS: `${ObjNavigation}.SURVEYS`,
  CATALOGS: `${ObjNavigation}.CATALOGS`,
  INQUIRIES: `${ObjNavigation}.INQUIRIES`,
  FOLLOW_UP: `${ObjNavigation}.FOLLOW_UP`,
  CONFIGURATION: `${ObjNavigation}.CONFIGURATION`,
  GUIDE1: `${ObjNavigation}.GUIDE1`,
  MENU_GUIDE1: `${ObjNavigation}.MENU_GUIDE1`,
  GUIDE2: `${ObjNavigation}.GUIDE2`,
  MENU_GUIDE2: `${ObjNavigation}.MENU_GUIDE2`,
  GUIDE3: `${ObjNavigation}.GUIDE3`,
  OWN: `${ObjNavigation}.OWN`,
  MENU_GUIDE3: `${ObjNavigation}.MENU_GUIDE3`,
  QUESTIONS: `${ObjNavigation}.QUESTIONS`,
  RESULTS: `${ObjNavigation}.RESULTS`,
  COLLABORATORS: `${ObjNavigation}.COLLABORATORS`,
  PERIODS: `${ObjNavigation}.PERIODS`,
  DEPARTMENTS: `${ObjNavigation}.DEPARTMENTS`,
  POSTS: `${ObjNavigation}.POSTS`,
  ZONE: `${ObjNavigation}.ZONE`,
  WORK_CENTER: `${ObjNavigation}.WORK_CENTER`,
  EVALUATORS: `${ObjNavigation}.EVALUATORS`,
  SCORE_RANGES: `${ObjNavigation}.SCORE_RANGES`,
  RESPONSE: `${ObjNavigation}.RESPONSE`,
  COMPANY: `${ObjNavigation}.COMPANY`,
  INTEGRATION: `${ObjNavigation}.INTEGRATION`,
  INTEGRATION_SHORT: `${ObjNavigation}.INTEGRATION_SHORT`,
  IMPORT_DATA: `${ObjNavigation}.IMPORT_DATA`,
  MIGRATOR: `${ObjNavigation}.MIGRATOR`,
}

const ObjToolBar = 'ToolBar'
export const ToolBarNames = {
  REFRESH: `${ObjToolBar}.REFRESH`,
  HELP: `${ObjToolBar}.HELP`,
  EDIT: `${ObjToolBar}.EDIT`,
  DELETE: `${ObjToolBar}.DELETE`,
  COMPANY: `${ObjToolBar}.COMPANY`,
  CHAT_SERVICE: `${ObjToolBar}.CHAT_SERVICE`,
  LOGOUT: `${ObjToolBar}.LOGOUT`,
  FAQS: `${ObjToolBar}.FAQS`,
  TERMS: `${ObjToolBar}.TERMS`,
  POLICY: `${ObjToolBar}.POLICY`,
  PRINT: `${ObjToolBar}.PRINT`,
  EXCEL: `${ObjToolBar}.EXCEL`,
  ADD_collaborator: `${ObjToolBar}.ADD_collaborator`,
  PDF: `${ObjToolBar}.PDF`,
  SEND_SELECTED: `${ObjToolBar}.SEND_SELECTED`,
  COMPANY_RELATED: `${ObjToolBar}.COMPANY_RELATED`,
  COMPANY_DATA: `${ObjToolBar}.COMPANY_DATA`,
  VERSION: `${ObjToolBar}.VERSION`,
}

const ObjNotificationToggle = 'NotificationToggle'
export const NotificationToggleNames = {
  NOTIFICATION_DISMISS_ALL: `${ObjNotificationToggle}.NOTIFICATION_DISMISS_ALL`,
  NOTIFICATION_PANEL_TITLE: `${ObjNotificationToggle}.NOTIFICATION_PANEL_TITLE`,
  NOTIFICATION_EMPTY: `${ObjNotificationToggle}.NOTIFICATION_EMPTY`,
  NOTIFICATION_TITLE: `${ObjNotificationToggle}.NOTIFICATION_TITLE`
}

const ObjGenerics = 'Generics'
export const GenericsNames = {
  ADD: `${ObjGenerics}.ADD`,
  COPY: `${ObjGenerics}.COPY`,
  NEW: `${ObjGenerics}.NEW`,
  EDIT: `${ObjGenerics}.EDIT`,
  DELETE: `${ObjGenerics}.DELETE`,
  CONFIRM: `${ObjGenerics}.CONFIRM`,
  SEARCH: `${ObjGenerics}.SEARCH`,
  SAVE: `${ObjGenerics}.SAVE`,
  CANCEL: `${ObjGenerics}.CANCEL`,
  ACCEPT: `${ObjGenerics}.ACCEPT`,
  NAME: `${ObjGenerics}.NAME`,
  NAMES: `${ObjGenerics}.NAMES`,
  CODE: `${ObjGenerics}.CODE`,
  FILTER_CODE: `${ObjGenerics}.FILTER_CODE`,
  FILTER_NAME: `${ObjGenerics}.FILTER_NAME`,
  LOADING: `${ObjGenerics}.LOADING`,
  GRID_ACTION: `${ObjGenerics}.GRID_ACTION`,
  GRID_SELECT: `${ObjGenerics}.GRID_SELECT`,
  GRID_ACTIVE_FLAG: `${ObjGenerics}.GRID_ACTIVE_FLAG`,
  GRID_UNACTIVE_FLAG: `${ObjGenerics}.GRID_UNACTIVE_FLAG`,
  EMAIL: `${ObjGenerics}.EMAIL`,
  GENDER: `${ObjGenerics}.GENDER`,
  FILTER_GENDER: `${ObjGenerics}.FILTER_GENDER`,
  FEMININE: `${ObjGenerics}.FEMININE`,
  MALE: `${ObjGenerics}.MALE`,
  WORK_CENTER: `${ObjGenerics}.WORK_CENTER`,
  FILTER_WORK_CENTER: `${ObjGenerics}.FILTER_WORK_CENTER`,
  DEPARTMENT: `${ObjGenerics}.DEPARTMENT`,
  FILTER_WORK_DEPARTMENT: `${ObjGenerics}.FILTER_WORK_DEPARTMENT`,
  ZONE: `${ObjGenerics}.ZONE`,
  FILTER_ZONE: `${ObjGenerics}.FILTER_ZONE`,
  POSTS: `${ObjGenerics}.POSTS`,
  FILTER_POSTS: `${ObjGenerics}.FILTER_POSTS`,
  STATUS: `${ObjGenerics}.STATUS`,
  FILTER_STATUS: `${ObjGenerics}.FILTER_STATUS`,
  ACTIVE: `${ObjGenerics}.ACTIVE`,
  SEARCH_FOR: `${ObjGenerics}.SEARCH_FOR`,
  VIEW_RANGE: `${ObjGenerics}.VIEW_RANGE`,
  VIEW_RANGE_TAP_TITLE_1: `${ObjGenerics}.VIEW_RANGE_TAP_TITLE_1`,
  VIEW_RANGE_TAP_TITLE_2: `${ObjGenerics}.VIEW_RANGE_TAP_TITLE_2`,
  VIEW_RANGE_TAP_TITLE_3: `${ObjGenerics}.VIEW_RANGE_TAP_TITLE_3`,
  EXEL: `${ObjGenerics}.EXEL`,
  PDF: `${ObjGenerics}.PDF`,
  PRINT: `${ObjGenerics}.PRINT`,
  CATEGORY: `${ObjGenerics}.CATEGORY`,
  FILTER_CATEGORY: `${ObjGenerics}.FILTER_CATEGORY`,
  DOMAIN: `${ObjGenerics}.DOMAIN`,
  FILTER_DOMAIN: `${ObjGenerics}.FILTER_DOMAIN`,
  DIMENSION: `${ObjGenerics}.DIMENSION`,
  FILTER_DIMENSION: `${ObjGenerics}.FILTER_DIMENSION`,
  QUESTION: `${ObjGenerics}.QUESTION`,
  FILTER_QUESTION: `${ObjGenerics}.FILTER_QUESTION`,
  LAST_STEP: `${ObjGenerics}.LAST_STEP`,
  END: `${ObjGenerics}.END`,
  NEXT: `${ObjGenerics}.NEXT`,
  BACK: `${ObjGenerics}.BACK`,
  SHARE: `${ObjGenerics}.SHARE`,
  VIEW: `${ObjGenerics}.VIEW`,
  SEND_EMAIL: `${ObjGenerics}.SEND_EMAIL`,
  COPIED: `${ObjGenerics}.COPIED`,
  FILTER: `${ObjGenerics}.FILTER`,
  COLLABORATOR: `${ObjGenerics}.COLLABORATOR`,
  VALIDATOR_SPECIAL_CHARACTERS: `${ObjGenerics}.VALIDATOR_SPECIAL_CHARACTERS`,
  NOT_AVAILABLE_OPTION: `${ObjGenerics}.NOT_AVAILABLE_OPTION`,
  AREA: `${ObjGenerics}.AREA`,
  POSITION: `${ObjGenerics}.POSITION`,
  WITHOUT_RESULT: `${ObjGenerics}.WITHOUT_RESULTS`,
  VALIDATOR_ID_GREATER_THAN_ZERO: `${ObjGenerics}.VALIDATOR_ID_GREATER_THAN_ZERO`,
  CLEAN_FILTER: `${ObjGenerics}.CLEAN_FILTER`,
  REPEAT_ERROR: `${ObjGenerics}.REPEAT_ERROR`,
  NO_EXPORT_DATA: `${ObjGenerics}.NO_EXPORT_DATA`,
  DONT_SEND_EMAIL: `${ObjGenerics}.DONT_SEND_EMAIL`
}

const ObjEvaluator = 'Evaluator'
export const EvaluatorNames = {
  SUCCESS_UPDATE: `${ObjEvaluator}.SUCCESS_UPDATE`,
  ERROR_UPDATE: `${ObjEvaluator}.ERROR_UPDATE`,
  GRID_EMPTY: `${ObjEvaluator}.GRID_EMPTY`,
  GRID_PAGE_REPORT_TEMPLATE: `${ObjEvaluator}.GRID_PAGE_REPORT_TEMPLATE`,
  GRID_COLUMN_KEY: `${ObjEvaluator}.GRID_COLUMN_KEY`,
  GRID_COLUMN_FILTER_KEY: `${ObjEvaluator}.GRID_COLUMN_FILTER_KEY`,
  GRID_COLUMN_NAME: `${ObjEvaluator}.GRID_COLUMN_NAME`,
  GRID_COLUMN_FILTER_NAME: `${ObjEvaluator}.GRID_COLUMN_FILTER_NAME`,
  GRID_ACTION: `${ObjEvaluator}.GRID_ACTION`,
  SUCCESS_ADD: `${ObjEvaluator}.SUCCESS_ADD`,
  ERROR_ADD: `${ObjEvaluator}.ERROR_ADD`,
  SUCCESS_DELETE: `${ObjEvaluator}.SUCCESS_DELETE`,
  GRID_SEARCH: `${ObjEvaluator}.GRID_SEARCH`,
  DIALOG_ADD: `${ObjEvaluator}.DIALOG_ADD`,
  DIALOG_EDIT: `${ObjEvaluator}.DIALOG_EDIT`,
  TITLE: `${ObjEvaluator}.TITLE`,
  CONFIRM_MESSAGE_DELETE: `${ObjEvaluator}.CONFIRM_MESSAGE_DELETE`,
  PROFESIONAL_ID: `${ObjEvaluator}.PROFESIONAL_ID`,
  VALIDATOR_DIGIT_PROFESIONAL_ID: `${ObjEvaluator}.VALIDATOR_DIGIT_PROFESIONAL_ID`,
  VALIDATOR_PROFESIONAL_ID_REQUIRED: `${ObjEvaluator}.VALIDATOR_PROFESIONAL_ID_REQUIRED`,
  EMPTY_EVALUATOR: `${ObjEvaluator}.EMPTY_EVALUATOR`,
  VALIDATOR_NAME_REQUIRED_SIZE: `${ObjEvaluator}.VALIDATOR_NAME_REQUIRED_SIZE`,
}

const ObjPosition = 'Position'
export const PositionNames = {
  TITLE: `${ObjPosition}.TITLE`,
  BUTTON_ADD: `${ObjPosition}.BUTTON_ADD`,
  GRID_COLUMN_NAME: `${ObjPosition}.GRID_COLUMN_NAME`,
  GRID_COLUMN_KEY: `${ObjPosition}.GRID_COLUMN_KEY`,
  GRID_COLUMN_FILTER_KEY: `${ObjPosition}.GRID_COLUMN_FILTER_KEY`,
  GRID_COLUMN_FILTER_NAME: `${ObjPosition}.GRID_COLUMN_FILTER_NAME`,
  GRID_EMPTY: `${ObjPosition}.GRID_EMPTY`,
  GRID_PAGE_REPORT_TEMPLATE: `${ObjPosition}.GRID_PAGE_REPORT_TEMPLATE`,
  GRID_SEARCH: `${ObjPosition}.GRID_SEARCH`,
  GRID_ACTION: `${ObjPosition}.GRID_ACTION`,
  CONFIRM_MESSAGE_DELETE: `${ObjPosition}.CONFIRM_MESSAGE_DELETE`,
  CONFIRM_DELETE: `${ObjPosition}.CONFIRM_DELETE`,
  DIALOG_ADD: `${ObjPosition}.DIALOG_ADD`,
  DIALOG_EDIT: `${ObjPosition}.DIALOG_EDIT`,
  DIALOG_CANCEL: `${ObjPosition}.DIALOG_CANCEL`,
  DIALOG_SAVE: `${ObjPosition}.DIALOG_SAVE`,
  VALIDATOR_ID_REQUIRED: `${ObjPosition}.VALIDATOR_ID_REQUIRED`,
  VALIDATOR_ID_REQUIRED_SIZE: `${ObjPosition}.VALIDATOR_ID_REQUIRED_SIZE`,
  VALIDATOR_ID_GREATER_THAN_ZERO: `${ObjPosition}.VALIDATOR_ID_GREATER_THAN_ZERO`,
  VALIDATOR_NAME_REQUIRED: `${ObjPosition}.VALIDATOR_NAME_REQUIRED`,
  VALIDATOR_NAME_REQUIRED_SIZE: `${ObjPosition}.VALIDATOR_NAME_REQUIRED_SIZE`,
  VALIDATOR_DIGIT_ALLOW: `${ObjPosition}.VALIDATOR_DIGIT_ALLOW`,
  SUCCESS_ADD: `${ObjPosition}.SUCCESS_ADD`,
  ERROR_ADD: `${ObjPosition}.ERROR_ADD`,
  SUCCESS_UPDATE: `${ObjPosition}.SUCCESS_UPDATE`,
  ERROR_UPDATE: `${ObjPosition}.ERROR_UPDATE`,
  SUCCESS_DELETE: `${ObjPosition}.SUCCESS_DELETE`,
  ERROR_DELETE: `${ObjPosition}.ERROR_DELETE`,
  EMPTY_POSITION: `${ObjPosition}.EMPTY_POSITION`,
  PERSONIA_DELETE: `${ObjPosition}.PERSONIA_DELETE`,
  PERSONIA_ADD: `${ObjPosition}.PERSONIA_ADD`
}

const ObjPositionPeriod = 'PositionPeriod'
export const PositionPeriodNames = {
  TITLE: `${ObjPositionPeriod}.TITLE`,
  SUCCESS_ADD: `${ObjPositionPeriod}.SUCCESS_ADD`,
  ERROR_ADD: `${ObjPositionPeriod}.ERROR_ADD`,
  SUCCESS_UPDATE: `${ObjPositionPeriod}.SUCCESS_UPDATE`,
  ERROR_UPDATE: `${ObjPositionPeriod}.ERROR_UPDATE`,
  SUCCESS_DELETE: `${ObjPositionPeriod}.SUCCESS_DELETE`,
  CONFIRM_DELETE: `${ObjPositionPeriod}.CONFIRM_DELETE`,
  CONFIRM_MESSAGE_DELETE: `${ObjPositionPeriod}.CONFIRM_MESSAGE_DELETE`,
  DIALOG_ADD: `${ObjPositionPeriod}.DIALOG_ADD`,
  DIALOG_EDIT: `${ObjPositionPeriod}.DIALOG_EDIT`,
  GRID_COLUMN_KEY: `${ObjPositionPeriod}.GRID_COLUMN_KEY`,
  GRID_COLUMN_NAME: `${ObjPositionPeriod}.GRID_COLUMN_NAME`,
  GRID_COLUMN_START_DATE: `${ObjPositionPeriod}.GRID_COLUMN_START_DATE`,
  GRID_COLUMN_END_DATE: `${ObjPositionPeriod}.GRID_COLUMN_END_DATE`,
  GRID_COLUMN_NOTES: `${ObjPositionPeriod}.GRID_COLUMN_NOTES`,
  GRID_COLUMN_ACTIVE: `${ObjPositionPeriod}.GRID_COLUMN_ACTIVE`,
  DIALOG_SAVE: `${ObjPositionPeriod}.DIALOG_SAVE`,
  DIALOG_CANCEL: `${ObjPositionPeriod}.DIALOG_CANCEL`,
  GRID_SEARCH: `${ObjPositionPeriod}.GRID_SEARCH`,
  GRID_EMPTY: `${ObjPositionPeriod}.GRID_EMPTY`,
  GRID_PAGE_REPORT_TEMPLATE: `${ObjPositionPeriod}.GRID_PAGE_REPORT_TEMPLATE`,
  GRID_COLUMN_FILTER_KEY: `${ObjPositionPeriod}.GRID_COLUMN_FILTER_KEY`,
  GRID_COLUMN_FILTER_NAME: `${ObjPositionPeriod}.GRID_COLUMN_FILTER_NAME`,
  GRID_COLUMN_FILTER_START_DATE: `${ObjPositionPeriod}.GRID_COLUMN_FILTER_START_DATE`,
  GRID_COLUMN_FILTER_END_DATE: `${ObjPositionPeriod}.GRID_COLUMN_FILTER_END_DATE`,
  GRID_COLUMN_END_NOTES: `${ObjPositionPeriod}.GRID_COLUMN_END_NOTES`,
  GRID_COLUMN_FILTER_END_NOTES: `${ObjPositionPeriod}.GRID_COLUMN_FILTER_END_NOTES`,
  GRID_FILTER_COLUMN_ACTIVE: `${ObjPositionPeriod}.GRID_FILTER_COLUMN_ACTIVE`,
  GRID_ACTION: `${ObjPositionPeriod}.GRID_ACTION`,
  BUTTON_ADD: `${ObjPositionPeriod}.BUTTON_ADD`,
  VALIDATOR_ID_REQUIRED: `${ObjPositionPeriod}.VALIDATOR_ID_REQUIRED`,
  VALIDATOR_DIGIT_ALLOW: `${ObjPositionPeriod}.VALIDATOR_DIGIT_ALLOW`,
  VALIDATOR_NAME_REQUIRED_SIZE: `${ObjPositionPeriod}.VALIDATOR_NAME_REQUIRED_SIZE`,
  NOTES: `${ObjPositionPeriod}.NOTES`
}

const ObjZone = 'Zone'
export const ZoneNames = {
  TITLE: `${ObjZone}.TITLE`,
  BUTTON_ADD: `${ObjZone}.BUTTON_ADD`,
  GRID_COLUMN_NAME: `${ObjZone}.GRID_COLUMN_NAME`,
  GRID_COLUMN_KEY: `${ObjZone}.GRID_COLUMN_KEY`,
  GRID_COLUMN_FILTER_KEY: `${ObjZone}.GRID_COLUMN_FILTER_KEY`,
  GRID_COLUMN_FILTER_NAME: `${ObjZone}.GRID_COLUMN_FILTER_NAME`,
  GRID_EMPTY: `${ObjZone}.GRID_EMPTY`,
  GRID_PAGE_REPORT_TEMPLATE: `${ObjZone}.GRID_PAGE_REPORT_TEMPLATE`,
  GRID_SEARCH: `${ObjZone}.GRID_SEARCH`,
  GRID_ACTION: `${ObjZone}.GRID_ACTION`,
  CONFIRM_MESSAGE_DELETE: `${ObjZone}.CONFIRM_MESSAGE_DELETE`,
  CONFIRM_DELETE: `${ObjZone}.CONFIRM_DELETE`,
  DIALOG_ADD: `${ObjZone}.DIALOG_ADD`,
  DIALOG_EDIT: `${ObjZone}.DIALOG_EDIT`,
  DIALOG_CANCEL: `${ObjZone}.DIALOG_CANCEL`,
  DIALOG_SAVE: `${ObjZone}.DIALOG_SAVE`,
  VALIDATOR_ID_REQUIRED: `${ObjZone}.VALIDATOR_ID_REQUIRED`,
  VALIDATOR_ID_REQUIRED_SIZE: `${ObjZone}.VALIDATOR_ID_REQUIRED_SIZE`,
  VALIDATOR_NAME_REQUIRED: `${ObjZone}.VALIDATOR_NAME_REQUIRED`,
  VALIDATOR_NAME_REQUIRED_SIZE: `${ObjZone}.VALIDATOR_NAME_REQUIRED_SIZE`,
  VALIDATOR_DIGIT_ALLOW: `${ObjZone}.VALIDATOR_DIGIT_ALLOW`,
  SUCCESS_ADD: `${ObjZone}.SUCCESS_ADD`,
  ERROR_ADD: `${ObjZone}.ERROR_ADD`,
  SUCCESS_UPDATE: `${ObjZone}.SUCCESS_UPDATE`,
  ERROR_UPDATE: `${ObjZone}.ERROR_UPDATE`,
  SUCCESS_DELETE: `${ObjZone}.SUCCESS_DELETE`,
  ERROR_DELETE: `${ObjZone}.ERROR_DELETE`,
  EMPTY_ZONE: `${ObjZone}.EMPTY_ZONE`
}

const ObjWorkCenter = 'WorkCenter'
export const WorkCenterNames = {
  TITLE: `${ObjWorkCenter}.TITLE`,
  BUTTON_ADD: `${ObjWorkCenter}.BUTTON_ADD`,
  GRID_COLUMN_NAME: `${ObjWorkCenter}.GRID_COLUMN_NAME`,
  GRID_COLUMN_KEY: `${ObjWorkCenter}.GRID_COLUMN_KEY`,
  GRID_COLUMN_ZIPCODE: `${ObjWorkCenter}.GRID_COLUMN_ZIPCODE`,
  GRID_COLUMN_STATUS: `${ObjWorkCenter}.GRID_COLUMN_STATUS`,
  GRID_COLUMN_STATE: `${ObjWorkCenter}.GRID_COLUMN_STATE`,
  GRID_COLUMN_MUNICIPALITY: `${ObjWorkCenter}.GRID_COLUMN_MUNICIPALITY`,
  GRID_COLUMN_STREET: `${ObjWorkCenter}.GRID_COLUMN_STREET`,
  GRID_COLUMN_EXTERIOR: `${ObjWorkCenter}.GRID_COLUMN_EXTERIOR`,
  GRID_COLUMN_INTERIOR: `${ObjWorkCenter}.GRID_COLUMN_INTERIOR`,
  GRID_COLUMN_EVALUATOR: `${ObjWorkCenter}.GRID_COLUMN_EVALUATOR`,
  GRID_COLUMN_ACTIVE: `${ObjWorkCenter}.GRID_COLUMN_ACTIVE`,
  GRID_COLUMN_SUBURB: `${ObjWorkCenter}.GRID_COLUMN_SUBURB`,
  GRID_COLUMN_FILTER_KEY: `${ObjWorkCenter}.GRID_COLUMN_FILTER_KEY`,
  GRID_COLUMN_FILTER_NAME: `${ObjWorkCenter}.GRID_COLUMN_FILTER_NAME`,
  CONFIRM_MESSAGE_DELETE: `${ObjWorkCenter}.CONFIRM_MESSAGE_DELETE`,
  CONFIRM_DELETE: `${ObjWorkCenter}.CONFIRM_DELETE`,
  GRID_EMPTY: `${ObjWorkCenter}.GRID_EMPTY`,
  GRID_PAGE_REPORT_TEMPLATE: `${ObjWorkCenter}.GRID_PAGE_REPORT_TEMPLATE`,
  GRID_ACTION: `${ObjWorkCenter}.GRID_ACTION`,
  GRID_SEARCH: `${ObjWorkCenter}.GRID_SEARCH`,
  DIALOG_ADD: `${ObjWorkCenter}.DIALOG_ADD`,
  DIALOG_EDIT: `${ObjWorkCenter}.DIALOG_EDIT`,
  DIALOG_CANCEL: `${ObjWorkCenter}.DIALOG_CANCEL`,
  DIALOG_SAVE: `${ObjWorkCenter}.DIALOG_SAVE`,
  VALIDATOR_ID_REQUIRED: `${ObjWorkCenter}.VALIDATOR_ID_REQUIRED`,
  VALIDATOR_ID_REQUIRED_SIZE: `${ObjWorkCenter}.VALIDATOR_ID_REQUIRED_SIZE`,
  VALIDATOR_NAME_REQUIRED: `${ObjWorkCenter}.VALIDATOR_NAME_REQUIRED`,
  VALIDATOR_NAME_REQUIRED_SIZE: `${ObjWorkCenter}.VALIDATOR_NAME_REQUIRED_SIZE`,
  VALIDATOR_DIGIT_ALLOW: `${ObjWorkCenter}.VALIDATOR_DIGIT_ALLOW`,
  VALIDATOR_ZIPCODE_REQUIRED: `${ObjWorkCenter}.VALIDATOR_ZIPCODE_REQUIRED`,
  VALIDATOR_ZIPCODE_MIN: `${ObjWorkCenter}.VALIDATOR_ZIPCODE_MIN`,
  VALIDATOR_ZIPCODE_MAX: `${ObjWorkCenter}.VALIDATOR_ZIPCODE_MAX`,
  VALIDATOR_STATE_REQUIRED: `${ObjWorkCenter}.VALIDATOR_STATE_REQUIRED`,
  VALIDATOR_MUNICIPALITY_REQUIRED: `${ObjWorkCenter}.VALIDATOR_MUNICIPALITY_REQUIRED`,
  VALIDATOR_MUNICIPALITY_MAX: `${ObjWorkCenter}.VALIDATOR_MUNICIPALITY_MAX`,
  VALIDATOR_STREET_MAX: `${ObjWorkCenter}.VALIDATOR_STREET_MAX`,
  VALIDATOR_EXTERIOR_MAX: `${ObjWorkCenter}.VALIDATOR_EXTERIOR_MAX`,
  VALIDATOR_INTERIOR_MAX: `${ObjWorkCenter}.VALIDATOR_INTERIOR_MAX`,
  VALIDATOR_SUBURB_MAX: `${ObjWorkCenter}.VALIDATOR_SUBURB_MAX`,
  VALIDATOR_EVALUATOR_ID: `${ObjWorkCenter}.VALIDATOR_EVALUATOR_ID`,
  SUCCESS_ADD: `${ObjWorkCenter}.SUCCESS_ADD`,
  ERROR_ADD: `${ObjWorkCenter}.ERROR_ADD`,
  SUCCESS_UPDATE: `${ObjWorkCenter}.SUCCESS_UPDATE`,
  ERROR_UPDATE: `${ObjWorkCenter}.ERROR_UPDATE`,
  SUCCESS_DELETE: `${ObjWorkCenter}.SUCCESS_DELETE`,
  ERROR_DELETE: `${ObjWorkCenter}.ERROR_DELETE`,
  GRID_COLUMN_PRINCIPAL_ACTIVITY: `${ObjWorkCenter}.GRID_COLUMN_PRINCIPAL_ACTIVITY`,
  GRID_COLUMN_OBJECTIVE: `${ObjWorkCenter}.GRID_COLUMN_OBJECTIVE`,
  GRID_COLUMN_ACTIVITIES: `${ObjWorkCenter}.GRID_COLUMN_ACTIVITIES`,
  VALIDATOR_PRINCIPAL_ACTIVITY: `${ObjWorkCenter}.VALIDATOR_PRINCIPAL_ACTIVITY`,
  VALIDATOR_STREE_REQUIRED: `${ObjWorkCenter}.VALIDATOR_STREE_REQUIRED`,
  VALIDATOR_EXTERIOR_REQUIRED: `${ObjWorkCenter}.VALIDATOR_EXTERIOR_REQUIRED`,
  EMPTY_WORK_CENTER: `${ObjWorkCenter}.EMPTY_WORK_CENTER`,
  VALIDATOR_SUBURB_REQUIRED: `${ObjWorkCenter}.VALIDATOR_SUBURB_REQUIRED`,
}

const ObjDepartments = 'Departments'
export const DepartmentsNames = {
  DIALOG_ADD: `${ObjDepartments}.DIALOG_ADD`,
  DIALOG_EDIT: `${ObjDepartments}.DIALOG_EDIT`,
  DIALOG_TITLE: `${ObjDepartments}.DIALOG_TITLE`,
  PERIOD_CONFIRM_MESSAGE_DELETE: `${ObjDepartments}.PERIOD_CONFIRM_MESSAGE_DELETE`,
  GRID_EMPTY: `${ObjDepartments}.GRID_EMPTY`,
  GRID_PAGE_REPORT_TEMPLATE: `${ObjDepartments}.GRID_PAGE_REPORT_TEMPLATE`,
  SUCCESS_ADD: `${ObjDepartments}.SUCCESS_ADD`,
  ERROR_ADD: `${ObjDepartments}.ERROR_ADD`,
  SUCCESS_UPDATE: `${ObjDepartments}.SUCCESS_UPDATE`,
  ERROR_UPDATE: `${ObjDepartments}.ERROR_UPDATE`,
  SUCCESS_DELETE: `${ObjDepartments}.SUCCESS_DELETE`,
  ERROR_DELETE: `${ObjDepartments}.ERROR_DELETE`,
  EMPTY_DEPARTMENT: `${ObjDepartments}.EMPTY_DEPARTMENT`,
  PERSONIA_ADD: `${ObjDepartments}.PERSONIA_ADD`,
  PERSONIA_DELETE: `${ObjDepartments}.PERSONIA_DELETE`
}

const ObjCollaborators = 'Collaborators'
export const CollaboratorsNames = {
  TITLE: `${ObjCollaborators}.TITLE`,
  VALIDATOR_NAME_REQUIRED: `${ObjCollaborators}.VALIDATOR_NAME_REQUIRED`,
  VALIDATOR_NAME_REQUIRED_SIZE: `${ObjCollaborators}.VALIDATOR_NAME_REQUIRED_SIZE`,
  VALIDATOR_LASTNAME_REQUIRED: `${ObjCollaborators}.VALIDATOR_LASTNAME_REQUIRED`,
  VALIDATOR_SURNAME_REQUIRED: `${ObjCollaborators}.VALIDATOR_SURNAME_REQUIRED`,
  VALIDATOR_EMAIL_REQUIRED: `${ObjCollaborators}.VALIDATOR_EMAIL_REQUIRED`,
  DIALOG_ADD: `${ObjCollaborators}.DIALOG_ADD`,
  DIALOG_EDIT: `${ObjCollaborators}.DIALOG_EDIT`,
  GRID_PAGE_REPORT_TEMPLATE: `${ObjCollaborators}.GRID_PAGE_REPORT_TEMPLATE`,
  GRID_EMPTY: `${ObjCollaborators}.GRID_EMPTY`,
  lAST_NAME: `${ObjCollaborators}.lAST_NAME`,
  SOR_NAME: `${ObjCollaborators}.SOR_NAME`,
  VALIDATOR_EMAIL: `${ObjCollaborators}.VALIDATOR_EMAIL`,
  VALIDATOR_AREA_REQUIRED: `${ObjCollaborators}.VALIDATOR_AREA_REQUIRED`,
  VALIDATOR_DEPARTMENT_REQUIRED: `${ObjCollaborators}.VALIDATOR_DEPARTMENT_REQUIRED`,
  VALIDATOR_POSITION_REQUIRED: `${ObjCollaborators}.VALIDATOR_POSITION_REQUIRED`,
  VALIDATOR_WORK_CENTER_REQUIRED: `${ObjCollaborators}.VALIDATOR_WORK_CENTER_REQUIRED`,
  VALIDATOR_NUMBER: `${ObjCollaborators}.VALIDATOR_NUMBER`,
  STATUS: `${ObjCollaborators}.STATUS`,
  HIGH: `${ObjCollaborators}.HIGH`,
  SHORT: `${ObjCollaborators}.SHORT`,
  REENTRY: `${ObjCollaborators}.REENTRY`,
  SUCCESS_ADD: `${ObjCollaborators}.SUCCESS_ADD`,
  ERROR_ADD: `${ObjCollaborators}.ERROR_ADD`,
  SUCCESS_UPDATE: `${ObjCollaborators}.SUCCESS_UPDATE`,
  ERROR_UPDATE: `${ObjCollaborators}.ERROR_UPDATE`,
  SUCCESS_DELETE: `${ObjCollaborators}.SUCCESS_DELETE`,
  CONFIRM_MESSAGE_DELETE: `${ObjCollaborators}.CONFIRM_MESSAGE_DELETE`,
  TAX_ID: `${ObjCollaborators}.TAX_ID`,
  VALIDATOR_TAX_ID_REQUIRED: `${ObjCollaborators}.VALIDATOR_TAX_ID_REQUIRED`,
  VALIDATOR_TAX_ID_SIZE: `${ObjCollaborators}.VALIDATOR_TAX_ID_SIZE`,
  EMPTY_COLLABORATOR: `${ObjCollaborators}.EMPTY_COLLABORATOR`,
  EMPTY_COLLABORATOR_ADD: `${ObjCollaborators}.EMPTY_COLLABORATOR_ADD`,
  EMPTY_COLLABORATOR_FILTER: `${ObjCollaborators}.EMPTY_COLLABORATOR_FILTER`,
  EMPTY_COLLABORATOR_NOT_FOUND: `${ObjCollaborators}.EMPTY_COLLABORATOR_NOT_FOUND`,
  PERSONIA_ADD: `${ObjCollaborators}.PERSONIA_ADD`,
  PERSONIA_DELETE: `${ObjCollaborators}.PERSONIA_DELETE`
}


const ObjScoreRange = 'ScoreRange'
export const ScoreRangeNames = {
  GRID_EMPTY: `${ObjScoreRange}.GRID_EMPTY`,
  GRID_PAGE_REPORT_TEMPLATE: `${ObjScoreRange}.GRID_PAGE_REPORT_TEMPLATE`,
  GRID_COLUMN_RANGE: `${ObjScoreRange}.GRID_COLUMN_RANGE`,
  GRID_COLUMN_RANGE_KEY: `${ObjScoreRange}.GRID_COLUMN_RANGE_KEY`,
  GRID_COLUMN_GROUPS: `${ObjScoreRange}.GRID_COLUMN_GROUPS`,
  GRID_COLUMN_NULL_MIN: `${ObjScoreRange}.GRID_COLUMN_NULL_MIN`,
  GRID_COLUMN_MAX: `${ObjScoreRange}.GRID_COLUMN_MAX`,
  GRID_COLUMN_MIN: `${ObjScoreRange}.GRID_COLUMN_MIN`,
  GRID_COLUMN_NULL: `${ObjScoreRange}.GRID_COLUMN_NULL`,
  GRID_COLUMN_SLOW: `${ObjScoreRange}.GRID_COLUMN_SLOW`,
  GRID_COLUMN_MEDIUM: `${ObjScoreRange}.GRID_COLUMN_MEDIUM`,
  GRID_COLUMN_HIGH: `${ObjScoreRange}.GRID_COLUMN_HIGH`,
  GRID_COLUMN_CRITICAL: `${ObjScoreRange}.GRID_COLUMN_CRITICAL`,
  GRID_COLUMN_ITEMS: `${ObjScoreRange}.GRID_COLUMN_ITEMS`,
  GRID_LABEL_GRID: `${ObjScoreRange}.GRID_LABEL_GRID`,
  Category: `${ObjScoreRange}.Category`,
  Domain: `${ObjScoreRange}.Domain`,
  Concept: `${ObjScoreRange}.Concept`,
}
const ObjQuestions = 'Questions'
export const QuestionsNames = {
  GRID_EMPTY: `${ObjQuestions}.GRID_EMPTY`,
  GRID_PAGE_REPORT_TEMPLATE: `${ObjQuestions}.GRID_PAGE_REPORT_TEMPLATE`,
  TITLE_1: `${ObjQuestions}.TITTLE_1`,
  TITLE_2: `${ObjQuestions}.TITTLE_2`,
  TITLE_3: `${ObjQuestions}.TITTLE_3`,
  TITLE_OWN: `${ObjQuestions}.TITTLE_OWN`,
  SUB_TITLE: `${ObjQuestions}.SUB_TITLE`,
  ID: `${ObjQuestions}.ID`,
  GROUP_QUESTION: `${ObjQuestions}.GROUP_QUESTION`,
  EMPTY_QUESTION: `${ObjQuestions}.EMPTY_QUESTION`
}
const ObjSurvey = 'Survey'
export const SurveyNames = {
  STEP_1: `${ObjSurvey}.STEP_1`,
  IDENTIFICATION_FREQUENCY: `${ObjSurvey}.IDENTIFICATION_FREQUENCY`,
  STEP_2_1: `${ObjSurvey}.STEP_2_1`,
  STEP_2: `${ObjSurvey}.STEP_2`,
  COLLABORATOR_LIST: `${ObjSurvey}.COLLABORATOR_LIST`,
  STEP_3: `${ObjSurvey}.STEP_3`,
  SEARCH_AND_collaborator_SELECTION: `${ObjSurvey}.SEARCH_AND_collaborator_SELECTION`,
  RENDER_SURVEY: `${ObjSurvey}.RENDER_SURVEY`,
  EVALUATION_PERIOD: `${ObjSurvey}.EVALUATION_PERIOD`,
  NUMBER_SURVEY: `${ObjSurvey}.NUMBER_SURVEY`,
  NAME: `${ObjSurvey}.NAME`,
  START_DATE: `${ObjSurvey}.START_DATE`,
  END_DATE: `${ObjSurvey}.END_DATE`,
  TOTAL_COLLABORATORS: `${ObjSurvey}.TOTAL_COLLABORATORS`,
  TO_SEND: `${ObjSurvey}.TO_SEND`,
  SEND: `${ObjSurvey}.SEND`,
  COMPLETED: `${ObjSurvey}.COMPLETED`,
  SEND_FOR_REPLY: `${ObjSurvey}.SEND_FOR_REPLY`,
  CURRENT_RESULT: `${ObjSurvey}.CURRENT_RESULT`,
  RESPONSE: `${ObjSurvey}.RESPONSE`,
  RESULT_COLOR: `${ObjSurvey}.RESULT_COLOR`,
  RESULT_DESCRIPTION: `${ObjSurvey}.RESULT_DESCRIPTION`,
  GRID_ACTION: `${ObjSurvey}.GRID_ACTION`,
  SELECT_collaborator: `${ObjSurvey}.SELECT_collaborator`,
  ADD_SURVEY: `${ObjSurvey}.ADD_SURVEY`,
  EDIT_SURVEY: `${ObjSurvey}.EDIT_SURVEY`,
  VALIDATOR_CODE_REQUIRED: `${ObjSurvey}.VALIDATOR_CODE_REQUIRED`,
  VALIDATOR_DIGIT_ALLOW: `${ObjSurvey}.VALIDATOR_DIGIT_ALLOW`,
  VALIDATOR_CODE_REQUIRED_SIZE: `${ObjSurvey}.VALIDATOR_CODE_REQUIRED_SIZE`,
  VALIDATOR_NAME: `${ObjSurvey}.VALIDATOR_NAME`,
  VALIDATOR_NAME_REQUIRED: `${ObjSurvey}.VALIDATOR_NAME_REQUIRED`,
  VALIDATOR_NAME_REQUIRED_SIZE: `${ObjSurvey}.VALIDATOR_NAME_REQUIRED_SIZE`,
  VALIDATOR_STARTDATE_REQUIRED: `${ObjSurvey}.VALIDATOR_STARTDATE_REQUIRED`,
  VALIDATOR_ENDDATE_REQUIRED: `${ObjSurvey}.VALIDATOR_ENDDATE_REQUIRED`,
  VALIDATOR_ENDDATE_BEFORE: `${ObjSurvey}.VALIDATOR_ENDDATE_BEFORE`,
  SUCCESS_ADD: `${ObjSurvey}.SUCCESS_ADD`,
  ERROR_ADD: `${ObjSurvey}.ERROR_ADD`,
  SUCCESS_UPDATE: `${ObjSurvey}.SUCCESS_UPDATE`,
  ERROR_UPDATE: `${ObjSurvey}.ERROR_UPDATE`,
  SUCCESS_DELETE: `${ObjSurvey}.SUCCESS_DELETE`,
  CONFIRM_MESSAGE_DELETE: `${ObjSurvey}.CONFIRM_MESSAGE_DELETE`,
  SURVEY: `${ObjSurvey}.SURVEY`,
  QUESTIONARY_TITLE: `${ObjSurvey}.QUESTIONARY_TITLE`,
  ERROR_DELETING_SURVEY: `${ObjSurvey}.ERROR_DELETING_SURVEY`,
  APPLICATION_DATE: `${ObjSurvey}.APPLICATION_DATE`,
  COLLABORATOR: `${ObjSurvey}.COLLABORATOR`,
  EMAIL: `${ObjSurvey}.EMAIL`,
  WORK_CENTER: `${ObjSurvey}.WORK_CENTER`,
  DEPARTMENT: `${ObjSurvey}.DEPARTMENT`,
  ZONE: `${ObjSurvey}.ZONE`,
  POSITION: `${ObjSurvey}.POSITION`,
  RESULT: `${ObjSurvey}.RESULT`,
  SUMMARY: `${ObjSurvey}.SUMMARY`,
  SEND_DATE: `${ObjSurvey}.SEND_DATE`,
  RESULT_VALUE: `${ObjSurvey}.RESULT_VALUE`,
  CONCLUSION: `${ObjSurvey}.CONCLUSION`,
  WITHOUT_CONCLUSION: `${ObjSurvey}.WITHOUT_CONCLUSION`,
  TABLE_RESULT_CATEGORY: `${ObjSurvey}.TABLE_RESULT_CATEGORY`,
  TABLE_RESULT_CATEGORY_SCORE: `${ObjSurvey}.TABLE_RESULT_CATEGORY_SCORE`,
  TABLE_RESULT_CATEGORY_DOMAIN: `${ObjSurvey}.TABLE_RESULT_CATEGORY_DOMAIN`,
  TABLE_RESULT_CATEGORY_DOMAIN_SCORE: `${ObjSurvey}.TABLE_RESULT_CATEGORY_DOMAIN_SCORE`,
  TABLE_RESULT_DIMENSION: `${ObjSurvey}.TABLE_RESULT_DIMENSION`,
  TABLE_RESULT_TOTAL: `${ObjSurvey}.TABLE_RESULT_TOTAL`,
  TABLE_RESULT_QUESTION: `${ObjSurvey}.TABLE_RESULT_QUESTION`,
  TABLE_RESULT_ITEMS: `${ObjSurvey}.TABLE_RESULT_ITEMS`,
  TABLE_RESULT_FACTOR: `${ObjSurvey}.TABLE_RESULT_FACTOR`,
  EMPTY_SURVEY: `${ObjSurvey}.EMPTY_SURVEY`,
  LINK_ERROR: `${ObjSurvey}.LINK_ERROR`,
  PERIOD_REQUIRED: `${ObjSurvey}.PERIOD_REQUIRED`,
  EVALUATION_PERIOD_REQUIRED: `${ObjSurvey}.EVALUATION_PERIOD_REQUIRED`,
  EMAIL_INVALID: `${ObjSurvey}.EMAIL_INVALID`,
  COLLABORATOR_INVALID: `${ObjSurvey}.COLLABORATOR_INVALID`,
  DEPARTMENT_NOT_EXIST: `${ObjSurvey}.DEPARTMENT_NOT_EXIST`,
}
const ObjSurveyCollaborators = 'SurveyCollaborators'
export const SurveyCollaboratorsNames = {
  QUESTIONNAIRE_ID: `${ObjSurveyCollaborators}.QUESTIONNAIRE_ID`,
  COLLABORATOR_NAME: `${ObjSurveyCollaborators}.COLLABORATOR_NAME`,
  DEPARTMENT: `${ObjSurveyCollaborators}.DEPARTMENT`,
  WORK_CENTER: `${ObjSurveyCollaborators}.WORK_CENTER`,
  EMAIL: `${ObjSurveyCollaborators}.EMAIL`,
  STATUS: `${ObjSurveyCollaborators}.STATUS`,
  VIEW_EVALUATION: `${ObjSurveyCollaborators}.VIEW_EVALUATION`,
  SHARE_LINK: `${ObjSurveyCollaborators}.SHARE_LINK`,
  SEND_EMAIL: `${ObjSurveyCollaborators}.SEND_EMAIL`,
  COLLABORATORS_LAST_NAME: `${ObjSurveyCollaborators}.COLLABORATORS_LAST_NAME`,
  COLLABORATORS_SURVEY: `${ObjSurveyCollaborators}.COLLABORATORS_SURVEY`,
  CANCEL_DATE: `${ObjSurveyCollaborators}.CANCEL_DATE`,
  SENT_DATE: `${ObjSurveyCollaborators}.SENT_DATE`,
  ANSWER_DATE: `${ObjSurveyCollaborators}.ANSWER_DATE`,
  QUESTIONNAIRE: `${ObjSurveyCollaborators}.QUESTIONNAIRE`,
  SEND: `${ObjSurveyCollaborators}.SEND`,
  CANCEL: `${ObjSurveyCollaborators}.CANCEL`,
  RESPONDED: `${ObjSurveyCollaborators}.RESPONDED`,
  COMPLETED: `${ObjSurveyCollaborators}.COMPLETED`,
  All: `${ObjSurveyCollaborators}.All`,
  FIRED: `${ObjSurveyCollaborators}.FIRED`,
  EMAIL_ERROR: `${ObjSurveyCollaborators}.EMAIL_ERROR`
}
const ObjQuestionaryCollaborators = 'QuestionaryCollaborators'
export const QuestionaryCollaboratorsNames = {
  QUESTIONARY_TITLE: `${ObjQuestionaryCollaborators}.QUESTIONARY_TITLE`,
  GRID_EMPTY: `${ObjQuestionaryCollaborators}.GRID_EMPTY`,
  GRID_PAGE_REPORT_TEMPLATE: `${ObjQuestionaryCollaborators}.GRID_PAGE_REPORT_TEMPLATE`,
  CATEGORY: `${ObjQuestionaryCollaborators}.CATEGORY`,
  QUESTION_NUMBER: `${ObjQuestionaryCollaborators}.QUESTION_NUMBER`,
  QUESTION_NAME: `${ObjQuestionaryCollaborators}.QUESTION_NAME`,
  ANSWER_VALUE: `${ObjQuestionaryCollaborators}.ANSWER_VALUE`,
  ALWAYS: `${ObjQuestionaryCollaborators}.ALWAYS`,
  ALMOST_ALWAYS: `${ObjQuestionaryCollaborators}.ALMOST_ALWAYS`,
  SOMETIMES: `${ObjQuestionaryCollaborators}.SOMETIMES`,
  ALMOST_NEVER: `${ObjQuestionaryCollaborators}.ALMOST_NEVER`,
  NEVER: `${ObjQuestionaryCollaborators}.NEVER`,
  AWSER_DATE: `${ObjQuestionaryCollaborators}.AWSER_DATE`,
  SEND: `${ObjQuestionaryCollaborators}.SEND`,
  CANCEL: `${ObjQuestionaryCollaborators}.CANCEL`,
  RESPONDED: `${ObjQuestionaryCollaborators}.RESPONDED`,
  COMPLETED: `${ObjQuestionaryCollaborators}.COMPLETED`,
  ALL: `${ObjQuestionaryCollaborators}.ALL`,
  YES: `${ObjQuestionaryCollaborators}.YES`,
  NO: `${ObjQuestionaryCollaborators}.NO`,
}

const ObjSurveysQuestions = 'SurveysQuestions'
export const SurveysQuestionsNames = {
  SurveyCollaboratorfinallyComponentTitle: `${ObjSurveysQuestions}.surveyCollaboratorfinallyComponentTitle`,
  SurveyCollaboratorfinallyComponentSubTitle: `${ObjSurveysQuestions}.surveyCollaboratorfinallyComponentSubTitle`,
  SurveyCollaboratorfinallyComponentBody: `${ObjSurveysQuestions}.surveyCollaboratorfinallyComponentBody`,
  SurveyCollaboratorfinallyComponentFotter: `${ObjSurveysQuestions}.surveyCollaboratorfinallyComponentFotter`,
  SurveyPersonal: `${ObjSurveysQuestions}.surveyPersonal`,
  SurveyDepartment: `${ObjSurveysQuestions}.surveyDepartment`,
  SurveyPosition: `${ObjSurveysQuestions}.surveyPosition`,
  SurveyInfo: `${ObjSurveysQuestions}.surveyInfo`,
  SurveyInfo2: `${ObjSurveysQuestions}.surveyInfo2`,
  SurveyPrivacyNotice: `${ObjSurveysQuestions}.surveyPrivacyNotice`,
  SurveyTherms: `${ObjSurveysQuestions}.surveyTherms`,
  SurveySegurityQuestions: `${ObjSurveysQuestions}.surveySegurityQuestions`,
  SurveyPrivacy: `${ObjSurveysQuestions}.suveyPrivacy`,
  surveyMessageSuccess: `${ObjSurveysQuestions}.surveyMessageSuccess`,
  surveyMessageError: `${ObjSurveysQuestions}.surveyMessageError`,
  SurveyMessageDontStart: `${ObjSurveysQuestions}.SurveMessageDontStart`,
  SurveyMessageFinish: `${ObjSurveysQuestions}.surverMessageFinsh`,
  SurveyMessageAnswered: `${ObjSurveysQuestions}.SurveyMessageAnswerd`,
  SurveyMessageDeleted: `${ObjSurveysQuestions}.SurveyMessageDeleted`,
  SurveyMessageCancel: `${ObjSurveysQuestions}.SurveyMessageCancel`,
  SurveyMessageInfoData: `${ObjSurveysQuestions}.SurveyMessageInfoData`,
  SurveyMessageLoadSuccessfully: `${ObjSurveysQuestions}.SurveyMessageLoadSuccefull`,
  SurveyMessageLoadError: `${ObjSurveysQuestions}.SurveyMessageLoadError`,
  SurveyError: `${ObjSurveysQuestions}.SurveyError`,
  SurveyAnswered: `${ObjSurveysQuestions}.SurveyAnswered`,
  SurveyCompanyInformationNotFound: `${ObjSurveysQuestions}.SurveyCompanyInformationNotFound`,
  SurveyUnanswered: `${ObjSurveysQuestions}.SurveyUnanswered`,
  SurveyNextButton: `${ObjSurveysQuestions}.SurveyNextButton`,
  SurveyPreviousButton: `${ObjSurveysQuestions}.SurveyPreviousButton`,
  SurveyButtonStart: `${ObjSurveysQuestions}.SurveyButtonStart`,
  SurveyInfoAlertStart: `${ObjSurveysQuestions}.SurveyInfoAlertStart`,
  SurveyButtonSend: `${ObjSurveysQuestions}.SurveyButtonSend`,
  SurveyInfoAlertEnd: `${ObjSurveysQuestions}.SurveyInfoAlertEnd`,
  SurveyAlwaysText: `${ObjSurveysQuestions}.SurveyAlwaysText`,
  SurveyAlmostAlwaysText: `${ObjSurveysQuestions}.SurveyAlmostAlwaysText`,
  SurveySometimesText: `${ObjSurveysQuestions}.SurveySometimesText`,
  SurveyAlmostNeverText: `${ObjSurveysQuestions}.SurveyAlmostNeverText`,
  SurveyNeverText: `${ObjSurveysQuestions}.SurveyNeverText`,
  SurveyYesText: `${ObjSurveysQuestions}.SurveyYesText`,
  SurveyNoText: `${ObjSurveysQuestions}.SurveyNoText`,
}
const ObjResults = 'Results'
export const ResultsNames = {
  REPORT: `${ObjResults}.REPORT`,
  RESULT: `${ObjResults}.RESULT`,
  GENERAL_RESULT: `${ObjResults}.GENERAL_RESULT`,
  SINCE: `${ObjResults}.SINCE`,
  TO: `${ObjResults}.TO`,
  RECOMMENDATION: `${ObjResults}.RECOMMENDATION`,
  SELECT_SURVEY: `${ObjResults}.SELECT_SURVEY`,
  CONCLUSION: `${ObjResults}.CONCLUSION`
}

const ObjCompany = 'Company'
export const CompanyNames = {
  NAME: `${ObjCompany}.NAME`,
  ALIAS: `${ObjCompany}.ALIAS`,
  RFC: `${ObjCompany}.RFC`,
  CP: `${ObjCompany}.CP`,
  STATE: `${ObjCompany}.STATE`,
  MUNICIPALTY: `${ObjCompany}.MUNICIPALTY`,
  SUBURB: `${ObjCompany}.SUBURB`,
  STREET: `${ObjCompany}.STREET`,
  EXTERIOR: `${ObjCompany}.EXTERIOR`,
  INTERIOR: `${ObjCompany}.INTERIOR`,
  LOGO: `${ObjCompany}.LOGO`,
  ADDRESS: `${ObjCompany}.ADDRESS`,
  VALIDATOR_REQUIRED: `${ObjCompany}.VALIDATOR_REQUIRED`,
  CANCEL: `${ObjCompany}.CANCEL`,
  UPDATE: `${ObjCompany}.UPDATE`,
  VALIDATOR_TAX_ID: `${ObjCompany}.VALIDATOR_TAX_ID`,
  SUCCESS_ADD: `${ObjCompany}.SUCCESS_ADD`,
  ERROR_ADD: `${ObjCompany}.ERROR_ADD`,
  LOGO_VALIDATOR: `${ObjCompany}.LOGO_VALIDATOR`,
  CLEAN: `${ObjCompany}.CLEAN`,
  SELECT_COMPANY: `${ObjCompany}.SELECT_COMPANY`,
  ZIP_CODE_NOT_FOUND: `${ObjCompany}.ZIP_CODE_NOT_FOUND`,
  CONFIRM_DIALOG: `${ObjCompany}.CONFIRM_DIALOG`,
  CONFIRM_REMOVE_RELATION: `${ObjCompany}.CONFIRM_REMOVE_RELATION`,
  VALIDATOR_NAME_REQUIRED_SIZE: `${ObjCompany}.VALIDATOR_NAME_REQUIRED_SIZE`,
}

const ObjQuery = 'Query'
export const QueryNames = {
  NEW_QUERY: `${ObjQuery}.NEW_QUERY`,
  QUERY_NAME: `${ObjQuery}.QUERY_NAME`,
  QUERY_DESCRIPTION: `${ObjQuery}.QUERY_DESCRIPTION`,
  QUERY_NAME_REQUIRED: `${ObjQuery}.QUERY_NAME_REQUIRED`,
  QUERY_NAME_MAX_LENGTH: `${ObjQuery}.QUERY_NAME_MAX_LENGTH`,
  QUERY_DESCRIPTION_REQUIRED: `${ObjQuery}.QUERY_DESCRIPTION_REQUIRED`,
  QUERY_DISCARD_CHANGES: `${ObjQuery}.QUERY_DISCARD_CHANGES`,
  QUERY_CATEGORY: `${ObjQuery}.QUERY_CATEGORY`,
  QUERY_CATEGORY_REQUIRED: `${ObjQuery}.QUERY_CATEGORY_REQUIRED`,
  QUERY_OPEN: `${ObjQuery}.QUERY_OPEN`,
  QUERY_DELETE: `${ObjQuery}.QUERY_DELETE`,
  QUERY_RENAME: `${ObjQuery}.QUERY_RENAME`,
  QUERY_SAVE_AS: `${ObjQuery}.QUERY_SAVE_AS`,
  QUERY_GET_ERROR: `${ObjQuery}.QUERY_GET_ERROR`,
  QUERY_UPDATE_ERROR: `${ObjQuery}.QUERY_UPDATE_ERROR`,
  QUERY_DELETE_ERROR: `${ObjQuery}.QUERY_DELETE_ERROR`,
  QUERY_POST_ERROR: `${ObjQuery}.QUERY_POST_ERROR`,
  ENTITY_GET_ERROR: `${ObjQuery}.ENTITY_GET_ERROR`,
  FILTER_CONTAINS: `${ObjQuery}.FILTER_CONTAINS`,
  FILTER_NOT_CONTAINS: `${ObjQuery}.FILTER_NOT_CONTAINS`,
  FILTER_EQUAL: `${ObjQuery}.FILTER_EQUAL`,
  FILTER_DIFFERENT: `${ObjQuery}.FILTER_DIFFERENT`,
  FILTER_NULL: `${ObjQuery}.FILTER_NULL`,
  FILTER_NOT_NULL: `${ObjQuery}.FILTER_NOT_NULL`,
  FILTER_RANGE: `${ObjQuery}.FILTER_RANGE`,
  FILTER_GREATER: `${ObjQuery}.FILTER_GREATER`,
  FILTER_LESS: `${ObjQuery}.FILTER_LESS`,
  QUERY_NOT_SAVED: `${ObjQuery}.QUERY_NOT_SAVED`,
  QUERY_DELETE_MODAL_TITLE: `${ObjQuery}.QUERY_DELETE_MODAL_TITLE`,
  QUERY_DELETE_MODAL_QUESTION: `${ObjQuery}.QUERY_DELETE_MODAL_QUESTION`,
  QUERY_EDIT: `${ObjQuery}.QUERY_EDIT`,
  SAVE_CHANGES: `${ObjQuery}.SAVE_CHANGES`,
  QUERY_COPY_MESSAGE: `${ObjQuery}.QUERY_COPY_MESSAGE`,
  QUERY_COPY_TITLE: `${ObjQuery}.QUERY_COPY_TITLE`,
  QUERY_COPY_NAME: `${ObjQuery}.QUERY_COPY_NAME`,
  QUERY_COPY: `${ObjQuery}.QUERY_COPY`,
  QUERY_EXCEL_FILTER: `${ObjQuery}.QUERY_EXCEL_FILTER`
}

const ObjectDepartment = 'Department'
export const DepartmentNames = {
  DepartmentCodeValidation: `${ObjectDepartment}.codeValidation`,
  DepartmentCodeIsRequired: `${ObjectDepartment}.codeIsRequired`,
  DepartmentNameValidation: `${ObjectDepartment}.nameValidation`
}

const ObjectImport = 'Import'
export const ImportNames = {
  COLLABORATOR: `${ObjectImport}.COLLABORATOR`,
  AREA: `${ObjectImport}.AREA`,
  DEPARTMENT: `${ObjectImport}.DEPARTMENT`,
  POSITION: `${ObjectImport}.POSITION`,
  WORK_CENTER: `${ObjectImport}.WORK_CENTER`,
  EVALUATOR: `${ObjectImport}.EVALUATOR`,
  DROPDOWN_TITLE: `${ObjectImport}.DROPDOWN_TITLE`,
  FILE_UPLOAD_TITLE: `${ObjectImport}.FILE_UPLOAD_TITLE`,
  FILE_UPLOAD_ERROR: `${ObjectImport}.FILE_UPLOAD_ERROR`,
  FILE_WRONG_FORMAT: `${ObjectImport}.FILE_WRONG_FORMAT`,
  IMPORT_BUTTON: `${ObjectImport}.IMPORT_BUTTON`,
  IMPORT_SUCCESS: `${ObjectImport}.IMPORT_SUCCESS`,
  IMPORT_ERROR: `${ObjectImport}.IMPORT_ERROR`,
  IMPORT_TITLE: `${ObjectImport}.IMPORT_TITLE`,
  REPLACE_TITLE: `${ObjectImport}.REPLACE_TITLE`,
  IMPORT_TEXT: `${ObjectImport}.IMPORT_TEXT`,
  IMPORT_REPLACE_TEXT: `${ObjectImport}.IMPORT_REPLACE_TEXT`,
  DOWNLOAD_BUTTON: `${ObjectImport}.DOWNLOAD_BUTTON`,
  IMPORT_DATA_SUCCESS: `${ObjectImport}.IMPORT_DATA_SUCCESS`,
  GUIDE_1: `${ObjectImport}.GUIDE_1`,
  GUIDE_2: `${ObjectImport}.GUIDE_2`,
  GUIDE_3: `${ObjectImport}.GUIDE_3`,
}