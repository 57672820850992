import { GET_STATES, GET_STATES_ERROR, GET_STATES_SUCCESS, ERROR_SHOW } from '../../constants';

export const states = (state, actions) => {
    switch (actions.type) {
        case GET_STATES:
            return {
                ...state,
                loading: true,
            }   
        case GET_STATES_ERROR:
            return {
                ...state,
                loading: false,
                error: actions.payload,
                errorShow: false
            }
        case GET_STATES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                errorShow: false,
                states: actions.payload
            }
        case ERROR_SHOW:
            return {
                ...state,
                errorShow: true
            }
        default:
            return state;
    }
}