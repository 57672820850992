import { sortBy } from 'lodash'
import { getImportOptions } from '../../../utils/utils'
import { IsNullOrEmpty } from '../../../utils/utilsService'
import { ERROR_SHOW, GET_DEPARTMENT, GET_DEPARTMENT_ERROR, GET_DEPARTMENT_SUCCESS } from '../../constants'
const getDepartmentsOptions = (Departments) => {
    const activeDepartments = Departments.filter((object) => {
        return IsNullOrEmpty(object.deleteBy)
    })
    return sortBy(activeDepartments, [
        function (o) {
            return o.name
        }
    ]).map((item) => ({ label: item.name, value: item.id, name: item.name }))
}


export const department = (state, actions) => {
    switch (actions.type) {
        case GET_DEPARTMENT:
            return {
                departments: state.departments,
                loading: true,
                error: null,
                errorShow: false
            }
        case GET_DEPARTMENT_ERROR:
            return {
                departments: state.departments,
                loading: false,
                error: actions.payload,
                errorShow: false
            }
        case GET_DEPARTMENT_SUCCESS:
            return {
                departments: actions.payload,
                departmentsOptions: getDepartmentsOptions(actions.payload),
                importOptions: getImportOptions(actions.payload),
                loading: false,
                error: null,
                errorShow: false
            }
        case ERROR_SHOW:
            return {
                departments: state.departments,
                loading: false,
                error: state.error,
                errorShow: true
            }
        default:
            return state;
    }
}