import { useContext, useEffect, useState } from 'react'
import { GET_SURVEY, GET_SURVEY_DETAIL, GET_SURVEY_ERROR, GET_SURVEY_RESULT, GET_SURVEY_SUCCESS } from '../../business/constants'
import { StoreContext } from '../../business/Provider'
import { Toast } from '../../Components/toast'
import { SurveyNames } from '../../translations'
import useApi from '../api'
import { getTranslation, useCustomTranslation } from '../useTranslations'

const useGuideSurveys = (guideId) => {
  const baseUrl = `${window.REACT_APP_EVALUA_API_URI}/guide/${guideId}`
  const [surveys, setSurveys] = useState(undefined)
  const [loadingSurveys, setloadingSurveys] = useState(false)
  const { genericApiCall } = useApi();
  const {surveysState, dispatchSurveys, dispatchSurveysResult} = useContext(StoreContext);
  const {t} = useCustomTranslation()
  const getSurveysList = async () => {
    try {

      setloadingSurveys(true)
      const response = await getSurvey()
      setSurveys(response.data.data.surveys)
      setloadingSurveys(false)
    } catch (error) {
      // console.log('error Survey->', error)
      setSurveys([])
      setloadingSurveys(false)
    }
  }

  const getSurvey = async () => {
    try{
      dispatchSurveys({ type: GET_SURVEY })
      const result = await genericApiCall(`${baseUrl}/surveys`, 'GET')
      if(result.status === 200) {
        const payload = {
          data : result.data.data.surveys,
          guideId : guideId
        }
        dispatchSurveys({ type: GET_SURVEY_SUCCESS, payload: payload })
      } else {
        let message = {
          body: '',
          header: getTranslation(t, SurveyNames.ERROR_ADD)
        }
        if (result.message) message.body = result.message
        dispatchSurveys({ type: GET_SURVEY_ERROR, payload: message })
      }
      return result
    } catch (error) {
      Toast('warning',error.message, "", error)
      dispatchSurveys({ type: GET_SURVEY_ERROR})
    }     
  }
  const postsurveys = async (survey) => {
    try{
      const result = await genericApiCall(`${baseUrl}/surveys`, 'POST', survey)
      if (result.status === 200) {
        Toast('success', getTranslation(t, SurveyNames.SUCCESS_ADD))
        return result
      } else {
        let message = {
          body: '',
          header: getTranslation(t, SurveyNames.ERROR_ADD)
        }
        if(result.message) message.body = result.message
        dispatchSurveys({ type: GET_SURVEY_ERROR, payload: message })
        return undefined
      }
    } catch (error) {
      Toast('warning',error.message, "", error)
      dispatchSurveys({ type: GET_SURVEY_ERROR})
    }     
  }
  //Aquí se manda el toast al editar encuesta 
  const putSurveys = async (survey) => {
    try{
      const result = await genericApiCall(`${baseUrl}/surveys`, 'PUT', survey)
      if (result.status === 200) {
        Toast('success', getTranslation(t, SurveyNames.SUCCESS_UPDATE))
        return result
      } else {
        let message = {
          body: '',
          header: getTranslation(t, SurveyNames.ERROR_UPDATE)
        }
        if(result.message) message.body = result.message
        dispatchSurveys({ type: GET_SURVEY_ERROR, payload: message })
        return undefined
      }
    } catch (error) {
      Toast('warning',error.message, "", error)
      dispatchSurveys({ type: GET_SURVEY_ERROR})
    }     
  }


  const getNextCode = async () => {
    try{
      return genericApiCall(`${baseUrl}/surveys/next-code`, 'get')
    } catch (error) {
      Toast('warning',error.message, "", error)
    }     
  }

  const getSurveyDetails = async (surveyId) => {
    try{
      dispatchSurveys({ type: GET_SURVEY })
      const result = await genericApiCall(`${baseUrl}/surveys/${surveyId}`, 'GET')
      if(result.status === 200) {
        dispatchSurveys({ type: GET_SURVEY_DETAIL })
        return result.data.data.survey
      } else {
        let message = ''
        if (result.data) message = result.data.message
        else message = result.message
        dispatchSurveys({ type: GET_SURVEY_ERROR, payload: message })
      }

      return result
    } catch (error) {
      Toast('warning',error.message, "", error)
      dispatchSurveys({ type: GET_SURVEY_ERROR})
    }     
  }

  const getSurveyResults = async (surveyId, filter) => {
    try{
      dispatchSurveysResult({ type: GET_SURVEY })
      const result = await genericApiCall(`${window.REACT_APP_EVALUA_API_URI}/surveys/${surveyId}/results`, 'post', filter)
      if(result.status === 200) {
        console.log('result->', result.data.data)
        dispatchSurveysResult({ type: GET_SURVEY_RESULT, payload: result.data.data.results })
      } else {
        let message = ''
        if (result.data) message = result.data.message
        else message = result.message
        dispatchSurveysResult({ type: GET_SURVEY_ERROR, payload: message })
      }
      return result
    } catch (error) {
      Toast('warning',error.message, "", error)
      dispatchSurveysResult({ type: GET_SURVEY_ERROR})
    }     
  }

  const deleteSurveys = async (survey) => {
    try{
      const result = await genericApiCall(`${baseUrl}/surveys`, 'DELETE', survey)
      if (result.status === 200) {
        Toast('success', getTranslation(t, SurveyNames.SUCCESS_DELETE))
        return result.data.data
      } else {
        let message = {
          body: '',
          header: 'Error'
        }
        if(result.message) message.body = result.message
        dispatchSurveys({ type: GET_SURVEY_ERROR, payload: message })
        return undefined
      }
    } catch (error) {
      Toast('warning',error.message, "", error)
      dispatchSurveys({ type: GET_SURVEY_ERROR})
    }     
  }

  useEffect(() => {
      // check if questionsState contains the key { 1 : [], 2 : [] }
      const contain = Object.keys(surveysState.surveys).includes(guideId)
    if ( surveysState.loading === false && surveysState.guideId !== guideId && contain === false ) {
      getSurvey()
    }
  }, [guideId])

  return {
    setSurveys,
    surveys,
    loadingSurveys,
    postsurveys,
    putSurveys,
    deleteSurveys,
    getNextCode,
    getSurvey,
    getSurveyDetails,
    getSurveyResults,
    getSurveysList
  }
}
export default useGuideSurveys
