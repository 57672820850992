import React from 'react'
import PropTypes from 'prop-types'
import './styles.css'
import { Button } from 'primereact/button';
import { btnDropDown } from '../../assets/customStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { date } from 'yup';
// comments about props
// onClick: function
// text: string (text to display)
// icon: html element (icon to display) ex: <FontAwesomeIcon icon={faPlus} />
// type: string (primary, secondary, success, info, warning, danger)
// disabled: boolean
export const ButtonDropDown = ({ children, onClick, keyItem, title, Icon = null, disabled = false, className, ...props }) => {
    // console.log('ButtonDropDown', keyItem + '' + new Date().getTime())
    const iconRender = Icon ? <FontAwesomeIcon className='!text-black' icon={Icon} /> : null
    return (
        <Button
        aria-controls="popup_menu" aria-haspopup 
            key={keyItem}
            disabled={disabled}
            onClick={() => onClick()}
            variant="link"
            className={`!border-0 ${btnDropDown} 
            flex !font-h5-font-family !rounded-none  !justify-start hover:!border hover:border-secondary ${className}`}
            {...props}
        >
            {iconRender}
            {title?<p className='pl-2'>{title}</p> : null}
            
            {children}
        </Button>
    )
}