import { MultiSelect } from 'primereact/multiselect'
import React from 'react'
import PropTypes from 'prop-types'
import { getTranslation, useCustomTranslation } from '../../hooks/useTranslations'
import { GenericsNames } from '../../translations'
export const MultiSelectComponent = ({ id, name, options, value, onChange, _onOpen, display, maxSelectedLabels, filter, showClear, title, error, errorText, onHide, ...props }) => {
    const { t } = useCustomTranslation()
    return (
        <div className="field w-full rounded-none !font-input-font-family">
            <label className='text-small-font-size font-semibold'>{title}</label>
            <MultiSelect
                className={`w-full !rounded-none !text-font-size-base ${error ? "p-invalid" : ''}`}
                id={id}
                name={name}
                options={options}
                value={value}
                onChange={onChange}
                //   onOpen={onOpen}
                display={display}
                maxSelectedLabels={maxSelectedLabels}
                filter={filter}
                showClear={showClear}
                onHide={onHide}
                emptyFilterMessage={getTranslation(t, GenericsNames.WITHOUT_RESULT)}
                {...props}

            />
            {error && <small id={`${name}-${errorText}`} className="p-error block">{errorText}</small>}
        </div>

    )
}
MultiSelectComponent.defaultProps = {
    id: '',
    name: '',
    options: [],
    value: [],
    onChange: () => { },
    //  onOpen: () => {},
    display: '',
    maxSelectedLabels: 3,
    filter: true,
    showClear: true,
    title: '',
    error: false,
    errorText: '',
    onHide: () => { }
}
MultiSelectComponent.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.array,
    value: PropTypes.array,
    onChange: PropTypes.func,
    //  onOpen: PropTypes.func,
    display: PropTypes.string,
    maxSelectedLabels: PropTypes.number,
    filter: PropTypes.bool,
    showClear: PropTypes.bool,
    title: PropTypes.string,
    error: PropTypes.bool,
    errorText: PropTypes.string
}
