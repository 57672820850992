import _ from '../../../RouterFiles/@lodash'
import { b2cSignOut } from './../../b2cService'
import { createSlice } from '@reduxjs/toolkit'
import history from '../../../RouterFiles/@history';

export const setUserData = (token) => async (dispatch) => {

  const user = {
    role: ['user'],
    from: 'b2c',
    data: {
      displayName: token.account.idToken.given_name,
      photoURL: undefined,
      email: token.account.idToken.email,
      shortcuts: ['example']
    }
  }

  return dispatch(setUser(user))
}

export const logoutUser = (instance) => async (dispatch, getState) => {
  const { user } = getState().auth

  if (!user.role || user.role.length === 0) {
    // is guest
    return null
  }

  history.push({
    pathname: '/'
  })
  b2cSignOut(instance)   
  return dispatch(userLoggedOut())
}

const initialState = {
  role: [], // guest
  data: {
    displayName: '',
    photoURL: 'assets/images/avatars/Velazquez.jpg',
    email: '',
    shortcuts: ['calendar', 'mail', 'contacts', 'todo']
  }
}

const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      return action.payload
    } ,
    userLoggedOut: (state, action) => initialState
  },
  extraReducers: {}
})

export const { setUser, userLoggedOut } = userSlice.actions

export default userSlice.reducer
