export const SurveyObject = {
   surveyId: undefined,
   code: '',
   name: '',
   conclusion: '',
   startDate: new Date(),
   endDate:  new Date(),
   questionaryid: 0,
   scode:'',
   collaborators:[],
   searchStartDate:null,
   searchEndDate:null,
   scodeSelect:[],
   result:'Todos',
}


     