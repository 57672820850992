import { EntityToExport, ToExcel, ToPdf } from '../../../../utils/exportService'
import { GenericsNames, PositionNames } from '../../../../translations';
import React, { useContext, useState } from 'react'
import { printGrid } from '../../../../utils/utils'
import { faPencilAlt, faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { getTranslation, useCustomTranslation } from '../../../../hooks/useTranslations';

import { Column } from 'primereact/column';
import { CustomIconButton } from '../../../../Components/Buttons/CustomIconButton';
import { EvaluaTable } from '../../../../Components/Table/EvaluaTable';
import { FilterMatchMode } from 'primereact/api';
import PositionObject from '../../../../utils/Constanst/FormikConstanst/Position/PositionObject';
import PositionSchema from '../../../../utils/Constanst/FormikConstanst/Position/PositionSchema';
import { StoreContext } from '../../../../business/Provider';
import { TableButton } from '../../../../Components/Buttons/TableButtons';
import ToolBarThreeColumns from '../../../../Components/Table/ToolBarThreeColumns';
import { useFormik } from 'formik'
import { Toast } from '../../../../Components/toast';

export const PositionGrid = ({
  onEdit,
  onDelete,
  loading,
  rows,
  selectionMode,
  selection,
  handleOnAdd,
  onSelectionChange,
  hasRelationWithPersonia
}) => {
  const { t } = useCustomTranslation();
  const { dispatchLoading } = useContext(StoreContext);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [sort, setSort] = useState([{ field: '', order: -1 }])

  const formik = useFormik({
    initialValues: { ...PositionObject },
    validationSchema: PositionSchema,
    //onSubmit: addOrUpdateDepartments
  })

  const onGlobalFilterChange = (e) => {
    const { value } = e.target;
    const _filters = { ...filters };
    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const exportColumns = [{ title: getTranslation(t, PositionNames.GRID_COLUMN_KEY), dataKey: 'scode' },
  { title: getTranslation(t, PositionNames.GRID_COLUMN_NAME), dataKey: 'name' },];
  const handleExcelExport = async () => {
    const printObj = { ...filters, sort: sort[0] };
    rows.length > 0 ? await ToExcel(printObj, EntityToExport.CATALOG_POSITION, dispatchLoading) : Toast('warning', getTranslation(t, GenericsNames.NO_EXPORT_DATA))
  }
  const handleToPDF = async () => {
    const printObj = { ...filters };
    rows.length > 0 ? await ToPdf(printObj, EntityToExport.CATALOG_POSITION, dispatchLoading) : Toast('warning', getTranslation(t, GenericsNames.NO_EXPORT_DATA))
  }
  const renderHeader = () => (
    <ToolBarThreeColumns
      setValue={setGlobalFilterValue}
      setFilters={setFilters}
      rightChildren={
        <CustomIconButton
          className='!m-1'
          disabled={hasRelationWithPersonia}
          toolTip={hasRelationWithPersonia ? getTranslation(t, PositionNames.PERSONIA_ADD) : getTranslation(t, GenericsNames.ADD)}
          onClick={() => { handleOnAdd() }}
          icon={faPlusCircle}
        />}
      value={globalFilterValue}
      onChange={onGlobalFilterChange}
      formik={formik}
      placeholder={getTranslation(t, GenericsNames.SEARCH)}
      onExcelClick={() => {
        handleExcelExport()
        //const printObj={exportableColumns:exportColumns , rows:rows,fileName:getTranslation(t, PositionNames.TITLE),title:getTranslation(t, PositionNames.TITLE),subTitle:''};     
        //exportToExcelV3(printObj)
      }}
      onPDFClick={() => {
        handleToPDF()
      }}
      onPrintClick={() => {
        const printObj = { exportableColumns: exportColumns, rows: rows, title: getTranslation(t, PositionNames.TITLE), subTitle: '' };
        printGrid(printObj);
      }}
    >

    </ToolBarThreeColumns>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <TableButton
          onClick={() => onEdit(rowData)}
          icon={faPencilAlt}
          toolTip={getTranslation(t, GenericsNames.EDIT)}
        />
        <TableButton
          onClick={() => onDelete(rowData)}
          icon={faTrashAlt}
          toolTip={hasRelationWithPersonia ? getTranslation(t, PositionNames.PERSONIA_DELETE) : getTranslation(t, GenericsNames.DELETE)}
          disabled={hasRelationWithPersonia}
        />
      </>
    )
  }

  return (
    <div className='datatable-doc-demo'>
      <div className='card'>
        <EvaluaTable
          sort={sort}
          setSort={setSort}
          value={rows}
          sortField='name'
          header={renderHeader()}
          filters={filters}
          //filterDisplay='row' // input search
          responsiveLayout='scroll'
          globalFilterFields={['name', 'scode']}
          emptyMessage={getTranslation(t, PositionNames.EMPTY_POSITION)}
          currentPageReportTemplate={getTranslation(t, PositionNames.GRID_PAGE_REPORT_TEMPLATE)}
          doubleClick={onEdit}
          // height={height}
          selectionMode={selectionMode}
          selection={selection}
          onSelectionChange={onSelectionChange}
          columnResizeMode="fit"
          loading={loading}
        >
          {selectionMode &&
            <Column
              selectionMode="multiple"
              //headerStyle={{ width: "5px" }}
              style={{ flexGrow: 1, flexBasis: '100px' }}
            ></Column>

          }
          <Column
            field='scode'
            header={getTranslation(t, PositionNames.GRID_COLUMN_KEY)}
            sortable
            style={{ flexGrow: 1, flexBasis: '100px' }}
          />
          <Column
            field='name'
            header={getTranslation(t, PositionNames.GRID_COLUMN_NAME)}
            sortable
            style={{ flexGrow: 1, flexBasis: '100px' }}

          />

          <Column
            header={getTranslation(t, PositionNames.GRID_ACTION)}
            headerStyle={{ width: '4rem', textAlign: 'center' }}
            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
            body={actionBodyTemplate}
            style={{ flexGrow: 1, flexBasis: '100px' }}
          />
        </EvaluaTable>
      </div>
    </div>
  )
}