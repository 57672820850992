import * as yup from 'yup';

import { GenericsNames, PositionNames } from '../../../../translations';

import { regexDescription } from '../../../utils';

const PositionSchema = yup.object().shape({
  scode: yup // licenseId: DataTypes.UUID,
    .string('')
    .required(PositionNames.VALIDATOR_ID_REQUIRED)
    .matches(/^[0-9]+$/, PositionNames.VALIDATOR_DIGIT_ALLOW)
    .min(4, PositionNames.VALIDATOR_ID_REQUIRED_SIZE)
    .max(4, PositionNames.VALIDATOR_ID_REQUIRED_SIZE)
    .test(
      'greaterThanZero',
      PositionNames.VALIDATOR_ID_GREATER_THAN_ZERO,
      (value) => parseInt(value, 10) > 0
    ),
  name: yup
    .string('')
    .required('POSITION_VALIDATOR_NAME_REQUIRED')
    .required(PositionNames.VALIDATOR_NAME_REQUIRED)
    // .min(4, 'El código postal debe tener al menos 5 caracteres')
    .max(150, PositionNames.VALIDATOR_NAME_REQUIRED_SIZE)
    .matches(regexDescription, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS),
});
export default PositionSchema;
