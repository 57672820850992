import { CheckBoxComponent } from '../../../../Components/CheckBox'
import { Input } from '../../../../Components/Input/Input'
import React from 'react'
import { SearchInput } from '../../../../Components/SearchInput'
import { SelectComponent } from '../../../../Components/Select'
import { WorkCenterNames } from '../../../../translations'
import { areaCodeMask } from '../../../../utils/utilsService'
import { firstOrDefault } from '../../../../Auth/arrayService'
import { getTranslation } from '../../../../hooks/useTranslations'

const renderInput = (
  id,
  name,
  placeholder,
  onChange,
  value,
  onBlur,
  error,
  errorText,
  type,
  important,
  rows,
  cols
) => {
  return (
    <div className="flex w-1/2 p-2">
      <Input
        id={id}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        error={error}
        errorText={errorText}
        type={type}
        important={important}
        rows={rows}
        cols={cols}

      />
    </div>
  )
}
const renderInputState = (t, formik) => {
  return <div className="flex w-1/2 p-2">
    <Input
      id='state'
      name='state'
      placeholder={getTranslation(t, WorkCenterNames.GRID_COLUMN_STATE)}
      onChange={formik.handleChange}
      value={formik.values.state}
      onBlur={(event) => {
        formik.handleBlur(event)
        formik.setFieldValue('state', event.target.value.trim())
      }}
      error={formik.touched.state && Boolean(formik.errors.state)}
      errorText={formik.touched.state && getTranslation(t, formik.errors.state)}
      type='text'
      important={true}
      disabled={true}
    />
  </div>
}

const renderStateSelected = (t, formik, states, searchCity) => {
  return <SelectComponent
    className={'flex flex-col p-2 w-1/2'}
    valueSelected={formik.values.state}
    labelText={getTranslation(t, WorkCenterNames.GRID_COLUMN_STATE)}
    ValuesOptions={states}
    handleChange={(e) => {
      formik.setFieldValue('state', e.target.value)
      // search state id in states
      const id = states.find(x => x.value === e.target.value).id
      searchCity(id)
      formik.setFieldValue('suburb', '')
    }}
    placeholderText={'...'}
    name={'state'}
    optionLabel={'label'}
    error={Boolean(formik.touched.state && formik.errors.state)}
    errorText={formik.touched.state && getTranslation(t, formik.errors.state)}
    important
  />
}
export const renderWorkCenterInputs = (t, formik, evaluators) => {
  return (
    <>
      <div className="flex w-full flex-row">
        {renderInput(
          'scode',
          'scode',
          getTranslation(t, WorkCenterNames.GRID_COLUMN_KEY),
          formik.handleChange,
          formik.values.scode,
          (event) => {
            formik.handleBlur(event)
            const paddingZero = areaCodeMask(event.target.value)
            formik.setFieldValue('scode', paddingZero)
          },
          formik.touched.scode && Boolean(formik.errors.scode),
          formik.touched.scode && getTranslation(t, formik.errors.scode),
          'number',
          true
        )}
        {renderInput(
          'name',
          'name',
          getTranslation(t, WorkCenterNames.GRID_COLUMN_NAME),
          formik.handleChange,
          formik.values.name,
          (event) => {
            formik.handleBlur(event)
            formik.setFieldValue('name', event.target.value.trim())
          },
          formik.touched.name && Boolean(formik.errors.name),
          formik.touched.name && getTranslation(t, formik.errors.name),
          'text',
          true
        )}
      </div>
      <div className="flex w-full flex-row">
        <SelectComponent
          className={'flex flex-col p-2 w-1/2'}
          valueSelected={formik.values.evaluatorId}
          labelText={getTranslation(t, WorkCenterNames.GRID_COLUMN_EVALUATOR)}
          ValuesOptions={evaluators}
          handleChange={(e) => {
            formik.setFieldValue('evaluatorId', e.target.value)
          }}
          placeholderText={'...'}
          name={'evaluatorId'}
          optionLabel={'label'}
          error={Boolean(formik.touched.evaluatorId && formik.errors.evaluatorId)}
          errorText={formik.touched.evaluatorId && getTranslation(t, formik.errors.evaluatorId)}

        />
        {renderInput(
          'activities',
          'activities',
          getTranslation(t, WorkCenterNames.GRID_COLUMN_ACTIVITIES),
          (e) => {
            const value = e.target.value
            if (value.startsWith(' ')) {
              formik.setFieldValue('activities', value.trim())
            } else {
              formik.setFieldValue('activities', value)
            }
          },
          formik.values.activities,
          (event) => {
            formik.handleBlur(event)
            formik.setFieldValue('activities', event.target.value.trim())
          },
          formik.touched.activities && Boolean(formik.errors.activities),
          formik.touched.activities && getTranslation(t, formik.errors.activities),
          'text',
          false,
          2,
          4
        )}


      </div>
      <div className="flex w-full flex-row">

        {renderInput(
          'objective',
          'objective',
          getTranslation(t, WorkCenterNames.GRID_COLUMN_OBJECTIVE),
          (e) => {
            // delete with space if is in the start
            const value = e.target.value
            if (value.startsWith(' ')) {
              formik.setFieldValue('objective', e.target.value.trim())
            } else {
              formik.setFieldValue('objective', e.target.value)
            }
          },
          formik.values.objective,
          (event) => {
            formik.handleBlur(event)
            formik.setFieldValue('objective', event.target.value.trim())
          },
          formik.touched.objective && Boolean(formik.errors.objective),
          formik.touched.objective && getTranslation(t, formik.errors.objective),
          'textArea',
          false,
          2,
          4
        )}

        {renderInput(
          'principalActivity',
          'principalActivity',
          getTranslation(t, WorkCenterNames.GRID_COLUMN_PRINCIPAL_ACTIVITY),
          (event) => {
            const value = event.target.value
            if (value.startsWith(' ')) {
              formik.setFieldValue('principalActivity', event.target.value.trim())
            } else {
              formik.setFieldValue('principalActivity', event.target.value)
            }
          },
          formik.values.principalActivity,
          formik.handleBlur,
          formik.touched.principalActivity && Boolean(formik.errors.principalActivity),
          formik.touched.principalActivity && getTranslation(t, formik.errors.principalActivity),
          'textArea',
          false,
          2,
          4
        )}

      </div>
      <div className="flex flex-row w-full ">
        <div className='flex w-1/2 pl-2'>
          <CheckBoxComponent
            className="flex w-1/2 p-2 content-center items-center"
            value={formik.values.isActive}
            text={getTranslation(t, WorkCenterNames.GRID_COLUMN_ACTIVE)}
            handleChange={(e) => formik.setFieldValue('isActive', e.checked)}
            name={"isActive"}
          />
        </div>

      </div>
    </>
  )
}
const renderInformationRelatedToZipCode = (t, formik, suburbs, municipality, getSuburbs, states) => {
  return (
    <>

      <div className="flex w-full flex-row">
        <SelectComponent
          className={'flex flex-col p-2 w-1/2'}
          valueSelected={formik.values.municipality}
          labelText={getTranslation(t, WorkCenterNames.GRID_COLUMN_MUNICIPALITY)}
          ValuesOptions={formik.values.state !== '' ? municipality : []}
          handleChange={(e) => {
            formik.setFieldValue('municipality', e.target.value)
            const stateId = states.find(x => x.value === formik.values.state).id
            const municipalityId = municipality.find(x => x.value === e.target.value).id
            getSuburbs(municipalityId, stateId)

          }}
          placeholderText={'...'}
          name={'municipality'}
          optionLabel={'label'}
          error={Boolean(formik.touched.municipality && formik.errors.municipality)}
          errorText={formik.touched.municipality && getTranslation(t, formik.errors.municipality)}
          important
          disabled={formik.values.state === ''}
        />
        <SelectComponent
          className={'flex flex-col p-2 w-1/2'}
          valueSelected={formik.values.municipality !== '' ? formik.values.suburb : []}
          labelText={getTranslation(t, WorkCenterNames.GRID_COLUMN_SUBURB)}
          ValuesOptions={suburbs}
          handleChange={(e) => {
            const option = firstOrDefault('value', e.target.value, suburbs)
            formik.setFieldValue('suburb', option.label)
          }}
          placeholderText={'...'}
          name={'suburb'}
          optionLabel={'label'}
          error={Boolean(formik.touched.suburb && formik.errors.suburb)}
          errorText={formik.touched.suburb && getTranslation(t, formik.errors.suburb)}
          important
          disabled={formik.values.municipality === ''}
        />
      </div>
    </>
  )
}
const renderStates = (t, formik, suburbs, municipality) => {
  // disabled if formik.values.municipality is string empty or null
  let disabled = municipality === null || municipality === '' || municipality === undefined
  // if formik.values.municipality is in municipality, then disabled is false
  if (municipality) {
    disabled = municipality.find(x => x.value === formik.values.municipality) !== undefined
  }
  return (
    <>
      <div className="flex w-full flex-row">
        <div className="flex w-1/2 p-2">
          <Input
            id='municipality'
            name='municipality'
            placeholder={getTranslation(t, WorkCenterNames.GRID_COLUMN_MUNICIPALITY)}
            onChange={formik.handleChange}
            value={formik.values.municipality}
            onBlur={(event) => {
              formik.handleBlur(event)
              formik.setFieldValue('municipality', event.target.value.trim())
            }}
            error={formik.touched.municipality && Boolean(formik.errors.municipality)}
            errorText={formik.touched.municipality && getTranslation(t, formik.errors.municipality)}
            type='text'
            important={true}
            disabled={disabled}
          />
        </div>
        <SelectComponent
          className={'flex flex-col p-2 w-1/2'}
          valueSelected={formik.values.suburb}
          labelText={getTranslation(t, WorkCenterNames.GRID_COLUMN_SUBURB)}
          ValuesOptions={suburbs}
          handleChange={(e) => {
            const option = firstOrDefault('value', e.target.value, suburbs)
            formik.setFieldValue('suburb', option.label)
          }}
          placeholderText={'...'}
          name={'suburb'}
          optionLabel={'label'}
          error={Boolean(formik.touched.suburb && formik.errors.suburb)}
          errorText={formik.touched.suburb && getTranslation(t, formik.errors.suburb)}
          important
        />
      </div>
    </>

  )
}



export const renderAddressInputs = (t, formik, onZipSearch, setZipCode, states, suburbs, zipCodeFound, searchCity, municipality, getSuburbs, suburb) => {
  return (
    <>
      <div className="flex w-full flex-row">
        <div className='flex w-1/2 pl-2'>
          <SearchInput
            title={getTranslation(t, WorkCenterNames.GRID_COLUMN_ZIPCODE)}
            name='zipCode'
            key='zipCode'
            onClickSearch={(e) => {
              // only numbers and 5 digits
              formik.handleBlur(e)
              onZipSearch(formik.values.zipCode)
            }}
            value={formik.values.zipCode}
            onChange={(e) => {
              //pars to number
              const value = e.target.value.replace(/[^0-9]/g, '')
              // value is a number
              // if is empty string
              if ((Number(value) && value.length <= 5 && value !== '') || value.length === 0) {
                formik.handleChange(e)
              }
            }
            }
            onBlur={(e) => {
              formik.handleBlur(e)
              setZipCode(formik.values.zipCode)
            }}
            error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
            errorText={formik.touched.zipCode && getTranslation(t, formik.errors.zipCode)}
            type='number'
            important
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === 'Tab') {
                formik.handleBlur(e)
                onZipSearch(formik.values.zipCode)
              }
            }}
          />
        </div>
        {zipCodeFound ? renderInputState(t, formik) : renderStateSelected(t, formik, states, searchCity)}
      </div>
      {!zipCodeFound ? renderInformationRelatedToZipCode(t, formik, suburb, municipality, getSuburbs, states) : renderStates(t, formik, suburbs, municipality)}
      <div className="flex w-full flex-row">
        {renderInput(
          'street',
          'street',
          getTranslation(t, WorkCenterNames.GRID_COLUMN_STREET),
          formik.handleChange,
          formik.values.street,
          (event) => {
            formik.handleBlur(event)
            formik.setFieldValue('street', event.target.value.trim())
          },
          formik.touched.street && Boolean(formik.errors.street),
          formik.touched.street && getTranslation(t, formik.errors.street),
          'text',
          true
        )}
        {renderInput(
          'exteriorNumber',
          'exteriorNumber',
          getTranslation(t, WorkCenterNames.GRID_COLUMN_EXTERIOR),
          formik.handleChange,
          formik.values.exteriorNumber,
          (event) => {
            formik.handleBlur(event)
            formik.setFieldValue('exteriorNumber', event.target.value.trim())
          },
          formik.touched.exteriorNumber && Boolean(formik.errors.exteriorNumber),
          formik.touched.exteriorNumber && getTranslation(t, formik.errors.exteriorNumber),
          'text',
          true
        )}


      </div>

      <div className="flex w-full flex-row">
        {renderInput(
          'interiorNumber',
          'interiorNumber',
          getTranslation(t, WorkCenterNames.GRID_COLUMN_INTERIOR),
          formik.handleChange,
          formik.values.interiorNumber,
          (event) => {
            formik.handleBlur(event)
            formik.setFieldValue('interiorNumber', event.target.value.trim())
          },
          formik.touched.interiorNumber && Boolean(formik.errors.interiorNumber),
          formik.touched.interiorNumber && getTranslation(t, formik.errors.interiorNumber),
          'text',
          false
        )}
      </div>
    </>
  )
}