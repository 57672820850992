import { sortBy } from 'lodash-es';

const getSuburbs = (res) => {
  return sortBy(res.data.colonias, [
    function (o) {
      return o.id;
    },
  ]).map((item) => ({ label: item.name, value: item.name }));
};
// eslint-disable-next-line import/prefer-default-export
export const getZipCodes = async (zipCode) => {
  try {
    const baseUrl = `${window.REACT_APP_EVALUA_API_URI}/cp`
    const response = await fetch(
      `${baseUrl}/${zipCode}`,
      {
        method: 'GET',
      }
    );
    const result = await response.json();

    if (result.status === "success" && result.data.estados && result.data.estados.length > 0) {
      return { municipality: result.data.ciudades[0].name, state: result.data.estados[0].name, suburbs: getSuburbs(result) };
    } else {
      return undefined;
    }
  } catch (error) {
    console.log(error);
    return undefined;
  }
};
