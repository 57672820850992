import { GET_POSITION, GET_POSITION_ERROR, GET_POSITION_SUCCESS } from '../../business/constants'
import { useContext, useEffect, useState } from 'react'

import { IsNullOrEmpty } from '../../utils/utilsService'
import { StoreContext } from '../../business/Provider'
import { sortBy } from 'lodash'
import useApi from '../api'
import { getTranslation, useCustomTranslation } from '../useTranslations'
import { PositionNames } from '../../translations'
import { Toast } from '../../Components/toast'
import { LoadingContext } from '../../context/LoadingContext'

const usePosition = () => {
  const baseUrl = `${window.REACT_APP_EVALUA_API_URI}/Position`
  const {t} = useCustomTranslation()
  const {setLoading} = useContext(LoadingContext);
  const [positions, setPositions] = useState(undefined)
  const [positionsOptions, setPositionsOptions] = useState(undefined)
  const [loadingPositions, setloadingPositions] = useState(false)
  const context = useContext(StoreContext);
  const { genericApiCall } = useApi();
  const getPositionsOptions = (positionResponse) => {
    const activePosition = positionResponse.filter((object) => {
      return IsNullOrEmpty(object.deleteBy)
    })
    return sortBy(activePosition, [
      function (o) {
        return o.name
      }
    ]).map((item) => ({ label: item.name, value: item.id }))

  }

  const getPositionList = async () => {
    try {
      setloadingPositions(true)
      const response = await getPositions()
      //console.log('data Position->', response.data)
      setPositions(response.data.data.positions)
      setPositionsOptions(getPositionsOptions(response.data.data.positions))
      setloadingPositions(false)
    } catch (error) {
      //console.log('error Position->', error)    
      Toast('warning',error.message, "", error)        
      setPositions([])
      setloadingPositions(false)
    }
  }

  const postPosition = async (Position) => {
    try{
      const result = await genericApiCall(baseUrl, 'POST', Position)
      if (result.status === 200) {
        Toast('success', getTranslation(t, PositionNames.SUCCESS_ADD))
        return result.data.data.position
      } else {
        let message = {
          body: '',
          header: getTranslation(t, PositionNames.ERROR_ADD)
        }
        if(result.message) message.body = result.message
        context.dispatchPosition({ type: GET_POSITION_ERROR, payload: message })
        return undefined
      }
    } catch (error) {
      Toast('warning',error.message, "", error)
      context.dispatchPosition({ type: GET_POSITION_ERROR})
    }   
  }

  const getPositions = async () => {
    try{
      context.dispatchPosition({ type: GET_POSITION })
      const result = await genericApiCall(baseUrl, 'GET')
      if (result.status === 200) {
        context.dispatchPosition({ type: GET_POSITION_SUCCESS, payload: result.data.data.positions })
      } else {
        let message = {
          body: '',
          header: 'Error'
        }
        if (result.message) message.body = result.message
        else message = result.message  
        context.dispatchPosition({ type: GET_POSITION_ERROR, payload: message })
      }
      return result
    } catch (error) {
      Toast('warning',error.message, "", error)
      context.dispatchPosition({ type: GET_POSITION_ERROR})
    }      
  }
  const putPosition = async (Position) => {
    try{
      const result = await genericApiCall(baseUrl, 'PUT', Position)
      if (result.status === 200) {
        Toast('success', getTranslation(t, PositionNames.SUCCESS_UPDATE))
        return result.data.data.position
      } else {
        let message = {
          body: '',
          header: getTranslation(t, PositionNames.ERROR_UPDATE)
        }
        if(result.message) message.body = result.message
        context.dispatchPosition({ type: GET_POSITION_ERROR, payload: message })
        return undefined
      }
    } catch (error) {
      Toast('warning',error.message, "", error)
      context.dispatchPosition({ type: GET_POSITION_ERROR})
    }      
  }

  const deletePosition = async (Position) => {
    try{
      const result = await genericApiCall(baseUrl, 'DELETE', Position)
      if (result.status === 200) {
        Toast('success', getTranslation(t, PositionNames.SUCCESS_DELETE))
        return result.data.data
      } else {
        let message = {
          body: '',
          header: getTranslation(t, PositionNames.ERROR_DELETE)
        }
        if(result.message) message.body = result.message
        context.dispatchPosition({ type: GET_POSITION_ERROR, payload: message })
        return undefined
      }
    } catch (error) {
      Toast('warning',error.message, "", error)
      context.dispatchPosition({ type: GET_POSITION_ERROR})
    }      
  }

  const getNextCode = async () => {
    try{
      setLoading(true)
      const result = await genericApiCall(`${baseUrl}/next-code`, 'get')
      setLoading(false)
      return  result
    } catch (error) {
      setLoading(false)
      Toast('warning',error.message, "", error)
    }      
  }

  useEffect(() => {
    if (!context.positionState?.positions && context.positionState.loading !== true && context.positionState.error === null) {
      getPositions()
    }
  }, [context.positionState])

  return {
    setPositions,
    positions,
    loadingPositions,
    postPosition,
    putPosition,
    deletePosition,
    getNextCode,
    getPositions,
    getPositionList,
    positionsOptions
  }
}
export default usePosition