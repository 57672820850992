import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import PropTypes from 'prop-types'

export const StepsMenu = ({
    onClose,
    open,
    formik,
    title,
    children,
    textSave,
    textCancel,
    maxWidth,
    steps,
    minHeight,
    activeStep,
    setActiveStep,
    ...other
}) => {
    const stepElement = (step, index) => {
        return(
            <div key={index} className={`p-[16px] pl-[15px] font-font-family-base  flex pr-[15px] items-center text-[11px] justify-between ${index === activeStep ? 'text-[#586680] bg-[#dfe9f2e6]' : 'text-[#4353748f]'}`}>
                  <p className={`truncate ${index === activeStep ? 'font-bold' : 'font-normal'}`}>{step.title}</p>  
                    <FontAwesomeIcon className='text-[15px] ' icon={faCaretRight} />
            </div>
        )
    }
    return (
        <div className='h-full w-full bg-[#f4f7fc] shadow-sideMenu-box-shadow'>
            {steps.map((step, index) => {
                return stepElement(step, index)
            })}
        </div>
    )
}

StepsMenu.defaultProps = {
    maxWidth: 'lg',
    minHeight: '500px',
    steps: [],
    activeStep: 0,
    setActiveStep: () => {},
    textSave: 'Save',
    textCancel: 'Cancel',
    onClose: () => {},
    open: false,
    formik: {},
    title: '',
    children: () => {},
}
StepsMenu.propTypes = {
    maxWidth: PropTypes.string,
    minHeight: PropTypes.string,
    steps: PropTypes.array,
    activeStep: PropTypes.number,
    setActiveStep: PropTypes.func,
    textSave: PropTypes.string,
    textCancel: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    formik: PropTypes.object,
    title: PropTypes.string,
    children: PropTypes.func,
}
